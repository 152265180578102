import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { useDebounce } from 'use-debounce';

import {
  SLOW_ANIMATION,
  FAST_ANIMATION,
  WIDTHS,
  FAST_ANIMATION_SPEED,
  INITIAL_DELAY,
  CLASS_NAMES,
  DEBOUNCE_INTERVAL,
} from './Loader-constants';

const ContainerComponent = ({ children, isLoading }) => {
  const loaded = useRef(false);

  const [animate, setAnimate] = useState(SLOW_ANIMATION);
  const [width, setWidth] = useState(WIDTHS.START);

  const [debouncedLoading] = useDebounce(isLoading, DEBOUNCE_INTERVAL);

  /**
   * Creates css animations when isLoading is true
   * and resets the state after it's completed
   */
  useEffect(() => {
    if (debouncedLoading) {
      // starts the fake animation to given percentage
      setTimeout(() => {
        loaded.current = true;
        setAnimate(SLOW_ANIMATION);
        setWidth(WIDTHS.LOADING);
      }, INITIAL_DELAY);
      return;
    }

    if (loaded.current) {
      // starts a fast animation to 100%
      setAnimate(FAST_ANIMATION);
      setWidth(WIDTHS.COMPLETE);
      loaded.current = false;
      setTimeout(() => {
        // resets the state to initial values after animation is complete
        setAnimate('');
        setWidth(WIDTHS.START);
      }, FAST_ANIMATION_SPEED);
      return;
    }

    // resets the state to initial values
    setAnimate('');
    setWidth(WIDTHS.START);
  }, [debouncedLoading]);

  const { baseBar, ...classNames } = CLASS_NAMES;

  const bar = clsx(baseBar, animate);

  /*
   * Uses portal to move modal "modals" div on root so it would not have problems with z-index
   */
  return ReactDOM.createPortal(
    children({
      width,
      classNames: {
        ...classNames,
        bar,
      },
    }),
    document.getElementById('modals')
  );
};

ContainerComponent.displayName = 'Loader-container';

export default ContainerComponent;
