export const CLASS_NAMES = {
  container: 'flex flex-row',
  logoContainer: 'flex rounded-lg h-8 min-h-8 w-8 min-w-8 bg-lavender-100 mr-3',
  text: 'text-style-g-em my-auto text-neutral-700',
  logo: 'w-8 min-w-8 h-8 min-h-8 rounded-lg object-contain mr-3',
};

export const SKELETONS = {
  avatar: {
    width: 32,
    height: 32,
    circle: true,
    className: 'leading-unset mr-3',
  },
  title: {
    width: 100,
  },
};

export const ICON_PROPS = {
  size: 18,
  icon: 'toolbox',
  stroke: 'lavender-200',
  className: 'm-auto',
};
