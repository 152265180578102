import React from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';

import { CLASS_NAMES, SKELETONS } from './CompanyInfo-constants';
import { Popover } from './components';

import Icon from '../../../Icon';
import SkeletonLoader from '../../../SkeletonLoader';
import { DEFAULT_AVATAR_ICON_PROPS } from '../../../../constants';

const ViewComponent = ({
  companyName,
  companyProfilePicture,
  isLoading,
  ...restProps
}) => (
  <div className={CLASS_NAMES.container}>
    <div className={CLASS_NAMES.content}>
      <SkeletonLoader
        isLoading={isLoading}
        {...SKELETONS.avatar}
        afterLoading={
          <Img
            key={companyProfilePicture}
            alt={companyName}
            className={CLASS_NAMES.companyProfilePicture}
            src={companyProfilePicture}
            loader={<SkeletonLoader isLoading {...SKELETONS.avatar} />}
            unloader={
              <div className={CLASS_NAMES.defaultAvatar}>
                <Icon {...DEFAULT_AVATAR_ICON_PROPS} />
              </div>
            }
          />
        }
      />
      {(isLoading || companyName) && (
        <p className={CLASS_NAMES.companyName}>
          <SkeletonLoader
            isLoading={isLoading}
            afterLoading={companyName}
            {...SKELETONS.title}
          />
        </p>
      )}
    </div>
    <Popover {...restProps} />
  </div>
);

ViewComponent.displayName = 'CompanyInfo-view';

ViewComponent.defaultProps = {
  companyName: undefined,
  companyProfilePicture: undefined,
  isLoading: undefined,
  logout: undefined,
};

ViewComponent.propTypes = {
  /**
   * Company name
   */
  companyName: PropTypes.string,
  /**
   * Company profile picture url
   */
  companyProfilePicture: PropTypes.string,
  /**
   * isLoading
   */
  isLoading: PropTypes.bool,
  /**
   * CompanyLinkProps
   */
  companyItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      target: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired
  ).isRequired,
  /**
   * Function for logging out
   */
  logout: PropTypes.func,
};

export default ViewComponent;
