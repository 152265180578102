import React from 'react';

import Container from './Desktop-container';

import { Title, Description, Quantity, Price, Vat, Total } from '../components';

const ViewComponent = () => (
  <Container>
    {({ classNames, smaller }) => (
      <div className={classNames.desktop.container}>
        {!smaller && <Quantity />}
        <div className={classNames.desktop.titleWrapper}>
          <div className="flex flex-row flex-1">
            {smaller && <Quantity />}
            <Title />
            {smaller && <Total />}
          </div>
          <Description />
        </div>
        <div>
          <div className={classNames.desktop.values}>
            {!smaller && (
              <React.Fragment>
                <Price />
                <Vat />
              </React.Fragment>
            )}
            {!smaller && <Total />}
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Desktop-view';

export default ViewComponent;
