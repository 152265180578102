export const CLASS_NAMES = {
  container: 'p-2 min-w-54 flex flex-col',
  heading: {
    container: 'w-full px-3 py-2',
    text: 'text-style-g-em text-neutral-700',
  },
};

export const HEADING_TEXT = 'Columns';

export const DROP_ID = 'settings';
