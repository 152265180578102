import clsx from 'clsx';
import { useCallback, useState } from 'react';

import { useFormTheme } from '../../FormTheme';

const ViewComponent = ({ children, error }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword, setShowPassword]);

  const type = showPassword ? 'text' : 'password';
  const {
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses,
    skeletonClasses,
  } = useFormTheme();

  const className = clsx(
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses(error),
    'pr-12'
  );
  const buttonClassName =
    'absolute right-0 stroke-current text-neutral-500 mt-3.5 mr-4';

  return children({
    className,
    skeletonClasses,
    buttonClassName,
    type,
    showPassword,
    toggleShowPassword,
  });
};

ViewComponent.displayName = 'UncontrolledPasswordInput-container';

export default ViewComponent;
