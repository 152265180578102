import React from 'react';
import PropTypes from 'prop-types';

import Container from './Input-container';

import Icon from '../../../Icon';
import SkeletonLoader from '../../../SkeletonLoader';

const ViewComponent = ({
  forwardRef,
  error,
  icon,
  className,
  isLoading,
  iconColor,
  iconVariant,
  italic,
  ...restProps
}) => (
  <Container
    error={error}
    icon={icon}
    className={className}
    iconColor={iconColor}
    iconVariant={iconVariant}
    italic={italic}
  >
    {({ classNames, iconProps }) => (
      <div className={classNames.container}>
        <SkeletonLoader
          wrapperClassName={classNames.seleton}
          isLoading={isLoading}
          afterLoading={
            <React.Fragment>
              {icon && (
                <div className={classNames.iconContaner}>
                  <Icon {...iconProps} icon={icon} />
                </div>
              )}
              <input
                className={classNames.input}
                ref={forwardRef}
                {...restProps}
              />
            </React.Fragment>
          }
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'UncontrolledInput-view';

ViewComponent.defaultProps = {
  forwardRef: undefined,
  id: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  icon: undefined,
  className: undefined,
  isLoading: undefined,
  iconVariant: 'default',
  iconColor: 'text-neutral-500',
  italic: false,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * ForwardRef
   */
  forwardRef: PropTypes.any,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * icon name
   */
  icon: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * icon string
   */
  iconColor: PropTypes.string,
  /**
   * icon varinat
   */
  iconVariant: PropTypes.oneOf(['default', 'dark', 'search']),
  /**
   * italic
   */
  italic: PropTypes.bool,
};

export default ViewComponent;
