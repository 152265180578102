import React from 'react';
import PropTypes from 'prop-types';

import Container from './SlideLeft-container';

const ViewComponent = ({ children, ...rest }) => (
  <Container {...rest}>
    {({ shouldRender, onAnimationEnd, className }) =>
      shouldRender && (
        <div className={className} onAnimationEnd={onAnimationEnd}>
          {children}
        </div>
      )
    }
  </Container>
);

ViewComponent.displayName = 'SlideLeft-view';

ViewComponent.defaultProps = {
  children: null,
  extClassName: null,
  onEnd: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Title of card
   */
  show: PropTypes.bool.isRequired,
  /**
   * ClassName
   */
  extClassName: PropTypes.string,
  /**
   * External on end animation function
   */
  onEnd: PropTypes.func,
};

export default ViewComponent;
