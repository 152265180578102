import Container from './ModalContainer';
import AnimatedContainer from './AnimatedModalContainer';
import Layout from './ModalLayout';

const Modal = {
  Container,
  Layout,
  AnimatedContainer,
};

export default Modal;
