import { CompanyInformationService, useLoadData } from '@kanda-api/library';
import { useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import { DEBOUNCE_INTERVAL, SKELETON_DATA } from './SearchResults-constants';
import { formatData } from './SearchResults-functions';

/**
 * useSearch
 * @param {String} query
 */
const useSearch = (query = '') => {
  const [debouncedQuery] = useDebounce(query, DEBOUNCE_INTERVAL);

  const { data, isValidating } = useLoadData(
    debouncedQuery && [CompanyInformationService.key.search, debouncedQuery],
    { shouldRetryOnError: false }
  );

  const isLoading = !data;

  const formattedData = useMemo(() => (data ? formatData(data) : []), [data]);

  const results = isValidating ? SKELETON_DATA : formattedData;

  const searchWords = useMemo(() => query.split(' '), [query]);

  return {
    results,
    isLoading,
    searchWords,
  };
};

export default useSearch;
