/**
 * Function to extract the name of the selected value from the options array
 * @param {Array.<Object>} options - Options array for select
 * @param {String} optionsObject.name - Name of selection
 * @param {String} optionsObject.value - Value of selection
 * @param {String} value - value
 * @returns boolean
 */
export const extractName = (options, value) => {
  const index = options.findIndex((option) => `${option.value}` === `${value}`);

  // If index is -1, string isn't available, return 'Select...'
  if (index === -1) return 'Select...';
  // Return the name of the selected value
  return options[index].name;
};
