import React from 'react';
import PropTypes from 'prop-types';

import Container from './Cell-container';

import SkeletonLoader from '../../../../../SkeletonLoader';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({
      cellProps,
      classNames,
      value,
      render,
      renderComponent: RenderComponent,
      renderProps,
      isLoading,
    }) => (
      <div {...cellProps} className={classNames.cell}>
        <div className={classNames.container}>
          <div className={classNames.render}>
            <SkeletonLoader
              isLoading={isLoading}
              afterLoading={
                render ? <RenderComponent {...renderProps} /> : value
              }
            />
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * Label for button
   */
  cell: PropTypes.object.isRequired,
};

ViewComponent.displayName = 'Cell-view';

export default ViewComponent;
