export const QUOTE_STATUS = {
  DRAFT: -20, // Used in the quote builder
  CANCELLED: -2, // Tradesperson has cancelled the quote
  DECLINED: -1, // Homeowner has declined the quote
  PENDING: 0, // Default Status
  FINANCE_PENDING: 1, // Finance is pending
  FINANCE_REJECTED: 2, // Finance is rejected
  FINANCE_ACCEPTED: 3, // Finance is accepted
  DEPOSIT_DUE: 5, //
  PAYMENT_DUE: 10, // Tradesperson has requested payment
  ONGOING: 15, // Job is ongoing
  AWAITING_PAYMENT: 20, // Tradesperson has requested payment
  MARKED_PAID: 22, // Customer has marked a COS quote as paid
  IN_DISPUTE: 25, // Homeowner/Tradesperson has raised a dispute
  PAID: 30, // Homeowner has released payments
};

export const OPEN_QUOTE_STATUSES = [
  QUOTE_STATUS.PENDING,
  QUOTE_STATUS.ONGOING,
  QUOTE_STATUS.AWAITING_PAYMENT,
  QUOTE_STATUS.IN_DISPUTE,
];

export const PAID_QUOTE_STATUSES = [
  QUOTE_STATUS.MARKED_PAID,
  QUOTE_STATUS.PAID,
];

export const QUOTE_LABELS = {
  DRAFT: {
    label: 'draft',
    color: 'grey',
  },
  CANCELLED: {
    label: 'cancelled',
    color: 'violet',
  },
  DECLINED: {
    label: 'declined',
    color: 'violet',
  },
  PENDING: {
    label: 'sent to customer',
    color: 'lavender',
  },
  FINANCE_PENDING: {
    label: 'applied for finance',
    color: 'blue',
  },
  FINANCE_REJECTED: {
    label: 'rejected for finance',
    color: 'violet',
  },
  FINANCE_ACCEPTED: {
    label: 'accepted with finance',
    color: 'turquoise',
  },
  DEPOSIT_DUE: {
    label: 'deposit due',
    color: 'blue',
  },
  PAYMENT_DUE: {
    label: 'payment due',
    color: 'blue',
  },
  ONGOING: {
    label: 'ongoing',
    color: 'turquoise',
  },
  AWAITING_PAYMENT: {
    label: 'awaiting payment',
    color: 'blue',
  },
  MARKED_PAID: {
    label: 'marked paid',
    color: 'turquoise',
  },
  IN_DISPUTE: {
    label: 'in dispute',
    color: 'violet',
  },
  PAID: {
    label: 'paid',
    color: 'turquoise',
  },
};

export const QUOTE_ACTION_LABELS = {
  DRAFT: 'Created',
  CANCELLED: 'Cancelled',
  DECLINED: 'Declined',
  PENDING: 'Sent',
  FINANCE_PENDING: 'Applied',
  FINANCE_REJECTED: 'Rejected',
  FINANCE_ACCEPTED: 'Accepted',
  DEPOSIT_DUE: 'Deposit requested',
  PAYMENT_DUE: 'Sent',
  ONGOING: 'Accepted',
  AWAITING_PAYMENT: 'Sent',
  MARKED_PAID: 'Paid',
  IN_DISPUTE: 'Disputed',
  PAID: 'Paid',
};
