import { useState, cloneElement, useCallback, useEffect } from 'react';

import { CLASS_NAMES } from './Popover-constants';
import useOuterClick from './Popover-useOuterClick';
import useEscClick from './Popover-useEscClick';

import { useClasses } from '../../../hooks';

const ContainerComponent = ({
  children,
  button,
  above,
  right,
  visible,
  className,
}) => {
  const [showPopover, _setShowPopover] = useState(visible);

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    _setShowPopover(!showPopover);
  };

  const hidePopover = () => _setShowPopover(false);

  /**
   * Handles close logic
   */
  const handleClose = useCallback(() => _setShowPopover(false), []);

  /*
   * Uses hook to call close when the click is outside of modal
   */
  const ref = useOuterClick(hidePopover);
  /*
   * Uses hook to call close when the click is outside of modal
   */
  useEscClick(hidePopover);

  const newButton =
    button && cloneElement(button, { onClick: (e) => onClick(e) });

  const classNames = useClasses(CLASS_NAMES, {
    container: ['relative', className],
    wrapper: [
      '.baseWrapper',
      above ? 'bottom-full mb-1.5' : 'top-full mt-1.5',
      right ? 'right-0' : 'left-0',
    ],
  });

  /**
   * Update state when prop changes
   */
  useEffect(() => {
    _setShowPopover(visible);
  }, [visible]);

  return children({
    handleClose,
    classNames,
    showPopover,
    onClick,
    button: newButton,
    ref,
  });
};

ContainerComponent.displayName = 'ModalsWrapper-container';

export default ContainerComponent;
