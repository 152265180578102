import { useMemo, useCallback } from 'react';
import {
  checkAndFormatTimestamp,
  formatDate,
  formatTime,
} from '@kanda-utils/library';
import clsx from 'clsx';

import { CLASS_NAMES, VARIANTS } from './DateTime-constants';

const ContainerComponent = ({
  children,
  timestamp,
  className,
  skeletonClassName,
  variant,
  seconds,
}) => {
  // Construct classNames
  const classNames = {
    text: clsx(className, CLASS_NAMES.text),
    skeleton: clsx(skeletonClassName, CLASS_NAMES.skeleton),
  };

  const dateObject = useMemo(
    () => checkAndFormatTimestamp(timestamp),
    [timestamp]
  );

  const date = useMemo(
    () => formatDate(dateObject, seconds),
    [dateObject, seconds]
  );
  const time = useMemo(
    () => formatTime(dateObject, seconds),
    [dateObject, seconds]
  );

  const dateTime = useCallback(
    (formattedDate, formattedTime) => {
      if (variant === VARIANTS.time) return formattedTime;
      if (variant === VARIANTS.date) return formattedDate;
      return `${formattedDate}, ${formattedTime}`;
    },
    [variant]
  );

  return children({
    dateTime: dateTime(date, time),
    classNames,
  });
};

ContainerComponent.displayName = 'DateTime-Container';

export default ContainerComponent;
