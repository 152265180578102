import React from 'react';
import PropTypes from 'prop-types';

import Container from './Tag-container';

const ViewComponent = ({ label, children, ...props }) => (
  <Container {...props}>
    {({ className }) => (
      <span className={className}>
        {label}
        {children}
      </span>
    )}
  </Container>
);

ViewComponent.displayName = 'Tag-view';

ViewComponent.defaultProps = {
  label: null,
  children: null,
  className: undefined,
  variant: 'outline',
  color: 'grey',
  size: 24,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Color
   */
  color: PropTypes.oneOf(['grey', 'violet', 'lavender', 'turquoise', 'blue']),
  /**
   * Size
   */
  size: PropTypes.oneOf([31, 28, 24, 21]),
  /**
   * Tag variant name
   */
  variant: PropTypes.oneOf(['solid', 'outline']),
};

export default ViewComponent;
