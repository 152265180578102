import React from 'react';
import PropTypes from 'prop-types';

import FieldContainer from '../../FieldContainer';
import JobPdfFileInput from '../../ComponentField/JobPdfFileInput';

const ViewComponent = ({ label, ...props }) => (
  <FieldContainer {...props}>
    {(fieldProps) => (
      <JobPdfFileInput {...fieldProps} label={label} hasLabel={!!label} />
    )}
  </FieldContainer>
);

ViewComponent.displayName = 'JobPdfFileInput-view';

ViewComponent.defaultProps = {
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  accept: undefined,
  maxFiles: 1,
  error: undefined,
  isLoading: undefined,
  small: false,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * array of MIME types that fileInput accepts
   */
  accept: PropTypes.arrayOf(PropTypes.string),
  /**
   * maximum number of files
   */
  maxFiles: PropTypes.number,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Whether to display the small variant
   */
  small: PropTypes.bool,
};

export default ViewComponent;
