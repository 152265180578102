export const CLASS_NAMES = {
  base: 'flex rounded-full w-8 h-8 border-2 border-neutral-200 bg-neutral-000',
  opacity: 'opacity-50',
  margin: {
    left: 'ml-4',
    right: 'mr-4',
  },
};

export const ICONS = {
  next: 'chevron-right',
  prev: 'chevron-left',
};

export const ICON_PROPS = {
  size: 16,
  stroke: 'neutral-500',
  className: 'm-auto',
};
