import React from 'react';
import PropTypes from 'prop-types';

import Container from './MultiStepForm-container';
import { Router, Footer, Indicator } from './components';
import Context, { Provider } from './Context';

const ViewComponent = ({ children, ...restProps }) => (
  <Container {...restProps}>
    {({ ...values }) => <Provider {...values}>{children}</Provider>}
  </Container>
);

ViewComponent.displayName = 'MultiStepForm-view';

ViewComponent.Context = Context;

ViewComponent.Footer = Footer;

ViewComponent.Router = Router;

ViewComponent.Indicator = Indicator;

ViewComponent.defaultProps = {
  initialStepIndex: 0,
  initialStepSafeIndex: undefined,
  initialData: undefined,
  steps: undefined,
  onSubmit: undefined,
  onStepChange: undefined,
  children: null,
  addStepsToUrl: true,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Initial data of parent form
   */
  initialData: PropTypes.any,
  /**
   * Steps as array
   */
  steps: PropTypes.array,
  /**
   * Redirect
   */
  onStepChange: PropTypes.func,
  /**
   * First step to display starting from 0
   */
  initialStepIndex: PropTypes.number,
  /**
   * Maximum safe step index
   */
  initialStepSafeIndex: PropTypes.number,
  /**
   * callback when the the form is submitted
   */
  onSubmit: PropTypes.func,
  /**
   * add steps to url
   */
  addStepsToUrl: PropTypes.bool,
};

export default ViewComponent;
