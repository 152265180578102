import { CLASS_NAMES, SKELETONS } from './Switch-constants';

const ContainerComponent = ({ children, small }) => {
  const classNames = small ? CLASS_NAMES.small : CLASS_NAMES.default;

  const skeletons = small ? SKELETONS.small : SKELETONS.default;

  return children({ classNames, skeletons });
};

ContainerComponent.displayName = 'Switch-Container';

export default ContainerComponent;
