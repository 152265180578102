import React from 'react';
import PropTypes from 'prop-types';

import Container from './ButtonLink-container';

import BaseButton from '../BaseButton';

const ViewComponent = ({
  className,
  variant,
  left,
  size,
  iconSpacing,
  ...restProps
}) => (
  <Container
    className={className}
    variant={variant}
    left={left}
    size={size}
    iconSpacing={iconSpacing}
    {...restProps}
  >
    {(variantProps) => <BaseButton {...restProps} {...variantProps} />}
  </Container>
);

ViewComponent.displayName = 'Button.Link';

ViewComponent.defaultProps = {
  variant: 'turquoise',
  iconSpacing: undefined,
  label: undefined,
  size: 16,
  children: null,
  icon: undefined,
  iconProps: {},
  className: undefined,
  submit: undefined,
  left: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Icon name
   */
  icon: PropTypes.string,
  /**
   * Icon props
   */
  iconProps: PropTypes.shape({
    className: PropTypes.string,
    stroke: PropTypes.string,
    flip: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.number,
    fill: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  /**
   * Button variant name
   */
  variant: PropTypes.oneOf(['turquoise', 'grey', 'light-grey', 'custom']),
  /**
   * sets button type to submit insted of button
   */
  submit: PropTypes.bool,
  /**
   * changes position of the icon to left from right
   */
  left: PropTypes.bool,
  /**
   * Button size
   */
  size: PropTypes.oneOf(['custom', 16, 14, 12]),
  /**
   * Button label
   */
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Icon spacing
   */
  iconSpacing: PropTypes.number,
};

export default ViewComponent;
