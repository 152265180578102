import React from 'react';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';

import { CLASS_NAMES, BUTTONS } from './CropperTopOptions-constants';

import Button from '../../../../../../Button';

const ViewComponent = ({
  handleRotateClockwise,
  handleRotateCounterClockwise,
}) => (
  <div className={CLASS_NAMES.container}>
    <Button.Icon
      onClick={handleRotateCounterClockwise}
      {...BUTTONS.rotateCounterClockwise}
    />
    <Button.Icon onClick={handleRotateClockwise} {...BUTTONS.rotateClockwise} />
  </div>
);

ViewComponent.displayName = 'CropperTopOptions-view';

ViewComponent.propTypes = {
  /**
   * Handle Rotate clockwise function
   */
  handleRotateClockwise: PropTypes.func.isRequired,
  /**
   * Handle Rotate counter clockwise function
   */
  handleRotateCounterClockwise: PropTypes.func.isRequired,
};

export default ViewComponent;
