import appendQuery from 'append-query';
/**
 * Checks if the page is in iframe
 */
const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Redirect to the given url
 * @param {string} url url of the new page
 */
export const redirectTo = (url, params) => {
  if (isInIframe()) {
    window.top.location.href = appendQuery(url, params, {
      encodeComponents: false,
    });
  } else {
    window.location.href = appendQuery(url, params, {
      encodeComponents: false,
    });
  }
};

/**
 * Get current url
 * @returns current url
 */
export const currentURL = () => {
  if (isInIframe()) {
    return window.top.location.href;
  }
  return window.location.href;
};
