import React from 'react';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';

import { ICON_PROPS, TITLE } from './CropperHeader-constants';

import Button from '../../../../../../Button';
import Header from '../../../../../../Header';

const ViewComponent = ({ onCancelCrop }) => (
  <Header.Base
    options={[
      <Button.Icon key="close" {...ICON_PROPS} onClick={onCancelCrop} />,
    ]}
  >
    {TITLE}
  </Header.Base>
);

ViewComponent.displayName = 'CropperHeader-view';

ViewComponent.propTypes = {
  /**
   * OnCancelCrop callback
   */
  onCancelCrop: PropTypes.func.isRequired,
};

export default ViewComponent;
