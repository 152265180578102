import { useCallback, useEffect, useMemo, useState } from 'react';
import { getTrackBackground } from 'react-range';

import { normalizeValue } from './RangeSlider-functions';

const ContainerComponent = ({
  children,
  value,
  defaultValue,
  onChange,
  colors,
  min,
  max,
}) => {
  const initialValue = value || defaultValue;

  const isArray = Array.isArray(initialValue);

  const [values, setValues] = useState(normalizeValue(initialValue));

  /**
   * Handles change event
   * @param {Array} newValues new values
   */
  const handleChange = useCallback(
    (newValues) => {
      if (onChange) {
        onChange(isArray ? newValues : newValues[0]);
      }

      setValues(newValues);
    },
    [onChange, isArray]
  );

  /**
   * Handles value change
   */
  useEffect(() => {
    setValues(normalizeValue(value));
  }, [value]);

  /**
   * Generates track styles
   */
  const trackStyle = useMemo(
    () => ({
      background: getTrackBackground({
        values,
        colors,
        min,
        max,
      }),
    }),
    [values, min, max, colors]
  );

  return children({
    values,
    handleChange,
    trackStyle,
  });
};

ContainerComponent.displayName = 'RangeSlider-container';

export default ContainerComponent;
