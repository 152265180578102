import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Container from './HeaderColumn-container';
import HeaderButton from './HeaderButton';
import Resizer from './HeaderResizer';
import ButtonWrapper from './ButtonWrapper';

import SkeletonLoader from '../../../../../SkeletonLoader';
import Popover from '../../../../../Popover';

const ViewComponent = ({ isLoading, handleAction, ...props }) => (
  <Container {...props}>
    {({ classNames, headerProps, popoverProps, label }) => (
      <div className={classNames.main} {...headerProps}>
        <SkeletonLoader
          isLoading={isLoading}
          wrapperClassName={classNames.loading}
          afterLoading={
            <Fragment>
              <Popover.Advanced
                {...popoverProps}
                onAction={handleAction}
                className="w-48"
                button={<HeaderButton label={label} />}
                wrapper={({ children }) => (
                  <ButtonWrapper {...props}>{children}</ButtonWrapper>
                )}
              />
              <Resizer {...props} />
            </Fragment>
          }
        />
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  isLoading: false,
  handleAction: undefined,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  isLoading: PropTypes.bool,
  /**
   * Handle Action
   */
  handleAction: PropTypes.func,
};

ViewComponent.displayName = 'HeaderColumn-view';

export default ViewComponent;
