import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';

import { isImage, normalizeFilesValue } from './FileInput-functions';

const ContainerComponent = ({
  name,
  accept,
  maxFiles = 0,
  maxSize,
  children,
  resolveFile,
  defaultValue,
  onAddFiles,
  onUpdateFiles,
  onRemoveFile,
  cropImage,
}) => {
  const [cropFile, setCropFile] = useState();
  const [fileError, setFileError] = useState();

  const { register, setValue } = useFormContext();

  const value = useWatch({ name, defaultValue });

  const files = useMemo(() => {
    const newFiles = normalizeFilesValue(value, maxFiles);

    onUpdateFiles?.(newFiles);

    return newFiles;
  }, [value, maxFiles, onUpdateFiles]);

  /**
   * @param {Array.File} newFiles
   */
  const setFiles = useCallback(
    (newFiles) => {
      setValue(name, maxFiles === 1 ? newFiles[0] : newFiles);
    },
    [maxFiles, name, setValue]
  );

  const showFileZone = maxFiles === 0 || files.length < maxFiles;

  const [resolvedFiles, setResolvedFiles] = useState([]);

  /**
   * When files change it calles resolve file callback
   * to get more information about file from default value
   */
  useEffect(() => {
    if (files.length === 0) setResolvedFiles([]);

    setResolvedFiles(
      files.map((file) => {
        if (!(file instanceof File)) {
          const resolvedField = resolveFile ? resolveFile(file) : {};
          return {
            name: resolvedField.name || 'Unknown File',
            preview: resolvedField.preview,
          };
        }
        return {
          name: file.name,
          preview: isImage(file) && URL.createObjectURL(file),
          fileObject: file,
        };
      })
    );
  }, [files, resolveFile]);

  /**
   * Handles on drop logic updates files state
   * @param {Array} droppedFiles - new uploaded files
   */
  const onDrop = useCallback(
    (droppedFiles) => {
      setFileError();

      const newFiles = [...files, ...droppedFiles];

      const finalFiles = maxFiles === 0 ? newFiles : newFiles.slice(-maxFiles);

      const newAddedFiles = finalFiles.filter((file) => !files.includes(file));

      if (!cropImage && onAddFiles) {
        onAddFiles(newAddedFiles);
      }

      if (cropImage) {
        setCropFile(newAddedFiles[0]);
      }

      setFiles(finalFiles);
    },
    [files, maxFiles, cropImage, onAddFiles, setFiles]
  );

  /**
   * Handles on crop logic
   * @param {Array} croppedFile - new cropped file
   */
  const onCrop = useCallback(
    (newCroppedFile) => {
      // replace original file with cropped one
      const newFiles = files.map((file) =>
        file === cropFile ? newCroppedFile : file
      );

      setCropFile();
      setFiles(newFiles);

      if (onAddFiles) {
        onAddFiles([newCroppedFile]);
      }
    },
    [files, setFiles, onAddFiles, cropFile]
  );

  /**
   * Handles cancel crop logic
   */
  const onCancelCrop = useCallback(() => {
    setCropFile();
  }, []);

  /**
   * Make function to create a closure to handle remove file logic
   * @param {number} index - new uploaded files
   * @returns function to handle remove file logic
   */
  const makeRemoveFile = (index) => (e) => {
    e.stopPropagation();
    if (onRemoveFile) {
      onRemoveFile(files[index]);
    }
    const newFiles = files.filter((_, i) => i !== index);

    setFiles(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    multiple: maxFiles !== 1,
    noDragEventsBubbling: true,
    ...(maxSize && { maxSize }),
    onDropRejected: (err) => {
      if (err[0]?.errors[0]?.code === 'file-too-large') {
        setFileError(
          `File size too large. File must be below ${maxSize / 1024 / 1024}mb`
        );
      }
    },
  });

  /**
   * Register and unregister field on react hook form
   * this should run on component did mount and unmount
   */
  useEffect(() => {
    register(name);
  }, [register, name]);

  return children({
    files: resolvedFiles,
    showFileZone,
    isDragActive,
    getInputProps,
    getRootProps,
    makeRemoveFile,
    onCrop,
    onCancelCrop,
    cropFile,
    fileError,
  });
};

ContainerComponent.displayName = 'FileInput-container';

export default ContainerComponent;
