export const DEFAULT_ICON_PROPS = {
  size: 24,
  icon: 'info',
  stroke: 'neutral-300',
  className: 'my-auto mr-3',
};

export const DEFAULT_CONTAINER_PROPS = {
  className: 'flex flex-row w-full',
};

export const DEFAULT_SPAN_PROPS = {
  className: 'text-style-g-em text-neutral-900 my-auto',
};
