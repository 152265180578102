import { useContext } from 'react';
import LoadingProvider from '../LoadingProvider';
import { DEFAULT_WRAPPER_CLASS_NAME } from './SkeletonLoader-constants';

const ContainerComponent = ({ children, wrapperClassName, isLoading }) => {
  const wrapper = wrapperClassName || DEFAULT_WRAPPER_CLASS_NAME;

  const contextLoading = useContext(LoadingProvider.Context);

  /**
   * Allow to force isLoading
   * To be able to set is loading false even if the parent context is loading
   */
  const loading = typeof isLoading !== 'undefined' ? isLoading : contextLoading;

  return children({
    loading,
    classNames: {
      wrapper,
    },
  });
};

ContainerComponent.displayName = 'SkeletonLoader-Container';

export default ContainerComponent;
