import { KandaAuthService } from './kandaAuthService';
import {
  LIST_CUSTOMER_QUERY,
  UPDATE_CUSTOMER_QUERY,
  CREATE_CUSTOMER_QUERY,
  RETRIEVE_CUSTOMER_QUERY,
} from './customersService-constants';

import config from '../config';
import { Service } from '../lib';
import { decamelize, camelize } from '../utils';

/**
 * Customers Service
 * @typedef {Object} CustomersServiceOptions
 * @property {String} graphQLURL baseURLs
 * @property {AuthService} authService auth service
 */

export const CustomersService = {
  create: 'CustomersService-create',
  list: 'CustomersService-list',
  update: 'CustomersService-update',
  retrieve: 'CustomersService-retrieve',
};

const CustomersServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {CustomersServiceOptions} options
   */
  constructor({ graphQLURL, authService }) {
    super({ authService, graphQLURL, keys: CustomersService });
  }

  /**
   * List customers
   * @returns {OmniInitialData} initial data
   */
  async [CustomersService.list]() {
    const results = await super.query(LIST_CUSTOMER_QUERY);

    return camelize(results.data.customers);
  }

  /**
   * Retrieve customer
   * @returns {OmniInitialData} initial data
   */
  async [CustomersService.retrieve](id) {
    const variables = { id };

    const result = await super.query(RETRIEVE_CUSTOMER_QUERY, variables);

    return camelize(result.data.customer);
  }

  /**
   * Creates customer
   * @param {Object} payload create customer payload
   * @param {string} payload.firstName first name
   * @param {string} payload.lastName last name
   * @param {string} payload.email email
   * @param {string} payload.phoneNumber phone number
   * @param {string} payload.postalCode postal code
   * @param {string} payload.addressLineOne address line one
   * @param {string} payload.addressLineTwo address line two
   * @param {string} payload.city city
   *
   */
  async [CustomersService.create](payload) {
    const variables = { input: decamelize(payload) };

    const result = await super.query(CREATE_CUSTOMER_QUERY, variables);

    const newCustomer = camelize(result.data.createCustomer);

    super.mutate([CustomersService.retrieve, result.id], newCustomer, false);

    super.mutate(
      [CustomersService.list],
      (prevData = []) => [newCustomer, ...prevData],
      false,
    );

    return newCustomer;
  }

  /**
   * Update customer
   * @param {string} id customer id
   * @param {Object} payload create customer payload
   * @param {string} payload.firstName first name
   * @param {string} payload.lastName last name
   * @param {string} payload.email email
   * @param {string} payload.phoneNumber phone number
   * @param {string} payload.postalCode postal code
   * @param {string} payload.addressLineOne address line one
   * @param {string} payload.addressLineTwo address line two
   * @param {string} payload.city city
   */
  async [CustomersService.update](id, payload) {
    const variables = { updateCustomerId: id, input: decamelize(payload) };

    const result = await super.query(UPDATE_CUSTOMER_QUERY, variables);

    const newCustomer = camelize(result.data.updateCustomer);

    super.mutate([CustomersService.retrieve, id], newCustomer, false);

    super.mutate(
      [CustomersService.list],
      (prevData = []) =>
        prevData.map((customer) =>
          customer.id === id ? { ...customer, ...newCustomer } : customer,
        ),
      false,
    );

    return newCustomer;
  }
};

export default new CustomersServiceImplementation({
  graphQLURL: config.GRAPHQL_GATEWAY,
  authService: KandaAuthService,
});
