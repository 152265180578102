import { useContext } from 'react';

import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { isLoading, quantity, classNames } = useContext(Context);

  return children({ quantity, isLoading, classNames });
};

ContainerComponent.displayName = 'LineItem-Quantity-container';

export default ContainerComponent;
