import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from './Content-constants';

const ViewComponent = ({ children }) => (
  <div className={CLASS_NAMES.container}>
    <div className={CLASS_NAMES.wrapper}>
      <div className={CLASS_NAMES.content}>{children}</div>
    </div>
  </div>
);

ViewComponent.displayName = 'DesktopLayoutDefault-Content-view';

ViewComponent.defaultProps = {
  children: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
