import { KandaAuthService } from './kandaAuthService';

import { QUOTES_SERVICE_LIST_QUERY } from './quotesService-constants';

import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';

/**
 * Quotes Service
 * @typedef {Object} QuotesServiceOptions
 * @property {string} baseURL base url
 * @property {string} GraphQLURL graphQL url
 * @property {Object} authService graphQL url
 */
export class QuotesServiceImplementation extends Service {
  /**
   * Constructor
   * @param {QuotesServiceOptions} options
   */
  constructor({ baseURL, graphQLURL, authService }) {
    super({ baseURL, graphQLURL, authService });

    this.create = this.create.bind(this);

    this.key = {
      list: 'QuotesService/list',
    };

    this.mapKeys({
      [this.key.list]: this.list,
    });
  }

  /**
   * Lists all quotes
   */
  async list() {
    const customAuthErrorCheck = async (response) => {
      const data = await response.json();

      if (!data.data.quotes) {
        return true;
      }

      return false;
    };

    const res = await super.query(
      QUOTES_SERVICE_LIST_QUERY,
      {},
      { customAuthErrorCheck },
    );

    return camelize(res.data.quotes);
  }

  /**
   * Creates a new quote
   * @param {Object} payload quote payload
   */
  async create(payload) {
    const res = await super.post('/quotes/', decamelize(payload));

    await super.refetch([this.key.list]);

    return camelize(res);
  }
}

export const QuotesService = new QuotesServiceImplementation({
  baseURL: config.QUOTES_API_URL,
  graphQLURL: config.API_GATEWAY,
  authService: KandaAuthService,
});
