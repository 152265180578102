/**
 * Create Sigend url payload
 * @param {string} fileName
 */
export const buildSignedUrlPayload = (fileName) => ({
  blob_name: fileName,
});

/**
 * Uploads file
 * @param {string} url
 * @param {File} file
 */
export const uploadFile = (url, file, onProgress) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', 'application/octet-stream');
    xhr.upload.addEventListener('progress', (e) => {
      if (onProgress) {
        onProgress(file.name, e.loaded / e.total);
      }
    });
    xhr.onloadend = () => {
      resolve(true);
      setTimeout(() => {
        if (onProgress) {
          onProgress(file.name, undefined);
        }
      }, 100);
    };
    xhr.onerror = () => {
      if (onProgress) {
        onProgress(file.name, undefined);
      }
      reject();
    };
    xhr.send(file);
  });
