export const RENDER_ID = 'pdf-renderer';

export const CLASS_NAME =
  'fixed -top-px -left-px w-0 h-0 overflow-hidden opacity-0';

export const FONTS = {
  Galano:
    'https://storage.googleapis.com/kanda-fonts/GalanoGrotesqueRegular.ttf',
  GalanoBold:
    'https://storage.googleapis.com/kanda-fonts/GalanoGrotesqueBold.ttf',
};
