export const QUOTES_SERVICE_LIST_QUERY = `
{
  quotes {
    address
    postal_code
    title
    reference
    status
    created_at
    updated_at
    finance_enabled
    zero_finance_enabled
    zero_finance_installment_type
    zero_finance_start_date
    finance {
       provider
       installment_type
       start_date
       updated_at
       created_at
    }
    line_items: items {
      id
      price: unit_price
      quantity: quantity_amount
      vat
    }
    tradesperson {
      first_name
      last_name
      username
    }
    homeowner {
      first_name
      last_name
      username
    }
  }
}
`;
