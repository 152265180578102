import { useState } from 'react';

import useSearch from '../SearchResults-useSearch';

const ContainerComponent = ({ children }) => {
  const [query, setQuery] = useState('');

  const { results, isLoading, searchWords } = useSearch(query);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return children({
    results,
    isLoading,
    searchWords,
    handleSearch,
  });
};

ContainerComponent.displayName = 'CompanyLookup-SearchModal-container';

export default ContainerComponent;
