/**
 * Formats input timestamp to JS date object
 *
 * @param {String} content content to be copied to clipboard
 */
export const checkAndFormatTimestamp = (timestamp, minYear = 2019) => {
  // Construct date object
  const date = new Date(timestamp);

  // Timestamp is incorrectly formatted (not in milliseconds) if year in date
  // object from timestamp is less than min year. Need to correct timestamp
  if (date.getFullYear() < minYear) {
    // catch case for timestamp in seconds - will be less than 10^10
    if (date.getTime() < 10 ** 10) {
      return new Date(timestamp * 1000);
    }
    // catch case for timestamp in tenths of seconds - will be less than 10^11
    if (date.getTime() < 10 ** 11) {
      return new Date(timestamp * 100);
    }
    // if neither of above, timestamp in hundreths of seconds - will be less
    // than 10^12
    return new Date(timestamp * 10);
  }

  return date;
};

/**
 * Extracts date from a JS date object. Returns in format dd/MM/yyyy
 *
 * @param {Object} date - JS date object
 */
export const formatDate = (date) => {
  // Check id date is Date object, if not return placeholder
  if (Object.prototype.toString.call(date) !== '[object Date]')
    return '01/01/1970';

  // Get year
  const year = date.getFullYear();
  // Get month and add leading 0 if needed
  const month = `0${date.getMonth() + 1}`.slice(-2);
  // Get day and add leading 0 if needed
  const day = `0${date.getDate()}`.slice(-2);

  return `${day}/${month}/${year}`;
};

/**
 * Extracts time from a JS date object. Returns in format HH:mm(:ss)
 *
 * @param {Object} date - JS date object
 */
export const formatTime = (date, seconds = false) => {
  // Check id date is Date object, if not return placeholder
  if (Object.prototype.toString.call(date) !== '[object Date]')
    return `12:00${seconds ? ':00' : ''}`;

  // Get hours
  const hours = date.getHours();
  // Get minutes and add leading 0 if needed
  const mins = `0${date.getMinutes()}`.slice(-2);
  // Get minutes and add leading 0 if needed
  const secs = `0${date.getSeconds()}`.slice(-2);

  return `${hours}:${mins}${seconds ? `:${secs}` : ''}`;
};

/**
 * Checks if date is in this year
 * @param {string} string iso time
 * @returns {boolean} return true if the date is on this year
 */
export const isCurrentYear = (string) => {
  const thisYear = new Date().getFullYear();
  const dateYear = new Date(string).getFullYear();

  return thisYear === dateYear;
};

/**
 * Add the specified number of months to the given date
 * @param {Date|Number} rawDate - the date to be changed
 * @param {Number} amount - the amount of months to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
 * @returns {Date} the new date with the months added
 */
export const addMonths = (rawDate, amount) => {
  const rawDateString = Object.prototype.toString.call(rawDate);

  // Check if rawDate is Date or timestamp and clone it
  const date =
    rawDate instanceof Date ||
    (typeof rawDate === 'object' && rawDateString === '[object Date]')
      ? new Date(rawDate.getTime())
      : new Date(rawDate);

  if (!amount)
    // If 0 months, no-op to avoid changing times in the hour before end of DST
    return date;

  const dayOfMonth = date.getDate();

  const endOfDesiredMonth = new Date(date.getTime());
  endOfDesiredMonth.setMonth(date.getMonth() + amount + 1, 0);
  const daysInMonth = endOfDesiredMonth.getDate();

  if (dayOfMonth >= daysInMonth) return endOfDesiredMonth;

  date.setFullYear(
    endOfDesiredMonth.getFullYear(),
    endOfDesiredMonth.getMonth(),
    dayOfMonth,
  );

  return date;
};

/**
 * Add the specified number of days to the given date
 * @param {Date|Number} rawDate - the date to be changed
 * @param {Number} amount - the amount of days to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
 * @returns {Date} the new date with the days added
 */
export const addDays = (rawDate, amount) => {
  const rawDateString = Object.prototype.toString.call(rawDate);

  // Check if rawDate is Date or timestamp and clone it
  const date =
    rawDate instanceof Date ||
    (typeof rawDate === 'object' && rawDateString === '[object Date]')
      ? new Date(rawDate.getTime())
      : new Date(rawDate);

  if (!amount)
    // If amount is 0, return the raw date
    return date;

  // Set date plus however many days
  date.setDate(date.getDate() + amount);

  // Return the new date
  return date;
};

/**
 * Gets the current timestamp - for signup
 */
export const getCurrentTimeStamp = () => new Date().getTime();
