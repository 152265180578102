import { useEffect } from 'react';
import { useLoadData } from '../../hooks';
import { UserAuthService, KandaAuthService } from '../../services';

const ContainerComponent = ({ children }) => {
  const { data: isUserLoggedIn, isValidating } = useLoadData([
    UserAuthService.key.isUserLoggedIn,
  ]);

  /**
   * Calles logout if token is not valid
   */
  useEffect(() => {
    if (!isValidating && !isUserLoggedIn) {
      KandaAuthService.logout(true);
    }
  }, [isValidating, isUserLoggedIn]);

  if (isValidating || !isUserLoggedIn) {
    return null;
  }

  return children;
};

ContainerComponent.displayName = 'AuthWrapper-container';

export default ContainerComponent;
