import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { pdf, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import Render from './Render';
import { formatJob, getLogoFromURL } from './QuoteDownload-functions';
import { FONTS } from './QuoteDownload-constants';

Font.register({
  family: 'Galano Grotesque',
  src: FONTS.Galano,
});

Font.register({
  family: 'Galano Grotesque Bold',
  src: FONTS.GalanoBold,
});

const ContainerComponent = ({ children, job: inputJob, isLoading }) => {
  /**
   * Stores the job
   */
  const jobRef = useRef(inputJob);
  const prerenderRef = useRef(null);
  const [job, setJob] = useState(formatJob(inputJob));
  const [logo, setLogo] = useState(null);
  const [fetchingLogo, setFetchingLogo] = useState(true);
  const [rendering, setRendering] = useState(false);
  const fileName = useMemo(
    () =>
      job?.details?.reference ? `job_${job.details.reference}.pdf` : 'job.pdf',
    [job]
  );

  const downloadPdf = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isLoading) return;
      setRendering(true);
      const blob = await pdf(
        <Render job={job} logo={logo} fetchingLogo={fetchingLogo} />
      ).toBlob();
      // Save the PDF
      saveAs(blob, fileName);
      // setRendering(false);
    },
    [job, isLoading, fileName, logo, fetchingLogo]
  );

  /**
   * triggers when initial data is changed
   */
  useEffect(() => {
    if (
      JSON.stringify(jobRef.current) !== JSON.stringify(formatJob(inputJob))
    ) {
      setJob(formatJob(inputJob));
      jobRef.current = formatJob(inputJob);
    }
  }, [inputJob]);

  /**
   * Fetched logo blob needed for PDF
   * TODO: Use kanda-api for this
   */
  useEffect(() => {
    if (!job?.company) return;
    if (!job?.company?.logo) {
      setFetchingLogo(false);
      return;
    }
    getLogoFromURL(
      job.company.logo,
      () => setFetchingLogo(false),
      (fetchedLogo) => {
        setLogo(fetchedLogo);
        setFetchingLogo(false);
      }
    );
  }, [job]);

  return children({
    job,
    logo,
    fetchingLogo,
    rendering,
    downloadPdf,
    isLoading,
    prerenderRef,
  });
};

ContainerComponent.displayName = 'QuoteDownload-container';

export default ContainerComponent;
