import PropTypes from 'prop-types';
import React from 'react';

import Container from './Desktop-container';
import { Item } from './components';
import { CLASS_NAMES } from './Desktop-constants';

import Popover from '../../../../../../Popover';
import Card from '../../../../../../Card';

const ViewComponent = ({
  companySearchName,
  companyFocusName,
  handleSelect,
}) => (
  <Container
    companySearchName={companySearchName}
    companyFocusName={companyFocusName}
  >
    {({ results, isLoading, searchWords, visible }) => (
      <div className={CLASS_NAMES.container}>
        <Popover.Standard visible={visible} className="w-full">
          {() => (
            <Card padding="p-2" className={CLASS_NAMES.card}>
              {results.map((item) => (
                <Item
                  handleSelect={handleSelect}
                  searchWords={searchWords}
                  key={item.companyNumber}
                  company={item}
                  isLoading={isLoading}
                />
              ))}
            </Card>
          )}
        </Popover.Standard>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyLookup-Desktop-view';

ViewComponent.propTypes = {
  /**
   * Company search field name
   */
  companySearchName: PropTypes.string.isRequired,
  /**
   * Company focus field name
   */
  companyFocusName: PropTypes.string.isRequired,
  /**
   * Handle select
   */
  handleSelect: PropTypes.func.isRequired,
};

export default ViewComponent;
