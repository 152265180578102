import React from 'react';

import Container from './Description-container';

import Text from '../../../Text';
import { SKELETONS } from '../../LineItem-constants';

const ViewComponent = () => (
  <Container>
    {({ description, isLoading, classNames }) => (
      <Text
        className={classNames.description}
        isLoading={isLoading}
        text={description}
        skeletonProps={SKELETONS.description}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Description-view';

export default ViewComponent;
