import Cookies from 'universal-cookie';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';

import { AuthService } from '../lib';
import { redirectTo } from '../utils';
import config from '../config';
import '../lib/firebase-config';

export const FirebaseAuthServiceImplementation = class extends AuthService {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.auth = getAuth();
    this.cookies = new Cookies();
    this.logout = this.logout.bind(this);
    this.onAuthStateChangedPromise = this.onAuthStateChangedPromise.bind(this);
  }

  /**
   * Auth state change promise
   */
  async onAuthStateChangedPromise() {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(this.auth, (user) => {
        // console.log(user);
        if (user) resolve(user);
        reject(new Error('Not signed in'));
      });
    });
  }

  /**
   * Returns current token from auth service
   */
  async token() {
    const user = await this.onAuthStateChangedPromise();
    // console.log({ user });
    const token = await user.getIdToken(true);
    // console.log({ token });
    return token;
  }

  /**
   * Returns current user from auth service
   */
  async user() {
    const user = await this.onAuthStateChangedPromise();
    // console.log({ user });
    return user;
  }

  /**
   * Calls logout on auth error
   */
  async onAuthError() {
    await this.logout();
  }

  /**
   * Logs user out - redirect to login page unless `redirect` set to false
   */
  async logout(redirect = true) {
    await super.logout();

    signOut(this.auth);

    if (!redirect) return;
    redirectTo(config.LOGIN_URL);
  }
};

export const FirebaseAuthService = new FirebaseAuthServiceImplementation();
