import React from 'react';
import PropTypes from 'prop-types';

import Container from './HeaderResizer-container';
import { CLASS_NAMES } from './HeaderResizer-constants';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ canResize, classNames, resizerProps }) =>
      canResize && (
        <div className={classNames.container}>
          <div className={CLASS_NAMES.borderClassName} />
          <div {...resizerProps} className={classNames.resizer} />
        </div>
      )
    }
  </Container>
);

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * column object
   */
  column: PropTypes.object.isRequired,
};

ViewComponent.displayName = 'HeaderResizer-view';

export default ViewComponent;
