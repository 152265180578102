import React from 'react';
import { toast } from 'react-toastify';

import {
  INFO_MESSAGE_PROPS,
  ERROR_MESSAGE_PROPS,
  SUCCESS_MESSAGE_PROPS,
  WARNING_MESSAGE_PROPS,
} from './Toast-constants';
import { Message } from './components';

const useToast = () => {
  const showInfo = (message, messageProps, options) => {
    const infoMessageProps = {
      ...INFO_MESSAGE_PROPS,
      ...messageProps,
    };
    toast.info(<Message message={message} {...infoMessageProps} />, options);
  };

  const showError = (message, messageProps, options) => {
    const errorMessageProps = {
      ...ERROR_MESSAGE_PROPS,
      ...messageProps,
    };
    toast.error(<Message message={message} {...errorMessageProps} />, options);
  };

  const showSuccess = (message, messageProps, options) => {
    const successMessageProps = {
      ...SUCCESS_MESSAGE_PROPS,
      ...messageProps,
    };
    toast.success(
      <Message message={message} {...successMessageProps} />,
      options
    );
  };

  const showWarning = (message, messageProps, options) => {
    const warningMessageProps = {
      ...WARNING_MESSAGE_PROPS,
      ...messageProps,
    };
    toast.warning(
      <Message message={message} {...warningMessageProps} />,
      options
    );
  };

  return {
    toast,
    showInfo,
    showError,
    showWarning,
    showSuccess,
  };
};

export default useToast;
