import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Container from './Router-container';

const LazySlider = React.lazy(() => import('react-slick'));

const ViewComponent = ({ slider, fallback, ...restProps }) => (
  <Container slider={slider} {...restProps}>
    {({ allPages, CurrentSlide, sliderProps }) => (
      <React.Fragment>
        {slider ? (
          <React.Fragment>
            <Helmet>
              <link
                rel="stylesheet"
                type="text/css"
                charset="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
              />
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
              />
            </Helmet>
            <Suspense fallback={fallback}>
              <LazySlider {...sliderProps}>
                {allPages.map((Page, i) => (
                  <React.Fragment key={String(i)}>
                    <Page />
                  </React.Fragment>
                ))}
              </LazySlider>
            </Suspense>
          </React.Fragment>
        ) : (
          <CurrentSlide />
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'MultiStepForm-Router-view';

ViewComponent.defaultProps = {
  fallback: null,
  slider: undefined,
  sliderProps: {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    infinite: false,
  },
};

ViewComponent.propTypes = {
  /**
   * Pages
   */
  pages: PropTypes.objectOf(PropTypes.elementType).isRequired,
  /**
   * Slider props
   */
  sliderProps: PropTypes.shape({
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
    adaptiveHeight: PropTypes.bool,
    infinite: PropTypes.bool,
  }),
  /**
   * Display as slider
   */
  slider: PropTypes.bool,
  /**
   * Loading component
   */
  fallback: PropTypes.node,
};

export default ViewComponent;
