const ContainerComponent = ({ children, submit }) => {
  const type = submit ? 'submit' : 'button';

  return children({
    type,
  });
};

ContainerComponent.displayName = 'BaseButton-Container';

export default ContainerComponent;
