import { useContext } from 'react';

import { CLASS_NAMES } from './PopoverMenu-constants';

import ConfigWrapper from '../ConfigWrapper';
import { useClasses } from '../../hooks';

const ContainerComponent = ({ children, className }) => {
  const { linkComponent } = useContext(ConfigWrapper.Context);

  const classNames = useClasses(CLASS_NAMES, {
    container: ['.baseContainer', className],
  });

  return children({
    linkComponent,
    classNames,
  });
};

ContainerComponent.displayName = 'PopoverMenu-Container';

export default ContainerComponent;
