import Skeleton from 'react-loading-skeleton';
import React from 'react';
import PropTypes from 'prop-types';

import Container from './SkeletonLoader-container';

const ViewComponent = ({
  isLoading,
  afterLoading,
  wrapperClassName,
  ...restProps
}) => (
  <Container wrapperClassName={wrapperClassName} isLoading={isLoading}>
    {({ classNames, loading }) => (
      <React.Fragment>
        {loading ? (
          <span className={classNames.wrapper}>
            <Skeleton {...restProps} />
          </span>
        ) : (
          afterLoading
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'SkeletonLoader-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  afterLoading: null,
  width: undefined,
  height: undefined,
  circle: undefined,
  wrapperClassName: undefined,
  className: undefined,
};

ViewComponent.propTypes = {
  /**
   * Is loading
   */
  isLoading: PropTypes.bool,
  /**
   * After loading component
   */
  afterLoading: PropTypes.node,
  /**
   * Width
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * After loading component
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Display skeleton as circle
   */
  circle: PropTypes.bool,
  /**
   * Skeleton wrapper className
   */
  wrapperClassName: PropTypes.string,
  /**
   * ClassName
   */
  className: PropTypes.string,
};

export default ViewComponent;
