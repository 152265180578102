import clsx from 'clsx';

import { CLASS_NAMES, ICONS, ICON_PROPS } from './PaginationNavButton-constants';

const ContainerComponent = ({ children, disabled, next }) => {
  const className = clsx(
    CLASS_NAMES.base,
    disabled ? CLASS_NAMES.opacity : '',
    next ? CLASS_NAMES.margin.left : CLASS_NAMES.margin.right
  );

  const iconProps = {
    ...ICON_PROPS,
    icon: next ? ICONS.next : ICONS.prev,
  };

  return children({
    className,
    iconProps,
  });
};

ContainerComponent.displayName = 'PaginationNavButton-container';

export default ContainerComponent;
