import React from 'react';
import PropTypes from 'prop-types';

import Container from './Switch-container';

import SkeletonLoader from '../../../SkeletonLoader';

const ViewComponent = React.forwardRef(
  ({ id, name, isLoading, small, ...restProps }, ref) => (
    <Container small={small}>
      {({ classNames, skeletons }) => (
        <div className={classNames.container}>
          <SkeletonLoader
            isLoading={isLoading}
            {...skeletons}
            afterLoading={
              <React.Fragment>
                <input
                  {...restProps}
                  ref={ref}
                  type="checkbox"
                  name={name}
                  id={id || name}
                  className={classNames.checkBox}
                />
                <label htmlFor={id || name} className={classNames.label}>
                  <span />
                </label>
              </React.Fragment>
            }
          />
        </div>
      )}
    </Container>
  )
);

ViewComponent.displayName = 'Switch-view';

ViewComponent.defaultProps = {
  id: undefined,
  onChange: undefined,
  onBlur: undefined,
  value: undefined,
  defaultChecked: undefined,
  isLoading: undefined,
  small: false,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * value
   */
  value: PropTypes.any,
  /**
   * initialy checked
   */
  defaultChecked: PropTypes.bool,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Boolean to define whether to use small switch or not
   */
  small: PropTypes.bool,
};

export default ViewComponent;
