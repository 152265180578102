import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import Container from './Select-container';

const ViewComponent = ({
  forwardRef,
  options: _options,
  placeholder,
  error,
  isLoading,
  className,
  name,
  ...restProps
}) => (
  <Container
    {...restProps}
    name={name}
    options={_options}
    placeholder={placeholder}
    error={error}
    isLoading={isLoading}
    className={className}
  >
    {({ options, classNames, currentValue, defaultValue }) => (
      <React.Fragment>
        {isLoading ? (
          <div className={classNames.select}>
            <div className={classNames.skeleton}>
              <Skeleton />
            </div>
          </div>
        ) : (
          <select
            name={name}
            className={classNames.select}
            options={options}
            defaultValue={defaultValue}
            required
            ref={forwardRef}
            value={currentValue}
            {...restProps}
          >
            {options.map(({ value, name: optionName }) => (
              <option
                key={value}
                disabled={value === defaultValue}
                value={value}
              >
                {optionName}
              </option>
            ))}
          </select>
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'UncontrolledSelect-view';

ViewComponent.defaultProps = {
  options: [],
  id: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  forwardRef: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  isLoading: undefined,
  className: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * class name
   */
  className: PropTypes.string,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * Forwared ref from parent component
   */
  forwardRef: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
