import { calculateLoan } from '@kanda-utils/library';

import { CLASS_NAMES, VARIANTS } from './FinancePlan-constants';

import { useClasses } from '../../hooks';

const ContainerComponent = ({
  children,
  price,
  deposit,
  apr,
  term,
  className,
  variant,
  isLoading,
}) => {
  const VARIANT = VARIANTS[variant] || VARIANTS.default;

  const classNames = useClasses(CLASS_NAMES, {
    container: ['.container', className],
    wrapper: ['.wrapper', VARIANT.wrapper],
    headingMobile: ['.headingMobile', VARIANT.headingMobile],
    headingDesktop: ['.headingDesktop', VARIANT.headingDesktop],
    grid: ['.grid', VARIANT.grid],
    title: ['.title', VARIANT.title],
    pounds: ['.pounds', VARIANT.pounds],
    text: ['.text', VARIANT.text],
  });

  if (isLoading)
    return children({
      formattedTerm: '0 months',
      calculatedDeposit: 0,
      monthly: 0,
      total: 0,
      fee: 0,
      classNames,
    });

  const selectedPlan =
    String(apr) === '0'
      ? `INTEREST_FREE_${term}`
      : `INTEREST_BEARING_${term}_${apr * 100}`;

  const calculatedDeposit = Math.ceil(
    (parseInt(deposit, 10) * parseInt(price, 10)) / 100
  );

  const toPay = parseInt(price, 10) - calculatedDeposit;

  const monthly = Math.ceil(calculateLoan(toPay, selectedPlan));

  const total = String(apr) === '0' ? toPay : monthly * term;

  const fee = String(apr) === '0' ? 0 : total - toPay;

  return children({
    formattedTerm: `${term} months`,
    calculatedDeposit,
    monthly,
    total,
    fee,
    classNames,
  });
};

ContainerComponent.displayName = 'FinancePlan-container';

export default ContainerComponent;
