import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';

export const APIEncodeDecodeService = {
  encode: 'APIEncodeDecodeService-encode',
  decode: 'APIEncodeDecodeService-decode',
};

const APIEncodeDecodeServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {StaffServiceOptions} options
   */
  constructor({ baseURL, env }) {
    super({ baseURL, keys: APIEncodeDecodeService });

    this.env = env;
  }

  /**
   * Retrieve user info
   * @returns {AuthUser} user
   */
  async [APIEncodeDecodeService.encode](data) {
    const payload = {
      env: this.env,
      data,
    };

    const res = await super.post('/encode', decamelize(payload));

    return camelize(res);
  }

  /**
   * Retrieve user info
   * @returns {AuthUser} user
   */
  async [APIEncodeDecodeService.decode](data) {
    const payload = {
      env: this.env,
      ...data,
    };

    const res = await super.post('/decode', decamelize(payload));

    return camelize(res);
  }
};

export default new APIEncodeDecodeServiceImplementation({
  baseURL: config.API_ENCODE_DECODE_URL,
  env: config.ENTERPRISE_ENVIRONMENT,
});
