import { useRef, useState, useCallback } from 'react';

import { calculateZoom, rotateAndReCrop, fit } from './Cropper-functions';

/**
 * Hook to handle image manipulation logic and state
 */
const useImageManipulation = () => {
  const [zoomRange, setZoomRange] = useState(1);
  const [zoom, setZoom] = useState();

  const cropperRef = useRef();
  const initialZoom = useRef(0);

  /**
   * Resets zoom
   */
  const resetZoom = useCallback(() => {
    const imageData = cropperRef.current.cropper.getImageData();

    initialZoom.current = imageData.width / imageData.naturalWidth;

    setZoom(initialZoom.current);
    setZoomRange(0);
  }, []);

  /**
   * Handles ready
   */
  const onReady = useCallback(() => {
    cropperRef.current.cropper.reset();

    resetZoom();
  }, [resetZoom]);

  /**
   * Handles zoom
   * @param {number} newZoom
   */
  const handleZoom = useCallback((newZoom) => {
    setZoomRange(newZoom);
    setZoom(calculateZoom(initialZoom.current, newZoom));
  }, []);

  /**
   * Handles reset
   * @param {number} newZoom
   */
  const handleReset = useCallback(() => {
    cropperRef.current.cropper.reset();

    resetZoom();
  }, [resetZoom]);

  /**
   * Handles fit
   * @param {number} newZoom
   */
  const handleFit = useCallback(() => {
    fit(cropperRef);
    resetZoom();
  }, [resetZoom]);

  /**
   * returns data url
   */
  const getDataUrl = useCallback(
    () => cropperRef.current.cropper.getCroppedCanvas().toDataURL(),
    []
  );

  /**
   * Handles rotate clockwise
   */
  const handleRotateClockwise = useCallback(() => {
    rotateAndReCrop(cropperRef, -90);
    resetZoom();
  }, [resetZoom]);

  /**
   * Handles rotate counter clockwise
   */
  const handleRotateCounterClockwise = useCallback(() => {
    rotateAndReCrop(cropperRef, 90);
    resetZoom();
  }, [resetZoom]);

  return {
    handleRotateClockwise,
    handleRotateCounterClockwise,
    cropperRef,
    zoomRange,
    zoom,
    onReady,
    handleFit,
    handleZoom,
    handleReset,
    getDataUrl,
  };
};

export default useImageManipulation;
