// import { isJwtExpired } from 'jwt-check-expiration';

import { KandaAuthService } from './kandaAuthService';

import config from '../config';
import { Service } from '../lib';
import { redirectTo } from '../utils';
import { USER_REDIRECT_NAME } from '../config/application';

/**
 * User Auth Service
 * @typedef {Object} UserAuthServiceOptions
 * @property {string} baseURL base url
 */
export class UserAuthServiceImplementation extends Service {
  /**
   * Constructor
   * @param {UserAuthServiceOptions} options
   */
  constructor({ baseURL, kandaAuthService }) {
    super({ baseURL });

    this.login = this.login.bind(this);
    this.getAuthToken = this.getAuthToken.bind(this);
    this.kandaAuthService = kandaAuthService;

    this.key = {
      isUserLoggedIn: 'UserAuthService/isUserLoggedIn',
    };

    this.mapKeys({
      [this.key.isUserLoggedIn]: this.isUserLoggedIn,
    });
  }

  /**
   * Handels login
   * @param {Object} payload login payload
   * @param {Boolean} persist persistent login state, default false
   */
  async login(payload, persist = false) {
    const data = await super.post('/login/', payload);

    /**
     * We are using localStorage for now since everyting is under one domain
     * This could be swiched to be using cookies if there is an issues with redirect in the future
     */

    const from = localStorage.getItem(USER_REDIRECT_NAME);

    // remove redirect
    localStorage.removeItem(USER_REDIRECT_NAME);

    KandaAuthService.setToken(data.token, persist);

    await super.refetch([this.key.isUserLoggedIn]);

    if (from) {
      redirectTo(from);
    } else {
      redirectTo(config.HOME_URL);
    }
  }

  /**
   * Gets auth token login
   * @param {Object} payload login payload
   * @param {Boolean} persist persistent login state, default false
   */
  async getAuthToken(payload) {
    const data = await super.post('/login/', payload);

    return data.token;
  }

  /**
   * Checks if user is logged in
   */
  async isUserLoggedIn() {
    const token = await this.kandaAuthService.token();

    return Boolean(token);
    // UN COMMENT THIS WHEN IS READY ON SERVER
    // return !isJwtExpired(token);
  }
}

export const UserAuthService = new UserAuthServiceImplementation({
  baseURL: `${config.API_MONOLITH}/api/v1`,
  kandaAuthService: KandaAuthService,
});
