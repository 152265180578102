import React from 'react';
import PropTypes from 'prop-types';

import Container from './FileCard-container';

import Icon from '../../../../Icon';

const ViewComponent = ({
  file,
  onRemove,
  fileProgress,
  loadingFiles,
  small,
  hasLabel,
}) => (
  <Container
    file={file}
    fileProgress={fileProgress}
    loadingFiles={loadingFiles}
    small={small}
    hasLabel={hasLabel}
  >
    {({ classNames, icons, imageUrl, progress, width }) => (
      <div className="mt-3 w-full flex flex-1">
        <div className={classNames.wrapper}>
          <div className="w-full flex flex-1 bg-neutral-000">
            {progress !== undefined && (
              <div
                className={classNames.progress}
                style={{
                  width,
                }}
              />
            )}
            <span className={classNames.container}>
              <span className="w-full flex flex-1 break-all">
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt={file.name}
                    className={classNames.image}
                  />
                ) : (
                  <React.Fragment>
                    <Icon {...icons.document} />
                    {file.name}
                  </React.Fragment>
                )}
              </span>
              {progress !== undefined ? (
                <React.Fragment>
                  <Icon {...icons.progress} />
                </React.Fragment>
              ) : (
                <button
                  type="button"
                  onClick={onRemove}
                  className={classNames.button}
                >
                  <Icon {...icons.delete} />
                </button>
              )}
            </span>
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'FileCard-view';

ViewComponent.defaultProps = {
  onRemove: undefined,
  loadingFiles: undefined,
  fileProgress: undefined,
  small: false,
  hasLabel: true,
};

ViewComponent.propTypes = {
  /**
   * File object
   */
  file: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  /**
   * Array of currently loading file names
   */
  loadingFiles: PropTypes.arrayOf(PropTypes.string),
  /**
   * File progress
   */
  fileProgress: PropTypes.any,
  /**
   * Remove callback
   */
  onRemove: PropTypes.func,
  /**
   * Whether to display the small variant or not
   */
  small: PropTypes.bool,
  /**
   * Field has a label
   */
  hasLabel: PropTypes.bool,
};

export default ViewComponent;
