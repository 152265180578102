import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Field from './Field';

import { HANDLE_TPYES } from '../../Form-constants';

const ViewComponent = ({
  name,
  control,
  controlProps,
  handle,
  isLoading,
  ...restProps
}) => (
  <Controller
    name={name}
    control={control}
    {...controlProps}
    render={({
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    }) => (
      <Field
        handle={handle}
        name={name}
        defaultChecked={value}
        ref={ref}
        value
        onChange={() => {
          onChange(!value);
        }}
        onBlur={onBlur}
        isLoading={isLoading}
        {...restProps}
        error={error}
      />
    )}
  />
);

ViewComponent.displayName = 'ControlledInput-view';

ViewComponent.defaultProps = {
  handle: undefined,
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  controlProps: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * react hook form control
   */
  control: PropTypes.any.isRequired,
  /**
   * react hook form control props
   */
  controlProps: PropTypes.any,
  /**
   * type of handle
   */
  handle: PropTypes.oneOf([HANDLE_TPYES.CHECKBOX, HANDLE_TPYES.SWITCH]),
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
