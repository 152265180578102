import { useContext } from 'react';

import { CLASS_NAMES } from './DesktopLayoutBoxed-constants';

import ConfigWrapper from '../../ConfigWrapper';
import { useClasses } from '../../../hooks';

const ContainerComponent = ({ children, sidebar, sidebarWidth, noHeader }) => {
  const {
    linkComponent,
    homeLinkProps,
    sidebar: defaultSideBar,
  } = useContext(ConfigWrapper.Context);

  const sidebarView = sidebar === true ? defaultSideBar : sidebar;

  const classNames = useClasses(CLASS_NAMES, {
    contentBox: ['.contentBox', sidebar ? 'basis-2/3' : 'w-full'],
    variants: {
      noHeader,
    },
    sidebar: ['.sidebarBase', sidebarWidth],
  });

  return children({
    linkComponent,
    homeLinkProps,
    sidebarView,
    classNames,
  });
};

ContainerComponent.displayName = 'DesktopLayoutBoxed-container';

export default ContainerComponent;
