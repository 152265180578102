import React from 'react';
import PropTypes from 'prop-types';

import Container from './Option-container';
import { CLASSNAME } from './Option-constants';

import SkeletonLoader from '../../../SkeletonLoader';

const ViewComponent = ({ isLoading, option, skeleton }) => (
  <Container skeleton={skeleton}>
    {({ skeletonProps }) => (
      <div className={CLASSNAME}>
        <SkeletonLoader
          isLoading={isLoading && !!skeleton}
          {...skeletonProps}
          afterLoading={option}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Card-Option-view';

ViewComponent.defaultProps = {
  option: null,
  isLoading: false,
  skeleton: false,
};

ViewComponent.propTypes = {
  /**
   * Item title
   */
  option: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Data is loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Option skeleton props
   */
  skeleton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
};

export default ViewComponent;
