import React from 'react';
import PropTypes from 'prop-types';

import Container from './LoadingSpinner-container';
import {
  DEFAULT_SVG_PROPS,
  DEFAULT_ANIMATION_PROPS,
  DEFAULT_STROKE_WIDTH,
  LINEAR_GRAIDENT_PROPS,
  PATHS,
} from './LoadingSpinner-constants';

const ViewComponent = ({ size, ...props }) => (
  <Container {...props}>
    {({ className, animationProps }) => (
      <svg
        width={size}
        height={size}
        className={className}
        {...DEFAULT_SVG_PROPS}
      >
        <defs>
          <linearGradient id={LINEAR_GRAIDENT_PROPS.secondHalf.id}>
            <stop {...LINEAR_GRAIDENT_PROPS.secondHalf.stop1} />
            <stop {...LINEAR_GRAIDENT_PROPS.secondHalf.stop2} />
          </linearGradient>
          <linearGradient id={LINEAR_GRAIDENT_PROPS.firstHalf.id}>
            <stop {...LINEAR_GRAIDENT_PROPS.firstHalf.stop1} />
            <stop {...LINEAR_GRAIDENT_PROPS.firstHalf.stop2} />
          </linearGradient>
        </defs>

        <g strokeWidth={DEFAULT_STROKE_WIDTH}>
          <path {...PATHS.path1} />
          <path {...PATHS.path2} />
        </g>

        <animateTransform {...DEFAULT_ANIMATION_PROPS} {...animationProps} />
      </svg>
    )}
  </Container>
);

ViewComponent.displayName = 'LoadingSpinner-view';

ViewComponent.defaultProps = {
  size: 64,
  duration: 1000,
  anticlockwise: false,
  className: undefined,
  stroke: undefined,
};

ViewComponent.propTypes = {
  /**
   * Size of the spinner
   */
  size: PropTypes.number,
  /**
   * Duration for the animation in ms
   */
  duration: PropTypes.number,
  /**
   * Duration for the animation
   */
  anticlockwise: PropTypes.bool,
  /**
   * Classname to be applied to SVG
   */
  className: PropTypes.string,
  /**
   * Tailwind color name to set as stroke of the icon e.g. blue-300
   */
  stroke: PropTypes.string,
};

export default ViewComponent;
