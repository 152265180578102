import { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { CLASS_NAMES } from './Dropzone-constants';

import { useClasses } from '../../hooks';

const ContainerComponent = ({ children, accept, ...dropzoneOptions }) => {
  const [droppedFiles, setDroppedFiles] = useState(undefined);

  const { onDrop: onDropProp } = dropzoneOptions;

  const acceptRef = useRef(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptRef.current) return;

      acceptRef.current = false;

      setDroppedFiles(...acceptedFiles);

      onDropProp?.(...acceptedFiles);
    },
    [onDropProp]
  );

  const { isDragAccept, ...parentDropZone } = useDropzone({
    accept,
    noClick: true,
    noKeyboard: true,
    ...dropzoneOptions,
    onDrop,
  });

  const innerDropZone = useDropzone({
    accept,
    noClick: true,
    noKeyboard: true,
    onDrop: () => {
      acceptRef.current = true;
    },
  });

  /**
   * Clear files after the state was published!
   */
  useEffect(() => {
    setDroppedFiles(undefined);
  }, [droppedFiles]);

  const classNames = useClasses(CLASS_NAMES, {
    variants: {
      isDragAccept,
    },
  });

  return children({
    classNames,
    parentDropZone,
    innerDropZone,
    droppedFiles,
  });
};

ContainerComponent.displayName = 'Dropzone-container';

export default ContainerComponent;
