/**
 * Hook for using helpcrunch Javascript API Methods.
 *
 * Docs:
 * - Methods:
 *   https://docs.helpcrunch.com/developers/methods
 */
const useHelpcrunch = () => {
  const openChat = () => {
    // If helpcrunch isn't available, return
    if (!window.HelpCrunch) return;
    // show chat window
    window.HelpCrunch('openChat');
  };

  const closeChat = () => {
    // If helpcrunch isn't available, return
    if (!window.HelpCrunch) return;
    // hide chat window
    window.HelpCrunch('closeChat');
  };

  const showNewMessage = (message) => {
    // If helpcrunch isn't available, return
    if (!window.HelpCrunch) return;
    window.HelpCrunch('typeUserMessage', message);
  };

  /**
   * Function calls the HelpCrunch update method
   * @param {Object} - Settings/Data (HelpCrunch setings) to update HelpCrunch with,
   * allowed attributes contained in Attributes & Objects docs link above
   */
  const update = (settings) => {
    // If helpcrunch isn't available, return
    if (!window.HelpCrunch) return;
    // Check the settings passed is an object - if so, run the update method
    if (
      typeof settings === 'object' &&
      settings !== null &&
      !Array.isArray(settings)
    ) {
      window.HelpCrunch('updateUser', settings);
    }
  };

  return {
    openChat,
    closeChat,
    update,
    showNewMessage,
  };
};

export default useHelpcrunch;
