import React from 'react';
import PropTypes from 'prop-types';

import Container from './FileCard-container';

import Icon from '../../../../Icon';
import Button from '../../../../Button';

import { ReactComponent as JobPDF } from '../../../../../assets/images/job-pdf.svg';

const ViewComponent = ({
  file,
  onRemove,
  fileProgress,
  loadingFiles,
  small,
  hasLabel,
}) => (
  <Container
    file={file}
    fileProgress={fileProgress}
    loadingFiles={loadingFiles}
    small={small}
    hasLabel={hasLabel}
  >
    {({ classNames, icons, progress, width }) => (
      <div className="mt-3 w-full flex flex-col flex-1">
        <div className={classNames.wrapper}>
          <JobPDF className="mx-auto" />
        </div>
        <div className={classNames.bottom}>
          {progress !== undefined && (
            <div
              className={classNames.progress}
              style={{
                width,
              }}
            />
          )}
          <div className={classNames.padding}>
            <div className="flex flex-row ">
              <Icon {...icons.document} />
              <span className="text-14-22 my-auto">{file.name}</span>
            </div>
            {progress !== undefined ? (
              <React.Fragment>
                <Icon {...icons.progress} />
              </React.Fragment>
            ) : (
              <Button.Icon {...icons.delete} onClick={onRemove} />
            )}
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'FileCard-view';

ViewComponent.defaultProps = {
  onRemove: undefined,
  loadingFiles: undefined,
  fileProgress: undefined,
  small: false,
  hasLabel: true,
};

ViewComponent.propTypes = {
  /**
   * File object
   */
  file: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  /**
   * Array of currently loading file names
   */
  loadingFiles: PropTypes.arrayOf(PropTypes.string),
  /**
   * File progress
   */
  fileProgress: PropTypes.any,
  /**
   * Remove callback
   */
  onRemove: PropTypes.func,
  /**
   * Whether to display the small variant or not
   */
  small: PropTypes.bool,
  /**
   * Field has a label
   */
  hasLabel: PropTypes.bool,
};

export default ViewComponent;
