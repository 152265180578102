import { useIsDesktop } from '../../hooks';

const ContainerComponent = ({ children }) => {
  const isDesktop = useIsDesktop();

  return children({
    isDesktop,
  });
};

ContainerComponent.displayName = 'BreakPoints-Container';

export default ContainerComponent;
