/**
 * Checks if the input is an empty object
 *
 * @param {Any} input
 */
export const isEmptyObject = (input) => {
  if (!(input instanceof Object)) return false;
  if (input instanceof Array) return false;
  return Object.keys(input).length === 0;
};

/**
 * Checks if the input is an empty array
 *
 * @param {Any} input
 */
export const isEmptyArray = (input) => {
  if (!(input instanceof Object)) return false;
  if (!(input instanceof Array)) return false;
  return input.length === 0;
};

/**
 * Cleans the JS object.
 *
 * Any field with empty string or null values are removed from the object. This
 * was developed for cleaning the API request payload.
 *
 * @param {Object} inputObject
 */
export const cleanObject = (inputObject) => {
  Object.keys(inputObject).forEach(
    // If the key is empty or null or NaN, delete the key.
    (key) =>
      (inputObject[key] === '' ||
        inputObject[key] === null ||
        inputObject[key] === undefined ||
        isEmptyObject(inputObject[key]) ||
        isEmptyArray(inputObject[key]) ||
        Number.isNaN(inputObject[key])) &&
      delete inputObject[key],
  );
  return inputObject;
};
