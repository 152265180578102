import React from 'react';
import PropTypes from 'prop-types';

import Container from './DateTime-container';

import SkeletonLoader from '../SkeletonLoader';

const ViewComponent = ({ isLoading, ...props }) => (
  <Container {...props}>
    {({ dateTime, classNames }) => (
      <span className={classNames.text}>
        <SkeletonLoader
          width={100}
          isLoading={isLoading}
          className={classNames.skeleton}
          afterLoading={dateTime}
        />
      </span>
    )}
  </Container>
);

ViewComponent.displayName = 'DateTime-view';

ViewComponent.defaultProps = {
  className: 'text-style-g text-neutral-600',
  skeletonClassName: '',
  isLoading: false,
  variant: 'both',
  seconds: false,
};

ViewComponent.propTypes = {
  /**
   *
   */
  timestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  /**
   * ClassName for wrapper
   */
  className: PropTypes.string,
  /**
   * ClassName for wrapper
   */
  skeletonClassName: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Display Loading state
   */
  variant: PropTypes.oneOf(['both', 'time', 'date']),
  /**
   * Show seconds
   */
  seconds: PropTypes.bool,
};

export default ViewComponent;
