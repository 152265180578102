import React from 'react';
import PropTypes from 'prop-types';

import Container from './Options-container';
import { CLASSNAMES } from './Options-constants';

import SkeletonLoader from '../../../../SkeletonLoader';

const ViewComponent = ({ isLoading, footerOptionsSkeleton, ...props }) => (
  <Container
    isLoading={isLoading}
    footerOptionsSkeleton={footerOptionsSkeleton}
    {...props}
  >
    {({ options, skeletonProps }) =>
      options.map((option) => (
        <React.Fragment key={option.key}>
          {option.showDot && <span className={CLASSNAMES.dot} />}
          <span className={CLASSNAMES.option}>
            <SkeletonLoader
              isLoading={isLoading && !!footerOptionsSkeleton}
              {...skeletonProps}
              afterLoading={option.content}
            />
          </span>
        </React.Fragment>
      ))
    }
  </Container>
);

ViewComponent.displayName = 'Card-FooterOptions-view';

ViewComponent.defaultProps = {
  footer: null,
  footerOptions: [],
  isLoading: false,
  footerOptionsSkeleton: false,
};

ViewComponent.propTypes = {
  /**
   * Item footer
   */
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Item footer options
   */
  footerOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  ),
  /**
   * Data is loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Skeleton props for footer options
   */
  footerOptionsSkeleton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
};

export default ViewComponent;
