import { CLASS_NAMES, TOTAL_ITEMS } from './Meter-constants';

import { SCORE_COLORS } from '../../PasswordStrengthIndicator-constants';
import { useClasses } from '../../../../hooks';

const ContainerComponent = ({ children, score }) => {
  const color = SCORE_COLORS[score];

  const classNames = useClasses(CLASS_NAMES, {
    activeItem: ['.baseItem', `bg-${color}`],
  });

  const itemsArray = Array.from(Array(TOTAL_ITEMS));

  const items = itemsArray.map((_, i) =>
    i < score ? classNames.activeItem : classNames.inactiveItem
  );

  return children({ items, classNames });
};

ContainerComponent.displayName = 'PasswordStrengthIndicator-Meter-Container';

export default ContainerComponent;
