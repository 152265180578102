import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import Container from './PasswordInput-container';
import { ICON_SIZE, SKELETONS } from './PasswordInput-contants';
import Icon from '../../../Icon';

const ViewComponent = ({ forwardRef, error, isLoading, ...restProps }) => (
  <Container error={error}>
    {({
      className,
      buttonClassName,
      type,
      showPassword,
      toggleShowPassword,
      skeletonClasses,
    }) => (
      <React.Fragment>
        {isLoading ? (
          <React.Fragment>
            <button type="button" className={buttonClassName}>
              <Skeleton
                width={SKELETONS.icon.width}
                height={SKELETONS.icon.height}
              />
            </button>
            <div className={className}>
              <div className={skeletonClasses}>
                <Skeleton />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <button
              tabIndex={-1}
              type="button"
              className={buttonClassName}
              onClick={toggleShowPassword}
            >
              <Icon icon={showPassword ? 'eye-off' : 'eye'} size={ICON_SIZE} />
            </button>
            <input
              className={className}
              ref={forwardRef}
              {...restProps}
              type={type}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'UncontrolledPasswordInput-view';

ViewComponent.defaultProps = {
  forwardRef: undefined,
  id: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Forwared ref from parent component
   */
  forwardRef: PropTypes.any,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
