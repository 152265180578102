export const STAFF_ME_QUERY = `
query {
  me {
    uid
    email
    name
    role
    subject
    audience
    issuer
  }
}
`;

const ENTERPRISE_USER_SNIPPET = `
  id
  username
  password
  organization_id
  organization_name
  is_first_time_login
  available_rates
`;

export const STAFF_ALL_ENTERPRISE_USERS_QUERY = `
query {
  allEnterpriseUsers {
    ${ENTERPRISE_USER_SNIPPET}
  }
}
`;

export const STAFF_GET_ENTERPRISE_USER_QUERY = `
query GetEnterpriseUser($id: ID!) {
  getEnterpriseUser(id: $id) {
    ${ENTERPRISE_USER_SNIPPET}
  }
}
`;

export const STAFF_UPDATE_ENTERPRISE_USER_MUTATION = `
  mutation UpdateEnterpriseUser($id: ID!, $input: EnterpriseUserInput!) {
    updateEnterpriseUser(id: $id, input: $input) {
      ${ENTERPRISE_USER_SNIPPET}
    }
  }
`;

export const STAFF_CREATE_ENTERPRISE_USER_MUTATION = `
  mutation CreateEnterpriseUser($input: EnterpriseUserInput!) {
    createEnterpriseUser(input: $input) {
      ${ENTERPRISE_USER_SNIPPET}
    }
  }
`;

export const STAFF_RESET_PASSWORD_MUTATION = `
  mutation UpdateEnterpriseUser($id: ID!, $input: EnterpriseUserInput!) {
    updateEnterpriseUser(id: $id, input: $input) {
      ${ENTERPRISE_USER_SNIPPET}
    }
  }
`;

const INFO_SNIPPET = `
  id
  name
  enterprise_id
  email
  skip_deposit
  key
  urls {
    success
    failure
  }
  rates {
    name
    fee
    enabled
  }
`;

export const STAFF_ALL_ENTERPRISE_INFO_QUERY = `
  query {
    allEnterprises {
      ${INFO_SNIPPET}
    }
  }
`;

export const STAFF_GET_ENTERPRISE_INFO_QUERY = `
  query GetEnterpriseInfo($id: ID!) {
    getEnterprise(id: $id) {
      ${INFO_SNIPPET}
    }
  }
`;

const APPLICATION_SNIPPET = `
  id
  application_id
  description
  price
  deposit
  reference
  status
  customer_details {
    first_name
    last_name
    middle_name
    email
    telephone
    mobile
    date_of_birth
    monthly_rent_amount
    number_of_dependants
    title
    gender
    marital_status
    residential_status
    current_address {
      years_at_address
      post_code
      city
      flat
      house_number
      house_name
      street
    }
  }
  employment_details {
    employment_status
    years_employed
    source_of_income
    business_name
    main_occupation
    employer_name
    gross_annual_income
    household_annual_income
  }
  enterprise_id
  created_at
  updated_at
  rate
  skip_deposit
  offer_finance_link
  bank_details {
    account_number
    sort_code
  }
`;

export const STAFF_ALL_LOAN_APPLICATIONS_QUERY = `
  query {
    allLoanApplications {
      ${APPLICATION_SNIPPET}
    }
  }
`;

export const STAFF_GET_LOAN_APPLICATION_QUERY = `
  query GetLoanApplication($id: ID!) {
    getLoanApplication(id: $id) {
      ${APPLICATION_SNIPPET}
    }
  }
`;

const ENTERPRISE_SNIPPET = `
  id
  name
  enterprise_id
  email
  skip_deposit
  key
  urls {
    success
    failure
  }
  rates {
    name
    fee
    enabled
  }
`;

export const STAFF_CREATE_ENTERPRISE_MUTATION = `
  mutation CreateEnterprises($input: EnterpriseInput!) {
    createEnterprise(input: $input) {
      ${ENTERPRISE_SNIPPET}
    }
  }
`;

export const STAFF_CREATE_ENTERPRISES_MUTATION = `
  mutation CreateEnterprises($input: [EnterpriseInput!]!) {
    createEnterprises(input: $input) {
      ${ENTERPRISE_SNIPPET}
    }
  }
`;

export const STAFF_UPDATE_ENTERPRISE_MUTATION = `
  mutation UpdateEnterprise($id: ID!, $input: EnterpriseInput!) {
    updateEnterprise(id: $id, input: $input) {
      ${ENTERPRISE_SNIPPET}
    }
  }
`;
