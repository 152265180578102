import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import Container from './DropDown-container';
import Button from '../../../Button';

const ViewComponent = ({
  forwardRef,
  options: _options,
  placeholder,
  error,
  isLoading,
  className,
  inline,
  ...restProps
}) => (
  <Container
    {...restProps}
    options={_options}
    placeholder={placeholder}
    error={error}
    isLoading={isLoading}
    className={className}
  >
    {({ options, defaultValue, currentValue, classNames, selectedLabel }) => (
      <React.Fragment>
        {isLoading ? (
          <div className={classNames.select}>
            <div className={classNames.skeleton}>
              <Skeleton />
            </div>
          </div>
        ) : (
          <div className="relative overflow-hidden">
            <select
              className={classNames.select}
              options={options}
              defaultValue={defaultValue}
              value={currentValue}
              required
              ref={forwardRef}
              {...restProps}
            >
              {options.map(({ value, name }) => (
                <option
                  key={value}
                  disabled={value === defaultValue}
                  value={value}
                >
                  {name}
                </option>
              ))}
            </select>
            <Button.Link
              ref={forwardRef}
              isLoading={isLoading}
              className="pointer-events-none"
              variant="grey"
              size={12}
              icon="chevron-down"
              label={selectedLabel}
            />
          </div>
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'UncontrolledDropDown-view';

ViewComponent.defaultProps = {
  options: [],
  id: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  forwardRef: undefined,
  onChange: undefined,
  onBlur: undefined,
  error: undefined,
  isLoading: undefined,
  className: undefined,
  inline: false,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * class name
   */
  className: PropTypes.string,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * Forwared ref from parent component
   */
  forwardRef: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Inline
   */
  inline: PropTypes.bool,
};

export default ViewComponent;
