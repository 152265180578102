import { useFormContext, useWatch } from 'react-hook-form';

import { CLASS_NAMES_MULTIPLE, VARIANTS } from './Option-constants';

import Handle from '../../../Handle';
import { useFormTheme } from '../../../FormTheme';
import { useClasses } from '../../../../../hooks';

const ContainerComponent = ({
  children,
  multiple,
  fieldName,
  optionValue,
  variant: variantName,
  inline,
}) => {
  const { register } = useFormContext();
  const { skeletonClasses } = useFormTheme();

  /**
   * Get current value from react hook forms
   */
  const fieldValue = useWatch({ name: fieldName });

  /**
   * Checks if option is selected
   */
  const isSelected =
    `${fieldValue}` === `${optionValue}` ||
    (Array.isArray(fieldValue) && fieldValue.includes(optionValue));

  /**
   * get styles from constats depending on if the field is multiple or single select
   */
  const variant = multiple ? CLASS_NAMES_MULTIPLE : VARIANTS[variantName];

  const optionFlex = inline ? '.inline.option' : '.multiline.option';

  const seletedPrefix = isSelected ? 'selected' : 'notSelected';

  const classNames = useClasses(variant, {
    option: [optionFlex, `.${seletedPrefix}.option`],
    container: [`.${seletedPrefix}.container`],
    skeleton: [skeletonClasses],
  });

  /**
   * return appropriet handle type depending if field is multiselect or not
   */
  const handle = multiple ? Handle.RadioCheck : Handle.RadioButton;

  /**
   * return appropriet handle type depending if field is multiselect or not
   */
  const id = `${fieldName}-${optionValue}`;

  /**
   * return input props from react hook forms
   */
  const handleProps = register(fieldName);

  return children({
    id,
    classNames,
    handleProps,
    handle,
  });
};

ContainerComponent.displayName = 'Option-container';

export default ContainerComponent;
