import { DEFAULT_THEME } from '../../FormTheme-constants';

const ContainerComponent = ({ children, ...props }) =>
  children({
    ...DEFAULT_THEME,
    ...props,
  });

ContainerComponent.displayName = 'FormThemeProvider-container';

export default ContainerComponent;
