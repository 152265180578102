import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';
import clsx from 'clsx';

import { OPACITIES, BACKDROP_CLASS_NAME } from '../Modals-constants';
import { opacitySuffix } from '../Modals-functions';
import useOuterClick from './ModalContainer-useOuterClick';
import ModalsWrapper from '../../ModalsWrapper';

/* eslint-disable react/prop-types */
const ContainerComponent = ({ children, id, opacity }) => {
  const { visibleModals, hideModal } = useContext(ModalsWrapper.Context);

  const suffix = opacitySuffix(opacity);

  const backdropClassName = clsx(BACKDROP_CLASS_NAME, `opacity${suffix}`);

  const showBackdrop = visibleModals.indexOf(id) === 0;

  /*
   * Handles close logic
   */
  const handleClose = useCallback(() => {
    hideModal(id);
  }, [id, hideModal]);

  /*
   * Uses hook to call close when the click is outside of modal
   */
  const ref = useOuterClick(handleClose);

  /*
   * Displays nothing if modal is not visible
   */
  if (visibleModals.indexOf(id) === -1) {
    return null;
  }

  /*
   * Uses portal to move modal "modals" div on root so it would not have problems with z-index
   */
  return ReactDOM.createPortal(
    children({ showBackdrop, backdropClassName, handleClose, ref }),
    document.getElementById('modals')
  );
};

ContainerComponent.displayName = 'ModalContainer-Container';

ContainerComponent.defaultProps = {
  opacity: 5,
};

ContainerComponent.propTypes = {
  /**
   * id of modal
   */
  id: PropTypes.string.isRequired,
  /**
   * modal opacity
   */
  opacity: PropTypes.oneOf(OPACITIES),
};

export default ContainerComponent;
