import { useState, useCallback } from 'react';

import { CLASS_NAMES } from './Row-constants';

import { useClasses } from '../../../../../hooks';

const ContainerComponent = ({
  children,
  row,
  prepareRow,
  hoverPopover,
  onRowClicked,
}) => {
  /**
   * handles row click
   * @param {*} e
   */
  const handleClick = (e) => onRowClicked && onRowClicked(row, e);

  prepareRow(row);

  const { cells, getRowProps, index } = row;

  const rowProps = getRowProps({
    onClick: handleClick,
  });

  const [showButton, _setShowButton] = useState(false);
  const [_delayHandler, _setDelayHandler] = useState(null);

  const classNames = useClasses(CLASS_NAMES, {
    // base: ['.base', index === 9 ? '' : '.border', hoverPopover ? '.hover' : ''],
    base: ['.base', index === 9 ? '' : '.border', '.hover'],
    button: ['.button'],
  });

  const outputCells = cells.map((cell) => ({
    ...cell,
    key: `${cell.column.Header}-row-${cell.row.id}`,
  }));

  const onMouseEnter = useCallback(() => {
    if (!hoverPopover) return;
    _setDelayHandler(setTimeout(() => _setShowButton(true), 50));
  }, [hoverPopover]);

  const onMouseLeave = useCallback(() => {
    clearTimeout(_delayHandler);
    if (!hoverPopover) return;
    if (!showButton) return;
    _setShowButton(false);
  }, [hoverPopover, _delayHandler, showButton]);

  return children({
    row,
    classNames,
    rowProps,
    cells: outputCells,
    showButton,
    onMouseEnter,
    onMouseLeave,
    hoverPopover,
  });
};

ContainerComponent.displayName = 'Row-container';

export default ContainerComponent;
