import { useContext, useEffect } from 'react';

import ModalsWrapper from '../ModalsWrapper';

/* eslint-disable react/prop-types */
const ContainerComponent = ({ isLoading }) => {
  const { setShowLoader } = useContext(ModalsWrapper.Context);

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading, setShowLoader]);

  useEffect(
    () => () => {
      setShowLoader(false);
    },
    [setShowLoader]
  );

  return null;
};

ContainerComponent.displayName = 'Loader-container';

export default ContainerComponent;
