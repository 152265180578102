import { clearOnMountMutation } from '../utils';

/**
 * Keep the map of all keys liked to the servie methods
 */
let fetchers = {};

/**
 * Function to return method from key
 * @param {String} key
 * @returns {Function}
 */
export const getMethod = (key) => fetchers[key];

/**
 * Checks if key has context
 * @param {String} key
 * @returns {Function}
 */
export const hasContextParam = (key) => key?.includes('-USECTX');

/**
 * Adds context to key
 * @param {String} key
 * @returns {String} key
 */
export const withContextParam = (key) => `${key}-USECTX`;

/**
 * Function to fetch services by key and arguments
 * @param {Array} arguments
 * @returns {Promise} result
 */
export const fetcher =
  (ctx) =>
  (...key) => {
    const [methodKey, ...args] = key;

    const method = getMethod(methodKey);

    if (ctx) {
      clearOnMountMutation(ctx.originalKey);
    }

    if (hasContextParam(methodKey)) {
      return method(ctx, ...args);
    }

    return method(...args);
  };

/**
 * Function to add another service on the list
 * @param {Object} service
 */
export const addService = (service) => {
  fetchers = { ...fetchers, ...service.fetcher };
};
