export const SEARCH_PLACEHOLDER = 'Search Companies House';

export const SKELETON_DATA = [
  { companyNumber: 'loading-0' },
  { companyNumber: 'loading-1' },
  { companyNumber: 'loading-2' },
];

export const DEBOUNCE_INTERVAL = 300;

export const NUMBERS_AND_DASH_REGEX = /^[\d-]+$/i;
