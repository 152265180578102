import React from 'react';

import Container from './ScrollTopButton-container';
import Button from '../../Button';
import { BUTTON_PROPS } from './ScrollTopButton-constants';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ className, scrollTop }) => (
      <Button.Icon
        onClick={scrollTop}
        {...BUTTON_PROPS}
        className={className}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Layout-view';

export default ViewComponent;
