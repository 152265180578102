import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import Container from './Item-container';
import {
  HIGHLIGHT_PROPS,
  CLASS_NAMES,
  SKELETONS,
  SEPERATOR,
} from './Item-constants';

import SkeletonLoader from '../../../../../../../../SkeletonLoader';

const ViewComponent = ({ isLoading, company, searchWords, handleSelect }) => (
  <Container
    handleSelect={handleSelect}
    company={company}
    isLoading={isLoading}
  >
    {({ onSelect, address }) => (
      <button
        type="button"
        className={CLASS_NAMES.container}
        onClick={onSelect}
      >
        <p className={CLASS_NAMES.title}>
          <SkeletonLoader
            {...SKELETONS.title}
            isLoading={isLoading}
            afterLoading={
              <Highlighter
                {...HIGHLIGHT_PROPS}
                searchWords={searchWords}
                textToHighlight={company?.companyName}
              />
            }
          />
        </p>
        <div className={CLASS_NAMES.content}>
          <SkeletonLoader
            {...SKELETONS.companyNumber}
            isLoading={isLoading}
            afterLoading={
              <Highlighter
                {...HIGHLIGHT_PROPS}
                searchWords={searchWords}
                textToHighlight={company?.companyNumber}
              />
            }
          />
          <span className={CLASS_NAMES.seperator}>{SEPERATOR}</span>
          <SkeletonLoader
            {...SKELETONS.address}
            isLoading={isLoading}
            afterLoading={
              <Highlighter
                {...HIGHLIGHT_PROPS}
                searchWords={searchWords}
                textToHighlight={address}
              />
            }
          />
        </div>
      </button>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyLookup-Mobile-Item-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  company: undefined,
  searchWords: undefined,
};

ViewComponent.propTypes = {
  /**
   * Handle select customer
   */
  handleSelect: PropTypes.func.isRequired,
  /**
   * Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Search words
   */
  searchWords: PropTypes.arrayOf(PropTypes.string),
  /**
   * Company
   */
  company: PropTypes.shape({
    companyNumber: PropTypes.string,
    companyName: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    addressLineOne: PropTypes.string,
    addressLineTwo: PropTypes.string,
  }),
};

export default ViewComponent;
