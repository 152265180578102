import React from 'react';
import PropTypes from 'prop-types';

import Container from './Footer-container';
import { CLASS_NAMES } from './Footer-constants';

import Button from '../../../Button';

const ViewComponent = ({ nextButton, ...restProps }) => (
  <Container {...restProps}>
    {({ prevButtonProps, nextButtonProps }) => (
      <div className={CLASS_NAMES.container}>
        <Button.Icon {...prevButtonProps} />
        {nextButton || <Button.Text {...nextButtonProps} />}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'MultiStepForm-Footer-view';

ViewComponent.defaultProps = {
  children: null,
  nextButton: undefined,
  nextButtonProps: undefined,
  prevButtonProps: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Next button
   */
  nextButton: PropTypes.element,
  /**
   * Next button props
   */
  nextButtonProps: PropTypes.shape({
    className: PropTypes.string,
    icon: PropTypes.string,
    iconProps: PropTypes.shape({
      className: PropTypes.string,
      stroke: PropTypes.string,
      flip: PropTypes.bool,
      width: PropTypes.number,
      height: PropTypes.number,
      size: PropTypes.number,
      fill: PropTypes.string,
      isLoading: PropTypes.bool,
    }),
    variant: PropTypes.oneOf(['solid', 'gradient', 'ghost', 'outline']),
    disabled: PropTypes.bool,
    submit: PropTypes.bool,
    left: PropTypes.bool,
    size: PropTypes.oneOf(['custom', 48, 40, 32]),
    label: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    isLoading: PropTypes.bool,
    iconSpacing: PropTypes.number,
  }),
  /**
   * Prev button props
   */
  prevButtonProps: PropTypes.shape({
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    id: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
    variant: PropTypes.oneOf([
      'turquoise',
      'light-turquoise',
      'solid-grey',
      'ghost-grey',
    ]),
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.oneOf(['48-20', '40-20', '32-20', '32-16', '28-16']),
    ]),
    submit: PropTypes.bool,
    isLoading: PropTypes.bool,
    iconProps: PropTypes.shape({
      className: PropTypes.string,
      stroke: PropTypes.string,
      flip: PropTypes.bool,
      width: PropTypes.number,
      height: PropTypes.number,
      size: PropTypes.number,
      fill: PropTypes.string,
      isLoading: PropTypes.bool,
    }),
    onClick: PropTypes.func,
  }),
};

export default ViewComponent;
