import {
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode as applyFirebaseActionCode,
  parseActionCodeURL,
  signInWithEmailLink as signInEmailLink,
  sendSignInLinkToEmail as sendSignInLinkEmail,
  updatePassword as submitNewPassword,
  updateEmail as submitNewEmail,
  reauthenticateWithCredential,
  sendEmailVerification as sendVerificationEmail,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
} from 'firebase/auth';

import { FirebaseAuthService } from './firebaseAuthService';
import { HubService } from './hubService';

import { Service } from '../lib';
import { redirectTo } from '../utils';
import config from '../config';
import '../lib/firebase-config';

/**
 * Firebase User Auth Service
 * @typedef {Object} FirebaseUserAuthServiceOptions
 * @property {AuthService} authService auth service
 */
export class FirebaseUserAuthServiceImplementation extends Service {
  /**
   * Constructor
   * @param {CustomersServiceOptions} options
   */
  constructor({ authService }) {
    super({ authService });

    this.signUpWithEmailAndPassword =
      this.signUpWithEmailAndPassword.bind(this);
    this.signInWithEmailAndPassword =
      this.signInWithEmailAndPassword.bind(this);
    this.signInWithGoogle = this.signInWithGoogle.bind(this);
    this.signInWithFacebook = this.signInWithFacebook.bind(this);
    this.sendResetPasswordEmail = this.sendResetPasswordEmail.bind(this);
    this.verifyResetCode = this.verifyResetCode.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.applyActionCode = this.applyActionCode.bind(this);
    this.parseActionCode = this.parseActionCode.bind(this);
    this.signInWithEmailLink = this.signInWithEmailLink.bind(this);
    this.sendSignInLinkToEmail = this.sendSignInLinkToEmail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.reauthenticate = this.reauthenticate.bind(this);
    this.sendEmailVerification = this.sendEmailVerification.bind(this);

    this.firebaseUser = this.firebaseUser.bind(this);
    this.refreshToken = this.refreshToken.bind(this);

    this.providers = {
      google: new GoogleAuthProvider(),
      facebook: new FacebookAuthProvider(),
    };

    this.key = {
      isUserLoggedIn: 'UserAuthService/isUserLoggedIn',
      firebaseUser: 'UserAuthService/firebaseUser',
    };

    this.mapKeys({
      [this.key.isUserLoggedIn]: this.isUserLoggedIn,
      [this.key.firebaseUser]: this.firebaseUser,
    });
  }

  /**
   * Adds sign up with email and password method
   * @typedef {Object} SignUpWithEmailAndPasswordPayload
   * @param {String} payload.email email of user
   * @param {String} payload.password password of user
   */
  async signUpWithEmailAndPassword({ email, password }, redirect = false) {
    await setPersistence(this.authService.auth, browserLocalPersistence);
    await createUserWithEmailAndPassword(
      this.authService.auth,
      email,
      password,
    );
    // Refesh auth service
    await super.refetch([this.key.isUserLoggedIn]);
    await super.refetch([this.key.firebaseUser]);
    // Call `me` service
    await super.use([HubService.me]);
    // Refetch token
    await this.authService.token();
    // Redirect back home
    if (!redirect) return;
    redirectTo(config.HOME_URL);
  }

  /**
   * Adds sign in with email and password method
   */
  async signInWithEmailAndPassword({ email, password }, redirect = false) {
    await setPersistence(this.authService.auth, browserLocalPersistence);
    await signInWithEmailAndPassword(this.authService.auth, email, password);
    // Refesh auth service
    await super.refetch([this.key.isUserLoggedIn]);
    await super.refetch([this.key.firebaseUser]);
    // Call `me` service
    await super.use([HubService.me]);
    // Refetch token
    await this.authService.token();
    // Redirect back home
    if (!redirect) return;
    redirectTo(config.HOME_URL);
  }

  /**
   * Adds sign in with email and password method
   */
  async signInWithGoogle(redirect = true) {
    // Log in
    await setPersistence(this.authService.auth, browserLocalPersistence);
    await signInWithPopup(this.authService.auth, this.providers.google);
    // Refesh auth service
    await super.refetch([this.key.isUserLoggedIn]);
    await super.refetch([this.key.firebaseUser]);
    // Call `me` service
    await super.use([HubService.me]);
    // Refetch token
    await this.authService.token();
    // Redirect back home
    // return { isLoggedIn: true };
    if (!redirect) return;
    redirectTo(config.HOME_URL);
  }

  /**
   * Adds sign in with email and password method
   */
  async signInWithFacebook(redirect = true) {
    // Log in
    await setPersistence(this.authService.auth, browserLocalPersistence);
    await signInWithPopup(this.authService.auth, this.providers.facebook);
    // Refesh auth service
    await super.refetch([this.key.isUserLoggedIn]);
    await super.refetch([this.key.firebaseUser]);
    // Call `me` service
    await super.use([HubService.me]);
    // Refetch token
    await this.authService.token();
    // Redirect back home
    // return { isLoggedIn: true };
    if (!redirect) return;
    redirectTo(config.HOME_URL);
  }

  /**
   * Adds sign in with email and password method
   */
  async sendResetPasswordEmail(email) {
    // Reset password
    await sendPasswordResetEmail(this.authService.auth, email);
  }

  /**
   * Verify the reset password code
   */
  async verifyResetCode(code) {
    const confirmation = await verifyPasswordResetCode(
      this.authService.auth,
      code,
    );
    return confirmation;
  }

  /**
   * Reset the password
   */
  async resetPassword(code, newPassword) {
    const confirmation = await confirmPasswordReset(
      this.authService.auth,
      code,
      newPassword,
    );
    return confirmation;
  }

  /**
   * Apply action code
   */
  async applyActionCode(code) {
    const result = await applyFirebaseActionCode(this.authService.auth, code);
    return result;
  }

  /**
   * Apply action code
   */
  async parseActionCode(url) {
    const result = await parseActionCodeURL(url);
    return result;
  }

  /**
   * Apply action code
   */
  async signInWithEmailLink(email, url) {
    const result = await signInEmailLink(this.authService.auth, email, url);
    return result;
  }

  /**
   * Apply action code
   */
  async sendSignInLinkToEmail(email, settings) {
    const result = await sendSignInLinkEmail(
      this.authService.auth,
      email,
      settings,
    );
    return result;
  }

  /**
   * Update user's password
   */
  async updatePassword(newPassword) {
    const result = await submitNewPassword(
      this.authService.auth.currentUser,
      newPassword,
    );
    return result;
  }

  /**
   * Update user's email
   */
  async updateEmail(newEmail) {
    const result = await submitNewEmail(
      this.authService.auth.currentUser,
      newEmail,
    );
    return result;
  }

  /**
   * Reauthenticate a user
   */
  async reauthenticate(password) {
    const user = this.authService.auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);
    const result = await reauthenticateWithCredential(user, credential);
    return result;
  }

  /**
   * Reauthenticate a user
   */
  async sendEmailVerification() {
    const user = this.authService.auth.currentUser;
    const result = await sendVerificationEmail(user);
    return result;
  }

  /**
   * Refreshes user token
   */
  async refreshToken() {
    console.log('refreshToken called');
    // console.log({ user });
    const token = await this.authService.token();
    console.log('token: ', token);
    // console.log({ token });
    return token;
  }

  /**
   * Checks if user is logged in
   */
  async isUserLoggedIn() {
    const token = await this.authService.token();
    return Boolean(token);
  }

  /**
   * Returns the currently signed in user info
   */
  async firebaseUser() {
    const user = await this.authService.user();
    return user;
  }
}

export const FirebaseUserAuthService =
  new FirebaseUserAuthServiceImplementation({
    authService: FirebaseAuthService,
  });
