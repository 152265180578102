import { extractJobLabel } from '@kanda-utils/library';

const ContainerComponent = ({ children, status, financeStatus }) => {
  const { label, color } = extractJobLabel(status, financeStatus);

  return children({
    label,
    color,
  });
};

ContainerComponent.displayName = 'StatusTag-Container';

export default ContainerComponent;
