import React from 'react';
import PropTypes from 'prop-types';

import Container from './PopoverButton-container';
import { CLASS_NAMES, BUTTON_PROPS } from './PopoverButton-constants';

import Button from '../../../../../Button';
import Popover from '../../../../../Popover';

const ViewComponent = ({ row, hoverPopover: HoverPopover }) => (
  <Container row={row}>
    {({ popoverProps }) => (
      <div className={CLASS_NAMES.stickyContainer}>
        <div className={CLASS_NAMES.relativeContainer}>
          <div className={CLASS_NAMES.absoluteContainer}>
            <div className={CLASS_NAMES.fadeLeft} />
            <div className={CLASS_NAMES.button}>
              <div className={CLASS_NAMES.wrapper}>
                <Popover.Standard
                  button={<Button.Icon {...BUTTON_PROPS} />}
                  {...popoverProps}
                >
                  {({ handleClose }) => (
                    <HoverPopover row={row} handleClose={handleClose} />
                  )}
                </Popover.Standard>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.propTypes = {
  /**
   *
   */
  hoverPopover: PropTypes.func.isRequired,
  /**
   *
   */
  row: PropTypes.object.isRequired,
};

ViewComponent.displayName = 'RowPopoverButton-view';

export default ViewComponent;
