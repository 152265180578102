import React from 'react';
import PropTypes from 'prop-types';

import HelpCrunch from '../HelpCrunch';
import ModalsWrapper from '../ModalsWrapper';
import ToastContainer from '../Toast';

const ViewComponent = ({ children, ...restProps }) => (
  <ModalsWrapper {...restProps}>
    <HelpCrunch />
    <ToastContainer />
    {children}
  </ModalsWrapper>
);

ViewComponent.displayName = 'CommonWrapper-view';

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
