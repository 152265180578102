import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import Container from './LineItem-container';
import { STYLES } from '../../../../Render-constants';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ quantity, title, description, price, vat, total }) => (
      <View style={STYLES.lineItem}>
        <Text
          style={{
            ...STYLES['text-12-18'],
            ...STYLES['text-neutral-600'],
            ...STYLES.lineDetailSmall,
            ...STYLES.quantity,
          }}
        >
          {quantity}
        </Text>
        <View
          style={{
            ...STYLES.column,
            ...STYLES.title,
          }}
        >
          <Text
            style={{
              ...STYLES['text-14-22-em'],
              ...STYLES['text-neutral-700'],
            }}
          >
            {title}
          </Text>
          {description && (
            <Text
              style={{
                ...STYLES['text-12-18'],
                ...STYLES['text-neutral-600'],
                ...STYLES.mt1,
              }}
            >
              {description}
            </Text>
          )}
        </View>
        <Text
          style={{
            ...STYLES['text-12-18'],
            ...STYLES['text-neutral-600'],
            ...STYLES.lineDetailSmall,
            ...STYLES.price,
          }}
        >
          {price}
        </Text>
        <Text
          style={{
            ...STYLES['text-12-18'],
            ...STYLES['text-neutral-600'],
            ...STYLES.lineDetailSmall,
            ...STYLES.vat,
          }}
        >
          {vat}
        </Text>
        <Text
          style={{
            ...STYLES['text-16-20-em'],
            ...STYLES['text-neutral-500'],
            ...STYLES.total,
          }}
        >
          {total}
        </Text>
      </View>
    )}
  </Container>
);

ViewComponent.displayName = 'Render-LineItem-view';

export default ViewComponent;
