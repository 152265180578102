import React from 'react';
import PropTypes from 'prop-types';

import { BUTTON_CLASSNAME, ICON_PROPS } from './HeaderButton-constants';

import Icon from '../../../../../../Icon';

const ViewComponent = ({ label, ...props }) => (
  <button className={BUTTON_CLASSNAME} {...props}>
    {label}
    <Icon {...ICON_PROPS} />
  </button>
);

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * Label for button
   */
  label: PropTypes.string.isRequired,
};

ViewComponent.displayName = 'HeaderButton-view';

export default ViewComponent;
