export const NAVIGATON_LINK_CLASS_NAMES = {
  containerBase: 'w-full flex flex-1 flex-row relative items-center',
  content: 'w-full flex flex-1 flex-col',
  titleWrapper: 'flex flex-1 flex-row',
  title: 'text-style-f-em text-neutral-700 relative',
  subtitle: 'text-style-g text-neutral-600 mt-0.5',
  badgeBase: 'ml-1 w-2 h-2 rounded-full',
};

export const ICON_PROPS = {
  icon: 'chevron-right',
  stroke: 'neutral-500',
  size: 20,
};

export const SKELETONS = {
  title: {
    width: 160,
  },
  subtitle: {
    width: 200,
  },
  icon: {
    width: 24,
    height: 24,
  },
};
