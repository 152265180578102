import { useEffect } from 'react';
import PropTypes from 'prop-types';

import useIntercom from './useIntercom';

const ContainerComponent = ({ children }) => {
  const { intercom, boot } = useIntercom();

  // Effect calls intercom boot method on render
  useEffect(() => {
    if (intercom) return;
    boot();
  }, [boot, intercom]);

  return children;
};

ContainerComponent.displayName = 'Intercom-Container';

ContainerComponent.defaultProps = {
  children: null,
};

ContainerComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node,
};

export default ContainerComponent;
