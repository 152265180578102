/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import FieldWrapper from './FieldWrapper-view';

export const withFieldWrapper = (Component) =>
  React.forwardRef(
    (
      {
        id,
        name,
        label,
        helperText,
        error,
        warning,
        isLoading,
        wrapperProps,
        autoWidth,
        prepend,
        append,
        ...restProps
      },
      ref
    ) => (
      <FieldWrapper
        id={id || name}
        label={label}
        helperText={helperText}
        error={error}
        warning={warning}
        isLoading={isLoading}
        autoWidth={autoWidth}
        prepend={prepend}
        append={append}
        {...wrapperProps}
      >
        <Component
          error={error}
          id={id}
          name={name}
          isLoading={isLoading}
          {...restProps}
          forwardRef={ref}
        />
      </FieldWrapper>
    )
  );

withFieldWrapper.defaultProps = {};

withFieldWrapper.displayName = 'withFieldWrapper';

withFieldWrapper.propTypes = {
  /**
   * name of the input
   */
  name: PropTypes.string.isRequired,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Text next to label
   */
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Auto width
   */
  autoWidth: PropTypes.bool,
  /**
   * Prepend element
   */
  prepend: PropTypes.element,
  /**
   * Append element
   */
  append: PropTypes.element,
};
