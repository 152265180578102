import { useContext } from 'react';

import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { job } = useContext(Context);

  return children({
    title: job?.title,
  });
};

ContainerComponent.displayName = 'Render-Title-container';

export default ContainerComponent;
