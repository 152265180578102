/* eslint import/no-cycle: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import Container from './Provider-container';

import Context from '../context';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {(values) => <Context.Provider value={values}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.defaultProps = { children: null, droppedFiles: undefined };

ViewComponent.displayName = 'Dropzone-Context-Provider-view';

ViewComponent.propTypes = {
  /**
   * Function to show modal
   */
  droppedFiles: PropTypes.array,
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
