import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from './Search-constants';

import FormTheme from '../../../../Form/FormTheme';
import UncontrolledField from '../../../../Form/UncontrolledField';

const ViewComponent = ({ ...props }) => (
  <div className={CLASS_NAMES.container}>
    <FormTheme variant="inline">
      <UncontrolledField.Input {...props} />
    </FormTheme>
  </div>
);

ViewComponent.displayName = 'Popover-Advanced-Search-view';

ViewComponent.defaultProps = {
  placeholder: undefined,
  defaultValue: undefined,
  onChange: undefined,
  onBlur: undefined,
  name: 'search',
};

ViewComponent.propTypes = {
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * HTML id
   */
  name: PropTypes.string,
};

export default ViewComponent;
