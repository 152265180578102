import React from 'react';
import PropTypes from 'prop-types';

import Container from './Loader-container';

const ViewComponent = ({ isLoading }) => (
  <Container isLoading={isLoading}>
    {({ width, classNames }) => (
      <React.Fragment>
        {isLoading && <div className={classNames.overlay} />}
        <div data-cy="loader" className={classNames.container}>
          <div className={classNames.bar} style={{ width }} />
        </div>
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'Loader-view';

ViewComponent.defaultProps = { isLoading: undefined };

ViewComponent.propTypes = {
  /**
   * Is loading
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
