import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from './ContentHeader-constants';

const ViewComponent = ({ children }) => (
  <div className={CLASS_NAMES.contentHeader}>{children}</div>
);

ViewComponent.displayName = 'DesktopLayoutBoxed-ContentHeader-view';

ViewComponent.defaultProps = {
  children: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
