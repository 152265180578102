import {
  QUOTE_STATUS,
  QUOTE_LABELS,
  QUOTE_ACTION_LABELS,
  JOB_STATUSES,
  JOB_LABELS,
  JOB_FINANCE_STATUSES,
  JOB_STATUS_FINANCE_LABEL,
} from '../constants';

/**
 * Extracts the label properties (text and color) for the Tag component in the
 * quote view
 *
 * @param {Number} status - The quote status number
 */
export const extractLabel = (status) => {
  if (status || status === 0) {
    const statusName = Object.keys(QUOTE_STATUS).find(
      (num) => QUOTE_STATUS[num] === status,
    );
    return QUOTE_LABELS[statusName];
  }
  return QUOTE_LABELS.DRAFT;
};

/**
 * Extracts the quote action label StatusActionLabel component
 *
 * @param {Number} status - The quote status number
 */
export const extractActionLabel = (status) => {
  if (status || status === 0) {
    const statusName = Object.keys(QUOTE_STATUS).find(
      (num) => QUOTE_STATUS[num] === status,
    );
    return QUOTE_ACTION_LABELS[statusName];
  }
  return QUOTE_ACTION_LABELS.DRAFT;
};

/**
 * Extracts the color and text for the tag for the job label
 *
 * @param {String} status - The job status string
 * @param {String} financeStatus - The fiance status string
 */
export const extractJobLabel = (status, financeStatus) => {
  // Show the finance label if there is a finance status and the job status is
  // in a status associated with finance
  const showFinanceLabel =
    financeStatus &&
    [JOB_STATUSES.SENT_TO_CUSTOMER, JOB_STATUSES.ACCEPTED].includes(status);
  // If not a financed quote, return the job label object
  if (!showFinanceLabel) return JOB_LABELS[status] || JOB_LABELS.DRAFT;
  // Extract the finance status key
  const financeStatusKey = Object.keys(JOB_FINANCE_STATUSES).find(
    (status) => JOB_FINANCE_STATUSES[status] === financeStatus,
  );
  // Return the finance label
  return (
    JOB_STATUS_FINANCE_LABEL[financeStatusKey] ||
    JOB_STATUS_FINANCE_LABEL.OFFERED
  );
};
