import { useContext } from 'react';

import useIsDesktop from '../isDesktop';
import LoadingProvider from '../../components/LoadingProvider';

const useVariants = (variants) => {
  const isDesktop = useIsDesktop();
  const loading = useContext(LoadingProvider.Context);

  const media = isDesktop ? 'desktop' : 'mobile';

  return { media, loading, ...variants };
};

export default useVariants;
