import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import Container from './ColumnOption-container';

import { Handle } from '../../../../../../Form';
import Icon from '../../../../../../Icon';

const ViewComponent = ({ column, index, ...props }) => (
  <Draggable draggableId={column.id} index={index}>
    {(provided, snapshot) => (
      <Container
        provided={provided}
        snapshot={snapshot}
        column={column}
        {...props}
      >
        {({
          handleProps,
          name,
          innerRef,
          draggableProps,
          dragHandleProps,
          classNames,
          iconProps,
        }) => (
          <div
            ref={innerRef}
            className={classNames.container}
            {...draggableProps}
          >
            <div className={classNames.checkbox.container}>
              <div className={classNames.checkbox.margin}>
                <Handle.Checkbox {...handleProps} />
              </div>
              <span className={classNames.text}>{name}</span>
            </div>
            <div {...dragHandleProps} className={classNames.dragHandle}>
              <Icon {...iconProps} />
            </div>
          </div>
        )}
      </Container>
    )}
  </Draggable>
);
//
//

ViewComponent.displayName = 'ColumnOption-view';

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * Table column data
   */
  column: PropTypes.object.isRequired,
  /**
   * Index of draggable item
   */
  index: PropTypes.number.isRequired,
};

export default ViewComponent;
