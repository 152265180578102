import { CARD_CLASS_NAMES } from './Card-constants';

import { useClasses } from '../../hooks';

const ContainerComponent = ({
  children,
  className,
  padding,
  footerOptions,
  noContent,
  footer,
  title,
  isLoading,
  showLoadingSkeleton,
  variant,
}) => {
  const showHeaderMargin =
    !noContent && (title || (isLoading && showLoadingSkeleton?.title));

  const classNames = useClasses(CARD_CLASS_NAMES, {
    variants: {
      variant,
    },
    container: ['.baseContainer', padding, className],
    header: ['.headerBase', showHeaderMargin && '.headerMargin'],
  });

  const showFooter =
    footer ||
    footerOptions.length > 0 ||
    (isLoading && showLoadingSkeleton?.footer) ||
    (isLoading && showLoadingSkeleton?.footerOptions);

  return children({
    classNames,
    showFooter,
  });
};

ContainerComponent.displayName = 'Card-Container';

export default ContainerComponent;
