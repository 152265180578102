import React from 'react';
import PropTypes from 'prop-types';

import Container from './DesktopLayoutDefault-container';
import { Content, ContentHeader } from './components';
import { CLASS_NAMES } from './DesktopLayoutDefault-constants';

import { ReactComponent as HeaderBackground } from '../../../assets/header/background.svg';

const ViewComponent = ({ children }) => (
  <Container>
    {({ header: Header, footer: Footer }) => (
      <div id="layout" className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.background}>
          <HeaderBackground className={CLASS_NAMES.lines} />
        </div>
        <div className={CLASS_NAMES.headerWrapper}>
          <Header />
        </div>
        {children}
        <Footer />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopLayoutDefault-view';

ViewComponent.Content = Content;

ViewComponent.ContentHeader = ContentHeader;

ViewComponent.defaultProps = {
  children: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
