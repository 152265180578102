import { useContext } from 'react';
import clsx from 'clsx';

import { CLASS_NAMES } from './Indicator-constants';

import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { currentStepIndex, steps } = useContext(Context);

  const totalSteps = steps.length;

  const { pageBase, pageActive, pageInactive } = CLASS_NAMES;

  const items = Array.from(Array(totalSteps), (_, i) =>
    clsx(pageBase, currentStepIndex === i ? pageActive : pageInactive)
  );

  return children({ items });
};

ContainerComponent.displayName = 'Indicator-container';

export default ContainerComponent;
