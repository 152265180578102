export const checkAndFormatTimestamp = (timestamp, minYear = 2019) => {
  // Construct date object
  const date = new Date(timestamp);

  // Timestamp is incorrectly formatted (not in milliseconds) if year in date
  // object from timestamp is less than min year. Need to correct timestamp
  if (date.getFullYear() < minYear) {
    // catch case for timestamp in seconds - will be less than 10^10
    if (date.getTime() < 10 ** 10) {
      return new Date(timestamp * 1000);
    }
    // catch case for timestamp in tenths of seconds - will be less than 10^11
    if (date.getTime() < 10 ** 11) {
      return new Date(timestamp * 100);
    }
    // if neither of above, timestamp in hundreths of seconds - will be less
    // than 10^12
    return new Date(timestamp * 10);
  }

  return date;
};
