import { JOB_STATUSES } from '@kanda-utils/library';
import { VARIANTS } from './StatusPrice-constants';

const ContainerComponent = ({ children, status }) => {
  const { color } = VARIANTS[status];

  const paid = status === JOB_STATUSES.FINISHED;

  return children({
    color,
    paid,
  });
};

ContainerComponent.displayName = 'StatusPrice-Container';

export default ContainerComponent;
