import React from 'react';
import PropTypes from 'prop-types';

import SkeletonLoader from '../SkeletonLoader';

const ViewComponent = ({
  text,
  isLoading,
  textComponent: TextComponent,
  className,
  skeletonClassName,
  skeletonProps,
  ...restProps
}) => (
  <TextComponent className={className} {...restProps}>
    <SkeletonLoader
      isLoading={isLoading}
      className={skeletonClassName}
      afterLoading={text}
      {...skeletonProps}
    />
  </TextComponent>
);

ViewComponent.displayName = 'Text';

ViewComponent.defaultProps = {
  className: 'text-style-g',
  skeletonClassName: '',
  skeletonProps: {},
  isLoading: false,
  textComponent: 'span',
};

ViewComponent.propTypes = {
  /**
   * Text to display
   */
  text: PropTypes.string.isRequired,
  /**
   * ClassName for wrapper
   */
  className: PropTypes.string,
  /**
   * ClassName for wrapper
   */
  skeletonClassName: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * linkComponent
   */
  textComponent: PropTypes.elementType,
  /**
   * Skeleton props
   */
  skeletonProps: PropTypes.object,
};

export default ViewComponent;
