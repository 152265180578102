import { useContext } from 'react';

import ConfigWrapper from '../../ConfigWrapper';

const ContainerComponent = ({ children }) => {
  const { desktopHeader: header, desktopFooter: footer } = useContext(
    ConfigWrapper.Context
  );

  return children({
    header,
    footer,
  });
};

ContainerComponent.displayName = 'DesktopLayoutDefault-container';

export default ContainerComponent;
