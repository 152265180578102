import React from 'react';
import PropTypes from 'prop-types';

import Container from './StatusTag-container';

import Tag from '../Tag';

const ViewComponent = ({ status, financeStatus, ...props }) => (
  <Container status={status} financeStatus={financeStatus}>
    {({ label, color }) => <Tag label={label} color={color} {...props} />}
  </Container>
);

ViewComponent.displayName = 'StatusTag-view';

ViewComponent.defaultProps = {
  variant: 'outline',
  status: 'DRAFT',
  financeStatus: null,
  size: 24,
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Job status
   */
  status: PropTypes.string,
  /**
   * Job finance  status
   */
  financeStatus: PropTypes.string,
  /**
   * Size
   */
  size: PropTypes.oneOf([31, 28, 24, 21]),
  /**
   * Tag variant name
   */
  variant: PropTypes.oneOf(['solid', 'outline']),
};

export default ViewComponent;
