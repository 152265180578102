import { useEffect, useState, useContext } from 'react';

import ModalsWrapper from '../../../../ModalsWrapper';

/**
 * Hook to handle image logic and state
 * @param {Object} - decomposed into:
 * @param {File} file - file object
 * @param {File} name - field name
 */
const useImage = ({ file, name }) => {
  const [image, setImage] = useState();

  const { showModal, hideModal } = useContext(ModalsWrapper.Context);

  const modalId = `${name}-cropper`;

  /**
   * Converts file on url when it changes and removes url if there is no file
   */
  useEffect(() => {
    if (!file) {
      setImage();
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file]);

  /**
   * Hides and shows cropper modal
   */
  useEffect(() => {
    if (!image) {
      hideModal(modalId);
      return;
    }

    showModal(modalId);
  }, [image, hideModal, showModal, modalId]);

  return { image, modalId };
};

export default useImage;
