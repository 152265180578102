import PropTypes from 'prop-types';
import React from 'react';

import Container from './FinancePlan-container';
import { MoneyCard, TextCard } from './components';

import Text from '../Text';

const ViewComponent = ({
  price,
  deposit,
  apr,
  term,
  className,
  variant,
  isLoading,
}) => (
  <Container
    price={price}
    deposit={deposit}
    apr={apr}
    term={term}
    className={className}
    variant={variant}
    isLoading={isLoading}
  >
    {({
      formattedTerm,
      calculatedDeposit,
      monthly,
      total,
      fee,
      classNames,
    }) => (
      <div className={classNames.container}>
        <Text
          isLoading={isLoading}
          text="Your plan"
          className={classNames.headingMobile}
          skeletonProps={{
            width: 80,
          }}
        />
        <div className={classNames.wrapper}>
          <Text
            isLoading={isLoading}
            text="Your plan"
            className={classNames.headingDesktop}
            skeletonProps={{
              width: 100,
            }}
          />
          <div className={classNames.grid}>
            <MoneyCard
              title="Monthly Payment"
              amount={monthly}
              classNames={classNames}
              isLoading={isLoading}
            />
            <MoneyCard
              title="Deposit"
              amount={calculatedDeposit}
              classNames={classNames}
              isLoading={isLoading}
            />
            <TextCard
              title="Term"
              text={formattedTerm}
              classNames={classNames}
              isLoading={isLoading}
            />
            <MoneyCard
              title="Interest Fee"
              amount={fee}
              classNames={classNames}
              isLoading={isLoading}
            />
            <MoneyCard
              title="Total cost"
              amount={total}
              classNames={classNames}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  price: undefined,
  deposit: undefined,
  apr: undefined,
  term: undefined,
  className: '',
  variant: 'default',
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Price
   */
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Deposit percentage (e.g. 50 = 50% deposit)
   */
  deposit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * APR of selected plan
   */
  apr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Term of plan, in months
   */
  term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * External className to apply to container
   */
  className: PropTypes.string,
  /**
   * Prop to determine if the box should be shown
   */
  variant: PropTypes.oneOf(['default', 'noBox']),
  /**
   * Prop to determine if the box should be shown
   */
  isLoading: PropTypes.bool,
};

ViewComponent.displayName = 'FinancePlan-view';

export default ViewComponent;
