import { useWatch } from 'react-hook-form';

import { formatAddress } from '../../CompanyLookup-functions';

const ContainerComponent = ({ children, ...props }) => {
  const [
    companyName,
    companyNumber,
    addressLineOne,
    addressLineTwo,
    postalCode,
    city,
  ] = useWatch({
    name: [
      props.name,
      props.companyNumberName,
      props.addressLineOneName,
      props.addressLineTwoName,
      props.postalCodeName,
      props.cityName,
    ],
  });

  const address = formatAddress({
    addressLineOne,
    addressLineTwo,
    postalCode,
    city,
  });

  return children({
    companyName,
    companyNumber,
    address,
  });
};

ContainerComponent.displayName = 'CompanyLookup-SelectedCompany-Container';

export default ContainerComponent;
