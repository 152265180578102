import Request from './request';
import {
  defaultErrorHandler,
  getSSECache,
  graphQLErrorHandler,
} from './service-functions';
import { HookEvents } from './types';
import { fetcher, addService } from '../fetcher';
import { refetch, mutate, unstableSerialize } from '../utils';

/**
 * Base Service Class
 * @typedef {Object} ServiceOptions
 * @property {string} baseURL base url
 * @property {string} graphQLURL graphQL url
 * @property {Object} authService auth service
 * @property {String} contentType request content type
 */
class Service {
  /**
   * Constructor
   * @param {ServiceOptions} options
   */
  constructor({ baseURL, graphQLURL, authService, contentType, keys } = {}) {
    this.baseURL = baseURL;
    this.graphQLURL = graphQLURL;
    this.fetcher = {};
    this.request = new Request({ authService, contentType });
    this.authService = authService;
    this.sseCache = {};

    if (keys) {
      this.fetcher = Object.fromEntries(
        Object.values(keys).map((key) => [key, this[key].bind(this)]),
      );

      // lists service to fetcher
      addService(this);
    }
  }

  /**
   * The for handeling response
   * @typedef {Object} HandleOptions
   * @property {function} errorHandler custom error handler
   * @property {function} customAuthErrorCheck custom auth error handler
   * @property {string} baseURL baseURL
   *
   * @param {Object} request request object
   * @param {HandleOptions} options handle options
   */
  async handle(requestCreator, { errorHandler, customAuthErrorCheck } = {}) {
    const reponse = await requestCreator();

    const next = errorHandler || defaultErrorHandler;

    /**
     * If authService is defined it calles the middlware to check if there is a problem with token
     */
    if (this.authService) {
      return this.authService.middleware(
        reponse,
        customAuthErrorCheck,
        next,
        () => this.handle(requestCreator),
      );
    }

    return next(reponse);
  }

  /**
   * Depricated
   * Exposes methods so they could be called by keys, binds class on method
   * @param {Object} map map of the keys liked to methods
   */
  async mapKeys(map) {
    const keys = Object.keys(map);
    this.fetcher = Object.fromEntries(
      keys.map((key) => [key, map[key].bind(this)]),
    );

    // lists service to fetcher
    addService(this);
  }

  /**
   * Created final endpoint url
   * @param {string} url relative url of request
   * @param {HandleOptions} options handle options
   */
  parseURL(url, options) {
    const base = (options && options.baseURL) || this.baseURL;

    return base + url;
  }

  /**
   * Creates GET request
   * @param {string} url relative url of request
   * @param {HandleOptions} options handle options
   */
  async get(url, options) {
    const endpointURL = this.parseURL(url, options);

    return this.handle(() => this.request.get(endpointURL), options);
  }

  /**
   * Creates SSE request
   * @param {string} url sse url
   * @param {Object} options SSE options
   */
  // eslint-disable-next-line class-methods-use-this
  sse(url, { ctx, ...options }) {
    const { originalKey, onHookEvents } = ctx || {};

    /**
     * Gets SSE from cache
     */
    const sse = getSSECache(url);

    /**
     * Creates an instance
     */
    if (!sse.instance) {
      sse.instance = new EventSource(url, options);

      sse.instance.addEventListener('message', (event) => {
        sse.messages.push(JSON.parse(event.data));

        Object.values(sse.keys).forEach(mutate);
      });
    }

    /**
     * Stops instance
     */
    const close = () => {
      // deletes instance
      sse?.instance?.close();
      delete sse.instance;
    };

    if (originalKey) {
      /**
       * Add key to sse
       */
      sse.keys[unstableSerialize(originalKey)] = originalKey;
      /**
       * Listens to hook events
       */
      onHookEvents((e) => {
        switch (e) {
          case HookEvents.onUnmount:
          case HookEvents.onEmptyKey:
            /**
             * Removes key from sse
             */
            delete sse.keys[unstableSerialize(originalKey)];
            /**
             * Mutates to undefined to trigger swr revalidation
             * which would add key again if there is any useLoadData with this key
             */
            mutate(originalKey, undefined).then(() => {
              /**
               * Closes SSE for this key if there is no key is using it
               */
              if (Object.keys(sse.keys).length === 0) close();
            });
            break;
          default:
            break;
        }
      });
    }

    return { messages: sse.messages, close };
  }

  /**
   * Creates POST request
   * @param {string} url relative url of request
   * @param {Object} body http request body
   * @param {HandleOptions} options handle options
   */
  async post(url, body, options) {
    const endpointURL = this.parseURL(url, options);

    return this.handle(() => this.request.post(endpointURL, body), options);
  }

  /**
   * Creates PATCH request
   * @param {string} url relative url of request
   * @param {Object} body http request body
   * @param {HandleOptions} options handle options
   */
  async patch(url, body, options) {
    const endpointURL = this.parseURL(url, options);

    return this.handle(() => this.request.patch(endpointURL, body), options);
  }

  /**
   * Creates PUT request
   * @param {string} url relative url of request
   * @param {Object} body http request body
   * @param {HandleOptions} options handle options
   */
  async put(url, body, options) {
    const endpointURL = this.parseURL(url, options);

    return this.handle(() => this.request.put(endpointURL, body), options);
  }

  /**
   * Creates GraphQL query request
   * @param {string} query GraphQL query
   * @param {Object} variables GraphQL variables
   * @param {HandleOptions} options handle options
   */
  async query(query, variables, options = {}) {
    return this.handle(
      () => this.request.post(this.graphQLURL, { query, variables }),
      { errorHandler: graphQLErrorHandler, ...options },
    );
  }

  /**
   * Call any service method by key
   * @param {Array} key
   * @param {Object} options use options
   * @param {Object} options.ctx context
   */
  // eslint-disable-next-line class-methods-use-this
  async use(key, { ctx } = {}) {
    return fetcher(ctx)(...key);
  }

  /**
   * Calls mutate of SWR to refetch the calls
   * @param {Array} key swr cache key with parameters
   */
  // eslint-disable-next-line class-methods-use-this
  async refetch(key) {
    return refetch(key);
  }

  /**
   * Calls mutate of SWR to refetch the calls
   * @param {Array} key swr cache key with parameters
   */
  // eslint-disable-next-line class-methods-use-this
  async mutate(key, data, options) {
    return mutate(key, data, options);
  }
}

export default Service;
