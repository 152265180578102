import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from './Context';
import Container from './FormTheme-container';
import FieldWrapper from './FieldWrapper';
import { VARIANTS } from './FormTheme-constants';

const ViewComponent = ({ children, variant, fieldWrapper, ...restProps }) => (
  <Container variant={variant}>
    {({ theme, Wrapper }) => (
      <Wrapper>
        <Provider
          {...theme}
          {...restProps}
          fieldWrapper={fieldWrapper || theme.fieldWrapper}
        >
          {children}
        </Provider>
      </Wrapper>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
  variant: undefined,
  fieldWrapper: undefined,
};

ViewComponent.displayName = 'FormTheme-view';

ViewComponent.FieldWrapper = FieldWrapper;

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Form Variant
   */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  /**
   * Element type of wrapper component that would wrap all the fields within context
   */
  fieldWrapper: PropTypes.elementType,
};

export default ViewComponent;
