import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { CLASS_NAMES, ICONS } from './FileCard-constants';

import { useFormTheme } from '../../../FormTheme';

const ContainerComponent = ({
  children,
  file,
  fileProgress,
  loadingFiles,
  small,
  hasLabel,
}) => {
  const [progress, setProgress] = useState();
  /**
   * Creates a listener function to progress updates
   * */
  useEffect(() => {
    if (fileProgress && fileProgress.current) {
      // eslint-disable-next-line no-param-reassign
      fileProgress.current[file.name] = (value) => setProgress(value);
    }
  }, [file, fileProgress]);

  /**
   * Updates progress when loadingFiles changes
   * */
  useEffect(() => {
    if (Array.isArray(loadingFiles) && loadingFiles.includes(file.name))
      setProgress(0);
    else setProgress();
  }, [loadingFiles, file]);

  const { baseClasses, inputClasses } = useFormTheme();

  const wrapper = clsx(
    baseClasses,
    inputClasses,
    'border-solid border overflow-hidden border-turquoise-300 relative',
    !small && !hasLabel ? 'rounded-2xl' : ''
  );

  const otherClassNames = small ? CLASS_NAMES.small : CLASS_NAMES.default;

  const classNames = {
    wrapper,
    ...otherClassNames,
  };

  const imageUrl = file.preview;

  const width = `${Math.round(progress * 100)}%`;

  const icons = small ? ICONS.small : ICONS.default;

  return children({ classNames, icons, imageUrl, progress, width });
};

ContainerComponent.displayName = 'FileCard-container';

export default ContainerComponent;
