import clsx from 'clsx';

import { CLASS_NAMES } from './HeaderResizer-constants';

const ContainerComponent = ({ children, column }) => {
  const { canResize, isResizing, getResizerProps } = column;

  const classNames = {
    resizer: clsx(
      CLASS_NAMES.resizer.base,
      isResizing ? CLASS_NAMES.resizer.resizing : CLASS_NAMES.resizer.static
    ),
    container: clsx(
      CLASS_NAMES.container.base,
      isResizing ? CLASS_NAMES.container.resizing : CLASS_NAMES.container.static
    ),
  };

  return children({
    canResize,
    classNames,
    resizerProps: getResizerProps(),
  });
};

ContainerComponent.displayName = 'HeaderResizer-container';

export default ContainerComponent;
