import React from 'react';
import PropTypes from 'prop-types';

import Container from './Pagination-container';
import Button from './PaginationNumberButton';
import NavButton from './PaginationNavButton';
import { CLASS_NAMES } from './Pagination-constants';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ buttons, previousPage, previousDisabled, nextPage, nextDisabled }) => (
      <div className={CLASS_NAMES.container}>
        <NavButton
          onClick={previousPage}
          disabled={previousDisabled}
          next={false}
        />
        <div className={CLASS_NAMES.buttonWrapper}>
          {buttons.map((button) => (
            <Button key={button.id} {...button} />
          ))}
        </div>
        <NavButton onClick={nextPage} disabled={nextDisabled} next />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Pagination-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  pageIndex: PropTypes.number.isRequired,
  /**
   * HTML ID of element
   */
  pageCount: PropTypes.number.isRequired,
  /**
   * HTML ID of element
   */
  setPage: PropTypes.func.isRequired,
};

export default ViewComponent;
