import { DICTIONARIES } from './PasswordStrengthIndicator-constants';

/**
 * Returns dictionaries needed to generate password strength score
 * @return {Array} dictionaries
 */
export const loadDictionaries = async () => {
  const promises = DICTIONARIES.map(async (url) => {
    const response = await fetch(url);

    return response.json();
  });

  return Promise.all(promises);
};
