import React from 'react';
import PropTypes from 'prop-types';

import Mobile from './Mobile';
import Desktop from './Desktop';

import BreakPoints from '../../../../../BreakPoints';

const ViewComponent = ({
  modalId,
  handleSelect,
  companySearchName,
  companyFocusName,
}) => (
  <BreakPoints
    mobile={<Mobile modalId={modalId} handleSelect={handleSelect} />}
    desktop={
      <Desktop
        handleSelect={handleSelect}
        companySearchName={companySearchName}
        companyFocusName={companyFocusName}
      />
    }
  />
);

ViewComponent.displayName = 'CompanyLookup-SearchResults-view';

ViewComponent.propTypes = {
  /**
   * Company search field name
   */
  companySearchName: PropTypes.string.isRequired,
  /**
   * Company focus field name
   */
  companyFocusName: PropTypes.string.isRequired,
  /**
   * Modal id
   */
  modalId: PropTypes.string.isRequired,
  /**
   * Handle select
   */
  handleSelect: PropTypes.func.isRequired,
};

export default ViewComponent;
