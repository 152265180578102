// import { isJwtExpired } from 'jwt-check-expiration';

import { KandaEnterpriseAuthService } from './kandaEnterpriseAuthService';

import config from '../config';
import { Service } from '../lib';
import { redirectTo } from '../utils';

/**
 * Enterprise User Auth Service
 * @typedef {Object} EnterpriseUserAuthServiceOptions
 * @property {string} baseURL base url
 */
export class EnterpriseUserAuthServiceImplementation extends Service {
  /**
   * Constructor
   * @param {EnterpriseUserAuthServiceOptions} options
   */
  constructor({ baseURL, kandaEnterpriseAuthService }) {
    super({ baseURL });

    this.login = this.login.bind(this);
    this.getAuthToken = this.getAuthToken.bind(this);
    this.kandaEnterpriseAuthService = kandaEnterpriseAuthService;

    this.key = {
      isUserLoggedIn: 'EnterpriseUserAuthService/isUserLoggedIn',
    };

    this.mapKeys({
      [this.key.isUserLoggedIn]: this.isUserLoggedIn,
    });
  }

  /**
   * Handels login
   * @param {Object} payload login payload
   * @param {Boolean} persist persistent login state, default false
   */
  async login(payload, persist = false) {
    const data = await super.post('/login', payload);

    KandaEnterpriseAuthService.setToken(data.token, persist);

    await super.refetch([this.key.isUserLoggedIn]);

    redirectTo(config.HOME_URL);
  }

  /**
   * Gets auth token login
   * @param {Object} payload login payload
   * @param {Boolean} persist persistent login state, default false
   */
  async getAuthToken(payload) {
    const data = await super.post('/login', payload);

    return data.token;
  }

  /**
   * Checks if user is logged in
   */
  async isUserLoggedIn() {
    const token = await this.kandaEnterpriseAuthService.token();

    return Boolean(token);
    // UN COMMENT THIS WHEN IS READY ON SERVER
    // return !isJwtExpired(token);
  }
}

export const EnterpriseUserAuthService =
  new EnterpriseUserAuthServiceImplementation({
    baseURL: `${config.ENTERPRISE_LOGIN}`,
    kandaEnterpriseAuthService: KandaEnterpriseAuthService,
  });
