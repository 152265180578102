import clsx from 'clsx';

import { PLACE_HOLDER_CLASS_NAMES } from './PlaceHolder-constants';

const ContainerComponent = ({ children, className: extClassName, top, bg }) => {
  const {
    baseContainer,
    baseWrapper,
    baseBackground,
    baseEffect,
    ...classNames
  } = PLACE_HOLDER_CLASS_NAMES;

  const container = clsx(baseContainer, extClassName);
  const wrapper = clsx(baseContainer, !top && 'justify-center');
  const background = clsx(baseBackground, bg ? `to-${bg}` : 'to-neutral-000');
  const effect = clsx(baseEffect, bg ? `from-${bg}` : 'from-neutral-000');

  return children({
    classNames: {
      ...classNames,
      container,
      wrapper,
      background,
      effect,
    },
  });
};

ContainerComponent.displayName = 'PlaceHolder-Container';

export default ContainerComponent;
