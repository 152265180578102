import React from 'react';
import PropTypes from 'prop-types';

import Container from './CopyContent-container';
import {
  DEFAULT_TIMEOUT,
  DEFAULT_MESSAGE,
  DEFAULT_CLICKED_MESSAGE,
} from './CopyContent-constants';

const ViewComponent = ({ children, clickedView, id, className, ...rest }) => (
  <Container {...rest}>
    {({ handleClick, clicked }) => (
      <button
        id={id}
        onClick={handleClick}
        className={className}
        disabled={clicked}
      >
        {clicked
          ? clickedView || DEFAULT_CLICKED_MESSAGE
          : children || DEFAULT_MESSAGE}
      </button>
    )}
  </Container>
);

ViewComponent.displayName = 'CopyContent-view';

ViewComponent.defaultProps = {
  children: null,
  clickedView: null,
  className: '',
  id: '',
  time: DEFAULT_TIMEOUT,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Element to be shown when clicked
   */
  clickedView: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Content to be copied
   */
  content: PropTypes.string.isRequired,
  /**
   * HTML ID for the input
   */
  id: PropTypes.string,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Time
   */
  time: PropTypes.number,
};

export default ViewComponent;
