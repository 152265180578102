import {
  encryptObject as encrypt,
  decryptObject as decrypt,
} from '@kanda-finance/base';

import { ENTERPRISE_PARTNERS } from '../constants';

import config from '../config';
import { camelize } from '../utils';
import { ValidationError } from '../lib';

/**
 * Removes excess backslashes from Unicode characters from server
 */
const unescapeString = (str) => str.replace('\\\\u', '\\u');

/**
 * Decodes base64 payload string and removes escape characters around Unicode
 * characters
 * @param {String} signedRequest The base64 encoded string
 * @returns {Object} The payload object
 */
export const escapeParsedPayload = (signedRequest) =>
  JSON.parse(unescapeString(atob(signedRequest)));

/**
 * Returns parner key
 * @param {String} enterpriceId
 * @returns {String} Partner key
 */
export const getPartnerKey = (enterpriseId) => {
  try {
    const keys = ENTERPRISE_PARTNERS[enterpriseId]?.key;

    return keys[config.ENTERPRISE_ENVIRONMENT];
  } catch (e) {
    throw new ValidationError('Invalid signature', e);
  }
};

/**
 * Returns payload from signed request
 * @param {String} signedRequest The base64 encoded string
 * @returns {Object} payload
 */
export const parsePayload = (signedRequest) => {
  try {
    const [, tokenPayload] = signedRequest.split('.');

    if (!tokenPayload) return undefined;

    return camelize(escapeParsedPayload(tokenPayload));
  } catch (e) {
    throw new ValidationError('Invalid signed request', e);
  }
};

/**
 * Returns parter info
 * @param {String} enterpriseId The enterprise ID
 * @returns {Object} Partner info
 */
export const getPartnerInfo = (enterpriseId) => {
  const info = ENTERPRISE_PARTNERS[enterpriseId];

  return {
    partner: info?.partner,
    urls: info?.urls,
    rates: info?.rates,
    skipDeposit: info.skipDeposit || false,
  };
};

/**
 * Encrypt object
 * @param {Object} data
 * @param {String} partnerKey
 * @returns {String} cipher
 */
export const encryptObject = (data, key) => encrypt(data, key);
/**
 * Decrypt object
 * @param {String} cipher
 * @param {String} partnerKey
 * @returns {Object} data
 */
export const decryptObject = (cipher, key) => decrypt(cipher, key);
