import React from 'react';
import PropTypes from 'prop-types';

import Container from './ModalLayoutLines-container';
import Button from '../../../Button';

import { ReactComponent as Background } from '../../../../assets/modals/background.svg';
import { ReactComponent as Lines } from '../../../../assets/modals/lines.svg';

const ViewComponent = React.forwardRef(
  ({ children, onClose, className: extClassName, opacity }, ref) => (
    <Container className={extClassName} opacity={opacity}>
      {({ className }) => (
        <div className={className} ref={ref}>
          <Background className="absolute top-0 left-0" />
          <Lines className="absolute w-full bottom-0 right-0" />
          <div className="flex flex-1 w-full flex-col relative">
            <div className="absolute top-0 right-0 p-4">
              <Button.Icon onClick={onClose} icon="close" />
            </div>
            {children}
          </div>
        </div>
      )}
    </Container>
  )
);

ViewComponent.displayName = 'ModalLayoutLines-view';

ViewComponent.defaultProps = {
  children: null,
  onClose: undefined,
  className: undefined,
  opacity: 5,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * on close function
   */
  onClose: PropTypes.func,
  /**
   * Etends current styles
   */
  className: PropTypes.func,
  /**
   * Etends current styles
   */
  opacity: PropTypes.number,
};

export default ViewComponent;
