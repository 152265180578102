/**
 * Validation Error
 */
class ValidationError extends Error {
  /**
   * constructor
   * @param {string} status Error message
   * @param {Object} errors Object of errors
   */
  constructor(message, errors, status) {
    super(message);

    this.errors = errors;
    this.status = status;
  }
}

export default ValidationError;
