import React from 'react';
import PropTypes from 'prop-types';

import Container from './Row-container';
import Cell from './Cell';
import PopoverButton from './PopoverButton';

const ViewComponent = ({ isLoading, ...props }) => (
  <Container {...props}>
    {({
      row,
      classNames,
      rowProps,
      cells,
      showButton,
      onMouseEnter,
      onMouseLeave,
      hoverPopover,
    }) => (
      <div
        {...rowProps}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classNames.base}
      >
        {cells.map((cell, index) => (
          <Cell
            key={cell.key}
            cell={cell}
            index={index}
            isLoading={row.original?.isValidating || isLoading}
          />
        ))}

        {showButton && <PopoverButton row={row} hoverPopover={hoverPopover} />}
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  isLoading: false,
  onRowClicked: undefined,
};

ViewComponent.propTypes = {
  /**
   * Label for button
   */
  isLoading: PropTypes.bool,
  /**
   * On row clicked
   */
  onRowClicked: PropTypes.func,
};

ViewComponent.displayName = 'Row-view';

export default ViewComponent;
