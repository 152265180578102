const ContainerComponent = ({ children, provided, columns }) => {
  const visibleColumns = columns.reduce(
    (total, column) => (column.isVisible ? total + 1 : total),
    0
  );
  const { droppableProps, innerRef, placeholder } = provided;

  return children({
    visibleColumns,
    droppableProps,
    innerRef,
    placeholder,
  });
};

ContainerComponent.displayName = 'DragDropWrapper-container';

export default ContainerComponent;
