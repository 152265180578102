import { useClasses } from '../../../../../hooks';

import { CLASS_NAMES } from './Content-constants';

const ContainerComponent = ({ children, footer, width, ...variants }) => {
  const classNames = useClasses(CLASS_NAMES, {
    variants,
    container: ['.container', footer ? '' : 'pb-18'],
    content: ['.content', width],
  });

  return children({
    classNames,
  });
};

ContainerComponent.displayName = 'DesktopLayoutBoxed-Content-container';

export default ContainerComponent;
