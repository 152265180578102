import React from 'react';
import PropTypes from 'prop-types';

import Container from './HeaderGroup-container';
import HeaderColumn from './HeaderColumn';
import { CLASS_NAMES } from './HeaderGroup-constants';

const ViewComponent = ({ isLoading, handleAction, ...props }) => (
  <Container {...props}>
    {({ headerGroupProps, headers, totalVisible, moveColumn }) => (
      <div {...headerGroupProps} className={CLASS_NAMES.base}>
        {headers.map((column, i) => (
          <HeaderColumn
            key={column.id}
            index={i}
            column={column}
            isLoading={isLoading}
            totalVisible={totalVisible}
            moveColumn={moveColumn}
            handleAction={handleAction}
          />
        ))}
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  isLoading: false,
  handleAction: false,
};

ViewComponent.propTypes = {
  /**
   * Label for button
   */
  isLoading: PropTypes.bool,
  /**
   * Handle Action
   */
  handleAction: PropTypes.func,
};

ViewComponent.displayName = 'HeaderGroup-view';

export default ViewComponent;
