import React from 'react';

import Container from './IconButton-container';
import { SKELETONS } from './IconButton-constants';

import SkeletonLoader from '../../SkeletonLoader';
import { ReactComponent as PdfDownload } from '../../../assets/images/pdf-download.svg';

const ViewComponent = () => (
  <Container>
    {({ isLoading, downloadPdf }) => (
      <SkeletonLoader
        isLoading={isLoading}
        {...SKELETONS.icon}
        afterLoading={
          <button type="button" onClick={downloadPdf}>
            <PdfDownload />
          </button>
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'QuoteDownload-IconButton-view';

export default ViewComponent;
