import { useState, useRef } from 'react';
import { copyToClipboard } from '@kanda-utils/library';

const ContainerComponent = ({ children, content, time }) => {
  const [clicked, _setClicked] = useState(false);

  const timerRef = useRef(null);

  const handleClick = () => {
    // Set state to clicked
    _setClicked(true);
    // Clear timeout
    clearTimeout(timerRef.current);
    // Copy content to clipboard
    copyToClipboard(content);
    // After `time`, set button to clickable again
    timerRef.current = setTimeout(() => _setClicked(false), time);
  };

  return children({
    handleClick,
    clicked,
  });
};

ContainerComponent.displayName = 'CopyContent-Container';

export default ContainerComponent;
