import React from 'react';
import PropTypes from 'prop-types';

import Container from './ButtonWrapper-container';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {({ classNames, containerStyle }) => (
      <div className={classNames.container} style={containerStyle}>
        <div className={classNames.flex}>{children}</div>
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
  index: 0,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Header index
   */
  index: PropTypes.number,
};

ViewComponent.displayName = 'ButtonWrapper-view';

export default ViewComponent;
