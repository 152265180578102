export const POST_CODE_PROPS = {
  label: 'Postcode',
  autoComplete: 'postal-code',
  wrapperProps: { className: 'mr-4' },
};

export const HOUSE_NUMBER_PROPS = {
  label: 'House number',
};

export const HIDDEN_PROPS = {
  wrapperProps: {
    className: 'hidden',
  },
  type: 'hidden',
};

export const NO_ADDRESS_FOUND_LABEL = 'No address found!';

export const SELECT_ADDRESS_LABEL = 'Select address...';

export const DEBOUNCE_INTERVAL = 500;

export const POST_CODE_REGEX =
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})\s*$/;
