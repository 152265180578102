export const ENTERPRISE_PARTNERS = ({
  '00000': {
    partner:      'Shnugg Ltd.',
    enterpriseId: '00000',
    key: {
      qa:         'mO9Fx6K2386ORS5BdO6PwraI7n',
      production: 'F!feb#fjQ8IBtOUqwV4!^8a*1Z',
    },
    urls: {
      qa: {
        success: 'https://dev.shnugg.co.uk/kanda/success/',
        failure: 'https://dev.shnugg.co.uk/kanda/failed/',
      },
      production: {
        success: 'https://booking.shnugg.co.uk/kanda/success/',
        failure: 'https://booking.shnugg.co.uk/kanda/failed/',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00001': {
    partner:      'Chargepoint EV',
    skipDeposit:  true,
    enterpriseId: '00001',
    key: {
      qa:         'mO9Fx6K2386ORS5BdO6PwraI7n',
      production: 'zV0n3W5bElHcvL7g6n8uEC6Zez',
    },
    urls: {
      qa: {
        success: 'https://chargepointev.intelicle-web.co.uk/kanda/success?ref=',
        failure: 'https://chargepointev.intelicle-web.co.uk/kanda/fail?ref=',
      },
      production: {
        success: 'https://chargepointev.co.uk/kanda/success?ref=',
        failure: 'https://chargepointev.co.uk/kanda/fail?ref=',
      },
    },
    rates: {
      interest_free_10:         750,
    },
  },
  '00002': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00002',
    key: {
      qa:         'NQuoI7m6e79CNnEHdTRJ6ikSMN',
      production: '3Bq2DOwLsR8sEqujMRCDWb7Si4',
      // qa: 'KGA2dMI7avAv1MEP5sTg4gzLtR',
      // production: 'KitJOaurdi4QBZ8T6gpTnonIfg',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00003': {
    partner:      'KJM Energy Ltd',
    enterpriseId: '00003',
    key: {
      qa:         'NQuoI7m6e79CNnEHdTRJ6ikSMN',
      production: '3Bq2DOwLsR8sEqujMRCDWb7Si4',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         835,
      interest_free_24:         1335,
      interest_bearing_60_1190: 0,
    },
  },
  '00004': {
    partner:      'Rhino Home Solutions',
    enterpriseId: '00004',
    key: {
      qa:         '',
      production: 'czS4Oc8j4bLxEOZX46Z8EFFqnm',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1230,
      interest_bearing_60_1190: 0,
    },
  },
  '00005': {
    partner:      'DCM Group Ltd',
    enterpriseId: '00005',
    key: {
      qa:         'TrPaLsSEiJPnkJRoYdrKEP7zp5',
      production: 'TrPaLsSEiJPnkJRoYdrKEP7zp5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00006': {
    partner:      'Eco Heat Boilers',
    enterpriseId: '00006',
    key: {
      qa:         's3u!tcqL4R64$nxmMy!V6K6E3c',
      production: 's3u!tcqL4R64$nxmMy!V6K6E3c',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00007': {
    partner:      'PlumbProud Limited',
    skipDeposit:  true,
    enterpriseId: '00007',
    key: {
      qa:         'P3t20T8NTCsFY#bPD^eG9sqpRY',
      production: 'P3t20T8NTCsFY#bPD^eG9sqpRY',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00008': {
    partner:      'Total Home Experts Ltd',
    enterpriseId: '00008',
    key: {
      qa:         'SvRQ&N1BiFw@b4Vs&PaHdnBp78',
      production: 'SvRQ&N1BiFw@b4Vs&PaHdnBp78',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00009': {
    partner:      'Turner Electrical Services',
    enterpriseId: '00009',
    key: {
      qa:         '',
      production: 'QdHSltWlYdzcJSTGketH3DBK96',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00010': {
    partner:      'Zenith Eco Solutions Ltd',
    enterpriseId: '00010',
    skipDeposit:  true,
    key: {
      qa:         'whpnA6aH5vjWvVV8i47E2lSp7m',
      production: 'SUZ2qMlQKPadBQnL8M4WyIjNFP',
    },
    urls: {
      qa: {
        success: 'https://zenithecosolutions.intelicle-web.co.uk/kanda/success?ref=',
        failure: 'https://zenithecosolutions.intelicle-web.co.uk/kanda/fail?ref=',
      },
      production: {
        success: 'https://zenithecosolutions.co.uk/kanda/success?ref=',
        failure: 'https://zenithecosolutions.co.uk/kanda/fail?ref=',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00011': {
    partner:      'McNally EV',
    enterpriseId: '00011',
    key: {
      qa:         'V53JxllD075Ra7a55TFdiw1h48',
      production: 'RjWGnXhNM3NXlJzZkEdKDU6wyL',
    },
    urls: {
      qa: {
        success: 'https://mcnallyev.uk/kanda/success?ref=',
        failure: 'https://mcnallyev.uk/kanda/fail?ref=',
      },
      production: {
        success: 'https://mcnallyev.uk/kanda/success?ref=',
        failure: 'https://mcnallyev.uk/kanda/fail?ref=',
      },
    },
    rates: {
      interest_free_10:         700,
      interest_free_24:         1250,
      interest_free_36:         1700,
      interest_bearing_60_1190: 0,
    },
  },
  '00012': {
    partner:      'Heatlex Group Installations Ltd',
    enterpriseId: '00012',
    key: {
      qa:         '',
      production: '8PidxrQAhp15c8P0DW5UVHX9IX',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00013': {
    partner:      'Gas Care Domestic',
    enterpriseId: '00013',
    key: {
      qa:         '5pvnFG4FShrPVvJV3KY137DFaU',
      production: 'H0tNdFTVop9Jtj6FB7rZLpSUmG',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00014': {
    partner:      'Maq Construction',
    enterpriseId: '00014',
    key: {
      qa:         '',
      production: '84XsYE59XFdKFbNmbtU3V6AHlZ',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00015': {
    partner:      'AntLin Plumbing',
    enterpriseId: '00015',
    key: {
      qa:         '',
      production: 'g2f01ySU2Z3UyOHiY6ukyxWqYH',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00016': {
    partner:      'DS CCTV Services',
    enterpriseId: '00016',
    key: {
      qa:         '',
      production: '3wmc38Ow8bgWFvHmPB4ljwrKdq',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00017': {
    partner:      'B C Heating Solutions Ltd',
    enterpriseId: '00017',
    key: {
      qa:         '',
      production: 'wiCMwKkOdpqjktdQNaHfAf5CS6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00018': {
    partner:      'HeatDial',
    enterpriseId: '00018',
    key: {
      qa:         '',
      production: 'Nmae482DN6Ot4QxNplxA0VbcTm',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00019': {
    partner:      'Spartan Plumbers',
    enterpriseId: '00019',
    key: {
      qa:         '',
      production: 'oB21V6EmLIZ1uSu1O90SUPeD5Q',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00020': {
    partner:      'Phoenix Fires',
    enterpriseId: '00020',
    key: {
      qa:         '',
      production: 'H1TbJHEJ5tuDTUi4jk6msmL9Dv',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00021': {
    partner:      'NU Windows',
    enterpriseId: '00021',
    key: {
      qa:         '',
      production: 'EH2Pmdk4roEhiSxqNqvV5ux4MT',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00022': {
    partner:      'Taylorheat',
    enterpriseId: '00022',
    key: {
      qa:         '',
      production: 'Rb16vtr0yQGXNYoKbC2OqrzVzj',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00023': {
    partner:      'Supreme Floors UK LTD',
    enterpriseId: '00023',
    key: {
      qa:         '',
      production: 'ImuKt5AQmDwHoAuTpQm227fG3J',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00024': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00024',
    key: {
      qa:         '',
      production: 'slytcoLctLPeFLNBIWuNfo47vt',
      // production: 'diXno5TK8zTfITNv0HD3OXj1c3',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00025': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00025',
    key: {
      qa:         '',
      production: 'G6lU3Uw9HOBrPNrinMa2e2Dmmv',
      // production: '6Z5IFL3HMGWp0qFmvarcKKVx04',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00026': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00026',
    key: {
      qa:         '',
      production: 'rjXs89p4CZkg2IYTJQpQxw3Hb0',
      // production: 'XCeqsnxqHlk5FCyWP3EYsgjr8g',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00027': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00027',
    key: {
      qa:         '',
      production: 'dORH2wdVkvCJSx16YKzGMwLBfW',
      // production: 'n8QcBOUwJdDlv65kqAB361vGIi',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00028': {
    partner:      'N&M Electrical',
    enterpriseId: '00028',
    key: {
      qa:         '',
      production: 'B2C32ZkaXyxhJOLVT8R0PyGirJ',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00029': {
    partner:      'Polar Security Ltd',
    enterpriseId: '00029',
    key: {
      qa:         '',
      production: 'cVK3NPyV4JvGsd8EGALVz9l16l',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00030': {
    partner:      'Redplumb',
    enterpriseId: '00030',
    key: {
      qa:         '',
      production: 'jc6OTYDsWwZql94xn0O2Mnir2H',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00031': {
    partner:      'Pinnacle Services (North East) Ltd',
    enterpriseId: '00031',
    key: {
      qa:         '',
      production: '74ELj53VBB4ddwOJvNjax21MNA',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00032': {
    partner:      'Your Boiler Experts',
    enterpriseId: '00032',
    key: {
      qa:         '',
      production: 'qSWQVT7pSMVxGKWMG3dHqD0Ut4',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00033': {
    partner:      'JDL South West',
    enterpriseId: '00033',
    key: {
      qa:         '',
      production: '5kgGV9cSY6D9ThuqEmVgJkchGk',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00034': {
    partner:      'Lifetime Energy Solutions',
    enterpriseId: '00034',
    key: {
      qa:         '',
      production: '1Hd7OcisIy26NVl9d6dsw3ArUf',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00035': {
    partner:      'Saber Plumbing and Heating',
    enterpriseId: '00035',
    key: {
      qa:         '',
      production: '7eTXW5tt3jRh62gQ1K8kf6sted',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00036': {
    partner:      'Sutterton Surveillance Systems',
    enterpriseId: '00036',
    key: {
      qa:         '',
      production: 'KApEJcnxcuUyX66NO1xZ36dKqU',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00037': {
    partner:      'CIR Electrical Ltd',
    enterpriseId: '00037',
    key: {
      qa:         '',
      production: '9l6ZwUHxowOGOgLKDhia0InZKo',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00038': {
    partner:      'Smart Gas Heating Ltd',
    enterpriseId: '00038',
    key: {
      qa:         '',
      production: 'h5j7jiG52BnDWPoXYYlnn7jHqQ',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00039': {
    partner:      'Kainoxe',
    enterpriseId: '00039',
    key: {
      qa:         '',
      production: 'g4z8QHk4zr87pQkcGDZtntEcsB',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00040': {
    partner:      'Cheshire VIP Services Ltd',
    enterpriseId: '00040',
    key: {
      qa:         '',
      production: 'QHpSU3I3etjpSzpNdRNJY0FhbD',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00041': {
    partner:      'Optama',
    enterpriseId: '00041',
    key: {
      qa:         '',
      production: 'bNZT7PCePaHTQ0YKLamMeodT72',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00042': {
    partner:      'SJ Peel Electrical LTD',
    enterpriseId: '00042',
    key: {
      qa:         '',
      production: 'LXTKiMHffnp45h4alaU3kbX6zr',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00043': {
    partner:      'Eazy Heat Holdings Ltd',
    enterpriseId: '00043',
    wcsite: 'eazyheat.co.uk',
    key: {
      qa:         '',
      production: 'aULl5kMJEreMrNxv3KY4Xi79w9',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: 'https://eazyheat.co.uk/finance-application-success?ref=',
        failure: 'https://eazyheat.co.uk/finance-application-failure?ref=',
      },
    },
    rates: {
      interest_bearing_12_790:  475,
      interest_bearing_24_790:  665,
      interest_bearing_36_790:  845,
      interest_bearing_48_790:  1025,
      interest_bearing_60_790:  1200,
      interest_bearing_12_990:  400,
      interest_bearing_24_990:  500,
      interest_bearing_36_990:  600,
      interest_bearing_48_990:  700,
      interest_bearing_60_990:  800,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00044': {
    partner:      'DWFMS Group Limited',
    enterpriseId: '00044',
    key: {
      qa:         '',
      production: '74g91v1rFd377b1aGblkEA9Ckp',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00045': {
    partner:      'Clear View Double Glazing Ltd',
    enterpriseId: '00045',
    key: {
      qa:         '',
      production: '2h9R8baDnnHjNJQMWPt4vR0VDM',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00046': {
    partner:      'Trident Plumbing & Heating LTD',
    enterpriseId: '00046',
    key: {
      qa:         '',
      production: '3tDG9eZDZx67NsXG8PzkEBJXtj',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00047': {
    partner:      'Sensible CCTV UK',
    enterpriseId: '00047',
    key: {
      qa:         '',
      production: '4fOoeOulhV7x1DeLqmzBhKR9J2',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00048': {
    partner:      'Spotlight Security UK Ltd',
    enterpriseId: '00048',
    key: {
      qa:         '',
      production: 'F0RQGo6TAK4CU5n7jAEKOyE193',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00049': {
    partner:      'Rapid Response Maintenance Services Ltd',
    skipDeposit: true,
    enterpriseId: '00049',
    key: {
      qa:         '',
      production: 'jFY2kw1UJ0K3EvrkLe61y6N769',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00050': {
    partner:      'Tavo Gas & Plumbing Ltd',
    enterpriseId: '00050',
    key: {
      qa:         '',
      production: '3V3Zfgd31vCBW62Gnd9tbTVSGr',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00051': {
    partner:      'Camerawatch Security Ltd',
    enterpriseId: '00051',
    key: {
      qa:         '',
      production: '0N8O2u51gQYPJIIj9f5DcheSsV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00052': {
    partner:      'Avanti FM Ltd',
    enterpriseId: '00052',
    key: {
      qa:         '',
      production: '401EMswI3nU9S4oyZwltKv07Fz',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00053': {
    partner:      'Iso Plumb',
    enterpriseId: '00053',
    key: {
      qa:         '',
      production: 'budnPgfpS7N0SyKlVgbAnvTL55',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00054': {
    partner:      'BPS Plumbing',
    enterpriseId: '00054',
    key: {
      qa:         '',
      production: '5F8V9LyeyVaz6g8gVhyC8SEdh6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00055': {
    partner:      'ASK Electrical NE Ltd',
    enterpriseId: '00055',
    key: {
      qa:         '',
      production: '610Jt0tr0I8EJ2AKE2OFGqrwa1',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00056': {
    partner:      'Best Installations Limited',
    enterpriseId: '00056',
    key: {
      qa:         '',
      production: 'x8mdih58ailjN61ZpyF7THoi7f',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00057': {
    partner:      'VIRKO (THE PROPERTY PARTNER) LTD',
    enterpriseId: '00057',
    key: {
      qa:         '',
      production: 'E19jgLSfgpGoIhOJB61QyVeO2z',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00058': {
    partner:      'Avery Plumbing and Heating',
    enterpriseId: '00058',
    key: {
      qa:         '',
      production: 'r4wyvjmqpyb51qqg385gAsaE7D',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00059': {
    partner:      'TBK SPECIALISTS',
    enterpriseId: '00059',
    key: {
      qa:         '',
      production: 'NS6U5vZZeLrDD2kY4AjlG9I5v3',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00060': {
    partner:      'JAW Electrical 7 Ltd',
    enterpriseId: '00060',
    key: {
      qa:         '',
      production: 'Bk6JecMG9lg7iksmiWvR4TXFCP',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00061': {
    partner:      'JMF Installations',
    enterpriseId: '00061',
    key: {
      qa:         '',
      production: '5zzJEvS9g58Qzvh1sil5ulEsL9',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00062': {
    partner:      'PH247',
    enterpriseId: '00062',
    key: {
      qa:         '',
      production: 'VVAz0f4wND1RjPv0CHLyS4Scg5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00063': {
    partner:      'Astonish Kitchens & Bathrooms Ltd',
    enterpriseId: '00063',
    key: {
      qa:         '',
      production: 'K3d76NRd7XFgg1W4wm0rTRE36p',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00064': {
    partner:      'LJB Home CCTV Ltd',
    enterpriseId: '00064',
    key: {
      qa:         '',
      production: 'vuAxHNaafHanPuLo7zG6mOMt0g',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00065': {
    partner:      'McCulloch Glazing',
    enterpriseId: '00065',
    key: {
      qa:         '',
      production: 'rMj5m0a27IiY1H4pjHm4q5PHnq',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00066': {
    partner:      'Fusion Gas Ltd',
    enterpriseId: '00066',
    key: {
      qa:         '',
      production: 'VoXOQ209mEwjlFnjun7lgjJd9H',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00067': {
    partner:      'Thermal Heating LTD',
    enterpriseId: '00067',
    key: {
      qa:         '',
      production: 'VKbqJShVYAL85yBPcW32T3V9YA',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00068': {
    partner:      'PC Plumbing and Gas',
    enterpriseId: '00068',
    key: {
      qa:         '',
      production: 'PQkSk8jyNzqlnddx9thr8RvIdi',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00069': {
    partner:      '',
    enterpriseId: '00069',
    key: {
      qa:         '',
      production: '',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00070': {
    partner:      'Blueflame Property',
    enterpriseId: '00070',
    key: {
      qa:         '',
      production: 'WaWoVHbrb4Su2EbAUunQPXva7t',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00071': {
    partner:      'Rose Hill Plumbing',
    enterpriseId: '00071',
    key: {
      qa:         '',
      production: '',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00072': {
    partner:      'Nicu Adrian Humaciu',
    enterpriseId: '00072',
    key: {
      qa:         '',
      production: 'XTL5edmYDZyyEQ95Ivcwyw733h',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00073': {
    partner:      'Gas Fixed',
    enterpriseId: '00073',
    key: {
      qa:         '',
      production: 'xBa0yxTXI65OzmybIsqNaOc88V',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00074': {
    partner:      'CL Plumbing & Heating Ltd',
    enterpriseId: '00074',
    key: {
      qa:         '',
      production: '40O3DsCnTM7h56uQnYHrjP8kfN',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00075': {
    partner:      'Kelly Domestic Services Ltd',
    enterpriseId: '00075',
    key: {
      qa:         '',
      production: '4GhFTZvVVVrsoscN6pvIGUpEmE',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00076': {
    partner:      'Easywarm',
    enterpriseId: '00076',
    key: {
      qa:         '',
      production: 'hHOHiEQDYet3Ia8Zmm9q4L1qV6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00077': {
    partner:      'CAJ Electrical Services Ltd',
    enterpriseId: '00077',
    key: {
      qa:         '',
      production: 'oUEZkhkvtCMGaqPHQpNa5gzc42',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00078': {
    partner:      '3 Counties Electrical',
    enterpriseId: '00078',
    key: {
      qa:         '',
      production: '94e8HV4CQUaFKDHvwlx76eBpH9',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00079': {
    partner:      'LRT Electrical LTD',
    enterpriseId: '00071',
    key: {
      qa:         '',
      production: '0BjKzkBqqizOHen29OuJKJMXy3',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00080': {
    partner:      'Yorkshire Green Heating',
    enterpriseId: '00080',
    key: {
      qa:         '',
      production: 'BsqfhCsX65GtjYHCkr7H1SbqnV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00081': {
    partner:      'Flooring Outlet',
    enterpriseId: '00081',
    key: {
      qa:         '',
      production: 'dutrxW8d42di97MSRk6J3kxPBN',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_free_36:         1700,
      interest_bearing_60_1190: 0,
    },
  },
  '00082': {
    partner:      'Plush Electrical LTD',
    enterpriseId: '00082',
    key: {
      qa:         '',
      production: 'S4yf3mUzhyLiLbo8Y3lYdO4UbP',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00083': {
    partner:      'Toasti',
    enterpriseId: '00083',
    // wcsite: 'toasti.co.uk',
    wcsite: 'stagesrv.uk/toasti.co.uk',
    key: {
      qa:         'Yka9Gz1xskhSYl8tVspmgWW1Ap',
      production: 'ZmL2k2S4K45TN7cR3AwHeNSQLi',
    },
    urls: {
      qa: {
        success: 'https://stagesrv.uk/toasti.co.uk/checkout/order-received/',
        failure: 'https://stagesrv.uk/toasti.co.uk/checkout/',
      },
      production: {
        success: 'https://toasti.co.uk/checkout/order-received/',
        failure: 'https://toasti.co.uk/checkout/',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_12:         835,
      interest_free_24:         1250,
      interest_free_36:         1800,
      interest_bearing_12_490:  625,
      interest_bearing_24_490:  945,
      interest_bearing_36_490:  1245,
      interest_bearing_48_490:  1545,
      interest_bearing_60_490:  1745,
      interest_bearing_12_790:  475,
      interest_bearing_24_790:  665,
      interest_bearing_36_790:  845,
      interest_bearing_48_790:  1025,
      interest_bearing_60_790:  1200,
      interest_bearing_12_990:  400,
      interest_bearing_24_990:  500,
      interest_bearing_36_990:  600,
      interest_bearing_48_990:  700,
      interest_bearing_60_990:  800,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00084': {
    partner:      'GSK Electrical',
    enterpriseId: '00084',
    key: {
      qa:         '',
      production: 'dxLfntQNBNTZSVG2bWYdKRFD7',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00085': {
    partner:      'Hillyards Total Property Services Ltd',
    enterpriseId: '00085',
    key: {
      qa:         '',
      production: 'uABJnAYSZfVeCvH3avAPcb5Fa',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00086': {
    partner:      'Heatingly Ltd',
    enterpriseId: '00086',
    key: {
      qa:         '',
      production: '3GerLkdguF5A55BECrW35nEGb',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00087': {
    partner:      'Seemoor Limited',
    enterpriseId: '00087',
    key: {
      qa:         '',
      production: 'Xhj7GQrgCNb8MtjLcN9SHR7M8',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00088': {
    partner:      'Zap Enterprises Ltd',
    enterpriseId: '00088',
    key: {
      qa:         '',
      production: 'CvgsqJU85csc6nLQGn48kMQBX',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00089': {
    partner:      'PB Plumbing',
    enterpriseId: '00089',
    key: {
      qa:         '',
      production: 'cbyX2DfhVQGgcmAct4rQmZn4J',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00090': {
    partner:      'SA Plumbing',
    enterpriseId: '00090',
    key: {
      qa:         '',
      production: 'VsPM4cxmmCzLHmx72Yh6nqrXy',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00091': {
    partner:      'Coutts Electrical Contractors LTD',
    enterpriseId: '00091',
    key: {
      qa:         '',
      production: '8e99XL3vsLkupcvDPDcbxKvxc',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00092': {
    partner:      'Glass Films UK LTD',
    enterpriseId: '00092',
    key: {
      qa:         '',
      production: 'L2VvEyz69bftajeWCB6YPAf4S',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00093': {
    partner:      'Bear Watch CCTV',
    enterpriseId: '00093',
    key: {
      qa:         '',
      production: 'q7MXPXY6knh8gULaftA4u7dJY',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00094': {
    partner:      'Lloyds Plumbing and Heating',
    enterpriseId: '00094',
    key: {
      qa:         '',
      production: 'cGuKELhWXtprQy4gVGMJCxtzp',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00095': {
    partner:      'Anth Constable Domestic Gas Services',
    enterpriseId: '00095',
    key: {
      qa:         '',
      production: 'V9rc3TJgqbHm5h5qw4YotZncwG',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00096': {
    partner:      'ISPY CCTV & Electrical',
    enterpriseId: '00096',
    key: {
      qa:         '',
      production: 'eNI81cYRA8wZ8bDgHiQOqm4Z11',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00097': {
    partner:      'Manchester Plumbing and Heating LTD',
    enterpriseId: '00097',
    key: {
      qa:         '',
      production: '4qc8BrmZ9phfmifk3CFP1yczb',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00098': {
    partner:      'Future Proof Builders',
    enterpriseId: '00098',
    key: {
      qa:         '',
      production: 'i3OhZxVRasA3TLbSv9lelYZtt',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00099': {
    partner:      'ST Boiler Services',
    enterpriseId: '00099',
    key: {
      qa:         '',
      production: 'Zc6w2VwuZ5KTbSgeZ5EnwnUnq',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00100': {
    partner:      'M3 Trades Ltd',
    enterpriseId: '00100',
    key: {
      qa:         '',
      production: 'vVri36qDtQfM3ZOJ6Gyy6FNLAO',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: 'https://m3trades.co.uk/finance-application-success',
        failure: 'https://m3trades.co.uk/finance-application-failure',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00101': {
    partner:      'Crayden Ltd',
    enterpriseId: '00101',
    key: {
      qa:         '',
      production: '9Sao3xOuCEjgh6oRDJRshr99zP',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00102': {
    partner:      'Edge 2 Edge',
    enterpriseId: '00102',
    key: {
      qa:         '',
      production: 'O0A1iVDEo2GIWYGoKv4CG4YRZd',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00103': {
    partner:      'First Plumbing and Heating',
    enterpriseId: '00103',
    key: {
      qa:         '',
      production: '786O0UDkGj2yltHQvcuIfHHKH4',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00104': {
    partner:      'Air Distribution LTD',
    enterpriseId: '00104',
    key: {
      qa:         '',
      production: '39FlW8dQSIW3zEk61LnrJDSyO5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00105': {
    partner:      'BPC Contractors',
    enterpriseId: '00105',
    key: {
      qa:         '',
      production: 'kkwix2CLyI5F7V8jgM3wFnXPu1',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00106': {
    partner:      'Salmon Heating Services Ltd',
    enterpriseId: '00106',
    key: {
      qa:         '',
      production: 'JK0Fkyf841e113tLSFixRSnxfV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00107': {
    partner:      'Premium Choice Construction Ltd',
    enterpriseId: '00107',
    key: {
      qa:         '',
      production: '1sCDRANFLoTjeiJTGEXBqA0Tlj',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00108': {
    partner:      'VOLTEV LTD',
    enterpriseId: '00108',
    key: {
      qa:         '',
      production: 'JAlGU2Jtdp4V167hHpg0d3nWNn',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00109': {
    partner:      '5 Star Plumbing',
    enterpriseId: '00109',
    key: {
      qa:         '',
      production: 'ZeQEtX9z3fAap7e6X6hM0fyS95',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00110': {
    partner:      'HBD promlumbing and Electrical Ltd',
    enterpriseId: '00110',
    key: {
      qa:         '',
      production: 't8Qfqs7OIP5EyEQg3PYmpUI8qW',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00111': {
    partner:      'Maximum Flooring',
    enterpriseId: '00111',
    key: {
      qa:         '',
      production: 'x8mvVxnvHKa9o5A8VY9HMKNPPk',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00112': {
    partner:      'Better Utilities NI LTD',
    enterpriseId: '00112',
    key: {
      qa:         '',
      production: '7yiUtzkUWGttkfb8gO03W48BCz',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00113': {
    partner:      'Crawford Systems Ltd',
    enterpriseId: '00113',
    key: {
      qa:         '',
      production: '31wB29k45cTt9vb3ZA7SrvcBRc',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00114': {
    partner:      'Salop Gardens & Construction',
    enterpriseId: '00114',
    key: {
      qa:         '',
      production: 'yuQI2HiNVRagFtnQKVXMTlW6c8',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00115': {
    partner:      'Electrical Call Out',
    enterpriseId: '00115',
    key: {
      qa:         '',
      production: '78ehREE91OvK7gXfu2fsOR9Ryl',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00116': {
    partner:      'BSM Group',
    enterpriseId: '00116',
    key: {
      qa:         '',
      production: 'eoPvUJShkjOJ4CLbJNr4mj0gzb',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00117': {
    partner:      'BGreenn Limited',
    enterpriseId: '00117',
    key: {
      qa:         '',
      production: 'YnuYIHL6eB73hc3vPSA1TFD360',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00118': {
    partner:      'Boiler Tech Scotland',
    enterpriseId: '00118',
    key: {
      qa:         '',
      production: 'EfSuyrVdH5d04D9Yx0Dyl7l5fH',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00119': {
    partner:      'Origin Gas Plumbing and Heating LTD',
    enterpriseId: '00119',
    key: {
      qa:         '77Hmu2LxSmmg8qp0Om2J4CZcRK',
      production: '4YsRUZoPx3EuY4ksj9bYmj7bzr',
    },
    urls: {
      qa: {
        success: 'https://boiler.origin-gph.com/finance-success/?id=',
        failure: 'https://boiler.origin-gph.com/finance-failure/?id=',
      },
      production: {
        success: 'https://boiler.origin-gph.com/finance-success/?id=',
        failure: 'https://boiler.origin-gph.com/finance-failure/?id=',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_free_36:         1750,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00120': {
    partner:      'Intelligent Plumbing and Heating LTD',
    enterpriseId: '00120',
    key: {
      qa:         '',
      production: 'zJvr7PJk66vYLGn6QRsaeS8J0L',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00121': {
    partner:      'Sol-Lux Electrical',
    enterpriseId: '00121',
    key: {
      qa:         '',
      production: 'ZVNB5KDbmRu4Td9og3Fe4hXIfv',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00122': {
    partner:      'Sustain Homes LTD',
    enterpriseId: '00122',
    key: {
      qa:         '',
      production: 'g48FtRcqc9Ab4YhI1uex82ppR1',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00123': {
    partner:      'Gastek',
    enterpriseId: '00123',
    key: {
      qa:         '',
      production: '29sckAx7sVRMcjR1wEgc6RR7Jk',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00124': {
    partner:      'Liverpool Electrics',
    enterpriseId: '00124',
    key: {
      qa:         '',
      production: 'WiqkeeJKbehG6hcmoBM55jH5ll',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00125': {
    partner:      'Vi-Tec Integrated Services Ltd',
    enterpriseId: '00125',
    key: {
      qa:         '',
      production: 'a3l74DqO1Q2oGJ2hRP68kURd70',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00126': {
    partner:      'DLS Kent',
    enterpriseId: '00126',
    key: {
      qa:         '',
      production: '8fusWF2Yzfh0a98D4qyVd5RDrg',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00127': {
    partner:      'Lloyds Plumbing and Heating',
    enterpriseId: '00127',
    key: {
      qa:         '',
      production: 'wESoGY5pc78Xxwi4mlkOG5h4Zy',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00128': {
    partner:      'Total Fire & Security Solutions',
    enterpriseId: '00128',
    key: {
      qa:         '',
      production: 'TgdcX2e3zF0Sr244U2bxcWiNd5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00129': {
    partner:      'Russell Fire and Security',
    enterpriseId: '00129',
    key: {
      qa:         '',
      production: 'l3TcaF9Oomy4jdacv0dJfZpyU5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00130': {
    partner:      'Prime Paving Limited',
    enterpriseId: '00130',
    key: {
      qa:         '',
      production: 'WjBM2qLV0w7AageXcth15LtbjM',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00131': {
    partner:      'Calero Integrated Solutions',
    enterpriseId: '00131',
    key: {
      qa:         '',
      production: '2cLXTnYBNym8btzf6HdQZ8ppik',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00132': {
    partner:      'Hydrus Plumbing and Heating LTD',
    enterpriseId: '00132',
    key: {
      qa:         '',
      production: 'LrCfMXwu9p9wM8JjkUTFX8Xwxc',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1125,
      interest_bearing_12_990:  250,
      interest_bearing_24_990:  300,
      interest_bearing_36_990:  400,
      interest_bearing_48_990:  500,
      interest_bearing_60_990:  600,
      interest_bearing_60_1190: 0,
    },
  },
  '00133': {
    partner:      'Inspired Smart Living Ltd',
    enterpriseId: '00133',
    key: {
      qa:         '',
      production: '8gYAU34ccadPpNVwTE6Fm4B9nV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00134': {
    partner:      'Sweeney Electrical Contractors Ltd',
    enterpriseId: '00134',
    key: {
      qa:         '',
      production: 'ecJTGSEP2FGN4tZE9rvaJ3KODt',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00135': {
    partner:      'FryMac Contractors LTD',
    enterpriseId: '00135',
    key: {
      qa:         '',
      production: 'WtvW9VEq3WEOqf8B2csQRz43gG',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00136': {
    partner:      'FloStar Plumbing and Heating',
    enterpriseId: '00136',
    key: {
      qa:         '',
      production: 'A7vqreXGQtep2ShwabKggkoOmJ',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00137': {
    partner:      'Voice Over Internet Solutions Installation Support Limited',
    enterpriseId: '00137',
    key: {
      qa:         '',
      production: '7N6Wu5fOyhPdeBLqtFFVcAYeyI',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00138': {
    partner:      'Lucid Fire & Security Ltd',
    enterpriseId: '00138',
    key: {
      qa:         '',
      production: 'tZwNrhz3cjZMdPFZISiUHuK5YH',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00139': {
    partner:      'Greens Decking and Landscaping',
    enterpriseId: '00139',
    key: {
      qa:         '',
      production: 'ZBecX5Afof8mZ0g9bZD5R7sQ7H',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00140': {
    partner:      'ABI Business Services Ltd',
    enterpriseId: '00140',
    key: {
      qa:         '',
      production: 'GJJ81xWnzd2ieRR4pT87028zqz',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00141': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00141',
    key: {
      qa:         '',
      production: 'xyzaXktD4gOxkxeKmMjLXJ4W07',
      // production: 'XiI8uO58KDCgvRSrfduF443XGS',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00142': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00142',
    key: {
      qa:         '',
      production: 'Com62KfIW1Q8wA4PMqQessDViH',
      // production: 'WXIRdbfjQnnLzSwBwOOFTMGYGw',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00143': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00143',
    key: {
      qa:         '',
      production: 'XeF9qro0uGX7Mvf1nqP8Olm6bl',
      // production: 'eosv5hEX1EXbHUC1jiC85cDIYu',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00144': {
    partner:      'BeSure Group',
    skipDeposit:  true,
    enterpriseId: '00144',
    key: {
      qa:         '',
      production: 'us3UBTT2Uu7OJ5XlswMcoszqRE',
      // production: 'qmHYFmT3RH5z9crjGixZKZ8R5W',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_12:         700,
      interest_free_24:         1230,
      interest_bearing_36_990:  500,
      interest_bearing_60_990:  700,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00145': {
    partner:      'Harveys Glazing',
    enterpriseId: '00145',
    key: {
      qa:         '',
      production: 'eUPszqIJ8P13ET6QqN3p49BoR0',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00146': {
    partner:      'TICS Global Limited',
    enterpriseId: '00146',
    key: {
      qa:         '',
      production: 'tykogj4xXo2Ksy4KoW0rBCSPU8',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00147': {
    partner:      'Premier Windows Direct Ltd',
    enterpriseId: '00147',
    key: {
      qa:         '',
      production: 'q6I7N6oOdae92F8C55ybFnzEjk',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00148': {
    partner:      'M C Electrical',
    enterpriseId: '00148',
    key: {
      qa:         '',
      production: '2zfX3sEtjbi4u46tBj6WkcWgdP',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00149': {
    partner:      'Oxley Electrical Contractors Limited',
    skipDeposit: true,
    enterpriseId: '00149',
    key: {
      qa:         'dh0bpQM1fzeLJ1hdg2TS8O2inX',
      production: 'CuhQ69KCBDdESp8aKyKyWa2M4d',
    },
    urls: {
      qa: {
        success: 'https://oxley.draftforclients.com/finance-success/?id=',
        failure: 'https://oxley.draftforclients.com/finance-failure/?id=',
      },
      production: {
        success: 'https://oxleyelectrical.com/finance-success/?id=',
        failure: 'https://oxleyelectrical.com/finance-failure/?id=',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00150': {
    partner:      'Alpha Power Solutions LTD',
    enterpriseId: '00150',
    key: {
      qa:         '',
      production: '27ZE7uEAKbZvcnE5wddrMpgpZ5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00151': {
    partner:      'IM Energy Limited',
    enterpriseId: '00151',
    key: {
      qa:         '',
      // User is locked out - this FE key won't match any stored BE key for user
      production: 'IlAUr4MkgrnnHess6SjpOXWHD6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00152': {
    partner:      'Lewis Gas & Electrical',
    enterpriseId: '00152',
    key: {
      qa:         '',
      production: 'u33DjEwcBTgw8aRZqtZnjXtmnN',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00153': {
    partner:      'Ecolek Electrical Services',
    enterpriseId: '00153',
    key: {
      qa:         '',
      production: 'sjf5u5Bh9CcK44ZymQjzykRYhF',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00154': {
    partner:      'C6 Energy LTD',
    enterpriseId: '00154',
    key: {
      qa:         '',
      production: 'x5VamM2DT55rHvpCbxMhFspvrr',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00155': {
    partner:      'ABSC Joinery',
    enterpriseId: '00155',
    key: {
      qa:         '',
      production: 'SGnxSGjwsyFmgRrG84TWWa6TES',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00156': {
    partner:      'Energy Haven LTD',
    enterpriseId: '00156',
    key: {
      qa:         'e75cRCA9xysFAHqMRcXrd8xk4m',
      production: 'E42Q6VHkKT2j9p8CH6Dc8vVMEv',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00157': {
    partner:      'Dream Designs KBB Ltd',
    enterpriseId: '00157',
    key: {
      qa:         '',
      production: '5UXu2ss2GzjGYaViMuAKvKF1c0',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00158': {
    partner:      'Platinum Electrical Contracting LTD',
    enterpriseId: '00158',
    key: {
      qa:         '',
      production: 'LVmJmdjiTkqR1DbJ3ZrqT9J7LV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00159': {
    partner:      'SH Flooring LTD',
    enterpriseId: '00159',
    key: {
      qa:         '',
      production: '1TG5I75wEntaz89UoYmzBJdu1b',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00160': {
    partner:      'Little London Electrics Ltd',
    enterpriseId: '00160',
    key: {
      qa:         '',
      production: 'sMuSnFh45UUOYajNaqgcZiUpbG',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00161': {
    partner:      'S.B Electrical and Security Solutions Ltd',
    enterpriseId: '00161',
    key: {
      qa:         '',
      production: 'rvtlY5UMY5HO0ve19tpiCrJkq1',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00162': {
    partner:      'T H I East Midlands Ltd',
    enterpriseId: '00162',
    key: {
      qa:         '',
      production: 'xq3BWhx0yuIMYKv6HFNiKitsuG',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00163': {
    partner:      'Stage Left Productions',
    enterpriseId: '00163',
    key: {
      qa:         '',
      production: 'bBM7FiKPnWnYaLwNiisQdalEYW',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00164': {
    partner:      'Trendguard',
    enterpriseId: '00164',
    key: {
      qa:         '',
      production: 'lZiCVteJVGNveUvY9ZnUuVMLM6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00165': {
    partner:      'Wilson Landscapes Driveways & Paving Limited',
    enterpriseId: '00165',
    key: {
      qa:         '',
      production: '5IVR4V8A8f9WSpfZZoClabjzwD',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00166': {
    partner:      'Ambismart Group Ltd',
    enterpriseId: '00166',
    key: {
      qa:         '',
      production: 'Lydph5v6IgkqYfP5u05l8HV5qq',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00167': {
    partner: 'Splash Refurbishments Ltd',
    enterpriseId: '00167',
    key: {
      qa:         '',
      production: '2t2UKomvk3elWQRGHiN8KUABnO',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00168': {
    partner:      'Hale heating and plumbing LTD',
    enterpriseId: '00168',
    key: {
      qa:         '',
      production: '8lkDa8bsNnXPnwQjnfeD07IKki',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00169': {
    partner:      'LNR Windows',
    enterpriseId: '00169',
    key: {
      qa:         '',
      production: 'U5SaZPxHlchJ5I8rHLwehjKbQH',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00170': {
    partner:      'Nevills Flooring Ltd',
    enterpriseId: '00170',
    key: {
      qa:         '',
      production: '4Qm6zgaZhluYoDpGd3myDPLZC3',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00171': {
    partner:      '',
    enterpriseId: '00171',
    key: {
      qa:         '',
      production: '',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00172': {
    partner:      'Westwood Smart Media & Security',
    enterpriseId: '00172',
    key: {
      qa:         '',
      production: 'U7jjYXpnGwSD3PGPjq9oeA2aDv',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00173': {
    partner:      'PCM247 Maintenance Ltd',
    enterpriseId: '00173',
    key: {
      qa:         '',
      production: 'bivaoJCGJj3tMmrozrNCnnkQqV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00174': {
    partner:      'EV Charging Experts',
    enterpriseId: '00174',
    key: {
      qa:         '',
      production: 'VkExrsGOB13jBKnTQmJiMFfpUz',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00175': {
    partner:      'Mcr GAS homecare LTD',
    enterpriseId: '00175',
    key: {
      qa:         '',
      production: 'uhaFsBDmWsFlWeIxP1C5fbJrlb',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00176': {
    partner:      'TAP Security Systems Ltd',
    enterpriseId: '00176',
    key: {
      qa:         '',
      production: 'FyZJlWNYFqw2Qwwp7VKvuWghTL',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_12:         800,
      interest_free_24:         1250,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00177': {
    partner:      'Perfecta Driveways Ltd',
    enterpriseId: '00177',
    key: {
      qa:         '',
      production: 'AQtJKAvebKF432VvYu5y4xYe6x',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00178': {
    partner:      'Unique Garden Creations',
    enterpriseId: '00178',
    key: {
      qa:         '',
      production: 'SAUmZF4z9ckfTTloTsbpCxoylg',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00179': {
    partner:      'Rockland Solutions Ltd',
    enterpriseId: '00179',
    key: {
      qa:         '',
      production: 'rUqFel2qIsJ033gnED0p6n3Pf6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00180': {
    partner:      'Plug it in Group LTD',
    enterpriseId: '00180',
    key: {
      qa:         '',
      production: '2zyKnY1YNamiUiE6S2SLDhOLcz',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00181': {
    partner:      'Spicy IT Support Limited',
    enterpriseId: '00181',
    key: {
      qa:         '',
      production: 'eHPvfznRWpizSJ7fa9eNL7BIsi',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00182': {
    partner:      'D&S Carpets&Flooring',
    enterpriseId: '00182',
    key: {
      qa:         '',
      production: 'XnOUb3EaTSvwdTdkanHoP94f6N',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00183': {
    partner:      'Fortis Windows',
    enterpriseId: '00183',
    key: {
      qa:         'Yzq56pfeNXiouopS83Q1gJqqY8',
      production: 'rjiFflNWMKUfNRyeTJuKGBaKUD',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00184': {
    partner:      'Thermsure UK Ltd',
    enterpriseId: '00184',
    key: {
      qa:         '',
      production: 'bxxO2fGAjEvn2TVc7eVclVdgch',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00185': {
    partner:      '',
    enterpriseId: '00185',
    key: {
      qa:         '',
      production: '',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00186': {
    partner:      'Belfast Boiler Installations',
    enterpriseId: '00186',
    key: {
      qa:         '',
      production: '9QyAMqykyjnCa3YbdLkJck0gSq',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00187': {
    partner:      'Gorilla Artificial Grass Ltd',
    enterpriseId: '00187',
    key: {
      qa:         '',
      production: '5V627792glJc6zBluWpXN8TJhE',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00188': {
    partner:      'Britlec Group Ltd',
    enterpriseId: '00188',
    key: {
      qa:         '',
      production: 'eKQjAUHlWBpVExeZTsSwNAcnJe',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00189': {
    partner:      'Cerberus Security Solutions',
    enterpriseId: '00189',
    key: {
      qa:         '',
      production: 'YiqIdBQHrfSa6xnLMDyoqGWSIR',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00190': {
    partner:      'Rs plumbing and heating',
    enterpriseId: '00190',
    key: {
      qa:         '',
      production: 'WXaBRGOt5en6W8jyEYmq2nkyEV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00191': {
    partner:      'Standford Electrical Ltd',
    enterpriseId: '00191',
    key: {
      qa:         '',
      production: 'wAAsrZ2nTG9FBhHWU0xs2WuiK0',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00192': {
    partner:      'Peel Electrical & Security Systems Ltd',
    enterpriseId: '00192',
    key: {
      qa:         '',
      production: '87cfql1GFWjtm5Kovr2rZSXWP3',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00193': {
    partner:      'Allumroll',
    enterpriseId: '00193',
    key: {
      qa:         '',
      production: '1iJUnmb4y1IvzSi2ih92fQd0Fb',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00194': {
    partner:      'All Driveways',
    enterpriseId: '00194',
    key: {
      qa:         '',
      production: 'fv5CSt4o0Kq2IcpJVtmpSDh3EU',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00195': {
    partner:      'Tek Security and Networks LTD',
    enterpriseId: '00195',
    key: {
      qa:         '',
      production: '8dTCrrkS1kbosXF0Lwh6KCBfy5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00196': {
    partner:      'Utley Electrical Ltd',
    enterpriseId: '00196',
    key: {
      qa:         '',
      production: 'dpFSvUtquq8KIlf8swYWZSlPio',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00197': {
    partner:      'Baird and Brown Ltd',
    enterpriseId: '00197',
    key: {
      qa:         '',
      production: '53gQXfbPpgXvLuEOk35DPRK38q',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00198': {
    partner:      'Glesca Garden Guy',
    enterpriseId: '00198',
    key: {
      qa:         '',
      production: 'eTDHjyntEwDZVVHSEr5HVEeA6e',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00199': {
    partner:      'Drives Direct ltd',
    enterpriseId: '00199',
    key: {
      qa:         '',
      production: 'yurouJYkQXgPiPkEnZBEeSrL3w',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00200': {
    partner:      'Tellystream Ltd',
    enterpriseId: '00200',
    key: {
      qa:         '',
      production: 'kk81FtWJ0dFo2UwwWGGNraihj9',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00201': {
    partner:      'Transformation Flooring',
    enterpriseId: '00201',
    key: {
      qa:         '',
      production: 'gx4dHtl4PTBtNETwCjCs1DswKv',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00202': {
    partner:      'GHS Loft Floooring & Ladders',
    enterpriseId: '00202',
    key: {
      qa:         '',
      production: 'eBg4aWUWwu71MqO87fZgtVRmNd',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00203': {
    partner:      'Woody\'s Building Services Ltd',
    enterpriseId: '00203',
    key: {
      qa:         '',
      production: 'kF9OMaDYeP7z296HNfiLS6MxD5',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00204': {
    partner:      'Danlec Electrical Solutions Ltd',
    enterpriseId: '00204',
    key: {
      qa:         '',
      production: 'uakXRBBC83TAEvVH5qGas8DWgV',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00205': {
    partner:      'Metro Group Services Ltd',
    enterpriseId: '00205',
    key: {
      qa:         '',
      production: 'JmgZdHvexcV2Xw0FoKtu587gGi',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         700,
      interest_free_24:         1125,
      interest_bearing_60_1190: 0,
    },
  },
  '00206': {
    partner:      'Elite Stoves LTD',
    skipDeposit:  true,
    enterpriseId: '00206',
    key: {
      qa:         '',
      production: 'r10Osu6Of3mE7HfrWpQf4GIjHk',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00207': {
    partner:      'Simply Fabulous Gardens',
    enterpriseId: '00207',
    key: {
      qa:         '',
      production: 'bkopNNqAtS1dUpbm445Lk93aSB',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00208': {
    partner:      'DH Landscaping svcs',
    enterpriseId: '00208',
    key: {
      qa:         '',
      production: 'D9yceqKdbB5Sd7lfx3IFK09sIr',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00209': {
    partner:      'Pro Glazing Windows and Doors',
    skipDeposit:  true,
    enterpriseId: '00209',
    key: {
      qa:         '',
      production: 'RLImem0KyVqelHgqf64gYCRkwX',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00210': {
    partner:      'Plug Energy LTD',
    enterpriseId: '00210',
    key: {
      qa:         '',
      production: 'xSMWQY6c9fYHCvcHCPDHzmsKIx',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00211': {
    partner:      'EVOLUTION SOLUTIONS (NW) LIMITED',
    enterpriseId: '00211',
    key: {
      qa:         '',
      production: 'xpBm75Xwzcmt4zUM7uzB1PZTAn',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_12:         800,
      interest_free_24:         1250,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '00212': {
    partner:      'Firth Electrical and Property Services Limited',
    enterpriseId: '00212',
    key: {
      qa:         '',
      production: 'qkFlxOmIIKlyLJaS3ZzShyrxDi',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00213': {
    partner:      'Mansfield landscape management Ltd',
    enterpriseId: '00213',
    key: {
      qa:         '',
      production: '7bERfrsWZ6Sxm8gO1iVYOB1PsE',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00214': {
    partner:      'Hex Communications Ltd',
    enterpriseId: '00214',
    key: {
      qa:         '',
      production: 'feqN82P2byk5tvM69mxUHb452M',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00215': {
    partner:      'Fife Property Care LTD',
    enterpriseId: '00215',
    key: {
      qa:         '',
      production: 'T6OgvdcFt4R28wQCAvlr0MW4nO',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00216': {
    partner:      'Rechrg.net',
    enterpriseId: '00216',
    key: {
      qa:         '',
      production: 'zbR6qUmTjF6TTvSuDrHseeezU6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00217': {
    partner:      'Force 9 Systems Ltd',
    enterpriseId: '00217',
    key: {
      qa:         '',
      production: 'GTx5tZXMtPB67uXsaxUwzMsAMe',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00218': {
    partner:      'Abridge ventures limited',
    enterpriseId: '00218',
    key: {
      qa:         '',
      production: 'zqXr2SJt8ab2WWRwYua3fPBSNY',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00219': {
    partner:      'Griffiths Heating & Renewables Ltd',
    enterpriseId: '00219',
    key: {
      qa:         '',
      production: 'ZXrZNNw5VWhPIlKFpsg7hvmr8X',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00220': {
    partner:      'CBD South Ltd',
    enterpriseId: '00220',
    key: {
      qa:         '',
      production: 'kJldYtGg6gmBxNCkptwJbSF508',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00221': {
    partner:      'JH Joinery Services North East',
    skipDeposit: true,
    enterpriseId: '00221',
    key: {
      qa:         '',
      production: 'rE3dbPIEBjCiBRTA1E10qXFQjd',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00222': {
    partner:      'GPHE',
    enterpriseId: '00222',
    key: {
      qa:         '',
      production: 'ybaTCHsC4hul6w4HVt305OxXK0',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00223': {
    partner:      'JS SECURITY SOLUTIONS',
    enterpriseId: '00223',
    key: {
      qa:         '',
      production: 'obabQffQUm3o25SFzLQGo2s9JT',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00224': {
    partner:      'ESF & Sons Ltd',
    enterpriseId: '00224',
    key: {
      qa:         '',
      production: 'ZF7Rmf9CSXoIBe0dYKDvi1NeOH',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00225': {
    partner:      'Williams Lawns & Landscaping Ltd',
    skipDeposit: true,
    enterpriseId: '00225',
    key: {
      qa:         '',
      production: 'USvSk57DaGFWx8F4AhylFqQWhM',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00226': {
    partner:      'A One Home Improvements South West Ltd',
    skipDeposit: true,
    enterpriseId: '00226',
    key: {
      qa:         '',
      production: 'iBJilo3w5iGQnlz3A8tCIHZsV6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00227': {
    partner:      'Electrical Ventilation Renewables Ltd',
    enterpriseId: '00227',
    key: {
      qa:         '',
      production: 'Q6dmCJbYJbJpmFLeQt7ijYdjIt',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00228': {
    partner:      'Inphase Electrics Limited',
    enterpriseId: '00228',
    key: {
      qa:         '',
      production: 'BHjwB383C9LOL75G7MRR0sC7vF',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00229': {
    partner:      'Stimson Electrical Solutions',
    enterpriseId: '00229',
    key: {
      qa:         '',
      production: 'PoMDP1YHeE0WyBaS5k3uOrGwBN',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00230': {
    partner:      '',
    enterpriseId: '00230',
    key: {
      qa:         '',
      production: '',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00231': {
    partner:      'My life midlands ltd',
    skipDeposit: true,
    enterpriseId: '00231',
    key: {
      qa:         '',
      production: 'Lqxy0kHYQyDNARqqVn59SsCzhK',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00232': {
    partner:      'Marlow Electrical',
    enterpriseId: '00232',
    key: {
      qa:         '',
      production: 'GlwHITtT9gYBfVAQ5fOln0FNZv',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00233': {
    partner:      'L harrison plumbing & heating ltd',
    enterpriseId: '00233',
    key: {
      qa:         '',
      production: 'wMPXZTOuouDaanT38y1ibQYfdl',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00234': {
    partner:      'J.M.W Plumbing & Heating',
    enterpriseId: '00234',
    key: {
      qa:         '',
      production: 'p0OLiomD1e28uvoEdtQvRIl9sg',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00235': {
    partner:      'Alternative Heating Solutions Limited',
    enterpriseId: '00235',
    key: {
      qa:         '',
      production: '00TbZxErm8XG4D20CgGbucqNKs',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00236': {
    partner:      'Odin Electrical Services Ltd',
    enterpriseId: '00236',
    key: {
      qa:         '',
      production: 'P99ufeNZ3191zG3NrmVAyhi5ez',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00237': {
    partner:      'Start smart electrical',
    enterpriseId: '00237',
    key: {
      qa:         '',
      production: 'sugUBwCt9CFplWjrJPFvesFrVc',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00238': {
    partner:      'TNS CONTRACTORS LTD',
    enterpriseId: '00238',
    key: {
      qa:         '',
      production: 'nP0zYHE8qUZaGPVPH5gZC3UH7y',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00239': {
    partner:      'Eclipse Electrical Solutions Ltd',
    enterpriseId: '00239',
    key: {
      qa:         '',
      production: 'gZqFT782NAruhhkBO0KQReDhDR',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00240': {
    partner:      'Delta Protection Security Services Ltd',
    enterpriseId: '00240',
    key: {
      qa:         '',
      production: 'mOM7z6RD7bkj2tEtQigHzlvsT4',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00241': {
    partner:      'Dark Soil LTD',
    skipDeposit: true,
    enterpriseId: '00241',
    key: {
      qa:         '',
      production: 'xivGirLS6vVtqbjSVKjO67DoUy',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00242': {
    partner:      'Vanquish eco limited',
    enterpriseId: '00242',
    key: {
      qa:         '',
      production: 'dbdlScph1HjNfYdc8ZeAE9o8eo',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00243': {
    partner:      'SHOLDEN HEATING LTD',
    enterpriseId: '00243',
    key: {
      qa:         '',
      production: 'qQQUcv9JhpgkQlb8n67btgeROA',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00244': {
    partner:      'FORRESTER CONTRACT SERVICES LIMITED',
    enterpriseId: '00244',
    key: {
      qa:         '',
      production: 'bUp81HQHUmodFwul1S5R1pAwJ9',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00245': {
    partner:      'Hants Electrical Ltd',
    enterpriseId: '00245',
    key: {
      qa:         '',
      production: '0v7HDjvpcnH183o4HAVGTeQ2MY',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },


  '00246': {
    partner:      'HJEC Ltd',
    enterpriseId: '00246',
    key: {
      qa:         '',
      production: 'mN45OMWRnPwuv6WtCAXOA5TrVG',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00247': {
    partner:      'Lavelec Electrical',
    enterpriseId: '00247',
    key: {
      qa:         '',
      production: 'zBCgyvCIEYqmG5vasWSsvkoIa6',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00248': {
    partner:      'Heatdial plumbing & heating Ltd',
    enterpriseId: '00248',
    key: {
      qa:         '',
      production: 'ocbVf4lsR469mQ6yHnlB9XIqTL',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00249': {
    partner:      'P & R Gas Solutions Ltd',
    enterpriseId: '00249',
    key: {
      qa:         '',
      production: 'Yj2ON5ZupyfppNU2Bdk4M4QHDq',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00250': {
    partner:      'Murray and McGregor Stoves and Fireplaces',
    enterpriseId: '00250',
    key: {
      qa:         '',
      production: 'spJed2pPaYCvbT72cv8pSBWGSm',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00251': {
    partner:      'Unique Gas solutions ltd',
    enterpriseId: '00251',
    key: {
      qa:         '',
      production: '14cXtUlKk5cPiRCGOszhgObpjy',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },
  '00252': {
    partner:      'Glazing Specialist LTD',
    enterpriseId: '00252',
    key: {
      qa:         '',
      production: 'ocVUnKnIb9MpkhnolZaTmOaBUH',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_24:         1250,
      interest_bearing_60_1190: 0,
    },
  },

  '99994': {
    partner:      'Kanda Products & Services Ltd.',
    enterpriseId: '99994',
    // WooCommerce site needed for
    wcsite: 'clleancode.com/kanda',
    key: {
      qa:         'g8JAJ4dRbxNQLg7oZp1dnT3Of5',
      production: 'hENoqoGr0EheN89H039jFI2ukT',
    },
    urls: {
      qa: {
        success: 'clleancode.com/kanda',
        failure: 'clleancode.com/kanda ',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_24:         1300,
      interest_bearing_36_790:  745,
      interest_bearing_60_790:  1100,
      interest_bearing_12_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '99996': {
    partner:      'Bizzi Marketing',
    wcsite: 'stboilerservices.online',
    enterpriseId: '99996',
    key: {
      qa:         'mt0lEYC3EoLF2rqKtP8MQS9Rx',
      production: 'TnlWvGqVS06YtI1RCIhiWIkSE',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_10:         750,
      interest_free_12:         835,
      interest_free_24:         1335,
      interest_free_36:         1800,
      interest_bearing_12_490:  625,
      interest_bearing_24_490:  945,
      interest_bearing_36_490:  1245,
      interest_bearing_48_490:  1545,
      interest_bearing_60_490:  1745,
      interest_bearing_12_790:  475,
      interest_bearing_24_790:  665,
      interest_bearing_36_790:  845,
      interest_bearing_48_790:  1025,
      interest_bearing_60_790:  1200,
      interest_bearing_12_990:  400,
      interest_bearing_24_990:  500,
      interest_bearing_36_990:  600,
      interest_bearing_48_990:  700,
      interest_bearing_60_990:  800,
      interest_bearing_12_1190: 0,
      interest_bearing_24_1190: 0,
      interest_bearing_36_1190: 0,
      interest_bearing_48_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
  '99997': {
    partner:      'Kanda WooCommerce',
    // WooCommerce site needed for
    wcsite: 'clleancode.com/kanda',
    enterpriseId: '99997',
    key: {
      qa:         'xth5vW7ZHQy2j5g8H1LqQdtRuE',
      production: 'bnjdOAammtv4YAaYj5ysCi8rnh',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_bearing_36_790:  745,
      interest_bearing_60_790:  1100,
      interest_bearing_12_1190: 0,
    },
  },
  '99998': {
    partner:      'Kanda (deposit skip)',
    skipDeposit:  true,
    enterpriseId: '99998',
    key: {
      qa:         'g8JAJ4dRbxNQLg7oZp1dnT3Of5',
      production: 'hENoqoGr0EheN89H039jFI2ukT',
    },
    urls: {
      qa: {
        success: '',
        failure: '',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_bearing_36_790:  745,
      interest_bearing_60_790:  1100,
      interest_bearing_12_1190: 0,
    },
  },
  '99999': {
    partner:      'Kanda Products & Services Ltd.',
    enterpriseId: '99999',
    // WooCommerce site needed for
    wcsite: 'clleancode.com/kanda',
    key: {
      qa:         'g8JAJ4dRbxNQLg7oZp1dnT3Of5',
      production: 'hENoqoGr0EheN89H039jFI2ukT',
    },
    urls: {
      qa: {
        success: 'clleancode.com/kanda',
        failure: 'clleancode.com/kanda ',
      },
      production: {
        success: '',
        failure: '',
      },
    },
    rates: {
      interest_free_24:         1300,
      interest_bearing_36_790:  745,
      interest_bearing_60_790:  1100,
      interest_bearing_12_1190: 0,
      interest_bearing_60_1190: 0,
    },
  },
});


export const BASE_RATES = ({
  interest_free_10:         750,
  interest_free_12:         835,
  interest_free_24:         1335,
  interest_free_36:         1800,
  interest_bearing_12_490:  625,
  interest_bearing_24_490:  945,
  interest_bearing_36_490:  1245,
  interest_bearing_48_490:  1545,
  interest_bearing_60_490:  1745,
  interest_bearing_12_790:  475,
  interest_bearing_24_790:  665,
  interest_bearing_36_790:  845,
  interest_bearing_48_790:  1025,
  interest_bearing_60_790:  1200,
  interest_bearing_12_990:  400,
  interest_bearing_24_990:  500,
  interest_bearing_36_990:  600,
  interest_bearing_48_990:  700,
  interest_bearing_60_990:  800,
  interest_bearing_12_1190: 0,
  interest_bearing_24_1190: 0,
  interest_bearing_36_1190: 0,
  interest_bearing_48_1190: 0,
  interest_bearing_60_1190: 0,
});
