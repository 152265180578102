import React from 'react';
import PropTypes from 'prop-types';

import Container from './StatusPrice-container';

import Price from '../Price';
import Icon from '../Icon';

const ViewComponent = ({
  status,
  financed,
  iconClassName,
  iconSize,
  ...restProps
}) => (
  <Container status={status}>
    {({ color, paid }) => (
      <div className="inline-flex flex-row">
        <Price color={color} {...restProps} />
        {financed && (
          <Icon
            stroke={color}
            className={iconClassName}
            size={iconSize}
            icon="financed"
          />
        )}
        {paid && (
          <Icon
            stroke={color}
            className={iconClassName}
            size={iconSize}
            icon="check"
          />
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Price-view';

ViewComponent.defaultProps = {
  status: 'DRAFT',
  financed: false,
  amount: 0,
  wrapperClassName: 'h-4',
  poundsClassName: 'text-style-g-em',
  centsClassName: 'text-style-k',
  className: undefined,
  isLoading: undefined,
  iconSize: 16,
  iconClassName: 'ml-1',
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Job status
   */
  status: PropTypes.string,
  /**
   * Job is financed
   */
  financed: PropTypes.bool,
  /**
   * Wrapper className
   */
  wrapperClassName: PropTypes.string,
  /**
   * Pounds className
   */
  poundsClassName: PropTypes.string,
  /**
   * Cents className
   */
  centsClassName: PropTypes.string,
  /**
   * Price amount in cents
   */
  amount: PropTypes.number,
  /**
   * Icon size
   */
  iconSize: PropTypes.number,
  /**
   * Icon class name
   */
  iconClassName: PropTypes.string,
  /**
   * IsLoading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
