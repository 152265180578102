import React from 'react';

import HeaderGroup from './HeaderGroup';

const ViewComponent = ({ headerGroups, ...props }) =>
  headerGroups.map((headerGroup, i) => (
    <HeaderGroup key={String(i)} headerGroup={headerGroup} {...props} />
  ));

ViewComponent.displayName = 'Header-view';

export default ViewComponent;
