export const CLASS_NAMES = {
  container: 'flex flex-1 flex-row justify-between',
};

export const DEFAULT_NEXT_BUTTON_PROPS = {
  variant: 'solid',
  size: 48,
  label: 'Next',
  icon: 'arrow-right',
};

export const DEFAULT_PREV_BUTTON_PROPS = {
  variant: 'solid-grey',
  size: '48-20',
  icon: 'arrow-left',
};
