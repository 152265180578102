import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from './ContentHeader-constants';

const ViewComponent = ({ children, option }) => (
  <React.Fragment>
    <div className={CLASS_NAMES.shadow} />
    <div className={CLASS_NAMES.container}>
      <div className={CLASS_NAMES.wrapper}>
        <div className={CLASS_NAMES.content}>
          {option && <div className={CLASS_NAMES.option}>{option}</div>}
          {children}
        </div>
      </div>
    </div>
  </React.Fragment>
);

ViewComponent.displayName = 'DesktopLayoutBoxed-ContentHeader-view';

ViewComponent.defaultProps = {
  children: null,
  option: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Option
   */
  option: PropTypes.node,
};

export default ViewComponent;
