import React from 'react';
import PropTypes from 'prop-types';

import Container from './Mobile-container';
import Modal from './components/Modal';

import Button from '../components/Button';

const ViewComponent = ({ children, name, options, ...restProps }) => (
  <Container name={name} options={options}>
    {({ ids, handleShowModal, buttonText }) => (
      <React.Fragment>
        <Button
          id={ids.button}
          onClick={handleShowModal}
          buttonText={children || buttonText}
          {...restProps}
        />
        <Modal
          {...restProps}
          modalId={ids.modal}
          name={name}
          options={options}
        />
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'SelectionModal-Mobile-view';

ViewComponent.defaultProps = {
  children: null,
  options: [],
  multiple: undefined,
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  icon: undefined,
  isLoading: undefined,
  error: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * icon name
   */
  icon: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

export default ViewComponent;
