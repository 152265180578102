import Switch from './Switch';
import Checkbox from './Checkbox';
import RadioCheck from './RadioCheck';
import RadioButton from './RadioButton';

const Handle = {
  Switch,
  Checkbox,
  RadioCheck,
  RadioButton,
};

export default Handle;
