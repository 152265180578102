import React from 'react';
import PropTypes from 'prop-types';

import Container from './StatusActionLabel-container';

const ViewComponent = ({ className, ...props }) => (
  <Container {...props}>
    {({ label }) => <span className={className}>{label}</span>}
  </Container>
);

ViewComponent.displayName = 'StatusActionLabel-view';

ViewComponent.defaultProps = {
  className: '',
  status: 0,
};

ViewComponent.propTypes = {
  /**
   * Job status
   */
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * ClassName
   */
  className: PropTypes.string,
};

export default ViewComponent;
