import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useRef, useState, useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';

import { OPACITIES, BACKDROP_CLASS_NAME, ESC_KEY } from '../Modals-constants';
import { opacitySuffix } from '../Modals-functions';
import useOuterClick from './AnimatedModalContainer-useOuterClick';
import ModalsWrapper from '../../ModalsWrapper';

const ContainerComponent = ({ children, id, opacity }) => {
  const { visibleAnimatedModals: visibleModals, hideAnimatedModal: hideModal } =
    useContext(ModalsWrapper.Context);

  const [isVisible, setIsVisible] = useState(visibleModals.indexOf(id) !== -1);
  const isShown = visibleModals.indexOf(id) !== -1;

  const suffix = opacitySuffix(opacity);

  const backdropClassName = clsx(BACKDROP_CLASS_NAME, `opacity${suffix}`);

  const modalsRef = useRef(visibleModals);

  /*
   * Handles close logic
   */
  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  /*
   * Uses hook to call close when the click is outside of modal
   */
  const ref = useOuterClick(handleClose);

  const onHide = useCallback(() => {
    hideModal(id);
    setIsVisible(false);
    modalsRef.current = [];
  }, [hideModal, id]);

  /**
   * Hiddes last modal when ESC key is pressed
   */
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === ESC_KEY) {
        setIsVisible(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if (modalsRef.current.indexOf(id) === -1 && isShown) {
      setIsVisible(true);
      modalsRef.current = visibleModals;
    }
  }, [id, isShown, visibleModals]);

  /*
   * Displays nothing if modal is not visible
   */
  // if (!isShown) {
  //   return null;
  // }

  /*
   * Uses portal to move modal "modals" div on root so it would not have problems with z-index
   */
  return ReactDOM.createPortal(
    children({
      isShown,
      backdropClassName,
      isVisible,
      handleClose,
      hideModal: onHide,
      ref,
    }),
    document.getElementById('modals')
  );
};

ContainerComponent.displayName = 'ModalContainer-Container';

ContainerComponent.propTypes = {
  /**
   * id of modal
   */
  id: PropTypes.string.isRequired,
  /**
   * modal opacity
   */
  opacity: PropTypes.oneOf(OPACITIES),
};

export default ContainerComponent;
