export const CLASS_NAMES = {
  container: 'flex flex-col flex-1 w-screen min-h-vvp relative',
  background: 'absolute w-full',
  lines: 'absolute left-0 top-0 z-20',
  headerWrapper: 'mt-10 flex w-full px-8 xl:px-16',
};

export const WRAPPER_CLASS_NAME =
  'flex w-full flex-col max-w-screen-lg 2xl:max-w-screen-2xl m-auto';

export const CONTAINER_CLASS_NAME = 'px-8 2xl:px-52 flex flex-col w-full';
