import { KandaEnterpriseAuthService } from './kandaEnterpriseAuthService';

import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';
import { encodingType } from '../lib/types';

/**
 * Enterprise Portal Service
 * @typedef {Object} EnterprisePortalServiceOptions
 * @property {Object} BaseURLS service urls
 * @property {Object} authService authentication service
 *
 * EnterprisePortalProfileObject
 * @typedef {Object} EnterprisePortalProfileObject
 * @property {Object} OrganizationObject
 * @property {String} isFirstTimeLogin string representation of boolean
 *
 * OrganizationObject
 * @typedef {Object} OrganizationObject
 * @property {String} id Enterprise ID of user
 * @property {String} name Enterprise User Name
 * @property {Array.<String>} availableRates Rates available to enterprise user
 *
 * EnterprisePortalLoanApplications
 * @typedef {Array} EnterprisePortalLoanApplications
 * @property {Object} LoanApplicationObject
 *
 * LoanApplicationObject
 * @typedef {Object} LoanApplicationObject
 * @property {String} status Current status of loan application
 * @property {Int} createdAt Timestamp loan was created at
 * @property {String} description Description of the goods being installed
 * @property {String} reference Reference given to loan application
 * @property {Int} updatedAt Timestamp loan was last updated at
 * @property {String} id Kanda UUID of loan
 * @property {Int} price Price of loan goods loan application is for
 * @property {Object} CustomerDetailsObject
 *
 * CustomerDetailsObject
 * @typedef {Object} CustomerDetailsObject
 * @property {String} firstName First name of the customer
 * @property {String} lastName Last name of the customer
 * @property {String} title Title of the customer
 * @property {String} mobile Mobile number of the cusyomer
 * @property {String} email Email of the customer
 * @property {Object} CustomerDetailsAddressObject
 *
 * CustomerDetailsAddressObject
 * @typedef {Object} CustomerDetailsAddressObject
 * @property {String} houseNumber House number of the customer
 * @property {String} street Street the customer lives on
 * @property {String} city City the customer lives in
 * @property {String} postcode Postcode of the customer
 *
 * ResetPasswordObject
 * @typedef {Object} ResetPasswordObject
 * @property {String} newPassword New password for user
 *
 * NewLoanApplicationObject
 * @typedef {Object} NewLoanApplicationObject
 * @property {String} reference New loan application object
 * @property {String} descriptionOfGoods Description of goods for application
 * @property {String} email Customer email
 * @property {Int} price Goods cost for loan
 * @property {Array.<String>} enabledRates Loan rates enabled for application
 * @property {String=} firstName Customer first name for application
 * @property {String=} lastName Customer last name for application
 * @property {String=} mobile Customer mobile number for application
 * @property {Int=} deposit The deposit percentage taken, if user takes deposit
 *
 * NewLoanLinkObject
 * @typedef {Object} NewLoanLinkObject
 * @property {String} formLink Loan application form link
 */

export const EnterprisePortalService = {
  createLoanApplication: 'EnterprisePortalService-createLoanApplication',
  retrieveProfile: 'EnterprisePortalService-retrieveProfile',
  retrieveLoanApplications: 'EnterprisePortalService-retrieveLoanApplications',
  resetPasswordFTL: 'EnterprisePortalService-resetPasswordFTL',
  resetPassword: 'EnterprisePortalService-resetPassword',
  // uploadFulfilmentDocs: 'EnterprisePortalService-uploadFulfilmentDocs',
  // sendSatNote: 'EnterprisePortalService-sendSatNote',
};

const EnterprisePortalServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {EnterprisePortalServiceOptions} options
   */
  constructor({ baseURLS, authService }) {
    super({ authService, keys: EnterprisePortalService });

    this.baseURLS = baseURLS;
  }

  /**
   * Submit a new loan application from user
   * @param {NewLoanApplicationObject} payload Payload conttaining new loan data
   * @returns {NewLoanLinkObject} new finance application link object
   */
  async [EnterprisePortalService.createLoanApplication](payload) {
    const result = await super.post('/loan-applications', decamelize(payload), {
      baseURL: this.baseURLS.createLoanApplication,
    });

    return camelize(result);
  }

  /**
   * Retrieve enterprise user's profile
   * @returns {EnterprisePortalProfileObject} profile data
   */
  async [EnterprisePortalService.retrieveProfile]() {
    const result = await super.post('/profile', null, {
      baseURL: this.baseURLS.retrieveProfile,
    });

    return camelize(result);
  }

  /**
   * Retrieve enterprise user's loan applications
   * @returns {EnterprisePortalLoanApplications} loan application data
   */
  async [EnterprisePortalService.retrieveLoanApplications]() {
    const result = await super.get('/list-applications', {
      baseURL: this.baseURLS.retrieveLoanApplications,
    });

    // const loans = {
    //   loan_applications: result.loan_applications.map((loan) => ({
    //     ...loan,
    //     status: loan.status.replace(/_/g, ' '),
    //   })),
    // };

    return camelize(result);
  }

  /**
   * Retrieve enterprise user's profile
   * @param {ResetPasswordObject} payload Reset password object
   * @returns {EnterprisePortalProfileObject} profile data
   */
  async [EnterprisePortalService.resetPasswordFTL](payload) {
    const result = await super.post(
      '/reset-password-ftl',
      decamelize(payload),
      {
        baseURL: this.baseURLS.resetPasswordFTL,
      },
    );

    return camelize(result);
  }

  /**
   * Reset user's password
   * @param {String} email Email of user to reset
   * @returns {EnterprisePortalProfileObject} profile data
   */
  async [EnterprisePortalService.resetPassword](email) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: encodingType.JSON,
        'Content-Type': encodingType.JSON,
        Authorization: `Bearer ${email}`,
      },
    };
    const url = `${this.baseURLS.forgotPassword}/forgot-password`;

    const res = await fetch(url, requestOptions);

    const json = await res.json();

    return camelize(json);
  }
};

export default new EnterprisePortalServiceImplementation({
  baseURLS: {
    createLoanApplication: `${config.ENTERPRISE_FINANCE_URL}`,
    retrieveProfile: `${config.ENTERPRISE_RETRIEVE_PROFILE}`,
    retrieveLoanApplications: `${config.ENTERPRISE_RETRIEVE_FINANCE}`,
    resetPasswordFTL: `${config.ENTERPRISE_RESET_PASSWORD_FTL}`,
    uploadFulfilmentDocs: `${config.ENTERPRISE_UPLOAD_DOCS}`,
    sendSatNote: `${config.ENTERPRISE_SEND_SAT_NOTE}`,
    forgotPassword: `${config.API_ENCODE_DECODE_URL}`,
  },
  authService: KandaEnterpriseAuthService,
});
