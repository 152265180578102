import React from 'react';
import PropTypes from 'prop-types';

import Container from './LoadingProvider-container';
import { Context } from './LoadingProvider-context';

const ViewComponent = ({ children, isLoading, overwrite }) => (
  <Container isLoading={isLoading} overwrite={overwrite}>
    {(value) => <Context.Provider value={value}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.displayName = 'LoadingProvider-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  children: null,
  overwrite: false,
};

ViewComponent.Context = Context;

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * IsLoading
   */
  isLoading: PropTypes.bool,
  /**
   * overwrite parent value
   */
  overwrite: PropTypes.bool,
};

export default ViewComponent;
