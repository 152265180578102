import {
  VARIANTS,
  BASE_ROUNDING,
  ROUNDING,
  ROUNDING_RIGHT,
} from './Advanced-constants';

export const popoverRouning = (showPanel, variant, right) => {
  if (!VARIANTS.includes(variant)) return BASE_ROUNDING;
  if (!showPanel) return BASE_ROUNDING;
  if (right) return ROUNDING_RIGHT[variant];
  return ROUNDING[variant];
};

export const emptyPanel = () => null;
