import { extractActionLabel } from '@kanda-utils/library';

const ContainerComponent = ({ children, status }) => {
  const label = extractActionLabel(status);

  return children({
    label,
  });
};

ContainerComponent.displayName = 'StatusActionLabel-Container';

export default ContainerComponent;
