import React from 'react';
import PropTypes from 'prop-types';

import Container from './SelectedCompany-container';

import Button from '../../../../../Button';
import Input from '../../../Input';

const ViewComponent = ({
  name,
  selectedLabel,
  removeSelected,
  removeSelectedLabel,
  ...props
}) => (
  <Container {...props} name={name}>
    {({ companyName }) => (
      <div className="relative">
        <div className="flex absolute right-0 top-0">
          <Button.InlineLink
            variant="turquoise"
            label={removeSelectedLabel}
            onClick={removeSelected}
          />
        </div>
        <Input
          label="Company Name"
          name={name}
          readOnly
          disabled
          defaultValue={companyName}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyLookup-SelectedCompany-view';

ViewComponent.propTypes = {
  /**
   * Company search field name
   */
  name: PropTypes.string.isRequired,
  /**
   * Company search field label
   */
  label: PropTypes.string.isRequired,
  /**
   * Field selected label
   */
  selectedLabel: PropTypes.string.isRequired,
  /**
   * Company search field label
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * City field name
   */
  cityName: PropTypes.string.isRequired,
  /**
   * Address line one field name
   */
  addressLineOneName: PropTypes.string.isRequired,
  /**
   * Address line two field name
   */
  addressLineTwoName: PropTypes.string.isRequired,
  /**
   * Postal code field name
   */
  postalCodeName: PropTypes.string.isRequired,
  /**
   * Company number field name
   */
  companyNumberName: PropTypes.string.isRequired,
  /**
   * Remove selected callback
   */
  removeSelected: PropTypes.func.isRequired,
  /**
   * Field remove selected label
   */
  removeSelectedLabel: PropTypes.string.isRequired,
};

export default ViewComponent;
