import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Controller } from 'react-hook-form';

import Field from '../AutoSizeInput/Field';

const ViewComponent = ({
  name,
  symbol = '£',
  control,
  controlProps,
  isLoading,
  ...restProps
}) => (
  <Controller
    name={name}
    control={control}
    {...controlProps}
    render={({
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    }) => (
      <NumberFormat
        customInput={Field}
        inputRef={ref}
        name={name}
        value={value ? value / 100 : null}
        onValueChange={(v) => onChange(v.value ? v.value * 100 : null)}
        onBlur={onBlur}
        placeholder={`${symbol}0.00`}
        thousandSeparator
        prefix={symbol}
        decimalScale={2}
        fixedDecimalScale
        isLoading={isLoading}
        {...restProps}
        error={error}
      />
    )}
  />
);

ViewComponent.displayName = 'AutoSizePriceInput-view';

ViewComponent.defaultProps = {
  controlProps: undefined,
  label: undefined,
  warning: undefined,
  error: undefined,
  wrapperProps: undefined,
  thousandsGroupStyle: undefined,
  symbol: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Control
   */
  control: PropTypes.any.isRequired,
  /**
   * ControlProps
   */
  controlProps: PropTypes.any,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Define the thousand grouping style, It support three types. thousand style
   */
  thousandsGroupStyle: PropTypes.oneOf(['thousand', 'lakh', 'wan']),
  /**
   * Currency symbol
   */
  symbol: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
