import { usePass } from '../../../hooks';

const ContainerComponent = ({ children, constants = {}, ...variants }) => {
  const { helpers, props, classNames } = constants;

  const passProps = usePass({
    classNames,
    variants,
    helpers,
    props,
  });

  return children(passProps);
};

ContainerComponent.displayName = 'Pass-Constant-container';

export default ContainerComponent;
