import React from 'react';

import EnterpriseAuthWrapper from './EnterpriseAuthWrapper-container';

// eslint-disable-next-line react/display-name
export const withEnterpriseAuthWrapper = (Component) => (props) =>
  (
    <EnterpriseAuthWrapper>
      <Component {...props} />
    </EnterpriseAuthWrapper>
  );
