import { KandaAuthService } from './kandaAuthService';
import { UserAuthService } from './userAuthService';

import config from '../config';
import { Service } from '../lib';
import { decamelize, redirectTo } from '../utils';

/**
 * Sign Up Service
 * @typedef {Object} SignUpServiceOptions
 * @property {string} baseURL base url
 */

export const SignUpService = {
  createUser: 'SignUpService-createUser',
  signup: 'SignUpService-signup',
};

const SignUpServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {SignUpServiceOptions} options
   */
  constructor({ baseURL }) {
    super({ baseURL, keys: SignUpService });
  }

  /**
   * Create user
   * @param {Object} payload user payload
   */
  async [SignUpService.createUser](payload) {
    return super.post('/signup/tradesperson', decamelize(payload));
  }

  /**
   * Handels signup
   * @param {Object} payload login payload
   */
  async [SignUpService.signup](payload) {
    const data = await super.use([SignUpService.createUser, payload]);

    KandaAuthService.setToken(data.token, true);

    await super.refetch([UserAuthService.key.isUserLoggedIn]);

    redirectTo(config.HOME_URL);
  }
};

export default new SignUpServiceImplementation({
  baseURL: `${config.API_MONOLITH}/api/v1`,
});
