import React from 'react';
import PropTypes from 'prop-types';

import Context, { Provider } from './Context';
import Container from './QuoteDownload-container';
// import Prerender from './Prerender';

const ViewComponent = ({ children, ...restProps }) => (
  <Container {...restProps}>
    {({ ...values }) => <Provider {...values}>{children}</Provider>}
  </Container>
);

ViewComponent.displayName = 'QuoteDownload-view';

ViewComponent.Context = Context;

ViewComponent.defaultProps = {
  children: null,
  job: null,
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Kanda `job` object
   */
  job: PropTypes.object,
  /**
   * Is Loading
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
