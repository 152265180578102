import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from '../../RangeSlider-constants';

const ViewComponent = React.forwardRef((props, ref) => (
  <div {...props} ref={ref} className={CLASS_NAMES.thumb} />
));

ViewComponent.displayName = 'Thumb-view';

ViewComponent.propTypes = {
  /**
   * OnKeyDown event
   */
  onKeyDown: PropTypes.func.isRequired,
  /**
   * OnKeyUp event
   */
  onKeyUp: PropTypes.func.isRequired,
};

export default ViewComponent;
