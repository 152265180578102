// eslint-disable-next-line camelcase
import { SWRConfig, unstable_serialize } from 'swr';
import globToRegex from 'glob-to-regexp';

export const { cache, mutate } = SWRConfig.default;

// eslint-disable-next-line camelcase
export const unstableSerialize = unstable_serialize;

/**
 * Mutates multiple keys
 * @param {string|Array} key
 * @returns {Array} keys
 */
export const getMachedKeys = (key) => {
  const methodKey = typeof key === 'string' ? key : key[0];

  const keys = Array.from(cache.keys());

  const serializedKey = unstableSerialize(key).replaceAll('"*"', '*');

  const regex = globToRegex(serializedKey);

  const filterdKeys = keys.filter(
    (testKey) =>
      regex.test(testKey) ||
      testKey === methodKey ||
      testKey === unstableSerialize([methodKey]),
  );

  return filterdKeys;
};

export const refetch = (key) => {
  const keys = getMachedKeys(key);

  keys.forEach((machedKey) =>
    mutate(['$revalidate-on-mount', machedKey], true, false),
  );

  return Promise.all(keys.map((machedKey) => mutate(machedKey)));
};

export const shouldRevalidateOnMount = (key) => {
  console.log(cache);
  console.log(
    unstableSerialize(['$revalidate-on-mount', unstableSerialize(key)]),
  );
  const cacheValue = cache.get(
    unstableSerialize(['$revalidate-on-mount', unstableSerialize(key)]),
  );

  return Boolean(cacheValue) || undefined;
};

export const clearOnMountMutation = (key) =>
  mutate(['$revalidate-on-mount', unstableSerialize(key)], false, false);
