import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import { checkAndFormatTimestamp } from './TimeAgo-functions';
import { FORMATTER } from './TimeAgo-constants';

const ContainerComponent = ({ children, timestamp }) => {
  const date = checkAndFormatTimestamp(timestamp);

  const formatter = buildFormatter(FORMATTER);

  return children({
    date,
    formatter,
  });
};

ContainerComponent.displayName = 'TimeAgo-Container';

export default ContainerComponent;
