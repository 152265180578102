import {
  INTEREST_BEARING_1190_PAYMENT_METHODS,
  NO_BASE_FEE_PAYMENT_METHODS,
  FINANCE_DETAILS,
  BASE_FEE_1190,
  BASE_FEE,
  CARD_COST,
} from '../constants';

/**
 * Calculates base fee from payment method
 * @param {Number} paymentMethod
 * @returns {Number} base fee
 */
export const calculateBaseFee = (paymentMethod) => {
  if (!FINANCE_DETAILS[paymentMethod]) return 0;

  if (NO_BASE_FEE_PAYMENT_METHODS.includes(paymentMethod)) return 0;

  if (INTEREST_BEARING_1190_PAYMENT_METHODS.includes(paymentMethod))
    return BASE_FEE_1190;

  return BASE_FEE;
};

/**
 * Calculates TP from total and payment method
 * @param {Number} total total amount
 * @param {Number} paymentMethod payment method
 * @returns {Number} base fee
 */
export const calculateTP = (total, paymentMethod) => {
  if (!FINANCE_DETAILS[paymentMethod]) return 0;

  const baseRate = (total * FINANCE_DETAILS[paymentMethod].baseRate) / 100;

  const baseFee = calculateBaseFee(paymentMethod);

  const totalTp = baseRate + baseFee;

  return Math.round(totalTp);
};

/**
 * Calculates Loan from total and payment method
 * @param {Number} total total amount
 * @param {Number} paymentMethod payment method
 * @returns {Number} base fee
 */
export const calculateLoan = (total, paymentMethod) => {
  if (!FINANCE_DETAILS[paymentMethod]) return 0;

  const { interest, duration } = FINANCE_DETAILS[paymentMethod];

  const rate = interest / 12 / 100;

  if (rate === 0) {
    return total / duration;
  }

  const compoundInterest = (1 + rate) ** duration;

  const monthlyLoan =
    (total * (rate * compoundInterest)) / (compoundInterest - 1);

  return Math.round(monthlyLoan);
};

/**
 * Checks if option is finace option
 */
export const isFinanceOption = (paymentMethod) =>
  Boolean(FINANCE_DETAILS[paymentMethod]);

/**
 * Calculates card cost
 * @param {Number} total amount in cents
 * @returns {number} card cost
 */
export const calculateCardCost = (total) =>
  Math.round((total * CARD_COST) / 100);

/**
 * Formats duration
 * @param {String} duration
 * @returns {String} formatted label
 */
export const getDurationLabel = (duration) => {
  if (duration / 12 === 1) return `1 year`;

  if (duration % 12 === 0) {
    return `${duration / 12} years`;
  }

  return `${duration} months`;
};
