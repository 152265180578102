import clsx from 'clsx';

import { DIRECTIONS } from './LoadingSpinner-constants';

const ContainerComponent = ({
  children,
  className: extClassName,
  duration,
  anticlockwise,
  stroke,
}) => {
  const animationProps = {
    dur: `${duration}ms`,
    ...(anticlockwise ? DIRECTIONS.anticlockwise : DIRECTIONS.clockwise),
  };

  const className = clsx(
    extClassName,
    stroke ? `stroke-current text-${stroke}` : ''
  );

  return children({ className, animationProps });
};

ContainerComponent.displayName = 'LoadingSpinner-container';

export default ContainerComponent;
