import Base from './HeaderBase';
import Main from './HeaderMain';
import Search from './HeaderSearch';

const Header = {
  Base,
  Main,
  Search,
};

export default Header;
