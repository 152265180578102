import { useRef, useEffect } from 'react';

/**
 * Hook that detect when the click is outside of the component
 * @param {funcion} callback
 * @returns ref to pas to that component
 */

const useOuterClick = (callback) => {
  /**
   * Stores callback on ref so the effect can be called on mount / unmount
   */
  const callbackRef = useRef(callback);
  const innerRef = useRef();

  /**
   * Sets listeners on mount and removes them on unmount,
   * calles callback if click target is outside of component suplied with ref
   */
  useEffect(() => {
    const handleClick = (e) => {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return innerRef;
};

export default useOuterClick;
