import { useState, cloneElement, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useDebounce } from 'use-debounce';

import {
  VARIANTS,
  PANEL_STYLE,
  BASE_CLASS_NAMES,
  VAIRANT_CLASS_NAMES,
  ACTIONS,
} from './Advanced-constants';
import { emptyPanel, popoverRouning } from './Advanced-functions';

import useOuterClick from '../hooks/useOuterClick';
import useEscClick from '../hooks/useEscClick';

const ContainerComponent = ({
  children,
  button,
  above,
  right,
  items: originalItems,
  onAction,
  searchDebounceInterval,
}) => {
  const variant = 'panel-full';
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, searchDebounceInterval);

  const [showPopover, _setShowPopover] = useState(false);
  const [panel, setPanel] = useState(() => emptyPanel);
  const [selected, setSelected] = useState(-1);

  const onClick = useCallback(
    () => _setShowPopover(!showPopover),
    [showPopover]
  );

  /**
   * Handles close logic
   */
  const handleClose = useCallback(() => {
    if (!showPopover) return;
    _setShowPopover(false);
    setPanel(() => emptyPanel);
  }, [showPopover]);

  /**
   * Handles chained esc key logic
   */
  const handleEsc = useCallback(() => {
    if (debouncedSearch) {
      setSearch('');
      return;
    }
    if (!showPopover) return;
    if (panel) {
      setPanel(() => emptyPanel);
      return;
    }
    _setShowPopover(false);
  }, [debouncedSearch, showPopover, panel]);

  /*
   * Uses hook to call close when the click is outside of modal
   */
  const ref = useOuterClick(handleClose);

  /*
   * Uses hook to call close when the click is outside of modal
   */
  useEscClick(handleEsc);

  const newButton = useMemo(
    () => cloneElement(button, { onClick: (e) => onClick(e) }),
    [button, onClick]
  );

  const containerClassName = clsx(
    BASE_CLASS_NAMES.container,
    above
      ? VAIRANT_CLASS_NAMES.above.container
      : VAIRANT_CLASS_NAMES.below.container,
    right
      ? VAIRANT_CLASS_NAMES.right.container
      : VAIRANT_CLASS_NAMES.left.container
  );

  const popoverClassName = clsx(
    BASE_CLASS_NAMES.popover,
    popoverRouning(panel, variant, right)
  );

  const panelClassName = clsx(
    BASE_CLASS_NAMES.panel,
    above ? VAIRANT_CLASS_NAMES.above.panel : VAIRANT_CLASS_NAMES.below.panel,
    right ? VAIRANT_CLASS_NAMES.right.panel : VAIRANT_CLASS_NAMES.left.panel
  );

  const panelStyle = VARIANTS.includes(variant) ? PANEL_STYLE[variant] : {};

  const classNames = {
    ...BASE_CLASS_NAMES,
    container: containerClassName,
    popover: popoverClassName,
    panel: panelClassName,
  };

  /**
   * Enhances items

   */
  const items = useMemo(
    () =>
      originalItems.map((itemProps, i) => {
        const action = itemProps.action || {};

        /**
         * Handles action click
         * @param {Event} event
         */
        const handleClick = (event) => {
          if (action.type === ACTIONS.OPEN_PANEL || itemProps.stopPropagation) {
            event.stopPropagation();
          }

          if (action.type === ACTIONS.OPEN_PANEL) {
            setSelected(i);

            if (panel && selected === i) {
              setPanel(() => emptyPanel);
              setSelected(-1);

              return;
            }

            setPanel(() => action.panel);
          }

          if (onAction) {
            onAction(action, event);
          }
        };

        const props = {
          ...itemProps,
          onClick: handleClick,
          selected: i === selected,
        };

        if (action.type !== ACTIONS.OPEN_PANEL) return props;

        return { ...props, arrow: true };
      }),
    [originalItems, selected, onAction, panel]
  );

  /**
   * Handles search
   * @param {Event} event
   */
  const onSearch = (event) => setSearch(event.target.value);

  const value = search;

  const clearSearch = () => setSearch();

  const showPanel = panel !== emptyPanel;

  return children({
    classNames,
    showPopover,
    button: newButton,
    panel,
    ref,
    handleClose,
    panelStyle,
    items,
    debouncedSearch,
    onSearch,
    clearSearch,
    showPanel,
    value,
  });
};

ContainerComponent.displayName = 'Popover-Advanced-container';

export default ContainerComponent;
