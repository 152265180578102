/**
 * Creates url with from link
 * @param {string} baseUrl baseUrl
 * @param {string} fromUrl baseUrl
 * @returns {string} capitalized text
 */
export const urlWithFrom = (baseUrl, fromUrl) => {
  const glue = baseUrl.includes('?') ? '&' : '?';

  const from = fromUrl || window.location.pathname;

  return `${baseUrl}${glue}from=${from}`;
};

/**
 * Creates url with params
 * @param {string} url url
 * @param {string} params baseUrl
 * @returns {string} capitalized text
 */
export const urlWithParams = (url, params) =>
  Object.keys(params).reduce(
    (appendedUrl, param) => appendedUrl.replace(`:${param}`, params[param]),
    url,
  );
