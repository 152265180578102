// import { useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { createTeleporter } from 'react-teleporter';

import { CLASS_NAMES } from './Layout-constants';

export const Footer = createTeleporter();
export const Header = createTeleporter();

const ContainerComponent = ({
  children,
  headerBg,
  bg,
  stickyHeader,
  stickyFooter,
  noBorder,
  scrollTop,
  noPadding,
}) => {
  const {
    containerSticky,
    containerBase,
    footerDefault,
    footerFixed,
    headerBase,
    headerBorder,
    headerFixed,
    widthLimit,
    contentBase,
    contentPadding,
    flexGrow,
    ...classNames
  } = CLASS_NAMES;

  const container = clsx(
    containerBase,
    bg ? `bg-${bg}` : 'bg-neutral-000',
    stickyFooter && containerSticky
  );

  const header = clsx(
    headerBase,
    !noBorder && headerBorder,
    headerBg ? `bg-${bg}` : 'bg-neutral-000',
    stickyHeader && headerFixed
  );

  const content = clsx(contentBase, !noPadding && contentPadding);

  const footer = clsx(stickyFooter ? footerFixed : footerDefault);
  const headerWidthLimiter = widthLimit;
  const contentWidthLimiter = clsx(flexGrow, widthLimit);

  // Updates the height of the `body` element to the visible portion of the
  // browser window
  // DEV_NOTE: to be put in if viewport fix on modals doesn't work
  // const updateLayoutHeight = useCallback(() => {
  //   const layoutElement = document.getElementById('layout');
  //   try {
  //     layoutElement.style.height = `${window.innerHeight}px`;
  //   } catch (err) {
  //     console.error('Unable to get body');
  //   }
  // }, []);
  //
  // useEffect(() => {
  //   updateLayoutHeight();
  //   window.addEventListener('resize', updateLayoutHeight);
  //   return () => {
  //     window.removeEventListener('resize', updateLayoutHeight);
  //   };
  // }, [updateLayoutHeight]);

  return children({
    scrollTop,
    classNames: {
      ...classNames,
      content,
      container,
      header,
      footer,
      headerWidthLimiter,
      contentWidthLimiter,
    },
  });
};

ContainerComponent.displayName = 'Layout-container';

export default ContainerComponent;
