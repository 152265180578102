import { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_SLIDER_PROPS } from './Router-constants';

import Context from '../../Context';

const ContainerComponent = ({
  children,
  pages,
  sliderProps: extSliderProps,
}) => {
  const {
    step,
    sliderRef,
    setCurrentStepIndex,
    currentStepIndex,
    lockButtons,
  } = useContext(Context);

  const [initialSlide] = useState(currentStepIndex);

  const CurrentSlide = pages[step];

  const allPages = useMemo(() => Object.values(pages), [pages]);

  const options = useMemo(
    () => ({
      ...DEFAULT_SLIDER_PROPS,
      ...extSliderProps,
    }),
    [extSliderProps]
  );
  /**
   * Updates step index when slide is swiped and
   * disables next and previous buttons while animating
   */
  const beforeChange = useCallback(
    (_, next) => {
      setTimeout(() => {
        if (lockButtons) {
          lockButtons.current = false;
        }
      }, options.speed);
      lockButtons.current = true;

      setCurrentStepIndex(next);
    },
    [setCurrentStepIndex, lockButtons, options]
  );

  const sliderProps = {
    ...options,
    initialSlide,
    beforeChange,
    ref: sliderRef,
  };

  return children({
    allPages,
    CurrentSlide,
    sliderProps,
  });
};

ContainerComponent.displayName = 'MultiStepForm-Router-container';

ContainerComponent.propTypes = {
  /**
   * Initial data of parent form
   */
  initialData: PropTypes.any,
  /**
   * Steps as array
   */
  steps: PropTypes.array,
  /**
   * First step to display starting from 0
   */
  initialStepIndex: PropTypes.number,
  /**
   * callback when the the form is submitted
   */
  onSubmit: PropTypes.func,
};

export default ContainerComponent;
