import { useMemo, useCallback } from 'react';

import { createButtons } from './Pagination-functions';

const ContainerComponent = ({ children, pageIndex, pageCount, setPage }) => {
  const buttons = useMemo(
    () => createButtons(pageIndex, pageCount, setPage),
    [setPage, pageCount, pageIndex]
  );

  const nextPage = useCallback(
    () => setPage(pageIndex + 1),
    [pageIndex, setPage]
  );
  const previousPage = useCallback(
    () => setPage(pageIndex - 1),
    [pageIndex, setPage]
  );
  const nextDisabled = pageIndex === pageCount - 1;
  const previousDisabled = pageIndex === 0;

  return children({
    buttons,
    nextPage,
    previousPage,
    nextDisabled,
    previousDisabled,
  });
};

ContainerComponent.displayName = 'Pagination-container';

export default ContainerComponent;
