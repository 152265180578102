/**
 * Adds event listener to media query and returns cleanup function
 * @param {String} query media query
 * @param {Event} callback media query callback
 * @returns {Function} cleanup function
 */
export const attachMediaListener = (query, callback) => {
  try {
    query.addEventListener('change', callback);
    return () => query.removeEventListener('change', callback);
  } catch (e) {
    query.addListener(callback);
    return () => query.removeListener(callback);
  }
};

/**
 * Gets current media query matches
 * @param {String} query media query
 * @returns {Boolean} matches
 */
export const getInitialValue = (query) => {
  if (typeof window !== 'undefined' && 'matchMedia' in window)
    return window.matchMedia(query).matches;

  return false;
};
