import set from 'lodash.set';
import { capitalize } from '@kanda-utils/library';

import { VARIANT_REGEX } from './props-constants';

import { flatObject } from '../../utils/Object-functions';

/**
 * Formats object values
 * @param {Object} object
 * @param {Function} formatter
 * @returns {Object} formated object
 */
export const formatObject = (object, variants) => {
  const flat = flatObject(object);

  const entries = Object.entries(flat).filter(([key]) => {
    const matches = Array.from(`${key}.`.matchAll(VARIANT_REGEX));

    return matches.every((match) => {
      const { variant, value } = match.groups;

      return (
        value === `${variants[variant]}` ||
        value === `${Boolean(variants[variant])}`
      );
    });
  });

  const replaced = entries.map(([key, value]) => [
    `${key}.`.replace(VARIANT_REGEX, '').slice(0, -1),
    value,
  ]);

  return replaced.reduce((prev, [key, value]) => set(prev, key, value), {});
};

/**
 * Creates a function name from className
 * @param {String} className
 * @returns {String} function name
 */
export const getFunctionName = (className) =>
  `get${capitalize(className)}Props`;

/**
 * Create getters
 * @param {Object} formattedProps
 * @param {Object} classNames
 * @param {Object} helpers
 * @returns {Object} getter functions
 */
export const createGetters = (formattedProps, classNames, helpers) => {
  const allKeys = [
    ...new Set([...Object.keys(formattedProps), ...Object.keys(classNames)]),
  ];

  return Object.fromEntries(
    allKeys.map((className) => [
      getFunctionName(className),
      (viewProps = {}) => {
        const helper = helpers?.[className];

        const initialProps = {
          ...formattedProps?.[className],
          ...(classNames[className] && { className: classNames[className] }),
        };

        if (typeof helper === 'function')
          return helper({}, initialProps, viewProps);

        return Object.entries(viewProps).reduce((prevProps, [key, value]) => {
          if (typeof helper?.[key] === 'function') {
            return helper?.[key]({ key }, prevProps, value);
          }

          return { ...prevProps, [key]: value };
        }, initialProps);
      },
    ])
  );
};
