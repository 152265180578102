import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import Container from './Totals-container';
import { STYLES } from '../../Render-constants';

const ViewComponent = () => (
  <Container>
    {({ showSubTotals, subTotal, vatTotal, total }) => (
      <View style={STYLES.totalsRow}>
        <View style={{ ...STYLES.column, ...STYLES.mr12 }}>
          {showSubTotals && (
            <React.Fragment>
              <Text
                style={{
                  ...STYLES['text-11-20-em-up'],
                  ...STYLES['text-neutral-600'],
                }}
              >
                SUBTOTAL
              </Text>
              <Text
                style={{
                  ...STYLES['text-11-20-em-up'],
                  ...STYLES['text-neutral-600'],
                }}
              >
                VAT
              </Text>
            </React.Fragment>
          )}
          <Text
            style={{
              ...STYLES['text-11-20-em-up'],
              ...STYLES['text-neutral-600'],
            }}
          >
            TOTAL
          </Text>
        </View>
        <View style={STYLES.column}>
          {showSubTotals && (
            <React.Fragment>
              <Text
                style={{
                  ...STYLES['text-16-20-em'],
                  ...STYLES['text-neutral-500'],
                  ...STYLES.alightRight,
                }}
              >
                {subTotal}
              </Text>
              <Text
                style={{
                  ...STYLES['text-16-20-em'],
                  ...STYLES['text-neutral-500'],
                  ...STYLES.alightRight,
                }}
              >
                {vatTotal}
              </Text>
            </React.Fragment>
          )}
          <Text
            style={{
              ...STYLES['text-16-20-em'],
              ...STYLES['text-neutral-900'],
              ...STYLES.alightRight,
            }}
          >
            {total}
          </Text>
        </View>
      </View>
    )}
  </Container>
);

ViewComponent.displayName = 'Render-Title-view';

export default ViewComponent;
