/**
 * Normalizes value
 * @param {Array | Array.number} value
 * @returns {Array.number} array
 */
export const normalizeValue = (value) => {
  if (!value) {
    return [0];
  }

  return Array.isArray(value) ? value : [value];
};
