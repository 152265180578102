import { Logo, Placeholder } from './components';

const ContainerComponent = ({ children, partner, logo }) => {
  const isLoading = !partner;

  const CompanyLogo = logo ? Logo : Placeholder;

  return children({
    isLoading,
    CompanyLogo,
  });
};

ContainerComponent.displayName = 'CompanyHeader-container';

export default ContainerComponent;
