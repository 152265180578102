import config from '../config';
import { Service } from '../lib';
import { camelize } from '../utils';

/**
 * Company Information Service
 * @typedef {Object} CompanyInformationServiceOptions
 * @property {string} baseURL base url
 * @property {Object} authService authService
 */
export class CompanyInformationServiceImplementation extends Service {
  /**
   * Constructor
   * @param {CompanyInformationServiceOptions} options
   */
  constructor({ baseURL }) {
    super({ baseURL });

    this.key = {
      search: 'CompanyInformationService/search',
      // companyProfile: 'CompanyInformationService/companyProfile',
      // companyOfficers: 'CompanyInformationService/companyOfficers'
    };

    this.mapKeys({
      [this.key.search]: this.search,
      // [this.key.companyProfile]: this.companyProfile,
      // [this.key.companyOfficers]: this.companyOfficers
    });
  }

  /**
   * Search companies
   * @param {String} q
   * @param {Number} itemsPerPage
   * @param {Number} startIndex
   */
  async search(q, itemsPerPage, startIndex) {
    const params = [
      `q=${q}`,
      itemsPerPage && `items_per_page=${itemsPerPage}`,
      startIndex && `start_index=${startIndex}`,
    ];

    const queryString = params.filter(Boolean).join('&');

    const res = await super.get(`/search_companies?${queryString}`);

    return camelize(res);
  }

  // /**
  //  * Get company profile
  //  */
  // async companyProfile(companyNumber) {
  //   const res = await super.get(`/company/${companyNumber}`);

  //   return camelize(res);
  // }

  // /**
  //  * Get company officers
  //  */
  // async companyOfficers(companyNumber) {
  //   const res = await super.get(`/company/${companyNumber}/officers`);

  //   return camelize(res);
  // }
}

export const CompanyInformationService =
  new CompanyInformationServiceImplementation({
    baseURL: config.COMPANY_SEARCH_API_URL,
  });
