import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';

import { CLASS_NAMES, DEFAULT_VALUE } from './Select-constants';

import { useFormTheme } from '../../FormTheme';
import { useClasses } from '../../../../hooks';

const ViewComponent = ({
  children,
  options: _options = [],
  placeholder,
  isLoading,
  error,
  className,
  name,
}) => {
  const {
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses,
    skeletonClasses,
  } = useFormTheme();

  const { trigger } = useFormContext();

  const value = useWatch({ name });

  /**
   * Force revalidate on change for select fields
   */
  useEffect(() => {
    if (value) {
      trigger(name);
    }
  }, [value, trigger, name]);

  const options = placeholder
    ? [{ name: placeholder, value: DEFAULT_VALUE }, ..._options]
    : _options;

  const defaultValue = DEFAULT_VALUE;

  const classNames = useClasses(CLASS_NAMES, {
    select: [
      baseClasses,
      inputClasses,
      paddingClasses,
      focusClasses,
      makeErrorClasses(error),
      !isLoading && '.baseSelect',
      className,
    ],
    skeleton: [skeletonClasses],
  });

  const currentValue = value ? String(value) : defaultValue;

  return children({
    options,
    currentValue,
    defaultValue,
    classNames,
    skeletonClasses,
  });
};

ViewComponent.displayName = 'UncontrolledSelect-container';

export default ViewComponent;
