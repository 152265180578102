import React from 'react';
import PropTypes from 'prop-types';

import { SKELETON_PROPS } from './BaseButton-constants';
import Container from './BaseButton-container';

import Icon from '../../Icon';
import SkeletonLoader from '../../SkeletonLoader';

const ViewComponent = ({
  children,
  disabled,
  icon,
  iconProps,
  label,
  isLoading,
  classNames,
  submit,
  append,
  prepend,
  ...restProps
}) => (
  <Container submit={submit}>
    {({ type }) => (
      <button
        type={type}
        disabled={disabled}
        className={classNames.button}
        {...restProps}
      >
        <span className="flex flex-1 items-center justify-items-center justify-center">
          {prepend}
          <span className={classNames.content}>
            {label || children}
            {icon && <Icon {...iconProps} icon={icon} />}
          </span>
          {append}
        </span>
        <SkeletonLoader {...SKELETON_PROPS} isLoading={isLoading} />
      </button>
    )}
  </Container>
);

ViewComponent.displayName = 'BaseButton-view';

ViewComponent.defaultProps = {
  label: undefined,
  children: null,
  append: null,
  prepend: null,
  icon: undefined,
  iconProps: {},
  disabled: undefined,
  submit: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * ClassNames
   */
  classNames: PropTypes.shape({
    button: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Icon name
   */
  icon: PropTypes.string,
  /**
   * Icon props
   */
  iconProps: PropTypes.shape({
    className: PropTypes.string,
    stroke: PropTypes.string,
    flip: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.number,
    fill: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  /**
   * sets button to disabled
   */
  disabled: PropTypes.bool,
  /**
   * sets button type to submit insted of button
   */
  submit: PropTypes.bool,
  /**
   * Button label
   */
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Append
   */
  append: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Append
   */
  prepend: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
