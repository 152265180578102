import React from 'react';
import PropTypes from 'prop-types';

import Container from './PasswordStrengthIndicator-container';
import { DESCRIPTION } from './PasswordStrengthIndicator-constants';
import { Meter } from './components';

import Text from '../Text';

const ViewComponent = (props) => (
  <Container {...props}>
    {({ classNames, score, label, isLoading }) => (
      <div className={classNames.container}>
        <div className={classNames.info}>
          <Meter score={score} isLoading={isLoading} />
          <Text
            className={classNames.label}
            isLoading={isLoading}
            text={label}
          />
        </div>
        <Text
          className={classNames.description}
          isLoading={isLoading}
          text={DESCRIPTION}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Price-view';

ViewComponent.defaultProps = {
  className: undefined,
  passwordFieldName: 'password',
  userInputs: [],
  userInputFieldNames: [],
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Password field name
   */
  passwordFieldName: PropTypes.string,
  /**
   * User input field names
   * ex: ['email', 'firstName', 'lastNName', ...]
   */
  userInputFieldNames: PropTypes.arrayOf(PropTypes.string),
  /**
   * Extra user inpus to check
   * ex: ['name@company.com', 'Lorem ipsum', ...]
   */
  userInputs: PropTypes.arrayOf(PropTypes.string),
};

export default ViewComponent;
