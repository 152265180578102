import React from 'react';
import PropTypes from 'prop-types';

import {
  POST_CODE_PROPS,
  HOUSE_NUMBER_PROPS,
  POST_CODE_REGEX,
} from './Address-constants';
import Container from './Address-container';

import Input from '../Input';
import Select from '../Select';

const ViewComponent = ({
  postalCodeProps,
  houseNumberProps,
  selectProps,
  autoHideSelect,
  name,
}) => (
  <Container name={name} autoHideSelect={autoHideSelect}>
    {({
      options,
      error,
      handleChange,
      placeholder,
      isLoading,
      disabled,
      selectWrapperClassName,
      fields,
      onHouseNumberChange,
    }) => (
      <React.Fragment>
        <div className="flex flex-row">
          <Input
            name={fields.postalCode}
            {...POST_CODE_PROPS}
            {...postalCodeProps}
          />
          <Input
            name={fields.houseNumber}
            {...HOUSE_NUMBER_PROPS}
            {...houseNumberProps}
            onChange={onHouseNumberChange}
          />
        </div>
        <div className={selectWrapperClassName}>
          <Select
            name={fields.selected}
            disabled={disabled}
            isLoading={isLoading}
            placeholder={placeholder}
            options={options}
            error={error}
            onChange={handleChange}
            {...selectProps}
          />
        </div>
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'Address-view';

ViewComponent.defaultProps = {
  name: 'address',
  autoHideSelect: undefined,
  postalCodeProps: {},
  houseNumberProps: {},
  selectProps: {},
};

ViewComponent.propTypes = {
  /**
   * Field name
   */
  name: PropTypes.string,
  /**
   * Postal code props
   */
  postalCodeProps: PropTypes.object,
  /**
   * House number props
   */
  houseNumberProps: PropTypes.object,
  /**
   * Select props
   */
  selectProps: PropTypes.object,
  /**
   * Auto hide select
   */
  autoHideSelect: PropTypes.bool,
};

ViewComponent.POST_CODE_REGEX = POST_CODE_REGEX;

export default ViewComponent;
