import ValidationError from './validationError';

import { camelize, cache } from '../utils';

/**
 * Handles graphQL errors
 */
export const graphQLErrorHandler = async (reponse) => {
  const data = await reponse.json();

  if (data.errors) {
    throw new ValidationError('GraphQL Error', camelize(data.errors));
  }

  return data;
};

/**
 * Closure to handle validation and parsing on reponse
 */

export const defaultErrorHandler = async (reponse) => {
  if (!reponse.ok) {
    const data = await reponse.json();

    console.log({ reponse });

    throw new ValidationError('Server Error', camelize(data), reponse.status);
  }
  const data = await reponse.json();

  return data;
};

/**
 * Return SSE cache or creates a new one
 * @param {string} url sse url
 */
export const getSSECache = (url) => {
  if (!cache.get(url)) {
    cache.set(url, {
      messages: [],
      keys: {},
    });
  }

  return cache.get(url);
};
