import Provider from './Provider';
import PillButton from './PillButton';
import IconButton from './IconButton';
import FetchDownload from './FetchDownload';

const QuoteDownload = {
  Provider,
  PillButton,
  IconButton,
  FetchDownload,
};

export default QuoteDownload;
