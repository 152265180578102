import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';

import { CLASSNAMES, TIMEOUT } from './ScrollTopButton-constants';
import { determineScrollHeight } from './ScrollTopButton-functions';

const ContainerComponent = ({ children, containerRef }) => {
  // Anonymous state to construct button className
  const [_showButton, _setShowButton] = useState(determineScrollHeight());

  /**
   * Function to scroll to top of element
   */
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Classname for button - extended with button show state
  const className = clsx(
    CLASSNAMES.base,
    _showButton ? CLASSNAMES.show : CLASSNAMES.hide
  );

  // Reference used for debouncing
  const checkScroll = useRef(true);
  // Effect attaches event listener to element
  useEffect(() => {
    // Scroll listener function to check scroll position
    const scrollListener = () => {
      // If checker reference is false, return
      if (!checkScroll.current) return;
      // Set checker reference to false
      checkScroll.current = false;
      // Set the show button state
      _setShowButton(determineScrollHeight());
      // Use timeout to debounce, runs ever 100 milliseconds
      setTimeout(() => {
        // Allow scroll checking again
        checkScroll.current = true;
        // Set show button state
        _setShowButton(determineScrollHeight());
      }, TIMEOUT);
    };
    // Add the event listener to the element
    window.addEventListener('scroll', scrollListener);
    return () => {
      // Remove the event listener
      window.removeEventListener('scroll', scrollListener);
    };
  }, [containerRef]);

  return children({
    className,
    scrollTop,
  });
};

ContainerComponent.displayName = 'ScrollTopButton-container';

export default ContainerComponent;
