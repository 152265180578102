import React from 'react';
import PropTypes from 'prop-types';

import Container from './ButtonInlineLink-container';

import BaseButton from '../BaseButton';

const ViewComponent = ({ className, variant, ...restProps }) => (
  <Container className={className} variant={variant} {...restProps}>
    {(variantProps) => <BaseButton {...restProps} {...variantProps} />}
  </Container>
);

ViewComponent.displayName = 'Button.InlineLink';

ViewComponent.defaultProps = {
  variant: 'grey',
  label: undefined,
  children: null,
  className: undefined,
  disabled: undefined,
  submit: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Button variant name
   */
  variant: PropTypes.oneOf(['custom', 'grey', 'turquoise', 'lavender']),
  /**
   * sets button to disabled
   */
  disabled: PropTypes.bool,
  /**
   * sets button type to submit insted of button
   */
  submit: PropTypes.bool,
  /**
   * Button label
   */
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
