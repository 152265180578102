import React from 'react';
import PropTypes from 'prop-types';

import Mobile from './Mobile';
import Desktop from './Desktop';

import FieldContainer from '../../FieldContainer';
import BreakPoints from '../../../BreakPoints';

const ViewComponent = (props) => (
  <FieldContainer {...props}>
    {(fieldProps) => (
      <BreakPoints
        mobile={<Mobile {...fieldProps} />}
        desktop={<Desktop {...fieldProps} />}
      />
    )}
  </FieldContainer>
);

ViewComponent.displayName = 'SelectionModal-view';

ViewComponent.defaultProps = {
  children: null,
  options: [],
  multiple: undefined,
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  icon: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * icon name
   */
  icon: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
