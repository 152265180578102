import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';

import Container from './TimeAgo-container';

const ViewComponent = ({ className, ...rest }) => (
  <Container {...rest}>
    {({ date, formatter }) => (
      <span className={className}>
        <TimeAgo date={date} formatter={formatter} />
      </span>
    )}
  </Container>
);

ViewComponent.displayName = 'TimeAgo-view';

ViewComponent.defaultProps = {
  className: '',
  timestamp: undefined,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Timestamp - any valid date timestamp format, as converted to date using
   * `new Date(timestamp)` for initial checking
   */
  timestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

export default ViewComponent;
