import React from 'react';
import PropTypes from 'prop-types';

import Container from './HelpButton-container';
import Button from '../Button';

import { HELP_BUTTON_PROPS } from '../../constants';

const ViewComponent = ({ variant }) => (
  <Container>
    {({ onClick }) =>
      variant === 'button' ? (
        <Button.Icon onClick={onClick} {...HELP_BUTTON_PROPS} />
      ) : (
        <Button.Link
          id="help-launcher"
          variant="custom"
          className="text-neutral-900"
          icon="help"
          onClick={onClick}
        >
          Help Center
        </Button.Link>
      )
    }
  </Container>
);

ViewComponent.displayName = 'HelpButton-view';

ViewComponent.defaultProps = {
  variant: 'button',
};

ViewComponent.propTypes = {
  /**
   * Display logo without link component
   */
  variant: PropTypes.string,
};

export default ViewComponent;
