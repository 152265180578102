import React from 'react';
import PropTypes from 'prop-types';

import Container from './DesktopLayoutBoxed-container';
import { Content, ContentHeader, ContentFooter } from './components';

import HelpButton from '../../HelpButton';
import { ReactComponent as HeaderBackground } from '../../../assets/header/background.svg';
import { ReactComponent as KandaLogo } from '../../../assets/logos/kanda-wordmark.svg';
import { ReactComponent as BackgroundLines } from '../../../assets/images/background-lines.svg';

const ViewComponent = ({
  children,
  options,
  logoProps,
  sidebar,
  sidebarWidth,
  backgroundLines,
  noHeader,
  help,
  layoutFooter,
}) => (
  <Container sidebar={sidebar} sidebarWidth={sidebarWidth} noHeader={noHeader}>
    {({
      classNames,
      linkComponent: LinkComponent,
      homeLinkProps,
      sidebarView: SidebarView,
    }) => (
      <div id="layout" className={classNames.container}>
        <div className={classNames.background}>
          {!backgroundLines && <div className={classNames.shadow} />}
          {backgroundLines && (
            <BackgroundLines className={classNames.backgroundLines} />
          )}
          <HeaderBackground className={classNames.lines} />
        </div>
        <div className={classNames.wrapper}>
          {!noHeader && (
            <div className={classNames.header}>
              <LinkComponent {...homeLinkProps} className={classNames.logo}>
                <KandaLogo {...logoProps} />
              </LinkComponent>
              <div className={classNames.options}>
                {help && <HelpButton />}
                {options.map((option, i) => (
                  <div key={String(i)}>{option}</div>
                ))}
              </div>
            </div>
          )}
          <div className={classNames.contentWrapper}>
            {SidebarView && (
              <div className={classNames.sidebar}>
                <SidebarView />
              </div>
            )}
            <div className={classNames.contentBox}>{children}</div>
          </div>
        </div>
        {layoutFooter}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopLayoutBoxed-view';

ViewComponent.Content = Content;

ViewComponent.ContentHeader = ContentHeader;

ViewComponent.ContentFooter = ContentFooter;

ViewComponent.defaultProps = {
  children: undefined,
  logoProps: undefined,
  sidebar: null,
  sidebarWidth: 'basis-1/3',
  options: [],
  backgroundLines: false,
  noHeader: false,
  help: false,
  layoutFooter: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Right side option for header
   */
  options: PropTypes.arrayOf(PropTypes.element),
  /**
   * Logo props
   */
  logoProps: PropTypes.object,
  /**
   * Sidebar
   */
  sidebar: PropTypes.oneOfType([PropTypes.bool, PropTypes.elementType]),
  /**
   * Sidebar width
   */
  sidebarWidth: PropTypes.string,
  /**
   * Background lines
   */
  backgroundLines: PropTypes.bool,
  /**
   * Hide header
   */
  noHeader: PropTypes.bool,
  /**
   * Show help
   */
  help: PropTypes.bool,
  /**
   * Children
   */
  layoutFooter: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
