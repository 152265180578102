import Icon from './ButtonIcon';
import Text from './ButtonText';
import Link from './ButtonLink';
import InlineLink from './ButtonInlineLink';

const Button = {
  Icon,
  Text,
  Link,
  InlineLink,
};

export default Button;
