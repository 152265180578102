import React from 'react';
import PropTypes from 'prop-types';

import Container from './FetchDownload-container';

const ViewComponent = ({ children }) => (
  <Container>{({ handleFetch }) => children({ handleFetch })}</Container>
);

ViewComponent.displayName = 'QuoteDownload-Fetch-view';

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.func,
};

export default ViewComponent;
