import { useContext } from 'react';

import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { isLoading, title, classNames } = useContext(Context);

  return children({ title, isLoading, classNames });
};

ContainerComponent.displayName = 'LineItem-Title-container';

export default ContainerComponent;
