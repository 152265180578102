import clsx from 'clsx';

import { createOptionalProps } from './Cell-functions';
import { CLASS_NAMES } from './Cell-constants';

const ContainerComponent = ({ children, cell, index, isLoading }) => {
  const {
    getCellProps,
    value,
    column: { renderComponent, subAccessors },
    row: { original },
  } = cell;

  const render =
    renderComponent && !isLoading
      ? !!(renderComponent?.component && renderComponent?.value)
      : false;

  const props = renderComponent
    ? {
        ...(renderComponent?.props || {}),
        ...(renderComponent?.value ? { [renderComponent?.value]: value } : {}),
        ...createOptionalProps(
          subAccessors,
          original,
          renderComponent.optionalProps
        ),
      }
    : {};

  const classNames = {
    cell: clsx(
      CLASS_NAMES.cell.base,
      index === 0 ? '' : CLASS_NAMES.cell.padding
    ),
    container: CLASS_NAMES.container,
    render: isLoading ? CLASS_NAMES.renderLoading : CLASS_NAMES.render,
  };

  return children({
    cellProps: getCellProps(),
    classNames,
    value,
    render,
    renderComponent: renderComponent?.component,
    renderProps: props,
    isLoading,
  });
};

ContainerComponent.displayName = 'Cell-container';

export default ContainerComponent;
