import React from 'react';

import Container from './Vat-container';

import Text from '../../../Text';

const ViewComponent = () => (
  <Container>
    {({ vat, isLoading, classNames }) => (
      <Text className={classNames.vat} isLoading={isLoading} text={vat} />
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Vat-view';

export default ViewComponent;
