import { useWatch } from 'react-hook-form';

import { CLASS_NAMES } from './PhoneNumberInput-constants';

import { useFormTheme } from '../../FormTheme';
import { useClasses } from '../../../../hooks';

const ContainerComponent = ({
  children,
  options: _options,
  placeholder,
  error,
  className: extClassName,
  isLoading,
  name,
  defaultValue,
}) => {
  const {
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses,
    skeletonClasses,
  } = useFormTheme();

  const classNames = useClasses(CLASS_NAMES, {
    container: [
      baseClasses,
      inputClasses,
      isLoading && paddingClasses,
      focusClasses.replaceAll('focus', 'focus-within'),
      makeErrorClasses(error),
      !isLoading && '.container',
      extClassName,
    ],
    select: [baseClasses, paddingClasses, focusClasses, '.select'],
    code: [paddingClasses, '.code'],
    phoneInput: [baseClasses, paddingClasses, focusClasses, '.phoneInput'],
  });

  const options = placeholder
    ? [{ name: placeholder, value: '' }, ..._options]
    : _options;

  const code = useWatch({ name, defaultValue });

  return children({ classNames, skeletonClasses, options, code });
};

ContainerComponent.displayName = 'UncontrolledPhoneNumberInput-container';

export default ContainerComponent;
