import PropTypes from 'prop-types';
import React from 'react';

import { CLASS_NAMES } from './RowFields-constants';

const ViewComponent = ({ children }) => (
  <div className={CLASS_NAMES.container}>
    {children.map((field, i) => (
      <div className={CLASS_NAMES.field} key={String(i)}>
        {field}
      </div>
    ))}
  </div>
);

ViewComponent.displayName = 'RowFields-view';

ViewComponent.defaultProps = {
  children: [],
};

ViewComponent.propTypes = {
  /**
   * Fields
   */
  children: PropTypes.arrayOf(PropTypes.element),
};

export default ViewComponent;
