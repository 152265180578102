export const CLASS_NAMES = {
  searchContainer: 'flex flex-col relative',
  inputWrapper: 'flex flex-1 flex-row items-center',
};

export const FIELDS = {
  search: {
    icon: 'search',
    autoComplete: 'off',
    wrapperProps: {
      className: 'w-full',
    },
  },
};
