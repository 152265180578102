import PropTypes from 'prop-types';
import React from 'react';

import Option from './Option';
import Container from './RadioSelect-container';

const ViewComponent = ({ name, options, inline, ...restProps }) => (
  <Container inline={inline}>
    {({ classNames }) => (
      <div className={classNames.container}>
        {options.map((option) => (
          <Option
            fieldName={name}
            value={option.value}
            name={option.name}
            inline={inline}
            key={option.value}
            {...restProps}
          />
        ))}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'RadioSelect-view';

ViewComponent.defaultProps = {
  options: [],
  multiple: undefined,
  inline: false,
  isLoading: undefined,
  variant: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Display variant
   */
  variant: PropTypes.oneOf([
    'default',
    'popover',
    'text-only',
    'narrow',
    'narrow-lg',
    'clean',
  ]),
  /**
   * Display options inline
   */
  inline: PropTypes.bool,
};

export default ViewComponent;
