import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from './DesktopMenu-constants';
import { itemClassName } from './DesktopMenu-functions';

const ViewComponent = ({ items, linkComponent: LinkComponent }) => (
  <div className={CLASS_NAMES.container}>
    {items.map(({ icon, name, active, ...itemProps }) => (
      <LinkComponent
        className={itemClassName(active)}
        {...itemProps}
        key={name}
      >
        {name}
      </LinkComponent>
    ))}
  </div>
);

ViewComponent.displayName = 'DesktopMenu-view';

ViewComponent.defaultProps = {
  items: [],
  linkComponent: 'a',
};

ViewComponent.propTypes = {
  /**
   * Items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      target: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
      active: PropTypes.bool,
    }).isRequired
  ),
  /**
   * LinkComponent
   */
  linkComponent: PropTypes.elementType,
};

export default ViewComponent;
