import clsx from 'clsx';
import { useEffect } from 'react';

import {
  CLASS_NAMES,
  FULLSCREEN_MODAL_CLASS_NAME,
  BODY_FULLSCREEN_MODAL_CLASS_NAME,
} from './ModalLayoutFullScreen-constants';

const ContainerComponent = ({ noBorder, children }) => {
  const { headerBase, headerBorder, ...classNames } = CLASS_NAMES;

  const header = clsx(headerBase, noBorder ? '' : headerBorder);

  useEffect(() => {
    document.body.classList.add(BODY_FULLSCREEN_MODAL_CLASS_NAME);

    return () => {
      if (
        document.getElementsByClassName(FULLSCREEN_MODAL_CLASS_NAME).length ===
        0
      ) {
        document.body.classList.remove(BODY_FULLSCREEN_MODAL_CLASS_NAME);
      }
    };
  }, []);

  return children({
    classNames: {
      ...classNames,
      header,
    },
  });
};

ContainerComponent.displayName = 'ModalLayoutFullScreen-container';

export default ContainerComponent;
