import React from 'react';
import PropTypes from 'prop-types';

import Container from './Meter-container';

import SkeletonLoader from '../../../SkeletonLoader';

const ViewComponent = ({ score, isLoading }) => (
  <Container score={score}>
    {({ items, classNames }) => (
      <SkeletonLoader
        wrapperClassName={classNames.skeletonWrapper}
        isLoading={isLoading}
        afterLoading={
          <div className={classNames.container}>
            {items.map((itemClassName, key) => (
              <div key={String(key)} className={itemClassName} />
            ))}
          </div>
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'PasswordStrengthIndicator-Meter-view';

ViewComponent.defaultProps = {
  score: 0,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Score
   */
  score: PropTypes.number,
  /**
   * isLoading
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
