import { useEffect } from 'react';

const ContainerComponent = ({ children, handleClose, name, multiple }) => {
  // Effect attaches event listener to page whilst modal is open
  useEffect(() => {
    /**
     * Click listener to close modal on selection if the selection field is a
     * single selection field only
     * @param {Event} e - the click event
     */
    const clickListener = (e) => {
      const targetId = e.target.id;
      if (targetId.includes(`${name}-`)) {
        handleClose();
      }
    };
    // Attach event listener if not multiple selection
    if (!multiple) {
      window.addEventListener('click', clickListener);
    }
    // Clean up - remove event listener if present
    return () => {
      if (!multiple) {
        window.removeEventListener('click', clickListener);
      }
    };
  }, [multiple, name, handleClose]);

  return children;
};

ContainerComponent.displayName = 'SelectionModal-Select-Container';

export default ContainerComponent;
