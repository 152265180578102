import { useCallback } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import get from 'lodash.get';

const ContainerComponent = ({
  children,
  register,
  passRegister,
  control,
  name,
  isLoading: fieldIsLoading,
  error: fieldError,
  ...restProps
}) => {
  const {
    register: formRegister,
    control: formControl,
    isLoading: formIsLoading,
  } = useFormContext();
  const { errors } = useFormState({ name });

  /**
   * extends register to support on blur on field level
   * @param {String} name
   * @param {Object} options
   * @returns {Object} props
   */
  const extendedRegister = useCallback(
    (fieldName, options = {}) => {
      const registerProps = formRegister(fieldName);

      /**
       * extends on blur function
       * @param {Event} e
       * @returns {Bool}
       */
      const extendOnBlur = (e) => {
        options.onBlur(e);

        return registerProps.onBlur(e);
      };

      /**
       * extends on blur function
       * @param {Event} e
       * @returns {Bool}
       */
      const extendOnChange = (e) => {
        options.onChange(e);

        return registerProps.onChange(e);
      };

      const onBlur = options.onBlur ? extendOnBlur : registerProps.onBlur;
      const onChange = options.onChange
        ? extendOnChange
        : registerProps.onChange;

      return { ...registerProps, onBlur, onChange };
    },
    [formRegister]
  );

  const error = fieldError || get(errors, name);

  const isLoading = formIsLoading || fieldIsLoading;

  return children({
    name,
    error,
    ...restProps,
    isLoading,
    ...(passRegister && {
      register: extendedRegister,
    }),
    ...(register &&
      extendedRegister(name, {
        onBlur: restProps.onBlur,
        onChange: restProps.onChange,
      })),
    ...(control && { control: formControl }),
  });
};

ContainerComponent.displayName = 'FieldWrapper-container';

export default ContainerComponent;
