export const CLASS_NAMES = {
  container: 'group invalid:text-neutral-400 overflow-hidden',
  // selectWrapper: 'flex min-w-20 border-r border-neutral-300',
  selectWrapper: 'flex min-w-16 border-r border-neutral-300',
  select:
    '!text-style-g-em !text-neutral-600 appearance-none bg-select-chevron focus:bg-select-chevron-active bg-no-repeat bg-right-4 invalid:text-neutral-400 overflow-hidden !border-0 !bg-transparent',
  country:
    '!text-style-g-em !text-neutral-600 appearance-none invalid:text-neutral-400 overflow-hidden !border-0 !bg-transparent m-auto',
  code: '!pr-0 my-auto flex cursor-text',
  phoneInput: '!border-0 !bg-transparent',
};

export const BASE_OPTIONS = [{ name: 'UK', value: '+44' }];
