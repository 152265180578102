export const CARD_CLASS_NAMES = {
  baseContainer: {
    base: 'flex-col flex text-neutral-600 w-full',
    '@variant': {
      default: 'border rounded-xl border-solid border-neutral-200',
    },
  },
  headerBase: 'flex flex-row space-between w-full',
  headerMargin: 'mb-2.5',
};
