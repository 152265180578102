import clsx from 'clsx';

import {
  BASE_CLASS_NAME,
  BACKDROP_CLASS_NAME,
} from './ModalLayoutLines-constants';

const ContainerComponent = ({ children, className: extClassName, opacity }) => {
  const className = clsx(BASE_CLASS_NAME, extClassName);

  const backdropClassName = clsx(BACKDROP_CLASS_NAME, `opacity-${opacity}`);

  return children({
    className,
    backdropClassName,
  });
};

ContainerComponent.displayName = 'ModalLayoutLines-container';

export default ContainerComponent;
