import { useContext } from 'react';

import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { job, logo } = useContext(Context);

  return children({
    tradesperson: job?.company || {},
    customer: job?.customer || {},
    details: job?.details || {},
    logo: logo || null,
  });
};

ContainerComponent.displayName = 'Render-Header-container';

export default ContainerComponent;
