import React, { useContext } from 'react';

import Button from '../../Button';
import ModalsWrapper from '../../ModalsWrapper';
import { MENU_MODAL_ID, MENU_BUTTON_PROPS } from '../../../constants';
import ConfigWrapper from '../../ConfigWrapper';

const ContainerComponent = ({ children, skip, onSkip }) => {
  const { showAnimatedModal } = useContext(ModalsWrapper.Context);

  const { homeLinkProps } = useContext(ConfigWrapper.Context);

  // const handleNotification = () => showModal(NOTIFICATIONS_MODAL_ID);
  const handleMenu = () => showAnimatedModal(MENU_MODAL_ID);

  const options = skip
    ? [
        <Button.Link
          variant="light-grey"
          size={16}
          key="skip"
          className="ml-2 mr-3"
          onClick={onSkip}
          label="Skip"
        />,
      ]
    : [
        // <Button.Icon
        //   key="notification"
        //   variant="large"
        //   icon="notification"
        //   onClick={handleNotification}
        // />,
        <Button.Icon key="menu" {...MENU_BUTTON_PROPS} onClick={handleMenu} />,
      ];

  return children({ options, homeLinkProps });
};

ContainerComponent.displayName = 'HeaderMain-Container';

export default ContainerComponent;
