import React from 'react';

/**
 * Creates wrapper from className
 * @param {String} className
 * @returns {React.ReactComponentElement} returns fragment if there is no className
 */
export const makeWrapper = (className) =>
  className
    ? ({ children }) => <div className={className}>{children}</div>
    : React.Fragment;
