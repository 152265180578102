import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../Button';

const ViewComponent = ({ closeToast }) => (
  <Button.Icon
    icon="close"
    variant="ghost-grey"
    size="32-16"
    onClick={closeToast}
    className="min-w-8"
  />
);

ViewComponent.displayName = 'Toast-CloseButton-view';

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  closeToast: PropTypes.func.isRequired,
};

export default ViewComponent;
