import React from 'react';
import { Text } from '@react-pdf/renderer';

import Container from './Title-container';
import { STYLES } from '../../Render-constants';

const ViewComponent = () => (
  <Container>
    {({ title }) => (
      <Text
        style={{
          ...STYLES.mb4,
          ...STYLES['text-16-20-em'],
          ...STYLES['text-neutral-900'],
        }}
      >
        {title}
      </Text>
    )}
  </Container>
);

ViewComponent.displayName = 'Render-Title-view';

export default ViewComponent;
