import PropTypes from 'prop-types';

import { withFieldWrapper } from '../../../../FieldWrapper';
import Button from './Button-view';

const WrappedInput = withFieldWrapper(Button);

WrappedInput.defaultProps = {};

WrappedInput.displayName = 'WrappedButton';

WrappedInput.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * The button ID
   */
  id: PropTypes.string,
};

export default WrappedInput;
