export const CLASS_NAMES = {
  container: 'flex w-full h-full',
  render:
    'w-full my-auto text-style-g text-neutral-600 whitespace-nowrap overflow-hidden overflow-ellipsis',
  renderLoading: 'w-full my-auto mr-8 text-style-g',
  cell: {
    base: 'h-16 flex',
    padding: 'pl-4',
  },
};
