import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';

/**
 * Formats camelCase to snake_case
 * @param {String} value
 */
export const decamelizeString = snakeCase;

/**
 * Formats snake_case, PascalCase to camelCase
 * @param {String} value
 */
export const camelizeString = camelCase;

/**
 * Convert keys of an object to style
 * @param {Object} object
 * @param {Function} style
 */
export const convertObjectKeys = (object, style) => {
  if (!object || typeof object !== 'object') {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map((item) => convertObjectKeys(item, style));
  }

  const keys = Object.keys(object);
  const newKeys = keys.map(style);

  return newKeys.reduce((acc, cur, index) => {
    const obj = object[keys[index]];

    const value = convertObjectKeys(obj, style);

    return {
      ...acc,
      [cur]: value,
    };
  }, {});
};

/**
 * Formats object keys from camelCase to snake_case
 * @param {Object} object
 */
export const decamelize = (object) =>
  convertObjectKeys(object, decamelizeString);

/**
 * Formats object keys from snake_case to camelCase
 * @param {Object} object
 */
export const camelize = (object) => convertObjectKeys(object, camelizeString);
