import React from 'react';
import PropTypes from 'prop-types';

import Container from './ModalContainer-container';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {({ showBackdrop, backdropClassName, ...rest }) => (
      <div className="w-vvp h-vvp">
        {showBackdrop && <div className={backdropClassName} />}
        {children({
          ...rest,
        })}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'ModalContainer-view';

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
};

export default ViewComponent;
