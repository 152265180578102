import { useCallback } from 'react';
import { ACTIONS } from './Table-constants';
import { reorder } from './Table-functions';

/**
 * Hook to handle table actions
 * @param {Object} options
 * @param {Function} options.onAction
 * @param {Function} options.setColumnOrder
 * @param {Function} options.allColumns
 * @returns handle action function
 */
const useActions = ({
  onAction,
  setColumnOrder,
  allColumns,
  setHiddenColumns,
}) => {
  /**
   * Moves column
   * @param {Number} direction
   * @param {String} id
   */
  const moveColumn = useCallback(
    (direction, id) => {
      const columnIds = allColumns.map((column) => column.id);
      const visibleColumns = allColumns.reduce((visible, column) => {
        if (column.isVisible) {
          visible.push(column.id);
        }
        return visible;
      }, []);
      const initialIndex = columnIds.indexOf(id);
      const finalIndex = columnIds.indexOf(
        visibleColumns[visibleColumns.indexOf(id) + parseInt(direction, 10)]
      );
      setTimeout(() => {
        setColumnOrder(reorder(initialIndex, finalIndex, columnIds));
      }, 10);
    },
    [allColumns, setColumnOrder]
  );

  /**
   * Hides column
   * @param {String} id
   */
  const hideColumn = useCallback(
    (id) => {
      setHiddenColumns((hiddenColumns) => [...hiddenColumns, id]);
    },
    [setHiddenColumns]
  );

  /**
   * Method to handle action
   * @param {Object} action
   * @param {Event} event
   * @returns ref to pas to that component
   */
  return useCallback(
    (action, event) => {
      const { type, payload } = action || {};

      if (onAction) {
        onAction(action, event);
      }

      switch (type) {
        case ACTIONS.MOVE_RIGHT:
          moveColumn(...payload);
          break;

        case ACTIONS.MOVE_LEFT:
          moveColumn(...payload);
          break;

        case ACTIONS.HIDE:
          hideColumn(payload);
          break;

        default:
      }
    },
    [hideColumn, moveColumn, onAction]
  );
};

export default useActions;
