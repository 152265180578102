import { useMemo, useEffect, useRef } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import {
  getRates,
  calculateDeposit,
  createAprOptions,
  createTermOptions,
} from './BuildFinancePlan-functions';
import useFields from './BuildFinancePlan-useFields';
import {
  CLASS_NAMES,
  DEPOSIT_PROPS,
  SET_DEPOSIT_PROPS,
  BASE_RATES,
} from './BuildFinancePlan-constants';

import { useClasses } from '../../../../hooks';

const ContainerComponent = ({
  children,
  name,
  availableRates,
  price,
  className,
  depositTaken,
  isLoading,
}) => {
  const fields = useFields(name);

  const { setValue } = useFormContext();

  const classNames = useClasses(CLASS_NAMES, {
    container: ['.container', className],
  });

  /**
   * Gets current form values
   */
  const [deposit, rate] = useWatch({
    name: [fields.deposit, fields.rate],
  });

  /**
   * Determines props for deposit field
   */
  const depositProps = depositTaken
    ? {
        ...SET_DEPOSIT_PROPS,
        options: [{ name: `${depositTaken}%`, value: `${depositTaken}` }],
      }
    : DEPOSIT_PROPS;

  /**
   * Determines the value of the deposit
   */
  const depositValue = useMemo(
    () => calculateDeposit(price, deposit),
    [price, deposit]
  );

  const [rates, aprs, terms] = useMemo(() => {
    if (isLoading)
      return [
        BASE_RATES,
        createAprOptions(Object.keys(BASE_RATES)),
        createTermOptions(BASE_RATES),
      ];
    return [
      getRates(availableRates),
      createAprOptions(Object.keys(getRates(availableRates))),
      createTermOptions(getRates(availableRates)),
    ];
  }, [isLoading, availableRates]);

  const rateRef = useRef(rate);

  /**
   * When rate is changed, set the term
   */
  useEffect(() => {
    if (isLoading || !rate) return;
    // console.log('Not loading.');
    if (rate === rateRef.current) {
      // console.log('Same rate');
      return;
    }
    // console.log('Rate chosen: ', rate);
    rateRef.current = rate;
    const initialTerm = terms[rate][0].value.toString();
    setValue(fields.term, initialTerm);
  }, [rate, fields, setValue, terms, isLoading]);

  /**
   * Set initial values
   */
  useEffect(() => {
    // reset values on isLoading
    if (isLoading) {
      // console.log('is loading');
      setValue(fields.deposit);
      setValue(fields.rate);
      setValue(fields.term);
      return;
    }
    // console.log('is no longer loading. Deposit: ', deposit);
    // If deposit is set, return
    if (deposit) {
      // console.log('deposit set: ', deposit);
      return;
    }
    // Extract intiial values
    const initialApr = aprs[0].value.toString();
    const initialTerm = terms[initialApr][0].value.toString();
    // console.log({ initialApr });
    // console.log({ initialTerm });
    // set rate reference
    rateRef.current = initialApr;
    // Set values
    if (depositTaken) {
      // console.log('Deposit taken: ', depositTaken);
      // console.log('Setting values (deposit taken)');
      // console.log({ deposit: depositTaken });
      // console.log({ initialApr });
      // console.log({ initialTerm });
      setValue(fields.deposit, depositTaken);
      setValue(fields.rate, initialApr);
      setValue(fields.term, initialTerm);
      return;
    }
    // console.log('Setting values');
    // console.log({ deposit: 10 });
    // console.log({ initialApr });
    // console.log({ initialTerm });
    setValue(fields.deposit, 10);
    setValue(fields.rate, initialApr);
    setValue(fields.term, initialTerm);
  }, [fields, deposit, depositTaken, rates, aprs, terms, setValue, isLoading]);

  return children({
    classNames,
    fields,
    depositValue,
    aprs,
    terms: terms[rate || '11.9'],
    depositProps,
  });
};

ContainerComponent.displayName = 'BuildFinancePlan-Container';

export default ContainerComponent;
