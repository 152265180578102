import { POST_CODE_REGEX, NO_ADDRESS_FOUND_LABEL } from './Address-constants';

export const getPlaceholder = (debouncedPostalCode) => {
  if (!debouncedPostalCode) return 'Enter postcode above to search';
  return '';
};

/**
 * Format address for proper display on select field
 * @param {Object} address api address
 * @returns {boolean} true if is valid
 */
export const formatSelectName = (address) =>
  address.formattedAddress.filter(Boolean).join(', ');

/**
 * Creates select field options from address api data
 * @param {Object} data api data
 * @returns {Array} options
 */
export const getOptions = (data) => {
  if (!data)
    return [{ value: '', name: 'Enter a postcode above to search address' }];
  if (data.addresses.length === 0)
    return [{ value: '', name: NO_ADDRESS_FOUND_LABEL }];

  return data.addresses.map((address, i) => ({
    name: formatSelectName(address),
    value: `${i}`,
  }));
  //
  // if (!houseNumber)
  //   return data.addresses.map((address, i) => ({
  //     name: formatSelectName(address),
  //     value: `${i}`,
  //   }));
  //
  // const filteredAddress = data.addresses.reduce((finalAddress, address) => {
  //   console.log(address);
  //
  //   if (
  //     address.buildingNumber === houseNumber ||
  //     address.buildingName === houseNumber
  //   )
  //     finalAddress.push({
  //       name: formatSelectName(address),
  //       value: `${finalAddress.length}`,
  //     });
  //   return finalAddress;
  // }, []);
  //
  // if (filteredAddress.length === 0)
  //   return [{ value: '', name: NO_ADDRESS_FOUND_LABEL }];
  //
  // return filteredAddress;
};

/**
 * Formats AddressLineOne from api data
 * @param {Object} address api data
 */
export const formatAddressLineOne = (address) =>
  [address.line1, address.line2, address.line3, address.line4]
    .filter(Boolean)
    .join(', ');

/**
 * Formats AddressLineTwo from api data
 * @param {Object} address api data
 */
export const formatAddressLineTwo = (address) => address.locality;

/**
 * Lookup function to get the index of address on edit mode
 * @param {Object} data api data
 * @param {string} addressLineOne
 * @param {string} addressLineTwo
 * @returns {string} addressLineTwo
 */
export const searchByLines = (data, addressLineOne, addressLineTwo) =>
  data.addresses.findIndex(
    (address) =>
      formatAddressLineOne(address) === addressLineOne &&
      formatAddressLineTwo(address) === addressLineTwo
  );

/**
 * Lookup function to get the index of address on edit mode
 * @param {Object} data api data
 * @param {string} addressLineOne
 * @param {string} addressLineTwo
 * @returns {string} addressLineTwo
 */
export const searchByHouseNumber = (data, houseNumber) => {
  // Check first against building number
  const buildingNumberIndex = data.addresses.findIndex(
    (address) => address.buildingNumber === houseNumber
  );
  // If found, return index
  if (buildingNumberIndex !== -1) return buildingNumberIndex;
  // If not found, check sub building number
  return data.addresses.findIndex(
    (address) => address.subBuildingNumber === houseNumber
  );
};

/**
 * Validates post code
 * @param {string} postcode postcode
 * @returns {boolean} true if is valid
 */
export const validatePostcode = (postcode) =>
  POST_CODE_REGEX.test(postcode.trim());
