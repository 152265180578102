import Service from './service';
import { buildSignedUrlPayload, uploadFile } from './fileService-functions';
/**
 * Base Service Class
 * @typedef {Object} FileServiceOptions
 * @property {string} baseURL base url
 * @property {Object} authService auth service
 */
class FulfilmentFileService extends Service {
  /**
   * Constructor
   * @param {FileServiceOptions} options
   */
  constructor(serviceOptions) {
    super(serviceOptions);

    this.upload = this.upload.bind(this);
  }

  /**
   * Fatches signed url
   * @param {string} fileName
   */
  // eslint-disable-next-line no-unused-vars
  async getSignedUrl(fileName, _ctx) {
    const data = await this.post('', buildSignedUrlPayload(fileName));

    return data.url;
  }

  /**
   * Uploads sigle or multiple files
   * @param {any} file file array or file
   * @param {Object} ctx
   */
  // eslint-disable-next-line no-unused-vars
  async upload(files, payload, onProgress, ctx) {
    if (!files || files.length === 0) {
      return undefined;
    }

    const signedUrls = await this.post('', payload);

    const filePromises = [
      uploadFile(signedUrls.quote_copy_url, files.quoteFile, onProgress),
      ...(files.satNoteFile
        ? [
            uploadFile(
              signedUrls.satisfaction_note_url,
              files.satNoteFile,
              onProgress,
            ),
          ]
        : []),
    ];

    // uploads all files
    try {
      const res = await Promise.all(filePromises);

      // unwraps array if there is only one file
      return Array.isArray(files) ? res : res[0];
    } catch (e) {
      throw new Error('Error uploading');
    }
  }
}

export default FulfilmentFileService;
