import React from 'react';
import PropTypes from 'prop-types';

import FormTheme from '../../Form/FormTheme';
import UncontrolledField from '../../Form/UncontrolledField';

import HeaderBase from '../HeaderBase';

const ViewComponent = ({ className, options, help, ...inputProps }) => (
  <HeaderBase className={className} help={help} options={options}>
    <div className="w-full -ml-4">
      <FormTheme variant="clean">
        <UncontrolledField.Input
          name="search"
          icon="search"
          iconVariant="search"
          {...inputProps}
        />
      </FormTheme>
    </div>
  </HeaderBase>
);

ViewComponent.displayName = 'HeaderSearch-view';

ViewComponent.defaultProps = {
  options: [],
  defaultValue: undefined,
  className: undefined,
  help: undefined,
};

ViewComponent.propTypes = {
  /**
   * Right side option for header
   */
  options: PropTypes.arrayOf(PropTypes.element),
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Display help option
   */
  help: PropTypes.bool,
};

export default ViewComponent;
