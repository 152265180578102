/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { Item } from './components';
import Container from './PopoverMenu-container';

const ViewComponent = ({
  items,
  className,
  onClose,
  size,
  header,
  content,
  notice: Notice,
}) => (
  <Container className={className}>
    {({ linkComponent: LinkComponent, classNames }) => (
      <div className={classNames.container}>
        {Notice && <Notice />}
        <div className={classNames.paddingContainer}>
          {header && (
            <React.Fragment>
              {header}
              <div className={classNames.seperatorWrapper}>
                <div className={classNames.seperator} />
              </div>
            </React.Fragment>
          )}
          <div onClick={onClose} className={classNames.content}>
            {content ||
              items.map(
                ({
                  icon,
                  name,
                  active,
                  arrow,
                  selected,
                  seperator,
                  ...linkProps
                }) => (
                  <React.Fragment key={name}>
                    {seperator ? (
                      <div className={classNames.seperatorWrapper}>
                        <div className={classNames.seperator} />
                      </div>
                    ) : (
                      <React.Fragment>
                        {linkProps.onClick ? (
                          <div
                            key={name}
                            {...linkProps}
                            className={classNames.link}
                          >
                            <Item
                              icon={icon}
                              name={name}
                              size={size}
                              arrow={arrow}
                              selected={selected}
                            />
                          </div>
                        ) : (
                          <LinkComponent
                            key={name}
                            {...linkProps}
                            className={classNames.link}
                          >
                            <Item
                              icon={icon}
                              name={name}
                              size={size}
                              arrow={arrow}
                              selected={selected}
                            />
                          </LinkComponent>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
              )}
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  items: [],
  className: undefined,
  onClose: undefined,
  header: null,
  content: null,
  size: 'small',
  notice: undefined,
};

ViewComponent.displayName = 'PopoverMenu-view';

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * On close function
   */
  onClose: PropTypes.func,
  /**
   * Items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      icon: PropTypes.string,
      target: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired
  ),
  /**
   * Size variant
   */
  size: PropTypes.string,
  /**
   * Header
   */
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Content
   */
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Notice banner to show
   */
  notice: PropTypes.func,
};

export default ViewComponent;
