import useVariants from '../variants';
import useClasses from '../classes';
import { useProps } from '../props';

export const usePass = (options = {}) => {
  const { props, helpers } = options;

  const variants = useVariants(options.variants);

  const classNames = useClasses(options.classNames, {
    variants,
  });

  return useProps(props, {
    classNames,
    variants,
    helpers,
  });
};
