export const SKELETON = {
  width: 80,
  height: 12,
};

export const CLASSNAMES = {
  option:
    'text-style-h text-neutral-500 flex flex-row items-center align-center',
  dot: 'block w-0.75 h-0.75 rounded-full bg-neutral-400 mx-2',
};
