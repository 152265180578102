/**
 * Configurations related to external integrations for the web app
 */

export const AMPLITUDE_API_KEY =
  process.env.REACT_APP_AMPLITUDE_API_KEY || 'e5017765e370840f53e049ecd822d05b';

export const GETADDRESSES_API_URL =
  process.env.REACT_APP_GETADDRESSES_API_URL || 'https://api.getAddress.io';

export const GETADDRESSES_API_KEY =
  process.env.REACT_APP_GETADDRESSES_API_KEY || 'byJL3HWLC0a39cnl5OKNBA31556';

export const COMPANY_SEARCH_API_URL =
  process.env.REACT_APP_COMPANY_SEARCH_API_URL ||
  'https://company-search-m2hpzurfja-ew.a.run.app';

// TODO: put this back in one the Omni staging env is back online and working
export const OMNI_API_URL =
  process.env.REACT_APP_OMNI_API_URL || 'https://api.omniporttest.ocrf.co.uk';

// export const OMNI_API_URL = (process.env.REACT_APP_OMNI_API_URL
//   || "https://api.omniportqa.omnicapital.co.uk");

export const OMNI_API_USERNAME =
  process.env.REACT_APP_OMNI_API_USERNAME || 'PDaneshyar';

export const OMNI_API_PASSWORD =
  process.env.REACT_APP_OMNI_API_PASSWORD || 'Lambkins_1995';

export const PAYMENT_PUBLISHABLE_KEY =
  process.env.REACT_APP_PAYMENT_PUBLISHABLE_KEY ||
  'pk_test_51JAZJ8FdKBoSocbs68i6MTAYiCVUDFz9Wv0BcwCzp8z1poVw6TN8ucy8XWf4hfJBCih95Mmg6ac1SuJsI38v7WIN00ZVWmeUya';

export const FINANCE_APPLICATION_API_URL =
  process.env.REACT_APP_FINANCE_APPLICATION_API_URL ||
  'https://omni-ent-request-finance-m2hpzurfja-ew.a.run.app';

export const OMNI_DEPOSIT_API_URL =
  process.env.REACT_APP_OMNI_DEPOSIT_API_URL ||
  'https://omni-deposit-payment-m2hpzurfja-ew.a.run.app';

export const OMNI_UPLOAD_DOCUMENTS_API_URL =
  process.env.OMNI_UPLOAD_DOCUMENTS_API_URL ||
  'https://omni-upload-support-documents-m2hpzurfja-ew.a.run.app';
