import { useCallback, useEffect, useRef } from 'react';

// import { VIEWPORT_GAP } from './ModalsWrapper-constants';

/**
 * Modals context values hook
 */
const useViewportVariables = () => {
  const styleElement = useRef();
  const enabled = useRef(typeof window.innerHeight === 'number');

  const innerViewport = useRef({ w: 0, h: 0 });
  const outerViewport = useRef({ w: 0, h: 0 });

  /**
   * Calculates new viewport
   */
  const calculateViewport = useCallback(
    () =>
      new Promise((resolve, reject) => {
        if (!enabled.current) {
          return reject(new Error('Could not calculate window.visualViewport'));
        }

        innerViewport.current.w = window.innerWidth;
        innerViewport.current.h = window.innerHeight;

        outerViewport.current.w = window.outerWidth;
        outerViewport.current.h = window.outerHeight;

        return resolve();
      }),
    []
  );

  /**
   * Stores viewport as css variables
   */
  const setViewport = useCallback(() => {
    if (styleElement.current) {
      styleElement.current.innerHTML = `
        :root {
          --100vvw: ${Math.min(
            innerViewport.current.w,
            outerViewport.current.w
          )}px;
          --100vvh: ${Math.min(
            innerViewport.current.h,
            outerViewport.current.h
          )}px;

          --offset-w: ${outerViewport.current.w - innerViewport.current.w}px;
          --offset-h: ${outerViewport.current.h - innerViewport.current.h}px;
        }
      `;
    }
  }, []);

  /**
   * Calculates and then stores viewport
   */
  const refresh = useCallback(() => {
    calculateViewport().then(setViewport);
  }, [calculateViewport, setViewport]);

  /**
   * Created and style tag on html head element
   */
  useEffect(() => {
    const styleTag = document.createElement('style');

    styleTag.id = 'viewport_fix_variables';

    styleElement.current = styleTag;

    document.head.prepend(styleTag);
  }, []);

  /**
   * Listens to resize to call refresh, and removes listener on dismount
   */
  useEffect(() => {
    if (!enabled.current) {
      return () => {};
    }

    refresh();

    window.visualViewport.addEventListener('resize', refresh);

    return () => {
      window.visualViewport.removeEventListener('resize', refresh);
    };
  }, [refresh]);

  /**
   * Dismiss keyboard when user starts to scroll the screen
   */
  useEffect(() => {
    document.addEventListener('touchstart', () => {
      if (document?.activeElement) {
        document?.activeElement?.blur();
      }
    });
  }, []);
};

export default useViewportVariables;
