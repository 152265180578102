import useButtonText from '../SelectionModal-useButtonText';

const ContainerComponent = ({ children, name, options }) => {
  const buttonText = useButtonText({ name, options });

  const buttonId = `${name}-button`;

  return children({
    buttonText,
    buttonId,
  });
};

ContainerComponent.displayName = 'SelectionModal-Desktop-Container';

export default ContainerComponent;
