import React from 'react';
import { ToastContainer } from 'react-toastify';

import Container from './ToastContainer-container';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ state }) => <ToastContainer {...state} />}
  </Container>
);

ViewComponent.displayName = 'Toast-ToastContainer-view';

export default ViewComponent;
