import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from '../../RangeSlider-constants';

const ViewComponent = React.forwardRef(
  ({ children, onMouseDown, onTouchStart, trackStyle }, ref) => (
    <div
      role="button"
      tabIndex={-1}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      className={CLASS_NAMES.trackWrapper}
    >
      <div ref={ref} className={CLASS_NAMES.track} style={trackStyle}>
        {children}
      </div>
    </div>
  )
);

ViewComponent.displayName = 'Track-view';

ViewComponent.defaultProps = {
  trackStyle: {},
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Mouse down event
   */
  onMouseDown: PropTypes.func.isRequired,
  /**
   * Touch start event
   */
  onTouchStart: PropTypes.func.isRequired,
  /**
   * Track styling object
   */
  trackStyle: PropTypes.object,
};

export default ViewComponent;
