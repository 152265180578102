import React from 'react';
import PropTypes from 'prop-types';

import Container from './ModalLayoutSlideLeft-container';

const ViewComponent = ({ children, header, footer, noBorder }) => (
  <Container noBorder={noBorder}>
    {({ classNames }) => (
      <div className={classNames.container}>
        <div className={classNames.wrapper}>
          <div className={classNames.header}>{header}</div>
          <div className={classNames.content}>{children}</div>
          <div className={classNames.footer}>{footer}</div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'ModalLayoutSlideLeft-view';

ViewComponent.defaultProps = {
  children: null,
  header: undefined,
  footer: undefined,
  noBorder: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Header component
   */
  header: PropTypes.element,
  /**
   * Footer component
   */
  footer: PropTypes.element,
  /**
   * Removes header border
   */
  noBorder: PropTypes.bool,
};

export default ViewComponent;
