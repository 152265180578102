import React from 'react';
import PropTypes from 'prop-types';

import Container from './Content-container';

const ViewComponent = ({ children, header, footer, width, ...variants }) => (
  <Container footer={footer} {...variants} width={width}>
    {({ classNames }) => (
      <React.Fragment>
        {header}
        <div className={classNames.wrapper}>
          <div className={classNames.container}>
            <div className={classNames.content}>{children}</div>
          </div>
          {footer}
        </div>
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopLayoutBoxed-Content-view';

ViewComponent.defaultProps = {
  children: undefined,
  footer: undefined,
  header: undefined,
  top: false,
  left: false,
  flex: false,
  width: 'w-120 max-w-120',
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Footer
   */
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Header
   */
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Align content to top
   */
  top: PropTypes.bool,
  /**
   * Align content to left
   */
  left: PropTypes.bool,
  /**
   * Flex
   */
  flex: PropTypes.bool,
  /**
   * width
   */
  width: PropTypes.string,
};

export default ViewComponent;
