/**
 * Copies string contents to the clipboard
 *
 * @param {String} content content to be copied to clipboard
 */
export const copyToClipboard = (content) => {
  // create element
  const element = document.createElement('textarea');
  // Set element value to content
  element.value = content;
  // Make element readonly
  element.setAttribute('readonly', '');
  // Style element to be hidden
  element.style.position = 'absolute';
  element.style.left = '-9999px';
  // Append to body
  document.body.appendChild(element);
  // Select the content
  element.select();
  // Fix for mobiles
  element.setSelectionRange(0, 99999);
  // Add content to clipboard
  navigator.clipboard.writeText(element.value);
  // Remove the element
  document.body.removeChild(element);
};

/**
 * Handles the response from the ipify API
 */
const _handleIpResponse = async (response) => {
  if (response.statusText === 'OK') {
    const json = await response.json();
    return json;
  }
  throw `IP address fetch error. Status code: ${response.status}`;
};

/**
 * Gets the user's IP address
 */
export const getIP = async () => {
  return fetch('https://api.ipify.org/?format=json').then((response) =>
    _handleIpResponse(response),
  );
};
