import {
  OMNI_LOGIN_PAYLOAD,
  OMNI_LOGIN_INTERVAL,
} from './omniAuthService-constants';

import { AuthService, encodingType } from '../lib';
import config from '../config';
import { camelize } from '../utils';

class OmniAuthServiceImplementation extends AuthService {
  /**
   * Constructor
   */
  constructor({ baseURL }) {
    super({ baseURL, contentType: encodingType.FORM });

    this.accessToken = undefined;
    this.loginTimeout = undefined;
  }

  /**
   * The method for logging in the user.
   */
  async login() {
    const result = await super.post('/api/Login', OMNI_LOGIN_PAYLOAD);

    const { accessToken } = camelize(result);

    this.accessToken = accessToken;

    if (this.loginTimeout) clearTimeout(this.loginTimeout);

    this.loginTimeout = setTimeout(() => {
      this.login();
    }, OMNI_LOGIN_INTERVAL);
  }

  /**
   * Returns current tooken from cookes
   */
  async token() {
    if (!this.accessToken) {
      await this.login();
    }

    return this.accessToken;
  }

  /**
   * Calls logout on auth error
   */
  async onAuthError(next, tryAgain, response) {
    try {
      await this.login();

      return tryAgain();
    } catch (e) {
      // TODO: Send to sentry or some other platform
      console.log(e);

      return next(response);
    }
  }
}

export const OmniAuthService = new OmniAuthServiceImplementation({
  baseURL: config.OMNI_API_URL,
});

export default OmniAuthServiceImplementation;
