import { useCallback } from 'react';

import { dataURItoFile } from './Cropper-functions';
import { CROPPER_PROPS } from './Cropper-constants';
import useImage from './Cropper-useImage';
import useImageManipulation from './Cropper-useImageManipulation';

const ContainerComponent = ({ children, file, onCrop, name }) => {
  const { image, modalId } = useImage({ file, name });

  const {
    cropperRef,
    zoom,
    zoomRange,
    onReady,
    handleZoom,
    handleFit,
    getDataUrl,
    handleReset,
    handleRotateClockwise,
    handleRotateCounterClockwise,
  } = useImageManipulation();

  /**
   * Handles save logic and calls callback function
   */
  const onSave = useCallback(() => {
    const dataUrl = getDataUrl();

    if (!dataUrl) return;

    const croppedFile = dataURItoFile(dataUrl, file.name, file.type);

    onCrop(croppedFile);
  }, [getDataUrl, file, onCrop]);

  const cropperProps = {
    ...CROPPER_PROPS,
    src: image,
    zoomTo: zoom,
    ref: cropperRef,
    ready: onReady,
  };

  const cropperButtomOptionsProps = {
    handleZoom,
    handleFit,
    handleReset,
    zoomRange,
  };

  const cropperTopOptionsProps = {
    handleRotateClockwise,
    handleRotateCounterClockwise,
  };

  return children({
    onSave,
    modalId,
    cropperProps,
    cropperButtomOptionsProps,
    cropperTopOptionsProps,
  });
};

ContainerComponent.displayName = 'Cropper-container';

export default ContainerComponent;
