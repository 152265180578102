import React from 'react';
import PropTypes from 'prop-types';

import Container from './Label-container';
import { LABEL_SKELETON } from './Label-constants';

import Text from '../../../../../Text';
import SkeletonLoader from '../../../../../SkeletonLoader';

const ViewComponent = ({ id, label, helperText, isLoading, autoWidth }) => (
  <Container label={label} autoWidth={autoWidth} helperText={helperText}>
    {({ classNames, skeletonProps, HelperText, stringLabel }) => (
      <div className={classNames.labelContainer}>
        {stringLabel ? (
          <Text
            isLoading={isLoading}
            className={classNames.label}
            skeletonProps={skeletonProps}
            textComponent="label"
            htmlFor={id}
            text={label}
          />
        ) : (
          <SkeletonLoader
            isLoading={isLoading}
            className={classNames.label}
            {...skeletonProps}
            {...LABEL_SKELETON}
            afterLoading={
              <label className={classNames.label} htmlFor={id}>
                {label}
              </label>
            }
          />
        )}
        {helperText &&
          (HelperText || (
            <Text
              isLoading={isLoading}
              className={classNames.helperText}
              text={helperText}
            />
          ))}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Label-view';

ViewComponent.defaultProps = {
  label: undefined,
  helperText: undefined,
  isLoading: undefined,
  autoWidth: undefined,
};

ViewComponent.propTypes = {
  /**
   * Field id
   */
  id: PropTypes.string.isRequired,
  /**
   * Label for field
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Helper text to display next to label
   */
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Auto width
   */
  autoWidth: PropTypes.bool,
};

export default ViewComponent;
