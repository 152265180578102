import { JOB_STATUSES } from '@kanda-utils/library';

export const VARIANTS = {
  [JOB_STATUSES.DRAFT]: {
    color: 'neutral-600',
  },
  [JOB_STATUSES.SENT_TO_CUSTOMER]: {
    color: 'neutral-600',
  },
  [JOB_STATUSES.ACCEPTED]: {
    color: 'turquoise-400',
  },
  [JOB_STATUSES.FINISHED]: {
    color: 'turquoise-400',
  },
  [JOB_STATUSES.REVOKED]: {
    color: 'violet-200 line-through',
  },
  [JOB_STATUSES.DECLINED]: {
    color: 'violet-200 line-through',
  },
  [JOB_STATUSES.CLOSED]: {
    color: 'violet-200 line-through',
  },
};
