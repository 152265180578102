import React from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';

import { CLASS_NAMES } from '../../CompanyHeader-constants';

const ViewComponent = ({ url, companyName }) => (
  <Img
    key={companyName}
    alt={companyName}
    className={CLASS_NAMES.logo}
    src={url}
  />
);

ViewComponent.displayName = 'CompanyHeader-Logo-view';

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * Company logo url
   */
  url: PropTypes.string.isRequired,
  /**
   * Company name
   */
  companyName: PropTypes.string.isRequired,
};

export default ViewComponent;
