import { CLASS_NAMES } from './Label-constants';

import useFormTheme from '../../../FormTheme-useFormTheme';
import { useClasses } from '../../../../../../hooks';

/* eslint-disable react/prop-types */
const ContainerComponent = ({ children, label, autoWidth, helperText }) => {
  const { wrapperClasses } = useFormTheme();

  const baseClassess = { ...CLASS_NAMES, ...wrapperClasses };

  const width = autoWidth ? 'w-full' : 'w-1/6';

  const classNames = useClasses(baseClassess, {
    skeletonWrapper: ['.baseSkeletonWrapper', width],
  });

  const skeletonProps = {
    wrapperClassName: classNames.skeletonWrapper,
  };

  const HelperText = typeof helperText === 'string' ? null : helperText;

  const stringLabel = typeof label === 'string';

  if (classNames.label.indexOf('hidden') !== -1) return null;

  return children({
    skeletonProps,
    classNames,
    HelperText,
    stringLabel,
  });
};

ContainerComponent.displayName = 'Label-Container';

export default ContainerComponent;
