/**
 * Conifguration related to the enterprise partner
 */

export const ENTERPRISE_ENVIRONMENT =
  process.env.REACT_APP_ENTERPRISE_ENVIRONMENT || 'qa';

export const ENTERPRISE_LOGIN =
  process.env.REACT_APP_ENTERPRISE_LOGIN ||
  'https://kf-login-m2hpzurfja-ew.a.run.app';

export const ENTERPRISE_FINANCE_URL =
  process.env.REACT_APP_ENTERPRISE_FINANCE_URL ||
  'https://kf-create-loan-application-m2hpzurfja-ew.a.run.app';

export const ENTERPRISE_RETRIEVE_PROFILE =
  process.env.REACT_APP_ENTERPRISE_RETRIEVE_PROFILE ||
  'https://admin-qa-1.kanda.co.uk/api';

export const ENTERPRISE_RETRIEVE_FINANCE =
  process.env.REACT_APP_ENTERPRISE_RETRIEVE_FINANCE ||
  'https://kf-list-loan-applications-m2hpzurfja-ew.a.run.app';

export const ENTERPRISE_RESET_PASSWORD_FTL =
  process.env.REACT_APP_ENTERPRISE_RESET_PASSWORD_FTL ||
  'https://kf-reset-password-ftl-m2hpzurfja-ew.a.run.app';

export const ENTERPRISE_UPLOAD_DOCS =
  process.env.REACT_APP_ENTERPRISE_UPLOAD_DOCS ||
  'https://kf-upload-fulfillment-doc-m2hpzurfja-ew.a.run.app';

export const ENTERPRISE_SEND_SAT_NOTE =
  process.env.REACT_APP_ENTERPRISE_SEND_SAT_NOTE ||
  'https://omni-send-sat-note-m2hpzurfja-ew.a.run.app';

export const ENTERPRISE_COOKIE_NAME =
  process.env.REACT_APP_ENTERPRISE_COOKIE_NAME ||
  'le0Vh5xtaGTJ7zMwxJeqcTQ63pLpGV5izXfG20Zcc8nD19aLJo6swmZZhxMx8Gw3Y1Q4895VH9KG925krP3EtvgW7O321C3O0IRzapK1SjZdCaQECme7abDMVQ0wuW6D';

export const API_ENCODE_DECODE_URL =
  process.env.REACT_APP_API_ENCODE_DECODE_URL ||
  'https://admin-qa-1.kanda.co.uk/api';
