export const SKELETONS = {
  button: {
    width: 220,
    height: 40,
  },
};

export const DOWNLOAD_BUTTON_PROPS = {
  left: true,
  variant: 'outline',
  size: 40,
  iconProps: {
    size: 16,
    stroke: 'neutral-500',
  },
  className: 'mx-auto',
};
