import fetch from 'cross-fetch';
import { checkSignedRequest, createSignedRequest } from '@kanda-finance/base';

import {
  parsePayload,
  encryptObject,
  decryptObject,
} from './signedRequestService-functions';

import { Service, ValidationError } from '../lib';
import { camelize, decamelize } from '../utils';
import config from '../config';
import { encodingType } from '../lib/types';

/**
 * Signed Request Service
 * parses and Encodes signed requests
 */
export const SignedRequestService = {
  getProfile: 'SignedRequestService-getProfile',
  encode: 'SignedRequestService-encode',
  parse: 'SignedRequestService-parse',
  getPartner: 'SignedRequestService-getPartner',
  encryptObject: 'SignedRequestService-encryptObject',
  decryptObject: 'SignedRequestService-decryptObject',
};

const SignedRequestServiceImplementation = class extends Service {
  /**
   * Constructor
   */
  constructor({ baseURL }) {
    super({ baseURL, keys: SignedRequestService });

    /**
     * Find enterprise id from payload
     * @param {Object} payload
     * @returns {String} enterpriseId
     */
    this.findEnterpiseId = (payload) => {
      try {
        const enterpriseId = payload.enterpriseId || payload.enterprise?.id;

        if (!enterpriseId) throw new Error();

        if (enterpriseId.toLowerCase().includes('tp-')) return '999999';

        return enterpriseId;
      } catch (e) {
        throw new ValidationError('Invalid enterprise id', e);
      }
    };

    this.getPartner = async (id) => {
      try {
        // const partnerKey = getPartnerKey(enterpriseId);
        const partnerProfile = await super.use([
          SignedRequestService.getProfile,
          id,
        ]);

        return partnerProfile;
      } catch (e) {
        throw new ValidationError('Invalid enterprise id', e);
      }
    };

    this.getPartnerKey = async (id) => {
      try {
        const partnerProfile = await super.use([
          SignedRequestService.getProfile,
          id,
        ]);

        return partnerProfile?.key;
      } catch (e) {
        throw new ValidationError('Invalid enterprise id', e);
      }
    };
  }

  async [SignedRequestService.getProfile](id) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: encodingType.JSON,
        'Content-Type': encodingType.JSON,
        Authorization: `Bearer ${id}`,
      },
    };
    const url = `${this.baseURL}/_profile`;

    const res = await fetch(url, requestOptions);

    const json = await res.json();

    return camelize(json);
  }

  /**
   * Encode signed request
   * @param {Object} payload
   * @returns {String} signed request
   */
  async [SignedRequestService.encode](payload = {}) {
    const enterpriseId = this.findEnterpiseId(payload);

    const partnerKey = await this.getPartnerKey(enterpriseId);

    const body = decamelize(payload);

    return createSignedRequest(body, partnerKey);
  }

  /**
   * Parse and validates signed request
   * @param {String} signedRequest
   * @returns {Object} initial data
   */
  async [SignedRequestService.parse](signedRequest) {
    const payload = parsePayload(signedRequest);

    const enterpriseId = this.findEnterpiseId(payload);

    const partnerKey = await this.getPartnerKey(enterpriseId);

    const result = checkSignedRequest(signedRequest, partnerKey);

    if (typeof result !== 'object') {
      throw new ValidationError(result);
    }

    return camelize(result);
  }

  /**
   * Return partner info
   * @param {String} enterpriseId
   * @returns {Object} partner info
   */
  // eslint-disable-next-line class-methods-use-this
  async [SignedRequestService.getPartner](enterpriseId) {
    const partner = await this.getPartner(enterpriseId);
    return partner;
  }

  /**
   * Encrypt object
   * @param {Object} data
   * @param {String} enterpriseId
   * @returns {String} cipher
   */
  // eslint-disable-next-line class-methods-use-this
  async [SignedRequestService.encryptObject](data, enterpriseId) {
    const partnerKey = await this.getPartnerKey(enterpriseId);
    return encryptObject(data, partnerKey);
  }

  /**
   * Decrypt object
   * @param {String} cipher
   * @param {String} enterpriseId
   * @returns {Object} data
   */
  // eslint-disable-next-line class-methods-use-this
  async [SignedRequestService.decryptObject](cipher, enterpriseId) {
    const partnerKey = await this.getPartnerKey(enterpriseId);
    return decryptObject(cipher, partnerKey);
  }
};

export default new SignedRequestServiceImplementation({
  baseURL: `${config.API_ENCODE_DECODE_URL}`,
});
