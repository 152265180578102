import { useFormTheme } from '../FormTheme';
import FieldWrapper from '../FormTheme/FieldWrapper';

const ContainerComponent = ({ children }) => {
  const { fieldWrapper } = useFormTheme();

  const Wrapper = FieldWrapper[fieldWrapper] || fieldWrapper;

  return children({
    Wrapper,
  });
};

ContainerComponent.displayName = 'FieldWrapper-container';

export default ContainerComponent;
