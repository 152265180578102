import { makeWrapper } from '../../../utils';

import { FORM_THEME_VARIANTS } from './FormTheme-constants';

const ContainerComponent = ({ children, variant }) => {
  const theme = FORM_THEME_VARIANTS[variant] || {};

  const Wrapper = makeWrapper(theme.themeWrapper);

  return children({
    theme,
    Wrapper,
  });
};

ContainerComponent.displayName = 'FormTheme-Container';

export default ContainerComponent;
