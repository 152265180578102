import React from 'react';
import PropTypes from 'prop-types';

import Container from './RichText-container';

import SkeletonLoader from '../SkeletonLoader';

const ViewComponent = ({
  text,
  isLoading,
  className,
  skeletonClassName,
  skeletonProps,
  wrapper: Wrapper,
  ...components
}) => (
  <Container text={text} components={components}>
    {({ items }) => (
      <div className={className}>
        <SkeletonLoader
          isLoading={isLoading}
          className={skeletonClassName}
          {...skeletonProps}
          afterLoading={items.map((item, i) => (
            <Wrapper key={String(i)}>{item}</Wrapper>
          ))}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'RichText-view';

ViewComponent.defaultProps = {
  wrapper: React.Fragment,
  className: 'w-full',
  skeletonClassName: '',
  skeletonProps: {},
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Text to display
   */
  text: PropTypes.string.isRequired,
  /**
   * ClassName for wrapper
   */
  className: PropTypes.string,
  /**
   * ClassName for wrapper
   */
  skeletonClassName: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Skeleton props
   */
  skeletonProps: PropTypes.object,
  /**
   * Wrapper
   */
  wrapper: PropTypes.elementType,
};

export default ViewComponent;
