import React from 'react';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';

import Container from './Cropper-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

import Modal from '../../../../Modal';
import BreakPoints from '../../../../BreakPoints';

const ViewComponent = ({ onCrop, onCancelCrop, file, name }) => (
  <Container file={file} onCrop={onCrop} name={name}>
    {({ modalId, ...props }) => (
      <Modal.Container id={modalId}>
        {() => (
          <BreakPoints
            mobile={<Mobile {...props} onCancelCrop={onCancelCrop} />}
            desktop={<Desktop {...props} onCancelCrop={onCancelCrop} />}
          />
        )}
      </Modal.Container>
    )}
  </Container>
);

ViewComponent.displayName = 'Cropper-view';

ViewComponent.defaultProps = {
  file: undefined,
};

ViewComponent.propTypes = {
  /**
   * OnCrop callback
   */
  onCrop: PropTypes.func.isRequired,
  /**
   * OnCancelCrop callback
   */
  onCancelCrop: PropTypes.func.isRequired,
  /**
   * Field name
   */
  name: PropTypes.string.isRequired,
  /**
   * File object to be cropped
   */
  file: PropTypes.object,
};

export default ViewComponent;
