import React from 'react';
import PropTypes from 'prop-types';

import FieldContainer from '../../FieldContainer';
import RadioSelect from '../../ComponentField/RadioSelect';

const ViewComponent = (props) => (
  <FieldContainer {...props}>
    {(fieldProps) => <RadioSelect {...fieldProps} />}
  </FieldContainer>
);

ViewComponent.displayName = 'RadioSelect-view';

ViewComponent.defaultProps = {
  multiple: undefined,
  options: [],
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
