export const SKELETONS = {
  default: {
    icon: {
      width: 24,
      height: 24,
      wrapperClassName: 'w-6 -mt-1 mr-3',
    },
    input: {
      wrapperClassName: 'hidden',
    },
  },
  small: {
    icon: {
      width: 20,
      height: 20,
      wrapperClassName: 'w-6 -mt-1 mr-3',
    },
    input: {
      wrapperClassName: 'hidden',
    },
  },
};

export const CLASS_NAMES = {
  default:
    'text-neutral-500 text-style-f stroke-current w-full flex flex-1 items-center p-6 bg-neutral-000',
  small:
    'text-neutral-500 text-style-g stroke-current w-full flex flex-1 items-center p-3 bg-neutral-000',
  center: 'flex flex-row',
};

export const ICON = {
  default: {
    width: 24,
    height: 24,
    icon: 'file-upload',
    className: 'mr-3 my-auto',
    stroke: 'neutral-500',
  },
  small: {
    width: 20,
    height: 20,
    icon: 'file-upload',
    className: 'mr-3 my-auto',
    stroke: 'neutral-500',
  },
};
