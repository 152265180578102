import React from 'react';
import PropTypes from 'prop-types';

import FieldContainer from '../../FieldContainer';
import DropDown from '../../UncontrolledField/DropDown';

const ViewComponent = (props) => (
  <FieldContainer {...props} register>
    {(fieldProps) => <DropDown {...fieldProps} />}
  </FieldContainer>
);

ViewComponent.displayName = 'DropDown-view';

ViewComponent.defaultProps = {
  id: undefined,
  placeholder: undefined,
  options: [],
  isLoading: undefined,
  error: undefined,
  onBlur: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * onBlur
   */
  onBlur: PropTypes.func,
};

export default ViewComponent;
