import { SkeletonTheme } from 'react-loading-skeleton';
import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import Container from './ModalsWrapper-container';
import { Loader } from './components';

const Context = createContext({ visibleModals: [] });

const ViewComponent = ({ children, ...restProps }) => (
  <Container {...restProps}>
    {({ showLoader, ...values }) => (
      <React.Fragment>
        <SkeletonTheme color="#F4F6FA" highlightColor="#E7EBF4">
          <Loader isLoading={showLoader} />
          <Context.Provider value={values}>{children}</Context.Provider>
        </SkeletonTheme>
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'ModalsWrapper-view';

ViewComponent.Context = Context;

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
