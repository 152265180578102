import { FirebaseAuthService } from './firebaseAuthService';

import config from '../config';
import { Service } from '../lib';

export const HubService = {
  me: 'HubService-me',
};

/**
 * Hub Service
 */

const HubServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {StaffServiceOptions} options
   */
  constructor({ baseURL, authService }) {
    super({ baseURL, authService, keys: HubService });
  }

  /**
   * Retrieve user info
   * @returns {AuthUser} user
   */
  async [HubService.me]() {
    const res = await super.get('/me');

    return res;
  }
};

export default new HubServiceImplementation({
  baseURL: config.HUB_URL,
  authService: FirebaseAuthService,
});
