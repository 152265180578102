import clsx from 'clsx';

import { CLASS_NAMES } from './HeaderBase-constants';

const ContainerComponent = ({ children, className: extClassName }) => {
  const { containerBase, ...restClassNames } = CLASS_NAMES;

  const container = clsx(containerBase, extClassName);

  const classNames = {
    ...restClassNames,
    container,
  };

  return children({
    classNames,
  });
};

ContainerComponent.displayName = 'HeaderBase-Container';

export default ContainerComponent;
