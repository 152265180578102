import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';

import { isImage, normalizeFilesValue } from './FileInput-functions';

const ContainerComponent = ({
  name,
  accept,
  maxFiles,
  children,
  resolveFile,
  defaultValue,
  onAddFiles,
  onUpdateFiles,
  onRemoveFile,
}) => {
  const { register, setValue } = useFormContext();

  const value = useWatch({ name, defaultValue });

  const files = useMemo(() => {
    const newFiles = normalizeFilesValue(value, maxFiles);

    onUpdateFiles?.(newFiles);

    return newFiles;
  }, [value, maxFiles, onUpdateFiles]);

  /**
   * @param {Array.File} newFiles
   */
  const setFiles = useCallback(
    (newFiles) => {
      setValue(name, maxFiles === 1 ? newFiles[0] : newFiles);
    },
    [maxFiles, name, setValue]
  );

  const showFileZone = maxFiles === 0 || files.length < maxFiles;

  const [resolvedFiles, setResolvedFiles] = useState([]);

  /**
   * When files change it calles resolve file callback
   * to get more information about file from default value
   */
  useEffect(() => {
    if (files.length === 0) setResolvedFiles([]);

    setResolvedFiles(
      files.map((file) => {
        if (!(file instanceof File)) {
          const resolvedField = resolveFile ? resolveFile(file) : {};
          return {
            name: resolvedField.name || 'Unknown File',
            preview: resolvedField.preview,
          };
        }
        return {
          name: file.name,
          preview: isImage(file) && URL.createObjectURL(file),
          fileObject: file,
        };
      })
    );
  }, [files, resolveFile]);

  /**
   * Handles on drop logic updates files state
   * @param {Array} droppedFiles - new uploaded files
   */
  const onDrop = useCallback(
    (droppedFiles) => {
      const newFiles = [...files, ...droppedFiles];

      const finalFiles = maxFiles === 0 ? newFiles : newFiles.slice(-maxFiles);

      const newAddedFiles = finalFiles.filter((file) => !files.includes(file));

      if (onAddFiles) onAddFiles(newAddedFiles);

      setFiles(finalFiles);
    },
    [files, maxFiles, onAddFiles, setFiles]
  );

  /**
   * Make function to create a closure to handle remove file logic
   * @param {number} index - new uploaded files
   * @returns function to handle remove file logic
   */
  const makeRemoveFile = (index) => (e) => {
    e.stopPropagation();
    if (onRemoveFile) {
      onRemoveFile(files[index]);
    }
    const newFiles = files.filter((_, i) => i !== index);

    setFiles(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    multiple: maxFiles !== 1,
    noDragEventsBubbling: true,
  });

  /**
   * Register and unregister field on react hook form
   * this should run on component did mount and unmount
   */
  useEffect(() => {
    register(name);
  }, [register, name]);

  return children({
    files: resolvedFiles,
    showFileZone,
    isDragActive,
    getInputProps,
    getRootProps,
    makeRemoveFile,
  });
};

ContainerComponent.displayName = 'JobPdfFileInputComponent-container';

export default ContainerComponent;
