import { camelize, decamelizeString } from './Case-functions';

/**
 * Formats sort by to typesence
 * @param {Array.Array} sortOptions soryBy ex:[['customerName', 1], ['status', -1]]
 */
export const formatSortBy = (sortOptions = []) => {
  const sortBy = sortOptions.map(([field, sortType]) => {
    const formattedField = decamelizeString(field);

    return sortType > 0 ? `${formattedField}:asc` : `${formattedField}:desc`;
  });

  if (sortBy.length === 0) return undefined;

  return sortBy.join(',');
};

/**
 * Formats filter by to typesence
 * @property {Object} rawData filters
 */
export const formatFilterBy = (filters = {}) => {
  if (Object.keys(filters).length === 0) return undefined;

  return Object.entries(filters)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, `[${value.join(',')}]`];
      }

      if (typeof value === 'object') {
        if (value.gt && value.lt) {
          return [key, `[${value.gt}..${value.lt}]`];
        }

        if (value.gt) {
          return [key, `>${value.gt}`];
        }

        if (value.lt) {
          return [key, `<${value.lt}`];
        }
      }

      return [key, value];
    })
    .map(([key, value]) => `${decamelizeString(key)}:${value}`)
    .join(' && ');
};

/**
 * Formats return data from typesence
 * @param {Object} rawData
 * @param {Number} perPage
 */
export const formatTypesenceData = (rawData, perPage = 10) => {
  if (!rawData) {
    return { data: [], page: 1, totalPages: 1 };
  }

  const { hits, found, ...rest } = camelize(rawData);

  const items = hits.map(({ document, ...search }) => ({
    ...document,
    search,
  }));

  const totalPages = Math.ceil(found / perPage);

  const paging = { totalPages, ...rest };

  return {
    items,
    paging,
  };
};
