import { CLASS_NAMES, ICON_PROPS } from './Input-constants';

import { useFormTheme } from '../../FormTheme';
import { useClasses } from '../../../../hooks';

const ContainerComponent = ({
  children,
  error,
  className,
  iconColor,
  iconVariant,
  italic,
}) => {
  const {
    baseClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses,
    skeletonClasses,
    inputClasses,
  } = useFormTheme();

  const classNames = useClasses(CLASS_NAMES, {
    variants: {
      iconVariant,
      italic,
    },
    container: [
      '.containerBase',
      baseClasses,
      focusClasses.replaceAll('focus:', 'focus-within:'),
      makeErrorClasses(error),
      className,
    ],
    seleton: [skeletonClasses, paddingClasses],
    icon: ['.iconBase', iconColor],
    input: ['.input', paddingClasses, inputClasses],
  });

  const iconProps = { className: classNames.icon, ...ICON_PROPS[iconVariant] };

  return children({ classNames, iconProps });
};

ContainerComponent.displayName = 'UncontrolledInput-container';

export default ContainerComponent;
