export const CLASS_NAMES = {
  container: 'relative inline-block w-5 h-5 mb-auto',
  checkBox: 'hidden',
  label:
    'w-5 h-5 flex align-center justify-center text-neutral-000 overflow-hidden rounded border-3 border-neutral-500 bg-neutral-000 cursor-pointer label-checked:bg-turquoise-300 label-checked:border-transparent',
};

export const SKELETONS = {
  checkbox: {
    height: 20,
    width: 20,
    wrapperClassName: 'absolute -top-1',
  },
};
