import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Background } from '../../assets/images/banner-background.svg';
import { CLASS_NAMES } from './Banner-constants';

const ViewComponent = ({ children }) => (
  <div className={CLASS_NAMES.container}>
    <Background className={CLASS_NAMES.background} />
    {children}
  </div>
);

ViewComponent.displayName = 'Banner';

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
