import { FirebaseAuthService } from './firebaseAuthService';

import {
  STAFF_ME_QUERY,
  STAFF_ALL_ENTERPRISE_USERS_QUERY,
  STAFF_GET_ENTERPRISE_USER_QUERY,
  STAFF_ALL_ENTERPRISE_INFO_QUERY,
  STAFF_GET_ENTERPRISE_INFO_QUERY,
  STAFF_ALL_LOAN_APPLICATIONS_QUERY,
  STAFF_GET_LOAN_APPLICATION_QUERY,
  STAFF_UPDATE_ENTERPRISE_USER_MUTATION,
  STAFF_CREATE_ENTERPRISE_USER_MUTATION,
  STAFF_CREATE_ENTERPRISES_MUTATION,
  STAFF_CREATE_ENTERPRISE_MUTATION,
  STAFF_RESET_PASSWORD_MUTATION,
  STAFF_UPDATE_ENTERPRISE_MUTATION,
} from './staffService-constants';

import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';

export const StaffService = {
  me: 'StaffService-me',
  allEnterpriseUsers: 'StaffService-allEnterpriseUsers',
  getEnterpriseUser: 'StaffService-getEnterpriseUser',
  allEnterpriseInfo: 'StaffService-allEnterpriseInfo',
  getEnterpriseInfo: 'StaffService-getEnterpriseInfo',
  allLoanApplications: 'StaffService-allLoanApplications',
  getLoanApplication: 'StaffService-getLoanApplication',
  updateEnterpriseUser: 'StaffService-updateEnterpriseUser',
  resetPassword: 'StaffService-resetPassword',
  createEnterpriseUser: 'StaffService-createEnterpriseUser',
  createEnterprises: 'StaffService-createEnterprises',
  createEnterprise: 'StaffService-createEnterprise',
  updateEnterprise: 'StaffService-updateEnterprise',
};

/**
 * Staff Service
 * @typedef {Object} StaffServiceOptions
 * @property {String} GraphQLURL graphQL url
 * @property {Object} authService authorisation service
 *
 * AuthUser
 * @typedef {Object} AuthUser
 * @property {String} uid user's id
 * @property {String} email user's email
 * @property {String} name user's account name
 * @property {String} role user's defined role
 * @property {String} subject
 * @property {String} audience
 * @property {String} issuer
 *
 * EnterpriseUsers
 * @typedef {Object} EnterpriseUser
 * @property {String} id id of user in firebase
 * @property {String} username username of enterprise user
 * @property {String} password password of enterprise user
 * @property {String} organizationId organization ID of enterprise user
 * @property {String} organizationName organization name of enterprise user
 * @property {Boolean} isFirstTimeLogin has the enterprise user signed in yet
 * @property {Array.<String>} availableRates rates available to enterprise user
 */

const StaffServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {StaffServiceOptions} options
   */
  constructor({ graphQLURL, authService }) {
    super({ graphQLURL, authService, keys: StaffService });
  }

  /**
   * Retrieve user info
   * @returns {AuthUser} user
   */
  async [StaffService.me]() {
    const res = await super.query(STAFF_ME_QUERY);

    return camelize(res.data.me);
  }

  /**
   * Retrieve all enterprise users
   * @returns {[EnterpriseUser]} enterprise users
   */
  async [StaffService.allEnterpriseUsers]() {
    const res = await super.query(STAFF_ALL_ENTERPRISE_USERS_QUERY);

    return camelize(res.data.allEnterpriseUsers);
  }

  /**
   * Retrieve all enterprise users
   * @param {String} id User's firebase ID
   * @returns {EnterpriseUser} enterprise users
   */
  async [StaffService.getEnterpriseUser](id) {
    const variables = { id };

    const res = await super.query(STAFF_GET_ENTERPRISE_USER_QUERY, variables);

    return camelize(res.data.getEnterpriseUser);
  }

  /**
   * Retrieve all enterprise users
   * @returns {[EnterpriseInfo]} enterprise users
   */
  async [StaffService.allEnterpriseInfo]() {
    const res = await super.query(STAFF_ALL_ENTERPRISE_INFO_QUERY);

    return camelize(res.data.allEnterprises);
  }

  /**
   * Retrieve all enterprise users
   * @param {String} id User's firebase ID
   * @returns {EnterpriseInfo} enterprise user info
   */
  async [StaffService.getEnterpriseInfo](id) {
    const variables = { id };

    const res = await super.query(STAFF_GET_ENTERPRISE_INFO_QUERY, variables);

    return camelize(res.data.getEnterprise);
  }

  /**
   * Retrieve all enterprise users
   * @returns {[EnterpriseInfo]} enterprise users
   */
  async [StaffService.allLoanApplications]() {
    const res = await super.query(STAFF_ALL_LOAN_APPLICATIONS_QUERY);

    return camelize(res.data.allLoanApplications);
  }

  /**
   * Retrieve all enterprise users
   * @param {String} id User's firebase ID
   * @returns {EnterpriseInfo} enterprise user info
   */
  async [StaffService.getLoanApplication](id) {
    const variables = { id };

    const res = await super.query(STAFF_GET_LOAN_APPLICATION_QUERY, variables);

    return camelize(res.data.getLoanApplication);
  }

  /**
   * Create a new user
   * @param {EnterpriseUser} payload New user info
   * @returns {EnterpriseInfo} enterprise user info
   */
  async [StaffService.createEnterpriseUser](payload) {
    const variables = { input: decamelize(payload) };

    const res = await super.query(
      STAFF_CREATE_ENTERPRISE_USER_MUTATION,
      variables,
    );

    return camelize(res.data.createEnterpriseUser);
  }

  /**
   * Upadte a user
   * @param {String} id ID of user to update
   * @param {EnterpriseUser} payload New user info
   * @returns {EnterpriseInfo} enterprise user info
   */
  async [StaffService.updateEnterpriseUser](id, payload) {
    const variables = { id, input: decamelize(payload) };

    const res = await super.query(
      STAFF_UPDATE_ENTERPRISE_USER_MUTATION,
      variables,
    );

    return camelize(res.data.updateEnterpriseUser);
  }

  /**
   * Upadte a user
   * @param {String} id ID of user to update
   * @param {EnterpriseUser} payload New user info
   * @returns {EnterpriseInfo} enterprise user info
   */
  async [StaffService.resetPassword](id, payload) {
    const variables = { id, input: decamelize(payload) };

    const res = await super.query(STAFF_RESET_PASSWORD_MUTATION, variables);

    return camelize(res.data.updateEnterpriseUser);
  }

  /**
   * Creates an enetrprise users
   * @param {Enterprise} payload New user info
   * @returns {Enterprise} enterprise user info
   */
  async [StaffService.createEnterprise](payload) {
    const variables = { input: decamelize(payload) };

    const res = await super.query(STAFF_CREATE_ENTERPRISE_MUTATION, variables);

    return camelize(res.data.createEnterprise);
  }

  /**
   * Creates enterprise users from an input array of users
   * @param {[Enterprise]} payload New user info
   * @returns {[Enterprise]} enterprise user info
   */
  async [StaffService.createEnterprises](payload) {
    const variables = { input: decamelize(payload) };

    const res = await super.query(STAFF_CREATE_ENTERPRISES_MUTATION, variables);

    return camelize(res.data.createEnterprises);
  }

  /**
   * Creates enterprise users from an input array of users
   * @param {[Enterprise]} payload New user info
   * @returns {[Enterprise]} enterprise user info
   */
  async [StaffService.updateEnterprise](id, payload) {
    const variables = { id, input: decamelize(payload) };

    const res = await super.query(STAFF_UPDATE_ENTERPRISE_MUTATION, variables);

    return camelize(res.data.createEnterprises);
  }
};

export default new StaffServiceImplementation({
  graphQLURL: config.STAFF_GRAPHQL_GATEWAY,
  authService: FirebaseAuthService,
});
