import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import Container from './Item-container';
import { SKELETONS, HIGHLIGHT_PROPS, TEXT_CLASSNAMES } from './Item-constants';

import Card from '../../../../../../../../Card';
import SkeletonLoader from '../../../../../../../../SkeletonLoader';

const ViewComponent = ({ isLoading, company, searchWords, handleSelect }) => (
  <Container
    handleSelect={handleSelect}
    company={company}
    isLoading={isLoading}
  >
    {({ className, onSelect, address }) => (
      <Card
        onClick={onSelect}
        className={className}
        title={
          <SkeletonLoader
            {...SKELETONS.title}
            isLoading={isLoading}
            afterLoading={
              <Highlighter
                {...HIGHLIGHT_PROPS}
                searchWords={searchWords}
                textToHighlight={company?.companyName}
                className={TEXT_CLASSNAMES.title}
              />
            }
          />
        }
        footer={
          <SkeletonLoader
            {...SKELETONS.footer}
            isLoading={isLoading}
            afterLoading={
              <Highlighter
                {...HIGHLIGHT_PROPS}
                searchWords={searchWords}
                textToHighlight={company?.companyNumber}
                className={TEXT_CLASSNAMES.footer}
              />
            }
          />
        }
      >
        <SkeletonLoader
          {...SKELETONS.description}
          isLoading={isLoading}
          afterLoading={
            <Highlighter
              {...HIGHLIGHT_PROPS}
              searchWords={searchWords}
              textToHighlight={address}
              className={TEXT_CLASSNAMES.body}
            />
          }
        />
      </Card>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyLookup-Mobile-Item-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  company: undefined,
  searchWords: undefined,
};

ViewComponent.propTypes = {
  /**
   * Handle select customer
   */
  handleSelect: PropTypes.func.isRequired,
  /**
   * Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Search words
   */
  searchWords: PropTypes.arrayOf(PropTypes.string),
  /**
   * Company
   */
  company: PropTypes.shape({
    companyNumber: PropTypes.string,
    companyName: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    addressLineOne: PropTypes.string,
    addressLineTwo: PropTypes.string,
  }),
};

export default ViewComponent;
