import PropTypes from 'prop-types';

import Field from './Field-view';

import { withFieldWrapper } from '../../../FieldWrapper';

const WrappedFileInput = withFieldWrapper(Field);

WrappedFileInput.displayName = 'WrappedField';

WrappedFileInput.defaultProps = {};

WrappedFileInput.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * PlaceHolder text
   */
  placeholder: PropTypes.string,
  /**
   * value
   */
  value: PropTypes.any,
  /**
   * initialy checked
   */
  defaultChecked: PropTypes.bool,
};

export default WrappedFileInput;
