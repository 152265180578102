import { useWatch } from 'react-hook-form';

import { CLASS_NAMES, DEFAULT_VALUE } from './DropDown-constants';

import { useFormTheme } from '../../FormTheme';
import { useClasses } from '../../../../hooks';

const ViewComponent = ({
  children,
  options: _options = [],
  placeholder,
  isLoading,
  error,
  className,
  name,
}) => {
  const {
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses,
    skeletonClasses,
  } = useFormTheme();

  const options = placeholder
    ? [{ name: placeholder, value: DEFAULT_VALUE }, ..._options]
    : _options;

  const classNames = useClasses(CLASS_NAMES, {
    select: [
      baseClasses,
      inputClasses,
      paddingClasses,
      focusClasses,
      makeErrorClasses(error),
      !isLoading && '.baseSelect',
      className,
    ],
    skeleton: [skeletonClasses],
  });

  const selected = useWatch({ name });

  const selectedOption = options.find(({ value }) => value === selected);

  const selectedLabel = selectedOption?.name || placeholder;

  return children({
    options,
    defaultValue: DEFAULT_VALUE,
    currentValue: selected || DEFAULT_VALUE,
    classNames,
    skeletonClasses,
    selectedLabel,
  });
};

ViewComponent.displayName = 'UncontrolledDropDown-container';

export default ViewComponent;
