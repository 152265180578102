export const CLASS_NAMES = {
  container: 'relative flex inline-block w-6 h-6 mb-auto',
  checkBox: 'hidden',
  label:
    'w-6 h-6 flex items-center align-center justify-center text-neutral-000 overflow-hidden rounded-full border-2 border-neutral-300 bg-neutral-000 cursor-pointer label-checked:bg-turquoise-300 label-checked:border-transparent',
};

export const SKELETONS = {
  radiocheck: {
    width: 24,
    height: 24,
    circle: true,
    wrapperClassName: 'absolute -top-1',
  },
};
