export const BODY_SLIDE_LEFT_MODAL_CLASS_NAME = 'slide-left-modal-visible';

export const SLIDE_LEFT_MODAL_CLASS_NAME = 'slide-left-modal';

export const CLASS_NAMES = {
  container: `${SLIDE_LEFT_MODAL_CLASS_NAME} w-screen max-w-sm min-h-vvp bg-neutral-000 absolute right-0`,
  headerBase: 'w-full',
  headerBorder: 'border-b border-neutral-200',
  wrapper: 'flex flex-1 w-full min-h-full flex-col absolute pb-6',
  content: 'flex flex-1 flex-col py-8 px-6 self-stretch',
  footer: 'flex w-full px-6',
};
