/**
 * Creates optional props object from accessors and original row data
 *
 * @param {String|Array.<String>} accessors - Either single accessor (String) or
 *    array of accessors
 * @param {Object} original - Original row data
 * @param {String|Array.<String>} optionalProps - Either single prop name
 *   (String) or array of prop names
 */
export const createOptionalProps = (accessors, original, optionalProps) => {
  // If optionalProps or accessors if not defined, return empty object
  if (!optionalProps || !accessors) return {};

  // If the accessors and optionalProps don't have matching classes, return
  if (
    Object.prototype.toString.call(accessors) !==
    Object.prototype.toString.call(optionalProps)
  )
    return {};

  // If the accessor is a string, return the single requested prop
  if (Object.prototype.toString.call(accessors) === '[object String]')
    return {
      [optionalProps]: original[accessors],
    };

  // If accessor is not an array, return
  if (Object.prototype.toString.call(accessors) !== '[object Array]') return {};

  // Create oprionalProps object by iterating over optionalProps
  if (optionalProps.length !== accessors.length) return {};
  return optionalProps.reduce((props, prop, index) => {
    if (original[accessors[index]] === undefined) return props;
    return {
      ...props,
      [prop]: original[accessors[index]],
    };
  }, {});
};
