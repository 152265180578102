import clsx from 'clsx';

import { CLASS_NAMES, BUTTON_VARIANTS } from './ButtonInlineLink-constants';

const ContainerComponent = ({
  children,
  variant,
  className: extClassName,
  isLoading,
}) => {
  const { buttonBase, buttonLoading, buttonNotLoading, ...classNames } =
    CLASS_NAMES;

  const colorVariant = BUTTON_VARIANTS[variant] || {};

  const button = clsx(
    buttonBase,
    colorVariant.className,
    extClassName,
    isLoading ? buttonLoading : buttonNotLoading
  );

  return children({
    classNames: {
      ...classNames,
      button,
    },
  });
};

ContainerComponent.displayName = 'ButtonInlineLink-Container';

export default ContainerComponent;
