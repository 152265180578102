/**
 * Conifguration related to the Kanda API's.
 */

export const API_GATEWAY =
  process.env.REACT_APP_API_GATEWAY ||
  'https://api-gateway-m2hpzurfja-ew.a.run.app';

export const API_MONOLITH =
  process.env.REACT_APP_API_MONOLITH ||
  'https://api-qa-dot-basic-garden-241310.appspot.com';

export const QUOTES_API_URL =
  process.env.REACT_APP_QUOTES_API_URL ||
  'https://quotes-m2hpzurfja-ew.a.run.app';

export const LOGO_UPLOAD_API_URL =
  process.env.REACT_APP_LOGO_UPLOAD_API_URL ||
  'https://company-logos-m2hpzurfja-ew.a.run.app';

export const CUSTOMER_LIST_API_URL =
  process.env.REACT_APP_CUSTOMER_LIST_API_URL ||
  'https://cd-list-customers-m2hpzurfja-ew.a.run.app';

export const CUSTOMER_CREATE_API_URL =
  process.env.REACT_APP_CUSTOMER_CREATE_API_URL ||
  'https://cd-create-customer-m2hpzurfja-ew.a.run.app';

export const CUSTOMER_UPDATE_API_URL =
  process.env.REACT_APP_CUSTOMER_UPDATE_API_URL ||
  'https://cd-update-customer-m2hpzurfja-ew.a.run.app';

export const GRAPHQL_GATEWAY =
  process.env.REACT_APP_GRAPHQL_GATEWAY ||
  'https://cerberus-7a34gobcka-ew.a.run.app';

export const OMNI_SSE_URL =
  process.env.REACT_APP_OMNI_SSE_URL ||
  'https://omni-sse-m2hpzurfja-ew.a.run.app';

export const JOB_QUOTE_UPLOAD_URL =
  process.env.REACT_APP_JOB_QUOTE_UPLOAD_URL ||
  'https://job-quote-pdf-upload-7a34gobcka-ew.a.run.app';

export const ADD_TRADE_INFO_URL =
  process.env.REACT_APP_ADD_TRADE_INFO_URL ||
  'https://add-trade-info-7a34gobcka-ew.a.run.app';

export const RETRIEVE_TRADE_INFO_URL =
  process.env.REACT_APP_RETRIEVE_TRADE_INFO_URL ||
  'https://retrieve-trade-info-7a34gobcka-ew.a.run.app';

export const UPDATE_TRADE_INFO_URL =
  process.env.REACT_APP_UPDATE_TRADE_INFO_URL ||
  'https://update-trade-info-7a34gobcka-ew.a.run.app';
