import React from 'react';
import PropTypes from 'prop-types';

import Container from './Field-container';
import { CLASS_NAMES } from './Field-constants';

import { HANDLE_TPYES } from '../../../Form-constants';
import SkeletonLoader from '../../../../SkeletonLoader';

const ViewComponent = ({
  forwardRef,
  handle,
  fieldText,
  name,
  isLoading,
  getIpAndTime,
  wrapperProps,
  ...restProps
}) => (
  <Container
    handle={handle}
    name={name}
    getIpAndTime={getIpAndTime}
    wrapperProps={wrapperProps}
  >
    {({ handle: Handle, skeletonClassName }) => (
      <div className={CLASS_NAMES.container}>
        <Handle
          ref={forwardRef}
          name={name}
          isLoading={isLoading}
          {...restProps}
        />
        {fieldText && (
          <SkeletonLoader
            isLoading={isLoading}
            wrapperClassName={skeletonClassName}
            afterLoading={
              <label htmlFor={name} className={CLASS_NAMES.label}>
                {fieldText}
              </label>
            }
          />
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Field-view';

ViewComponent.defaultProps = {
  handle: HANDLE_TPYES.SWITCH,
  label: undefined,
  fieldText: undefined,
  warning: undefined,
  wrapperProps: undefined,
  value: undefined,
  defaultChecked: undefined,
  forwardRef: undefined,
  isLoading: undefined,
  getIpAndTime: false,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * ForwardRef
   */
  forwardRef: PropTypes.any,
  /**
   * type of handle
   */
  handle: PropTypes.oneOf([HANDLE_TPYES.CHECKBOX, HANDLE_TPYES.SWITCH]),
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Accompanying field text
   */
  fieldText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * value
   */
  value: PropTypes.any,
  /**
   * initialy checked
   */
  defaultChecked: PropTypes.bool,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Get IP address and timestamp when boolean was clicked
   */
  getIpAndTime: PropTypes.bool,
};

export default ViewComponent;
