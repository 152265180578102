import { useFormTheme } from '../../../../FormTheme';

const ContainerComponent = ({ children }) => {
  const { paddingClasses } = useFormTheme();

  const className = paddingClasses;

  return children({ className });
};

ContainerComponent.displayName = 'SelectionModal-Button-container';

export default ContainerComponent;
