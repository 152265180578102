import React from 'react';
import PropTypes from 'prop-types';

import Container from './Provider-container';

import Context from '../context';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {(values) => <Context.Provider value={values}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
  data: undefined,
  setStep: undefined,
  sliderRef: undefined,
};

ViewComponent.displayName = 'MultiStepFormProvider-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Function to handle continuing to next step
   */
  handleContinue: PropTypes.func.isRequired,
  /**
   * Function to handle form submission
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Function to handle going to next step - does not save data!
   */
  nextStep: PropTypes.func.isRequired,
  /**
   * Function to handle going to previous step
   */
  prevStep: PropTypes.func.isRequired,
  /**
   * Function to set data
   */
  setData: PropTypes.func.isRequired,
  /**
   * Form data
   */
  data: PropTypes.object,
  /**
   * Function to set specific step
   */
  setStep: PropTypes.func,
  /**
   * Step the form is currently on
   */
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * Slider ref
   */
  sliderRef: PropTypes.any,
};

export default ViewComponent;
