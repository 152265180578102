import React from 'react';
import PropTypes from 'prop-types';

import Container from './BreakPoints-container';

const ViewComponent = ({ mobile, desktop }) => (
  <Container>{({ isDesktop }) => (isDesktop ? desktop : mobile)}</Container>
);

ViewComponent.displayName = 'BreakPoints-view';

ViewComponent.defaultProps = {
  desktop: null,
  mobile: null,
};

ViewComponent.propTypes = {
  /**
   * Desktop
   */
  desktop: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Mobile
   */
  mobile: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
