import clsx from 'clsx';

import { CLASS_NAMES, ICON, SKELETONS } from './DropZoneCard-constants';

import { useFormTheme } from '../../../FormTheme';

const ContainerComponent = ({
  children,
  isDragActive,
  small,
  hasLabel,
  centerPlaceholder,
}) => {
  const { baseClasses, inputClasses, skeletonClasses } = useFormTheme();

  const container = clsx(
    baseClasses,
    inputClasses,
    'border overflow-hidden mt-3',
    !small && !hasLabel ? 'rounded-2xl' : '',
    isDragActive ? 'border-turquoise-300' : 'border-dashed border-neutral-300'
  );

  const span = small ? CLASS_NAMES.small : CLASS_NAMES.default;

  const icon = small ? ICON.small : ICON.default;

  const center = clsx(
    CLASS_NAMES.center,
    centerPlaceholder ? 'mx-auto' : 'w-full'
  );

  const skeletons = small ? SKELETONS.small : SKELETONS.default;

  return children({
    classNames: {
      container,
      span,
      center,
    },
    icon,
    skeletonClasses,
    skeletons,
  });
};

ContainerComponent.displayName = 'DropZoneCard-container';

export default ContainerComponent;
