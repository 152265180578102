import React from 'react';
import PropTypes from 'prop-types';

import Container from './PillButton-container';
import { SKELETONS } from './PillButton-constants';

import Button from '../../Button';
import SkeletonLoader from '../../SkeletonLoader';

const ViewComponent = ({ label, icon, ...restProps }) => (
  <Container {...restProps}>
    {({ isLoading, downloadPdf, downloadButtonProps }) => (
      <SkeletonLoader
        isLoading={isLoading}
        {...SKELETONS.button}
        wrapperClassName="mx-auto"
        afterLoading={
          <Button.Text
            {...downloadButtonProps}
            label={label}
            icon={icon}
            onClick={downloadPdf}
          />
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'QuoteDownload-PillButton-view';

ViewComponent.defaultProps = {
  label: 'Download quote as PDF',
  icon: 'download-file',
  buttonProps: {},
};

ViewComponent.propTypes = {
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * Size
   */
  icon: PropTypes.string,
  /**
   * Tag variant name
   */
  buttonProps: PropTypes.object,
};

export default ViewComponent;
