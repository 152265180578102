import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';

import Container from './Header-container';
import { STYLES } from '../../Render-constants';

const ViewComponent = () => (
  <Container>
    {({ tradesperson, logo, customer, details }) => (
      <React.Fragment>
        <View style={STYLES.headerRow}>
          <View style={STYLES.headerHalfColumn}>
            {logo && (
              <View style={STYLES.logoContainer}>
                <Image src={logo} style={STYLES.logo} />
              </View>
            )}
          </View>
          <View style={STYLES.headerHalfColumn}>
            <View style={STYLES.column}>
              <View style={{ ...STYLES.row, ...STYLES.mb4 }}>
                <View style={STYLES.headerDetailHeading}>
                  <Text
                    style={{
                      ...STYLES['text-12-18-em'],
                      ...STYLES['text-neutral-500'],
                    }}
                  >
                    From
                  </Text>
                </View>
                <View style={STYLES.headerDetailColumn}>
                  {tradesperson?.name && (
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-700'],
                      }}
                    >
                      {tradesperson.name}
                    </Text>
                  )}
                  {tradesperson?.addressLineOne && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.addressLineOne}
                    </Text>
                  )}
                  {tradesperson?.addressLineTwo && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.addressLineTwo}
                    </Text>
                  )}
                  {tradesperson?.postalCode && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.postalCode}
                    </Text>
                  )}
                  {tradesperson?.email && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.email}
                    </Text>
                  )}
                  {tradesperson?.phone && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.phone}
                    </Text>
                  )}
                </View>
              </View>

              {tradesperson?.vatNumber && (
                <View style={{ ...STYLES.row, ...STYLES.mb4 }}>
                  <View style={STYLES.headerDetailHeading}>
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-500'],
                      }}
                    >
                      VAT no.
                    </Text>
                  </View>
                  <View style={STYLES.headerDetailColumn}>
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.vatNumber}
                    </Text>
                  </View>
                </View>
              )}

              {tradesperson?.accountName && (
                <View style={STYLES.row}>
                  <View style={STYLES.headerDetailHeading}>
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-500'],
                      }}
                    >
                      Account name
                    </Text>
                  </View>
                  <View style={STYLES.headerDetailColumn}>
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.accountName}
                    </Text>
                  </View>
                </View>
              )}

              {tradesperson?.accountNumber && (
                <View style={STYLES.row}>
                  <View style={STYLES.headerDetailHeading}>
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-500'],
                      }}
                    >
                      Account no.
                    </Text>
                  </View>
                  <View style={STYLES.headerDetailColumn}>
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.accountNumber}
                    </Text>
                  </View>
                </View>
              )}

              {tradesperson?.sortCode && (
                <View style={STYLES.row}>
                  <View style={STYLES.headerDetailHeading}>
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-500'],
                      }}
                    >
                      Sort code
                    </Text>
                  </View>
                  <View style={STYLES.headerDetailColumn}>
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {tradesperson.sortCode}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>

        <View style={STYLES.detailsRow}>
          <View style={STYLES.headerHalfColumn}>
            <View style={STYLES.column}>
              <View style={STYLES.row}>
                <View style={STYLES.customerDetailHeading}>
                  <Text
                    style={{
                      ...STYLES['text-12-18-em'],
                      ...STYLES['text-neutral-500'],
                    }}
                  >
                    For
                  </Text>
                </View>
                <View style={STYLES.customerDetailColumn}>
                  {customer?.name && (
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-700'],
                      }}
                    >
                      {customer.name}
                    </Text>
                  )}
                  {customer?.addressLineOne && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {customer.addressLineOne}
                    </Text>
                  )}
                  {customer?.addressLineTwo && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {customer.addressLineTwo}
                    </Text>
                  )}
                  {customer?.postalCode && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {customer.postalCode}
                    </Text>
                  )}
                  {customer?.email && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {customer.email}
                    </Text>
                  )}
                  {customer?.phoneNumber && (
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {customer.phoneNumber}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={STYLES.headerHalfColumn}>
            <View style={STYLES.column}>
              {details?.reference && (
                <View style={STYLES.row}>
                  <View style={STYLES.headerDetailHeading}>
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-500'],
                      }}
                    >
                      Reference
                    </Text>
                  </View>
                  <View style={STYLES.headerDetailColumn}>
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {details.reference}
                    </Text>
                  </View>
                </View>
              )}
              {details?.date && (
                <View style={STYLES.row}>
                  <View style={STYLES.headerDetailHeading}>
                    <Text
                      style={{
                        ...STYLES['text-12-18-em'],
                        ...STYLES['text-neutral-500'],
                      }}
                    >
                      Date
                    </Text>
                  </View>
                  <View style={STYLES.headerDetailColumn}>
                    <Text
                      style={{
                        ...STYLES['text-12-18'],
                        ...STYLES['text-neutral-600'],
                      }}
                    >
                      {details.date}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'Render-Header-view';

export default ViewComponent;
