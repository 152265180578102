import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from './Popover-constants';
import Container from './Popover-container';
import PopoverMenu from '../../../../../PopoverMenu';

const ViewComponent = ({ companyItems, notice, logout }) => (
  <Container companyItems={companyItems} logout={logout}>
    {({ items }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.popover}>
          <PopoverMenu items={items} notice={notice} />
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopHeader-Popover-view';

ViewComponent.defaultProps = {
  notice: undefined,
  logout: undefined,
};

ViewComponent.propTypes = {
  /**
   * Company items
   */
  companyItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      target: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired
  ).isRequired,
  /**
   * Notice banner to show
   */
  notice: PropTypes.func,
  /**
   * Function for logging out
   */
  logout: PropTypes.func,
};

export default ViewComponent;
