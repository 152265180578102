import { CHECKOUT_URL } from '../config';
import { QUOTE_STATUS, VAT_PERCENTAGES } from '../constants';

/**
 * Item Object.
 * @typedef {Object} Item
 * @param {number} item.quantity quantity amount
 * @param {number} item.price unit price
 * @param {String} item.vatType vat percentage
 */

/**
 * Calculates items total
 * @param {Item} item
 */
export const calculateItemTotal = ({ quantity, price, vat }) => {
  const subTotal = quantity * price || 0;

  const vatPercentage = VAT_PERCENTAGES[vat] || Math.max(vat, 0);

  const vatTotal = Math.round((subTotal * vatPercentage) / 100) || 0;

  const total = subTotal + vatTotal;

  return {
    subTotal,
    vatTotal,
    total,
  };
};

/**
 * Calculates jobs total
 * @param {Array.Item} lineItems
 */
export const calculateJobTotal = (lineItems) =>
  lineItems.reduce(
    (acc, lineItem) => {
      const { subTotal, vatTotal, total } = calculateItemTotal(lineItem);

      return {
        subTotal: acc.subTotal + subTotal,
        vatTotal: acc.vatTotal + vatTotal,
        total: acc.total + total,
      };
    },
    {
      subTotal: 0,
      vatTotal: 0,
      total: 0,
    },
  );

/**
 * Constructs the link to a job from the job's reference
 *
 * @param {String} ref The quote reference
 */
export const constructJobLink = (ref) => {
  if (!ref) return '';
  return `${CHECKOUT_URL}/job/${ref}`;
};

/**
 * Returns the formatted currency.
 * The currency has been left as a variable for future proofing.
 *
 * DEV_NOTE: The locale is currently set to 'en-US', but in the future could
 * take any value from https://gist.github.com/ncreated/9934896 to allow
 * formatting from other countries.
 *
 * @param {number} value The price to be converted in the minor currency unit,
 * e.g. for GBP, in pence (£1.23 -> 123)
 * @param {String=} currency The 3-letter currency code as defined by ISO 4217,
 * given in uppercase. See here for codes:
 * https://www2.1010data.com/documentationcenter/prod/1010dataReferenceManual/DataTypesAndFormats/currencyUnitCodes.html
 * Defaults to 'GBP'
 * @param {String=} locale The locale of the format. Defaults to 'en-US'.
 * @param {Number=} minorUnit The minor unit decimal place of the currency.
 * Defaults to 2 (e.g. GBP has 2 minpr units, £1.23)
 */
export const formatToCurrency = (
  value,
  currency = 'GBP',
  locale = 'en-US',
  minorUnit = 2,
) => {
  const majorUnitValue = value / 10 ** minorUnit;
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: minorUnit,
  });
  return formatter.format(majorUnitValue);
};

export const filterJobsByStatus = (jobs, status) => {
  if (!jobs) return [];
  if (status || status === 0)
    return jobs.filter((job) => job.status === status);
  return jobs;
};

/**
 * Returns the current status enum key
 *
 * @param {number} status The job status
 */
export const extractStatusEnum = (status) =>
  Object.keys(QUOTE_STATUS).find((key) => QUOTE_STATUS[key] === status);
