import React from 'react';
import PropTypes from 'prop-types';

import Container from './AnimatedModalContainer-container';
import Animations from '../../Animations';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {({ isShown, backdropClassName, isVisible, ...rest }) =>
      isShown && (
        <div className="w-vvp h-vvp">
          <Animations.Fade show={isVisible}>
            <div className={backdropClassName} />
          </Animations.Fade>
          {children({
            isVisible,
            ...rest,
          })}
        </div>
      )
    }
  </Container>
);

ViewComponent.displayName = 'AnimatedModalContainer-view';

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
};

export default ViewComponent;
