import React from 'react';
import PropTypes from 'prop-types';

import constants from './ButtonText-constants';

import Icon from '../../Icon';
import Pass from '../../Pass';
import SkeletonLoader from '../../SkeletonLoader';

const ViewComponent = ({
  className,
  variant,
  left,
  size,
  iconSpacing,
  submit,
  prepend,
  append,
  label,
  children,
  isLoading,
  icon,
  iconProps,
  ...restProps
}) => (
  <Pass.Constants
    constants={constants}
    className={className}
    variant={variant}
    left={left}
    iconSpacing={iconSpacing}
    loading={isLoading}
    size={size}
  >
    {({ classNames, getButtonProps, getIconProps, getSkeletonProps }) => (
      <button
        type={submit ? 'submit' : 'button'}
        {...getButtonProps(restProps)}
      >
        <span className={classNames.container}>
          {prepend}
          <span className={classNames.content}>
            {label || children}
            {icon && <span className={classNames.iconSpace} />}
            {icon && <Icon {...getIconProps({ ...iconProps, icon })} />}
          </span>
          {append}
        </span>
        <SkeletonLoader {...getSkeletonProps({ isLoading })} />
      </button>
    )}
  </Pass.Constants>
);

ViewComponent.displayName = 'Button.Text';

ViewComponent.defaultProps = {
  variant: 'solid',
  iconSpacing: undefined,
  label: undefined,
  size: 48,
  children: null,
  icon: undefined,
  iconProps: {},
  className: undefined,
  disabled: undefined,
  submit: undefined,
  left: undefined,
  isLoading: undefined,
  append: null,
  prepend: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Icon name
   */
  icon: PropTypes.string,
  /**
   * Icon props
   */
  iconProps: PropTypes.shape({
    className: PropTypes.string,
    stroke: PropTypes.string,
    flip: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.number,
    fill: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  /**
   * Button variant name
   */
  variant: PropTypes.oneOf(['solid', 'gradient', 'ghost', 'outline']),
  /**
   * sets button to disabled
   */
  disabled: PropTypes.bool,
  /**
   * sets button type to submit insted of button
   */
  submit: PropTypes.bool,
  /**
   * changes position of the icon to left from right
   */
  left: PropTypes.bool,
  /**
   * Button size
   */
  size: PropTypes.oneOf(['custom', 48, 40, 32, 24]),
  /**
   * Button label
   */
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Icon spacing
   */
  iconSpacing: PropTypes.number,
  /**
   * Append
   */
  append: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Append
   */
  prepend: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
