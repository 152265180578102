import React from 'react';
import PropTypes from 'prop-types';

const ViewComponent = ({ warning }) => <span>{warning}</span>;

ViewComponent.displayName = 'Warning-view';

ViewComponent.defaultProps = { warning: undefined };

ViewComponent.propTypes = {
  /**
   * Warning message
   */
  warning: PropTypes.string,
};

export default ViewComponent;
