import React from 'react';
import PropTypes from 'prop-types';

import Container from './BuildFinancePlan-container';
import { RATE_PROPS, TERM_PROPS } from './BuildFinancePlan-constants';

import RadioSelect from '../RadioSelect';
import Price from '../../../Price';

const ViewComponent = ({
  price,
  name,
  availableRates,
  isLoading,
  className,
  depositTaken,
}) => (
  <Container
    name={name}
    availableRates={availableRates}
    price={price}
    className={className}
    depositTaken={depositTaken}
    isLoading={isLoading}
  >
    {({ classNames, fields, depositValue, aprs, terms, depositProps }) => (
      <div className={classNames.container}>
        <div className={classNames.depositRow}>
          <div className={classNames.depositFlex}>
            <RadioSelect
              name={fields.deposit}
              isLoading={isLoading}
              {...depositProps}
            />
          </div>
          {(depositValue || isLoading) && (
            <div className={classNames.price}>
              <Price
                poundsClassName={classNames.pounds}
                centsClassName={classNames.cents}
                amount={depositValue}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>

        <RadioSelect
          name={fields.rate}
          options={aprs}
          isLoading={isLoading}
          {...RATE_PROPS}
        />

        <RadioSelect
          name={fields.term}
          options={terms}
          isLoading={isLoading}
          {...TERM_PROPS}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'BuildFinancePlan-view';

ViewComponent.defaultProps = {
  price: 0,
  name: 'plan',
  availableRates: [],
  isLoading: false,
  className: '',
  depositTaken: undefined,
};

ViewComponent.propTypes = {
  /**
   * Job price
   */
  price: PropTypes.number,
  /**
   * Field name
   */
  name: PropTypes.string,
  /**
   * Availbale rates
   */
  availableRates: PropTypes.arrayOf(PropTypes.string),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * External HTML class name
   */
  className: PropTypes.string,
  /**
   * Percentage deposit taken
   */
  depositTaken: PropTypes.number,
};

export default ViewComponent;
