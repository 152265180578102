import Service from './service';
import { buildSignedUrlPayload, uploadFile } from './fileService-functions';
/**
 * Base Service Class
 * @typedef {Object} FileServiceOptions
 * @property {string} baseURL base url
 * @property {Object} authService auth service
 */
class FileService extends Service {
  /**
   * Constructor
   * @param {FileServiceOptions} options
   */
  constructor(serviceOptions) {
    super(serviceOptions);

    this.upload = this.upload.bind(this);
  }

  /**
   * Fatches signed url
   * @param {string} fileName
   */
  // eslint-disable-next-line no-unused-vars
  async getSignedUrl(fileName, _ctx) {
    const data = await this.post('', buildSignedUrlPayload(fileName));

    return data.url;
  }

  /**
   * Uploads sigle or multiple files
   * @param {any} file file array or file
   * @param {Object} ctx
   */
  async upload(files, onProgress, ctx) {
    if (!files || files.length === 0) {
      return undefined;
    }

    // makes an array of files if there is only one file
    const allFiles = Array.isArray(files) ? files : [files];

    // creates an array of fetch sign url promisses
    const signedUrlsPromisses = allFiles.map((file) =>
      this.getSignedUrl(file.name, ctx),
    );

    // resolves signed urls
    const signedUrls = await Promise.all(signedUrlsPromisses);

    // creates an array of upload promisses
    const filePrimisses = allFiles.map((file, i) =>
      uploadFile(signedUrls[i], file, onProgress),
    );

    // uploads all files
    try {
      const res = await Promise.all(filePrimisses);

      // unwraps array if there is only one file
      return Array.isArray(files) ? res : res[0];
    } catch (e) {
      throw new Error('Error uploading');
    }
  }
}

export default FileService;
