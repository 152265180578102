/**
 * Normalizes initial value
 * @param {Array} value - value from the form
 * @param {Array} maxFiles - limit of files
 * @returns {Array} initial value
 */
export const normalizeFilesValue = (value, maxFiles) => {
  if (!value) {
    return [];
  }

  if (maxFiles === 1) {
    return [value];
  }

  return value;
};

/**
 * Checks if file is image
 * @param {Object} file
 * @returns {Boolean} true if file type is image
 */
export const isImage = (file) => file.type.split('/')[0] === 'image';
