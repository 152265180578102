import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import Container from './Price-container';
import { SKELETONS } from './Price-constants';

import SkeletonLoader from '../SkeletonLoader';

const ViewComponent = ({
  isLoading,
  poundsClassName,
  centsClassName,
  ...restProps
}) => (
  <Container {...restProps}>
    {({ classNames, pounds, suffix, centsLabel }) => (
      <div className={classNames.wrapper}>
        <div className={classNames.container}>
          <div className={poundsClassName}>
            <SkeletonLoader
              isLoading={isLoading}
              {...SKELETONS.pounds}
              afterLoading={
                <NumberFormat
                  displayType="text"
                  value={pounds}
                  thousandSeparator
                  prefix="£"
                  suffix={suffix}
                />
              }
            />
          </div>
          {!suffix && (
            <div className={centsClassName}>
              <SkeletonLoader
                isLoading={isLoading}
                {...SKELETONS.cents}
                afterLoading={centsLabel}
              />
            </div>
          )}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Price-view';

ViewComponent.defaultProps = {
  color: 'turquoise-300',
  amount: 0,
  wrapperClassName: 'h-6', // line height of text-style-b
  poundsClassName: 'text-style-d',
  centsClassName: 'text-style-h-em',
  className: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Price color
   */
  color: PropTypes.string,
  /**
   * Wrapper className
   */
  wrapperClassName: PropTypes.string,
  /**
   * Pounds className
   */
  poundsClassName: PropTypes.string,
  /**
   * Cents className
   */
  centsClassName: PropTypes.string,
  /**
   * Price amount in cents
   */
  amount: PropTypes.number,
  /**
   * IsLoading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
