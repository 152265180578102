/**
 * Check if chunk is key
 * @param {String} chunk
 * @returns {Boolean} is variable
 */
export const isKey = (chunk) => /{{\s*[\w.]+\s*}}/g.test(chunk);

/**
 * Unescape variable
 * @param {String} chunk
 * @returns {String} key
 */
export const unescape = (chunk) => chunk.trim().replace(/{{|}}/gi, '').trim();

/**
 * Unescape variable
 * @param {String} text
 * @returns {Array.String} chunks
 */
export const createChunks = (text) =>
  text
    .replaceAll(' ', ' {{space}} ')
    .replaceAll('\n', ' {{break}}')
    .trim()
    .split(' ');
