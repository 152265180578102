import { useCallback } from 'react';

import { reorder } from '../../../Table-functions';

const ContainerComponent = ({ children, columns, setColumnOrder }) => {
  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      const {
        source: { index: startIndex },
        destination: { index: endIndex },
      } = result;
      const columnNames = columns.map((column) => column.id);
      setColumnOrder(reorder(startIndex, endIndex, columnNames));
    },
    [columns, setColumnOrder]
  );

  return children({
    onDragEnd,
  });
};

ContainerComponent.displayName = 'SettingsPopover-container';

export default ContainerComponent;
