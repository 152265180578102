export const CLASS_NAMES = {
  containerBase:
    'flex flex-1 w-screen min-h-vvp flex-col space-between relative',
  containerSticky: '',
  wrapper: 'flex flex-1 w-full flex-col relative',
  headerBase: 'w-full',
  headerBorder: 'border-b-solid border-b border-neutral-200',
  headerFixed: 'sticky top-0 z-50',
  contentBase: 'flex flex-1 w-full flex-col',
  contentPadding: 'py-8 px-6',
  footerDefault: 'w-full px-6 mb-6',
  footerFixed: 'w-full px-6 sticky bottom-6',
  modalsShader: 'absolute bg-neutral-900 opacity-5 w-full h-full z-50',
  widthLimit: 'w-full h-full max-w-7xl mx-auto',
  flexGrow: 'flex flex-1',
};
