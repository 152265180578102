import {
  WRAPPER_CLASS_NAME,
  CONTAINER_CLASS_NAME,
} from '../../DesktopLayoutDefault-constants';

export const CLASS_NAMES = {
  container: `${CONTAINER_CLASS_NAME} flex-1`,
  wrapper: `${WRAPPER_CLASS_NAME} flex-1`,
  content: 'flex flex-1 w-full flex-col py-20',
};
