import Service from './service';

/**
 * AuthService
 */
class AuthService extends Service {
  /**
   * Constructor
   * @param {Object} options
   * @param {String} options.method auth method
   * @param {String} options.headerKey auth header key
   * @param {String} options.apiToken auth header key
   * @param {String} options.baseURL base url
   * @param {String} options.graphQLURL graphQL url
   * @param {String} options.contentType request content type
   */
  constructor({
    method,
    headerKey,
    apiToken,
    baseURL,
    graphQLURL,
    contentType,
  } = {}) {
    super({ baseURL, graphQLURL, contentType });

    this.logout_listeners = [];
    this.method = method || 'Bearer';
    this.headerKey = headerKey || 'Authorization';
    this.apiToken = apiToken;
  }

  /**
   * The method for adding logout listeners
   * @param {Function} newListener function that would be called on logout
   */
  addLogoutListener(newListener) {
    this.logout_listeners.push(newListener);
  }

  /**
   * The method for removing logout listeners
   * @param {Function} removeListener function that would be removed from current listeners
   */
  removeLogoutListener(removeListener) {
    this.logout_listeners = this.logout_listeners.filter(
      (listener) => listener !== removeListener,
    );
  }

  /**
   * Builds the value of the Authorization header.
   * @param {String} authKey Authorization key
   * @returns {String} The Authorization header value.
   */
  buildAuthorizationHeaderValue(authKey) {
    return `${this.method} ${authKey}`;
  }

  /**
   * Builds new header includes the auth
   * @param {Object} requestHeader Authorization key
   * @returns {Object} requestHeader + authorization
   */
  async buildAuthorizationHeader(requestHeader) {
    const authKey = await this.token();

    if (!authKey) {
      return requestHeader;
    }

    return {
      ...requestHeader,
      [this.headerKey]: this.buildAuthorizationHeaderValue(authKey),
    };
  }

  /**
   * Method to return current token
   * @returns {string} token that would be used to request header
   */
  async token() {
    if (this.apiToken) {
      return this.apiToken;
    }

    throw new Error('Token method needs to be implemented');
  }

  /**
   * Callback when there is an auth error
   * @param {Object} response http response
   * @param {Function} next function that continues normal flow
   * @param {Function} tryAgain function that re-calles the request
   */
  // eslint-disable-next-line class-methods-use-this
  async onAuthError() {
    throw new Error('Auth failed');
  }

  /**
   * Middleware method on request to check if there is auth error
   * @param {Object} response http response
   * @param {Function} customAuthErrorCheck
   * @param {Function} next function that continues normal flow
   * @param {Function} tryAgain function that re-calles the request
   */
  async middleware(response, customAuthErrorCheck, next, tryAgain) {
    if (response && response.status === 401) {
      return this.onAuthError(next, tryAgain, response);
    }

    if (
      customAuthErrorCheck &&
      (await customAuthErrorCheck(response.clone()))
    ) {
      return this.onAuthError(next, tryAgain, response);
    }

    return next(response);
  }

  /**
   * the method to handle logout, will call all listeners when called
   */
  async logout() {
    await Promise.all(this.logout_listeners.map((listener) => listener()));
  }
}

export default AuthService;
