import { useEffect } from 'react';

import { useLoadData } from '../../hooks';
import { FirebaseAuthService, FirebaseUserAuthService } from '../../services';

const ContainerComponent = ({ children }) => {
  const { data: isUserLoggedIn, isValidating } = useLoadData([
    FirebaseUserAuthService.key.isUserLoggedIn,
    { revalidateOnMount: true },
  ]);

  /**
   * Calles logout if token is not valid
   */
  useEffect(() => {
    if (!isValidating && !isUserLoggedIn) {
      FirebaseAuthService.logout();
    }
  }, [isValidating, isUserLoggedIn]);

  if (isValidating || !isUserLoggedIn) {
    return null;
  }

  return children;
};

ContainerComponent.displayName = 'FirebaseAuthWrapper-container';

export default ContainerComponent;

// import { useState } from 'react';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';
//
// import { FirebaseAuthService } from '../../services';
//
// const ContainerComponent = ({ children }) => {
//   const [showPage, setShowPage] = useState(null);
//
//   const auth = getAuth();
//
//   onAuthStateChanged(auth, (user) => {
//     if (user) {
//       setShowPage(true);
//       return;
//     }
//     FirebaseAuthService.logout();
//   });
//
//   if (!showPage) return null;
//
//   return children;
// };
//
// ContainerComponent.displayName = 'FirebaseAuthWrapper-container';
//
// export default ContainerComponent;
