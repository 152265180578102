export const SKELETONS = {
  title: {
    width: 80,
  },
  description: {
    width: 160,
  },
  footer: {
    width: 100,
  },
};

export const HIGHLIGHT_PROPS = {
  highlightClassName: 'text-turquoise-300 bg-transparent',
  autoEscape: true,
};

export const TEXT_CLASSNAMES = {
  title: 'my-ayuto',
  footer: 'text-style-h text-neutral-600',
  body: 'text-style-g text-neutral-600',
};
