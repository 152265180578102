import React from 'react';
import PropTypes from 'prop-types';

import Container from './Popover-container';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {({ classNames, showPopover, button: Button, ref, handleClose }) => (
      <div className={classNames.container}>
        {Button}
        {showPopover && (
          <div ref={ref} className={classNames.wrapper}>
            {children({ handleClose })}
          </div>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Popover-view';

ViewComponent.defaultProps = {
  above: false,
  right: false,
  visible: false,
  button: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.func.isRequired,
  /**
   * Children
   */
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Position above button rather than below
   */
  above: PropTypes.bool,
  /**
   * Position popover to right-align rather than left-align
   */
  right: PropTypes.bool,
  /**
   * Visible state
   */
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default ViewComponent;
