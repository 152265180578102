import React from 'react';
import PropTypes from 'prop-types';

import Container, { Header, Footer } from './Layout-container';
import ScrollTopButton from './ScrollTopButton';

const ViewComponent = ({ children, header, footer, ...restProps }) => (
  <Container {...restProps}>
    {({ classNames, scrollTop }) => (
      <div id="layout" className={classNames.container}>
        <div className={classNames.wrapper}>
          <div className={classNames.header}>
            <div className={classNames.headerWidthLimiter}>
              <Header.Target />
            </div>
          </div>
          <div className={classNames.contentWidthLimiter}>
            <div className={classNames.content}>
              <Header.Source>{header}</Header.Source>
              <Footer.Source>{footer}</Footer.Source>
              {children}
            </div>
          </div>
        </div>
        <div className={classNames.footer}>
          <Footer.Target />
        </div>

        {scrollTop && <ScrollTopButton />}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Layout-view';

ViewComponent.defaultProps = {
  children: undefined,
  header: undefined,
  footer: undefined,
  stickyHeader: undefined,
  stickyFooter: undefined,
  headerBg: undefined,
  bg: undefined,
  noBorder: undefined,
  noPadding: undefined,
  scrollTop: false,
};

ViewComponent.Footer = Footer.Source;

ViewComponent.Header = Header.Source;

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Sticky Header
   */
  stickyHeader: PropTypes.bool,
  /**
   * Sticky Footer
   */
  stickyFooter: PropTypes.bool,
  /**
   * header background color
   */
  headerBg: PropTypes.string,
  /**
   * background color
   */
  bg: PropTypes.string,
  /**
   * Removes Border
   */
  noBorder: PropTypes.bool,
  /**
   * Header component
   */
  header: PropTypes.element,
  /**
   * Footer component
   */
  footer: PropTypes.element,
  /**
   * Boolean flag for whether to show srcoll top button
   */
  scrollTop: PropTypes.bool,
  /**
   * Removes content padding
   */
  noPadding: PropTypes.bool,
};

export default ViewComponent;
