import React from 'react';
import PropTypes from 'prop-types';

import Container from './FieldWrapper-container';

const ViewComponent = (props) => (
  <Container>{({ Wrapper }) => <Wrapper {...props} />}</Container>
);

ViewComponent.defaultProps = {
  autoWidth: undefined,
  error: undefined,
  string: undefined,
  warning: undefined,
  wrapperProps: undefined,
  isLoading: undefined,
  prepend: undefined,
  append: undefined,
};

ViewComponent.propTypes = {
  /**
   * The HTML element ID.
   */
  id: PropTypes.string.isRequired,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Field label
   */
  string: PropTypes.string,
  /**
   * Field warning message
   */
  warning: PropTypes.string,
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Auto width
   */
  autoWidth: PropTypes.bool,
  /**
   * Prepend element
   */
  prepend: PropTypes.element,
  /**
   * Append element
   */
  append: PropTypes.element,
};

ViewComponent.displayName = 'FieldWrapper-view';

export default ViewComponent;
