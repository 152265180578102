import clsx from 'clsx';

import { useFormTheme } from '../../FormTheme';

const ContainerComponent = ({ children, error, className: extClassName }) => {
  const {
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses,
    skeletonClasses,
  } = useFormTheme();

  const className = clsx(
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses(error),
    extClassName
  );

  return children({ className, skeletonClasses });
};

ContainerComponent.displayName = 'UncontrolledTextArea-container';

export default ContainerComponent;
