import get from 'lodash.get';

import { VARIABLE_REGEX } from './classes-constants';

/**
 * Gets variants value
 * @param {String} key
 *
 * @returns {any} variant value
 */
export const getVariantValue = (variants, key) =>
  variants[key.replace('@', '')];

/**
 * Checks if key has variable
 * @param {String} key
 *
 * @returns {boolean} true if key is variable
 */
export const isVariable = (key) => key.includes('@');

/**
 * replaces variables
 * @param {String} string
 * @param {Object} variants
 *
 * @returns {boolean} true if key is variable
 */
export const replaceVariables = (string, variants) => {
  const matches = Array.from(string.matchAll(VARIABLE_REGEX));

  return matches.reduce((prev, match) => {
    const { variable, key } = match.groups || {};

    if (variable && isVariable(variable)) {
      return prev.replace(key, getVariantValue(variants, variable));
    }

    return prev;
  }, string);
};

/**
 * Formats value
 * @param {Object} classes classes
 * @param {String|Array<String>|Object} value values
 * @param {Object} variables
 *
 * @returns {String} value
 */
export const formatValue = (classes, value, variants = {}) => {
  if (typeof value === 'object' && !Array.isArray(value)) {
    if (!Object.keys(value).some(isVariable)) return value;

    const flatValue = Object.keys(value).reduce((arrayValues, key) => {
      if (isVariable(key)) {
        const variantKey = getVariantValue(variants, key);

        if (
          typeof value[key][variantKey] === 'object' &&
          !Array.isArray(value[key][variantKey])
        )
          return [
            ...arrayValues,
            formatValue(classes, value[key][variantKey], variants),
          ];

        if (
          typeof value[key][Boolean(variantKey)] === 'object' &&
          !Array.isArray(value[key][Boolean(variantKey)])
        )
          return [
            ...arrayValues,
            formatValue(classes, value[key][Boolean(variantKey)], variants),
          ];

        if (value[key][variantKey])
          return [...arrayValues, value[key][variantKey]];

        if (value[key][Boolean(variantKey)])
          return [...arrayValues, value[key][Boolean(variantKey)]];

        return arrayValues;
      }

      if (typeof value[key] === 'string') {
        return [...arrayValues, value[key]];
      }

      return arrayValues;
    }, []);

    return formatValue(classes, flatValue, variants);
  }

  const normalizedValue = Array.isArray(value) ? value : [value];

  return replaceVariables(
    normalizedValue
      .filter((part) => typeof part === 'string')
      .join(' ')
      .split(' ')
      .reduce((acc, className) => {
        const injectedClassName =
          className.indexOf('.') === 0
            ? get(classes, className.substr(1))
            : className;

        if (!injectedClassName) return acc;

        return acc ? `${acc} ${injectedClassName}` : injectedClassName;
      }, ''),
    variants
  );
};

/**
 * Formats object values
 * @param {Object} object
 * @param {Function} formatter
 * @returns {Object} formated object
 */
export const formatObject = (object, variants, initial = {}) =>
  Object.entries(object).reduce(
    (formattedObject, [key, value]) => ({
      ...formattedObject,
      [key]: formatValue(formattedObject, value, variants),
    }),
    initial
  );
