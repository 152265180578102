import React from 'react';
import PropTypes from 'prop-types';

import Container from './NavigationLink-container';
import { ICON_PROPS, SKELETONS } from './NavigationLink-constants';

import Icon from '../Icon';
import SkeletonLoader from '../SkeletonLoader';

const ViewComponent = ({
  className: extClassName,
  title,
  subtitle,
  badgeColor,
  isLoading,
}) => (
  <Container className={extClassName} badgeColor={badgeColor}>
    {({ classNames }) => (
      <div className={classNames.container}>
        <div className={classNames.content}>
          <div className={classNames.titleWrapper}>
            <span className={classNames.title}>
              <SkeletonLoader
                {...SKELETONS.title}
                isLoading={isLoading}
                afterLoading={title}
              />
            </span>
            {!isLoading && badgeColor && <div className={classNames.badge} />}
          </div>
          <p className={classNames.subtitle}>
            <SkeletonLoader
              {...SKELETONS.subtitle}
              isLoading={isLoading}
              afterLoading={subtitle}
            />
          </p>
        </div>
        <div>
          <Icon isLoading={isLoading} {...ICON_PROPS} />
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'NavigationLink-view';

ViewComponent.defaultProps = {
  title: undefined,
  subtitle: undefined,
  badgeColor: undefined,
  className: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Title
   */
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Subtitle
   */
  subtitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Badge color
   */
  badgeColor: PropTypes.string,
  /**
   * Classname
   */
  className: PropTypes.string,
  /**
   * isLoading
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
