import PropTypes from 'prop-types';
import React from 'react';

import Container from './FileInput-container';
import DropZoneCard from './DropZoneCard';
import FileCard from './FileCard';
import Cropper from './Cropper';

const ViewComponent = ({
  name,
  accept,
  placeholder,
  maxFiles = 0,
  maxSize,
  defaultValue,
  resolveFile,
  onUpdateFiles,
  fileProgress,
  onRemoveFile,
  onAddFiles,
  loadingFiles,
  cropImage,
  isLoading,
  small,
  hasLabel,
  centerPlaceholder,
}) => (
  <Container
    maxSize={maxSize}
    maxFiles={maxFiles}
    name={name}
    accept={accept}
    defaultValue={defaultValue}
    resolveFile={resolveFile}
    onRemoveFile={onRemoveFile}
    onAddFiles={onAddFiles}
    onUpdateFiles={onUpdateFiles}
    cropImage={cropImage}
  >
    {({
      getRootProps,
      getInputProps,
      files,
      isDragActive,
      makeRemoveFile,
      showFileZone,
      cropFile,
      onCrop,
      onCancelCrop,
      fileError,
    }) => (
      <div className="flex flex-col w-full -mt-3">
        <Cropper
          name={name}
          file={cropFile}
          onCrop={onCrop}
          onCancelCrop={onCancelCrop}
        />
        {showFileZone && (
          <DropZoneCard
            isDragActive={isDragActive}
            placeholder={placeholder}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            name={name}
            isLoading={isLoading}
            small={small}
            hasLabel={hasLabel}
            centerPlaceholder={centerPlaceholder}
            fileError={fileError}
          />
        )}
        {files.map((file, index) => (
          <FileCard
            file={file}
            key={file.name}
            onRemove={makeRemoveFile(index)}
            loadingFiles={loadingFiles}
            fileProgress={fileProgress}
            small={small}
            hasLabel={hasLabel}
          />
        ))}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'FileInputComponent-view';

ViewComponent.defaultProps = {
  hasLabel: true,
  maxFiles: 0,
  maxSize: undefined,
  placeholder: undefined,
  accept: undefined,
  defaultValue: undefined,
  onAddFiles: undefined,
  onUpdateFiles: undefined,
  onRemoveFile: undefined,
  resolveFile: undefined,
  fileProgress: undefined,
  loadingFiles: undefined,
  cropImage: undefined,
  isLoading: undefined,
  small: false,
  centerPlaceholder: false,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * FIeld has a label
   */
  hasLabel: PropTypes.bool,
  /**
   * Placeholder
   */
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Array of MIME types that fileInput accepts
   */
  accept: PropTypes.arrayOf(PropTypes.string),
  /**
   * Maximum number of files
   */
  maxFiles: PropTypes.number,
  /**
   * Maximum size of file in bytes
   */
  maxSize: PropTypes.number,
  /**
   * Default value of field
   */
  defaultValue: PropTypes.any,
  /**
   * File progress ref
   */
  fileProgress: PropTypes.any,
  /**
   * Resolve file details value function
   */
  resolveFile: PropTypes.func,
  /**
   * Callback callend when a file is removed
   */
  onRemoveFile: PropTypes.func,
  /**
   * Callback callend when a new file is added
   */
  onAddFiles: PropTypes.func,
  /**
   * Callback callend when a new file is added
   */
  onUpdateFiles: PropTypes.func,
  /**
   * Array of currently loading file names
   */
  loadingFiles: PropTypes.arrayOf(PropTypes.string),
  /**
   * Crop Images
   */
  cropImage: PropTypes.bool,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Whether to display the small variant or not
   */
  small: PropTypes.bool,
  /**
   * Whether to center the placeholder text or not
   */
  centerPlaceholder: PropTypes.bool,
};

export default ViewComponent;
