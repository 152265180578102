import React from 'react';
import PropTypes from 'prop-types';

import Container from './PlaceHolder-container';

const ViewComponent = ({
  children,
  title,
  cta,
  className: extClassName,
  top,
  bg,
  ...restProps
}) => (
  <Container className={extClassName} top={top} bg={bg}>
    {({ classNames }) => (
      <div className={classNames.container} {...restProps}>
        <div className={classNames.wrapper}>
          <div className={classNames.effect}>
            <div className={classNames.content}>
              <div className={classNames.title}>{title} </div>
              <div className={classNames.cta}>{cta}</div>
            </div>
          </div>
        </div>

        <div className={classNames.background} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'PlaceHolder-view';

ViewComponent.defaultProps = {
  children: null,
  className: undefined,
  title: undefined,
  cta: undefined,
  top: undefined,
  bg: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Background color
   */
  bg: PropTypes.string,
  /**
   * Title of card
   */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Cta
   */
  cta: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Set's content to top
   */
  top: PropTypes.bool,
};

export default ViewComponent;
