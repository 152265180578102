import {
  calculateItemTotal,
  formatToCurrency,
  VAT_LABELS,
} from '@kanda-utils/library';

const ContainerComponent = ({ children, lineItem }) => {
  // Get line item totals
  const { subTotal, total } = (lineItem &&
    calculateItemTotal({ ...lineItem })) || {
    subTotal: 0,
    total: 0,
  };

  // Extract the correct VAT label from the value
  const vat = lineItem && VAT_LABELS[lineItem.vat];

  // Structure all values into an object
  const values = {
    title: lineItem?.title || 'No line item title given...',
    description: lineItem?.description || null,
    quantity: `${lineItem?.quantity}x`,
    price: formatToCurrency(subTotal),
    vat: vat.replace('+', ''),
    total: formatToCurrency(total),
  };

  return children({
    ...values,
  });
};

ContainerComponent.displayName = 'Render-LineItem-container';

export default ContainerComponent;
