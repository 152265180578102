import config from '../config';
import { Service } from '../lib';
import { camelize } from '../utils';

/**
 * Reset Password Service
 * @typedef {Object} ResetPasswordServiceOptions
 * @property {string} baseURL base url
 */
class ResetPasswordServiceImplementation extends Service {
  /**
   * Constructor
   * @param {ResetPasswordServiceOptions} options
   */
  constructor({ baseURL }) {
    super({ baseURL });

    this.requestResetPassword = this.requestResetPassword.bind(this);

    this.checkCodeIsValid = this.checkCodeIsValid.bind(this);

    this.setNewPassword = this.setNewPassword.bind(this);
  }

  /**
   * Handles password reset request
   * @param {Object} payload login payload
   */
  async requestResetPassword(payload) {
    const res = await super.post('/reset_password/', payload);
    return camelize(res);
  }

  /**
   * Handles checking of code in reset link
   * @param {String} code link code
   */
  async checkCodeIsValid(code) {
    const res = await super.get(`/reset_password/${code}`);
    return camelize(res);
  }

  /**
   * Handles submission of new password
   * @param {Object} payload new password payload
   * @param {String} code link code
   */
  async setNewPassword(payload, code) {
    const res = await super.put(`/reset_password/${code}`, payload);
    return camelize(res);
  }
}

export const ResetPasswordService = new ResetPasswordServiceImplementation({
  baseURL: `${config.API_MONOLITH}/api/v1`,
});
