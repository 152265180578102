import { KandaAuthService } from '@kanda-api/library';
import { useMemo, useCallback } from 'react';

import { LOGOUT_ITEM } from './Popover-constants';

const ContainerComponent = ({ children, companyItems, logout }) => {
  /** Handles logout */
  const handleLogout = useCallback(() => {
    if (logout) {
      logout();
      return;
    }
    KandaAuthService.logout();
  }, [logout]);

  const items = useMemo(
    () => [
      ...companyItems,
      {
        ...LOGOUT_ITEM,
        onClick: handleLogout,
      },
    ],
    [companyItems, handleLogout]
  );

  return children({ handleLogout, items });
};

ContainerComponent.displayName = 'DesktopHeader-Popover-Container';

export default ContainerComponent;
