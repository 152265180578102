import Input from './Input';
import PasswordInput from './PasswordInput';
import Select from './Select';
import DropDown from './DropDown';
import TextArea from './TextArea';
import AutoSizeTextArea from './AutoSizeTextArea';
import PhoneNumberInput from './PhoneNumberInput';

const UncontrolledField = {
  Input,
  PasswordInput,
  Select,
  TextArea,
  AutoSizeTextArea,
  PhoneNumberInput,
  DropDown,
};

export default UncontrolledField;
