import React from 'react';
import PropTypes from 'prop-types';

import SkeletonLoader from '../SkeletonLoader';
import Container from './CompanyHeader-container';
import { CLASS_NAMES, SKELETONS } from './CompanyHeader-constants';

const ViewComponent = ({ partner, logo }) => (
  <Container partner={partner} logo={logo}>
    {({ isLoading, CompanyLogo }) => (
      <div className={CLASS_NAMES.container}>
        <SkeletonLoader
          isLoading={isLoading}
          {...SKELETONS.avatar}
          afterLoading={<CompanyLogo companyName={partner} url={logo} />}
        />
        <div className={CLASS_NAMES.text}>
          <SkeletonLoader
            isLoading={isLoading}
            {...SKELETONS.title}
            afterLoading={partner}
          />
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyHeader-view';

ViewComponent.defaultProps = {
  partner: '',
  logo: '',
};

ViewComponent.propTypes = {
  /**
   * Company name
   */
  partner: PropTypes.string,
  /**
   * Company logo url
   */
  logo: PropTypes.string,
};

export default ViewComponent;
