import React from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';

import { CLASS_NAMES } from './MenuHeader-constants';

import Header from '../../Header';
import Button from '../../Button';
import Icon from '../../Icon';
import { DEFAULT_AVATAR_ICON_PROPS } from '../../../constants';

const ViewComponent = ({
  onClose,
  companyName,
  companyProfilePicture,
  plan,
  hideHelp,
}) => (
  <Header.Base
    help={!hideHelp}
    options={[<Button.Icon key="close" onClick={onClose} icon="close" />]}
  >
    <div className={CLASS_NAMES.container}>
      {companyProfilePicture ? (
        <Img
          alt={companyName}
          className={CLASS_NAMES.companyProfilePicture}
          src={companyProfilePicture}
          loader={<div className={CLASS_NAMES.loader} />}
          unloader={
            <div className={CLASS_NAMES.defaultAvatar}>
              <Icon {...DEFAULT_AVATAR_ICON_PROPS} />
            </div>
          }
        />
      ) : (
        <div className={CLASS_NAMES.defaultAvatar}>
          <Icon {...DEFAULT_AVATAR_ICON_PROPS} />
        </div>
      )}
      <div className={CLASS_NAMES.infoContainer}>
        <p className={CLASS_NAMES.companyName}>{companyName}</p>
        {plan && <p className={CLASS_NAMES.plan}>{plan}</p>}
      </div>
    </div>
  </Header.Base>
);

ViewComponent.displayName = 'MenuHeader-view';

ViewComponent.defaultProps = {
  companyName: undefined,
  companyProfilePicture: undefined,
  plan: undefined,
  hideHelp: false,
};

ViewComponent.propTypes = {
  /**
   * On close callback
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Company name
   */
  companyName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Company profile picture url
   */
  companyProfilePicture: PropTypes.string,
  /**
   * plan
   */
  plan: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Hide help
   */
  hideHelp: PropTypes.bool,
};

export default ViewComponent;
