import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Container from './SettingsPopover-container';
import DragDropWrapper from './DragDropWrapper';
import { CLASS_NAMES, HEADING_TEXT, DROP_ID } from './SettingsPopover-constants';

const ViewComponent = ({ columns, ...rest }) => (
  <Container columns={columns} {...rest}>
    {({ onDragEnd }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.heading.container}>
          <span className={CLASS_NAMES.heading.text}>{HEADING_TEXT}</span>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={DROP_ID}>
            {(provided, snapshot) => (
              <DragDropWrapper
                provided={provided}
                snapshot={snapshot}
                columns={columns}
              />
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'SettingsPopover-view';

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * Table column data
   */
  columns: PropTypes.array.isRequired,
  /**
   * Table column data
   */
  setColumnOrder: PropTypes.func.isRequired,
};

export default ViewComponent;
