/**
 * Creates dataurl from svg string
 * @param {String} svgStr
 * @returns {String} dataurl
 */
export const svgToDataURL = (svgStr) => {
  const encoded = encodeURIComponent(svgStr)
    .replace(/'/g, '%27')
    .replace(/"/g, '%22');

  const header = 'data:image/svg+xml,';
  const dataUrl = header + encoded;

  return dataUrl;
};
