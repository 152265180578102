import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import Container from './Option-container';

const ViewComponent = ({
  fieldName,
  name,
  value,
  multiple,
  variant,
  isLoading,
  inline,
}) => (
  <Container
    fieldName={fieldName}
    multiple={multiple}
    optionValue={value}
    variant={variant}
    inline={inline}
  >
    {({ id, handle: Handle, classNames, handleProps }) => (
      <label htmlFor={id} key={value} className={classNames.option}>
        <div className={classNames.container}>
          <div className={classNames.handleContainer}>
            <Handle
              id={id}
              {...handleProps}
              value={value}
              isLoading={isLoading}
            />
          </div>
          {isLoading ? (
            <div className={classNames.skeletonWrapper}>
              <div className={classNames.skeleton}>
                <Skeleton />
              </div>
            </div>
          ) : (
            <span className={classNames.span}>{name}</span>
          )}
        </div>
      </label>
    )}
  </Container>
);

ViewComponent.displayName = 'Option-view';

ViewComponent.defaultProps = {
  name: undefined,
  value: undefined,
  multiple: undefined,
  isLoading: undefined,
  inline: false,
  variant: 'default',
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  fieldName: PropTypes.string.isRequired,
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string,
  /**
   * Option value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Display options inline
   */
  inline: PropTypes.bool,
  /**
   * Display variant
   */
  variant: PropTypes.oneOf([
    'default',
    'popover',
    'text-only',
    'narrow',
    'narrow-lg',
  ]),
};

export default ViewComponent;
