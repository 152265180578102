import clsx from 'clsx';
import { CLASS_NAMES } from './DesktopMenu-constants';

/**
 * Items class name
 * @param {Boolean} active
 */
export const itemClassName = (active) =>
  clsx(
    CLASS_NAMES.itemBase,
    active ? CLASS_NAMES.itemActive : CLASS_NAMES.itemInactive
  );
