import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';

const ViewComponent = ({
  children,
  form,
  isLoading,
  onSubmit,
  ...restProps
}) => (
  <FormProvider {...form} isLoading={isLoading}>
    <form
      noValidate
      onSubmit={form.handleSubmit(onSubmit)}
      className="w-full"
      {...restProps}
    >
      {children}
    </form>
  </FormProvider>
);

ViewComponent.defaultProps = { children: null, isLoading: undefined };

ViewComponent.displayName = 'Form-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * React hook form methods
   */
  form: PropTypes.any.isRequired,
  /**
   * on submit function
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
