import { KandaAuthService } from './kandaAuthService';

import { USER_SERVICE_PROFILE_QUERY } from './userService-constants';

import config from '../config';
import { Service } from '../lib';
import { decamelize, camelize } from '../utils';

/**
 * User Service
 * @typedef {Object} UserServiceOptions
 * @property {string} baseURL base url
 * @property {string} GraphQLURL graphQL url
 * @property {Object} authService authService
 */
export class UserServiceImplementation extends Service {
  /**
   * Constructor
   * @param {UserServiceOptions} options
   */
  constructor({ baseURL, graphQLURL, authService }) {
    super({ baseURL, graphQLURL, authService });

    this.updateProfile = this.updateProfile.bind(this);

    this.key = {
      profile: 'UserService/profile',
    };

    this.mapKeys({
      [this.key.profile]: this.profile,
    });
  }

  /**
   * Loads users profile
   */
  async profile() {
    const customAuthErrorCheck = async (response) => {
      const data = await response.json();

      if (!data.data.profile.id) {
        return true;
      }

      return false;
    };

    const res = await super.query(
      USER_SERVICE_PROFILE_QUERY,
      {},
      { customAuthErrorCheck },
    );

    return camelize({
      ...res.data.profile,
      subscription: res.data.subscription || {},
    });
  }

  /**
   * Updates user profile
   * @param {Object} payload user profile parameters
   */
  async updateProfile(payload) {
    const res = await super.put('/profile', decamelize(payload));

    await super.refetch([this.key.profile]);

    return camelize(res);
  }
}

export const UserService = new UserServiceImplementation({
  baseURL: `${config.API_MONOLITH}/api/v1`,
  graphQLURL: config.API_GATEWAY,
  authService: KandaAuthService,
});
