import { KandaAuthService } from './kandaAuthService';
import { UserService } from './userService';

import config from '../config';
import { FileService } from '../lib';
import { camelize } from '../utils';

/**
 * User Company logo Service
 * @typedef {Object} UserCompanyLogoServiceOptions
 * @property {string} baseURL base url
 * @property {Object} authService authService
 */
export class UserCompanyLogoServiceImplementation extends FileService {
  /**
   * Constructor
   * @param {UserCompanyLogoServiceOptions} options
   */
  constructor({ baseURL, authService }) {
    super({ baseURL, authService });

    this.upload = this.upload.bind(this);
  }

  /**
   * Uploads file
   */
  async upload(file, onProgress) {
    const res = await super.upload(file, onProgress);

    await super.refetch([UserService.key.profile]);

    return camelize(res);
  }
}

export const UserCompanyLogoService = new UserCompanyLogoServiceImplementation({
  baseURL: `${config.LOGO_UPLOAD_API_URL}/get_upload_url`,
  authService: KandaAuthService,
});
