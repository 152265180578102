/* eslint jsx-a11y/no-static-element-interactions: 0 */ // --> OFF
import React from 'react';
import PropTypes from 'prop-types';

import MenuHeader from './MenuHeader';

import Modal from '../Modal';
import Icon from '../Icon';
import HelpButton from '../HelpButton';
import Animations from '../Animations';
import { MENU_MODAL_ID } from '../../constants';

const ViewComponent = ({
  footer,
  companyName,
  companyProfilePicture,
  plan,
  items,
  linkComponent: LinkComponent,
  hideHelp,
}) => (
  <Modal.AnimatedContainer id={MENU_MODAL_ID} opacity={50}>
    {({ isVisible, hideModal, handleClose }) => (
      <Animations.SlideLeft show={isVisible} onEnd={hideModal}>
        <Modal.Layout.SlideLeft
          footer={footer}
          header={
            <MenuHeader
              companyName={companyName}
              companyProfilePicture={companyProfilePicture}
              plan={plan}
              onClose={handleClose}
              hideHelp={hideHelp}
            />
          }
        >
          <div className="-mt-8">
            {items.map(({ icon, name, ...itemProps }, i) => (
              <div
                onClick={handleClose}
                className="cursor-pointer mt-8"
                key={String(i)}
              >
                <LinkComponent {...itemProps}>
                  <div className="flex flex-1 w-full h-8 items-center ">
                    <Icon size={32} icon={icon} />
                    <p className="ml-4 style-d-em">{name}</p>
                  </div>
                </LinkComponent>
              </div>
            ))}
          </div>
          {!hideHelp && (
            <div className="flex flex-row mt-8">
              <HelpButton variant="text" />
            </div>
          )}
        </Modal.Layout.SlideLeft>
      </Animations.SlideLeft>
    )}
  </Modal.AnimatedContainer>
);

ViewComponent.displayName = 'Menu-view';

ViewComponent.defaultProps = {
  items: [],
  linkComponent: 'a',
  footer: undefined,
  companyName: undefined,
  companyProfilePicture: undefined,
  plan: undefined,
  hideHelp: false,
};

ViewComponent.propTypes = {
  /**
   * Items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      href: PropTypes.string,
      target: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired
  ),
  /**
   * Footer component
   */
  footer: PropTypes.element,
  /**
   * Company name
   */
  companyName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Company profile picture url
   */
  companyProfilePicture: PropTypes.string,
  /**
   * plan
   */
  plan: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * linkComponent
   */
  linkComponent: PropTypes.elementType,
  /**
   * hide help
   */
  hideHelp: PropTypes.bool,
};

export default ViewComponent;
