import React from 'react';
import PropTypes from 'prop-types';

import FieldContainer from '../../FieldContainer';
import PasswordInput from '../../UncontrolledField/PasswordInput';

const ViewComponent = (props) => (
  <FieldContainer {...props} register>
    {(fieldProps) => <PasswordInput {...fieldProps} />}
  </FieldContainer>
);

ViewComponent.displayName = 'PasswordInput-view';

ViewComponent.defaultProps = {
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
