import { useContext } from 'react';

import { Context } from './LoadingProvider-context';

const ContainerComponent = ({ children, isLoading, overwrite }) => {
  const isParentLoading = useContext(Context);

  const defaultValue = isParentLoading || isLoading;

  const overwritenValue =
    typeof isLoading !== 'undefined' ? isLoading : isParentLoading;

  const value = overwrite ? overwritenValue : defaultValue;

  return children(value);
};

ContainerComponent.displayName = 'LoadingProvider-container';

export default ContainerComponent;
