import React from 'react';
import PropTypes from 'prop-types';

import Container from './DropZoneCard-container';
import Placeholder from '../Placeholder';

import Icon from '../../../../Icon';
import SkeletonLoader from '../../../../SkeletonLoader';

const ViewComponent = ({
  hasLabel,
  placeholder,
  isDragActive,
  name,
  getInputProps,
  getRootProps,
  isLoading,
  small,
  centerPlaceholder,
}) => (
  <Container
    isDragActive={isDragActive}
    small={small}
    hasLabel={hasLabel}
    centerPlaceholder={centerPlaceholder}
  >
    {({ classNames, icon, skeletonClasses, skeletons }) => (
      <div
        {...getRootProps()}
        type="file"
        role="button"
        aria-label="File Upload"
        id={name}
        className={classNames.container}
      >
        <SkeletonLoader
          isLoading={isLoading}
          {...skeletons.input}
          afterLoading={<input {...getInputProps()} />}
        />
        <span className={classNames.span}>
          <div className={classNames.center}>
            <SkeletonLoader
              isLoading={isLoading}
              {...skeletons.icon}
              afterLoading={<Icon {...icon} />}
            />
            <SkeletonLoader
              wrapperClassName={skeletonClasses}
              isLoading={isLoading}
              afterLoading={placeholder}
            />
          </div>
        </span>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DropZoneCard-view';

ViewComponent.defaultProps = {
  hasLabel: true,
  placeholder: <Placeholder />,
  isDragActive: undefined,
  name: undefined,
  isLoading: undefined,
  small: false,
  centerPlaceholder: true,
};

ViewComponent.propTypes = {
  /**
   * GetInputProps function
   */
  getInputProps: PropTypes.func.isRequired,
  /**
   * GetRootProps function
   */
  getRootProps: PropTypes.func.isRequired,
  /**
   * Field has a label
   */
  hasLabel: PropTypes.bool,
  /**
   * Palceholder
   */
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * IsDragActive
   */
  isDragActive: PropTypes.bool,
  /**
   * Name of input
   */
  name: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Whether to display the small variant or not
   */
  small: PropTypes.bool,
  /**
   * Whether to center the placeholder text or not
   */
  centerPlaceholder: PropTypes.bool,
};

export default ViewComponent;
