import { KandaEnterpriseAuthService } from './kandaEnterpriseAuthService';
// import { UserService } from './userService';

import config from '../config';
import { FulfilmentFileService } from '../lib';
import { camelize, decamelize } from '../utils';

export const EnterpriseUploadFileService = {
  uploadFulfilmentDocs: 'EnterpriseUploadFileService-uploadFulfilmentDocs',
  sendSatNote: 'EnterpriseUploadFileService-sendSatNote',
};

/**
 * User Company logo Service
 * @typedef {Object} UserCompanyLogoServiceOptions
 * @property {string} baseURL base url
 * @property {Object} authService authService
 */
const EnterpriseUploadFileServiceImplementation = class extends FulfilmentFileService {
  /**
   * Constructor
   * @param {UserCompanyLogoServiceOptions} options
   */
  constructor({ baseURL, satNoteURL, authService }) {
    super({ baseURL, authService, keys: EnterpriseUploadFileService });

    this.satNoteURL = satNoteURL;
  }

  /**
   * Uploads file
   */
  async [EnterpriseUploadFileService.uploadFulfilmentDocs](
    files,
    payload,
    onProgress,
  ) {
    const res = await super.upload(files, decamelize(payload), onProgress);

    // await super.refetch([UserService.key.profile]);

    return camelize(res);
  }

  /**
   * Uploads file
   */
  async [EnterpriseUploadFileService.sendSatNote](file, payload, onProgress) {
    await super.upload(file, decamelize(payload), onProgress);

    const id = payload.loanApplicationId;

    const url = `/${id}/send-satisfaction-note`;

    const res = await super.post(url, null, { baseURL: this.satNoteURL });

    // await super.refetch([UserService.key.profile]);

    return camelize(res);
  }
};

export default new EnterpriseUploadFileServiceImplementation({
  baseURL: `${config.ENTERPRISE_UPLOAD_DOCS}/fulfillment-documents`,
  satNoteURL: `${config.ENTERPRISE_SEND_SAT_NOTE}/loan-applications`,
  authService: KandaEnterpriseAuthService,
});
