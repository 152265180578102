import { createGetters, formatObject } from './props-functions';

export const useProps = (props = {}, options = {}) => {
  const { helpers, variants, classNames } = options;

  const formattedProps = formatObject(props, variants);

  const getters = createGetters(formattedProps, classNames, helpers);

  return { classNames, ...getters };
};
