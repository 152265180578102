import { calculateItemTotal, VAT_LABELS } from '@kanda-utils/library';

import { VARIANTS } from '../../LineItem-constants';

const ContainerComponent = ({ children, item, isLoading, variant }) => {
  // Get line item totals
  const { subTotal, total } = (item && calculateItemTotal({ ...item })) || {
    subTotal: 0,
    total: 0,
  };

  // Extract the correct VAT label from the value
  const vat = item && VAT_LABELS[item.vat];

  // Structure all values into an object
  const values = {
    title: item?.title || 'No line item title given...',
    description: item?.description,
    quantity: `${item?.quantity} x`,
    price: subTotal,
    vat,
    total,
  };

  const classNames = VARIANTS[variant];

  return children({
    isLoading,
    classNames,
    ...values,
    variant,
  });
};

ContainerComponent.displayName = 'LineItem-Context-Provider-container';

export default ContainerComponent;
