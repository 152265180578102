import { useCallback, useState, useEffect, useRef } from 'react';

/**
 * Modals context values hook
 */
const useAnimatedModals = () => {
  const [visibleAnimatedModals, setVisibleAnimatedModals] = useState([]);
  const [animatedModalsUpdated, setAnimatedModalsUpdate] = useState();

  const visibleAnimatedModalsRef = useRef([]);

  /**
   * Sets modal to visible
   * @param {string} modalId - id of modal
   */
  const showAnimatedModal = useCallback((modalId) => {
    const isVisible = visibleAnimatedModalsRef.current.includes(modalId);

    if (isVisible) {
      return;
    }

    visibleAnimatedModalsRef.current = [
      ...visibleAnimatedModalsRef.current,
      modalId,
    ];
    setAnimatedModalsUpdate(Math.random());
  }, []);

  /**
   * Sets modal to hidden
   * @param {string} modalId - id of modal
   */
  const hideAnimatedModal = useCallback((modalId) => {
    const isVisible = visibleAnimatedModalsRef.current.includes(modalId);

    if (!isVisible) {
      return;
    }

    const newVisibleModals = visibleAnimatedModalsRef.current.filter(
      (modal) => modal !== modalId
    );

    visibleAnimatedModalsRef.current = newVisibleModals;
    setAnimatedModalsUpdate(Math.random());
  }, []);

  /**
   * Update state from ref
   */
  useEffect(() => {
    setVisibleAnimatedModals(visibleAnimatedModalsRef.current);
  }, [animatedModalsUpdated]);

  return {
    showAnimatedModal,
    hideAnimatedModal,
    visibleAnimatedModals,
  };
};

export default useAnimatedModals;
