import useFormTheme from '../../../FormTheme-useFormTheme';

/* eslint-disable react/prop-types */
const ContainerComponent = ({ children }) => {
  const { wrapperClasses } = useFormTheme();

  const className = wrapperClasses.error;

  if (className.indexOf('hidden') !== -1) return null;

  return children({
    className,
  });
};

ContainerComponent.displayName = 'Error-Container';

export default ContainerComponent;
