import clsx from 'clsx';

import {
  CLASS_NAMES,
  BUTTON_VARIANTS,
  BUTTON_SIZES_VARIANTS,
} from './ButtonLink-constants';

const ContainerComponent = ({
  children,
  variant,
  left,
  size,
  className: extClassName,
  iconProps: extIconProps,
  iconSpacing: extIconSpacing,
  isLoading,
}) => {
  const { buttonBase, disabledBase, contentBase, ...classNames } = CLASS_NAMES;

  const colorVariant = BUTTON_VARIANTS[variant] || {};
  const sizeVariant = BUTTON_SIZES_VARIANTS[size] || {};

  const button = clsx(
    buttonBase,
    colorVariant.className,
    sizeVariant.className,
    extClassName,
    isLoading && 'pointer-events-none'
  );

  const content = clsx(contentBase, left ? 'flex-row-reverse' : 'flex-row');

  const iconSpace = extIconSpacing || sizeVariant.iconSpacing;

  const iconClassName = clsx(
    left ? `mr-${iconSpace}` : `ml-${iconSpace}`,
    extIconProps.className
  );

  const iconProps = {
    ...sizeVariant.iconProps,
    ...extIconProps,
    className: iconClassName,
  };

  return children({
    classNames: {
      ...classNames,
      button,
      content,
    },
    iconProps,
  });
};

ContainerComponent.displayName = 'ButtonLink-Container';

export default ContainerComponent;
