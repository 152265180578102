import trim from 'lodash.trim';

import { NUMBERS_AND_DASH_REGEX } from './SearchResults-constants';

/**
 * Formats AddressLineOne from api data
 * @param {Object} address api data
 */
export const formatAddressLineOne = (address) =>
  [address?.premises, address?.addressLine1]
    .map(trim)
    .filter(Boolean)
    // join ["44", "Hepleswell"] with space "44 Hepleswell"
    // join ["C/O Streets Chartered Accountants", "3 Wellbrook Court"] with comma and space "C/O Streets Chartered Accountants, 3 Wellbrook Court"
    .join(NUMBERS_AND_DASH_REGEX.test(trim(address?.premises)) ? ' ' : ', ');

/**
 * Formats AddressLineTwo from api data
 * @param {Object} address api data
 */
export const formatAddressLineTwo = (address) => address?.locality;

/**
 * Format company data
 * @param {Object} company api data
 * @returns {Object} response data
 */
export const formatCompany = (company) => ({
  companyName: company.title,
  companyNumber: company.companyNumber,
  postalCode: company.address?.postalCode,
  city: company.address?.region,
  addressLineOne: formatAddressLineOne(company.address),
  addressLineTwo: formatAddressLineTwo(company.address),
});

/**
 * Format response data
 * @param {Object} data api data
 * @returns {Object} response data
 */
export const formatData = (data) => data.items.map(formatCompany);
