import React from 'react';
import PropTypes from 'prop-types';

import Container from './Desktop-container';

import Button from '../components/Button';
import Select from '../components/Select';
import Popover from '../../../../Popover';
import FormTheme from '../../../FormTheme';

const ViewComponent = ({ children, name, options, label, ...restProps }) => (
  <Container name={name} options={options}>
    {({ buttonId, handleShowModal, buttonText }) => (
      <Popover.Standard
        button={
          <Button
            id={buttonId}
            label={label}
            onClick={handleShowModal}
            buttonText={children || buttonText}
            {...restProps}
          />
        }
      >
        {({ handleClose }) => (
          <FormTheme variant="clean">
            <div className="min-w-64 px-4">
              <Select
                handleClose={handleClose}
                variant="popover"
                {...restProps}
                name={name}
                options={options}
              />
            </div>
          </FormTheme>
        )}
      </Popover.Standard>
    )}
  </Container>
);

ViewComponent.displayName = 'SelectionModal-Desktop-view';

ViewComponent.defaultProps = {
  children: null,
  options: [],
  multiple: undefined,
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  icon: undefined,
  isLoading: undefined,
  error: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * icon name
   */
  icon: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

export default ViewComponent;
