import React from 'react';
import PropTypes from 'prop-types';

import Container from './Error-container';

const ViewComponent = ({ error }) => (
  <Container>
    {({ className }) => <span className={className}>{error}</span>}
  </Container>
);

ViewComponent.displayName = 'Error-view';

ViewComponent.defaultProps = { error: undefined };

ViewComponent.propTypes = {
  /**
   * Error message
   */
  error: PropTypes.string,
};

export default ViewComponent;
