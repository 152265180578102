import clsx from 'clsx';
import {
  TAG_BASE_CLASSES,
  TAG_VARIANTS,
  TAG_COLOURS,
  TAG_SIZES,
} from './Tag-constants';

const ContainerComponent = ({
  children,
  color,
  variant,
  size,
  className: extClassName,
}) => {
  const variantClassName = TAG_VARIANTS[variant];
  const colours = TAG_COLOURS[color];
  const sizeClassName = TAG_SIZES[size];

  const className = clsx(
    TAG_BASE_CLASSES,
    variantClassName,
    sizeClassName,
    colours,
    extClassName
  );

  return children({
    className,
  });
};

ContainerComponent.displayName = 'Tag-Container';

export default ContainerComponent;
