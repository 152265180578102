/**
 * Formats Address
 * @param {Object} company
 * @param {String} company.addressLineOne address line one
 * @param {String} company.addressLineTwo address line two
 * @param {String} company.city city
 * @param {String} company.postalCode postalCode
 * @returns {String} address
 */
export const formatAddress = (company) =>
  [
    company?.addressLineOne,
    company?.addressLineTwo,
    company?.city,
    company?.postalCode,
  ]
    .filter(Boolean)
    .join(', ');
