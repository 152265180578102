import { SignedRequestService } from './signedRequestService';
import { KANDA_APPLICATION_STATUS } from './omniFinanceApplicationService-constants';

import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';
import { withContextParam } from '../fetcher';

/**
 * Finance application service
 * @typedef {Object} OmniFinanceApplicationServiceOptions
 * @property {String} baseURL base url
 *
 * @typedef {(Number|String)} OmniGUID
 *
 * @typedef {Object} OmniAddress
 * @property {String} houseNumber
 * @property {String} street
 * @property {String} city
 * @property {String} postCode
 * @property {OmniGUID} yearsAtAddress
 *
 * Finance application customer details
 * @typedef {Object} OmniCustomerDetails
 * @property {String} email
 * @property {OmniGUID} title
 * @property {String} firstName
 * @property {String} lastName
 * @property {OmniGUID} gender
 * @property {String} dateOfBirth
 * @property {String} mobile
 * @property {OmniGUID} maritalStatus
 * @property {Number} numberOfDependants
 * @property {OmniGUID} residentialStatus
 * @property {OmniAddress} currentAddress
 * @property {OmniAddress} goodsDeliveryAddress
 * @property {OmniAddress} previousAddress1
 * @property {OmniAddress} previousAddress2
 *
 * Finance application employment details
 * @typedef {Object} OmniEmploymentDetails
 * @property {Number} grossAnnualIncome
 * @property {Number} householdAnnualIncome
 * @property {OmniGUID} employmentStatus
 * @property {OmniGUID} mainOccupation
 * @property {String} employerName
 * @property {OmniGUID} yearsEmployed
 * @property {OmniGUID} typeOfBusiness
 * @property {OmniGUID} sourceOfIncome
 * @property {OmniGUID} businessName
 *
 * Finance application bank details
 * @typedef {Object} OmniBankDetails
 * @property {String} sortCode
 * @property {String} accountNumber
 *
 * Finance marketing preferences
 * @typedef {Object} OmniMarketingPreferences
 * @property {Boolean} receiveInfoViaPost
 * @property {Boolean} receiveInfoViaSms
 * @property {Boolean} receiveInfoViaEmail
 * @property {Boolean} receiveInfoViaPhone
 * @property {Boolean} agreePersonalDetailsToBeDisclosed
 *
 * Finance application payload
 * @typedef {Object} FinanceApplicationPayload
 * @property {Object} enterprise
 * @property {String} enterprise.id
 * @property {Object} data
 * @property {Boolean} data.acceptAllTermsAndConditions
 * @property {String} data.reference
 * @property {Number} data.price
 * @property {OmniGUID} data.rate
 * @property {Number} data.deposit
 * @property {String} data.description
 * @property {String} data.ipAddress
 * @property {OmniCustomerDetails} data.customerDetails
 * @property {OmniEmploymentDetails} data.employmentDetails
 * @property {OmniBankDetails} data.bankDetails
 * @property {OmniMarketingPreferences} data.marketingPreferences
 *
 * Finance application data
 * @typedef {Object} FinanceApplicationData
 * @property {String} id
 *
 * Check Initialised data
 * @typedef {Object} CheckInitialisedData
 * @property {String} signedData
 */

export const OmniFinanceApplicationService = {
  submit: 'OmniFinanceApplicationService-submit',
  checkInitialised: withContextParam(
    'OmniFinanceApplicationService-checkInitialised',
  ),
};

const OmniFinanceApplicationServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {OmniFinanceApplicationServiceOptions} options
   */
  constructor({ baseURL }) {
    super({ baseURL, keys: OmniFinanceApplicationService });
  }

  /**
   * Submits finance application
   * @param {FinanceApplicationPayload} payload
   * @param {String} rowId
   * @returns {FinanceApplicationData} initial data
   */
  async [OmniFinanceApplicationService.submit](payload, rowId) {
    const url = ['/finance', rowId].filter(Boolean).join('/');

    const signedRequest = await super.use([
      SignedRequestService.encode,
      payload,
    ]);

    const result = await super.post(url, decamelize({ signedRequest }));

    return camelize(result);
  }

  /**
   * Checked Initialised status on aplication and returns signedData
   * @param {Context} ctx
   * @param {String} rowId
   * @param {String} enterpriseId
   * @returns {CheckInitialisedData} initialised data
   */
  async [OmniFinanceApplicationService.checkInitialised](
    ctx,
    rowId,
    enterpriseId,
  ) {
    const body = {
      rowId,
      enterprise: {
        id: enterpriseId,
      },
    };

    const signedRequest = await super.use([SignedRequestService.encode, body]);

    const { messages } = super.sse(
      `${config.OMNI_SSE_URL}/events?data=${signedRequest}`,
      { ctx },
    );

    const lastMessage = messages.at(-1);

    if (!lastMessage) return undefined;

    const { status, loanId: applicationId, reference, skipDeposit } =
      camelize(lastMessage);

    if (KANDA_APPLICATION_STATUS.indexOf(status) === -1) return undefined;

    const loanApplicationReference = reference.includes('/')
      ? reference.split('/').slice(-1)[0]
      : reference;

    // Created returned data object
    return {
      applicationId,
      enterpriseId,
      loanApplicationReference,
      skipDeposit,
    };
  }
};

export default new OmniFinanceApplicationServiceImplementation({
  baseURL: config.FINANCE_APPLICATION_API_URL,
});
