import React, { createContext } from 'react';

import PropTypes from 'prop-types';

const Context = createContext();

const ViewComponent = ({ children, ...values }) => (
  <Context.Provider value={values}>{children}</Context.Provider>
);

ViewComponent.displayName = 'ConfigWrapper-view';

ViewComponent.Context = Context;

ViewComponent.defaultProps = {
  children: null,
  linkComponent: 'a',
  destopHeader: null,
  homeLinkProps: { href: '/' },
  sidebar: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Link Component
   */
  linkComponent: PropTypes.elementType,
  /**
   * Default desktop header
   */
  destopHeader: PropTypes.elementType,
  /**
   * Home link props
   */
  homeLinkProps: PropTypes.object,
  /**
   * Default sidebar
   */
  sidebar: PropTypes.elementType,
};

export default ViewComponent;
