import clsx from 'clsx';

import { ICONS, DEFAULT_SIZE, CLASS_NAMES } from './Icon-constants';
import { getStrokeClassName } from './Icon-functions';

const ContainerComponent = ({
  children,
  icon,
  stroke,
  flip,
  width: extWidth,
  height: extHeight,
  className: extClassName,
  size,
  fill,
}) => {
  const { Icon, ...defaultProps } = ICONS[icon] || { Icon: () => null };

  const { skeletonBase, indicatorBase, ...restClassNames } = CLASS_NAMES;

  const strokeClassName =
    stroke || defaultProps.stroke
      ? `stroke-current text-${stroke || defaultProps.stroke}`
      : '';

  const rotateClassName =
    flip || defaultProps.flip ? 'transform rotate-180' : '';

  const fillClassName = fill || defaultProps.fill ? `fill-${fill}` : '';

  const sizeClass = getStrokeClassName(size);

  const svg = clsx(
    strokeClassName,
    rotateClassName,
    fillClassName,
    extClassName,
    !extClassName?.includes('icon-stroke') && sizeClass
  );

  const classNames = { ...restClassNames, svg };

  const width = extWidth || size || defaultProps.width || DEFAULT_SIZE.width;

  const height =
    extHeight || size || defaultProps.height || DEFAULT_SIZE.height;

  return children({
    Icon,
    classNames,
    width,
    height,
    style: strokeClassName ? {} : undefined,
  });
};

ContainerComponent.displayName = 'Icon-Container';

export default ContainerComponent;
