const SKIP_OPTIONS =
  '$skipPayments: Boolean!, $skipLineItems: Boolean!, $skipNotes: Boolean!, $skipCustomer: Boolean!, $skipLoanApplications: Boolean!, $skipQuote: Boolean!';

export const CREATE_JOB_MUTATION = `
mutation SendJob($input: JobInput!) {
  sendJob(input: $input) {
    id
  }
}
`;

export const ADD_NOTE_MUTATION = `
mutation AddNote($id: ID!, $input: JobNoteInput!) {
  addJobNote(id: $id, input: $input) {
    id
  }
}
`;

export const UPDATE_NOTE_MUTATION = `
mutation UpdateNote($noteId: ID!, $jobId: ID!, $input: JobNoteInput!) {
  updateJobNote(noteId: $noteId, jobId: $jobId, input: $input) {
    id
  }
}
`;

export const CREATE_DRAFT_MUTATION = `
mutation CreateJobDraft($input: JobInput!) {
  createJobDraft(input: $input) {
    id
  }
}
`;

export const CREATE_EXTERNAL_JOB_DRAFT_MUTATION = `
mutation CreateExternalJobDraft($input: ExternalJobInput!) {
  createExternalJobDraft(input: $input) {
    id
  }
}
`;

export const UPDATE_DRAFT_MUTATION = `
mutation UpdateJobDraft($updateJobDraftId: ID!, $input: JobInput!) {
  updateJobDraft(id: $updateJobDraftId, input: $input) {
    id
  }
}
`;

export const UPDATE_EXTERNAL_JOB_DRAFT_MUTATION = `
mutation UpdateExternalJobDraft($updateJobDraftId: ID!, $input: ExternalJobInput!) {
  updateExternalJobDraft(id: $updateJobDraftId, input: $input) {
    id
  }
}
`;

export const CREATE_JOB_FROM_DRAFT_MUTATION = `
mutation SendJobFromDraft($sendJobFromDraftId: ID!, $input: JobInput!) {
  sendJobFromDraft(id: $sendJobFromDraftId, input: $input) {
    id
  }
}
`;

export const CREATE_EXTERNAL_JOB_FROM_DRAFT_MUTATION = `
mutation SendExternalJobFromDraft($id: ID!, $input: ExternalJobInput!) {
  sendExternalJobFromDraft(id: $id, input: $input) {
    id
  }
}
`;

export const JOB_FRAGMENT = `
  id
  title
  description
  deposit_type
  deposit_value
  user_id
  status
  type
  payment_method
  customer_id
  total_amount
  customer @skip(if: $skipCustomer) {
    id
    first_name
    last_name
    address_line_one
    address_line_two
    city
    email
    phone_number
    postal_code
  }
  loan_applications @skip(if: $skipLoanApplications) {
    deposit
    description
    id
    price
    reference
    status
  }
  payments @skip(if: $skipPayments) {
    id
    status
    amount
    created_at
    updated_at
    deleted_at
  }
  line_items @skip(if: $skipLineItems) {
    title
    description
    quantity
    price
    vat
  }
  notes @skip(if: $skipNotes) {
    id
    content
    created_at
    updated_at
    deleted_at
  }
  quote @skip(if: $skipQuote) {
    download_url
    mime_type
  }
  created_at
  updated_at
  deleted_at
`;

export const RETRIEVE_JOB_QUERY = `
query Query($jobId: ID!, ${SKIP_OPTIONS}) {
  job(id: $jobId) {
    ${JOB_FRAGMENT}
  }
}
`;

export const RETRIEVE_JOB_QUERY_OPTIONS = {
  skipPayments: false,
  skipLineItems: false,
  skipCustomer: false,
  skipLoanApplications: false,
  skipNotes: false,
  skipQuote: false,
};

export const PUBLIC_JOB_FRAGMENT = `
  id
  title
  description
  deposit_type
  deposit_value
  status
  type
  payment_method
  customer @skip(if: $skipCustomer) {
    id
    address_line_one
    address_line_two
    city
    email
    first_name
    last_name
    phone_number
    postal_code
  }
  tradesperson @skip(if: $skipTradesperson) {
    id
    address
    address_line_two
    city
    company_name
    company_type
    company_logo
    first_name
    last_name
    phone
    postal_code
    username
    company_number
    vat_number
    account_name
    account_number
    sort_code
  }
  payments @skip(if: $skipPayments) {
    id
    status
    amount
    created_at
    updated_at
    deleted_at
  }
  line_items @skip(if: $skipLineItems) {
    title
    description
    quantity
    price
    vat
  }
  notes @skip(if: $skipNotes) {
    id
    content
    created_at
    updated_at
    deleted_at
  }
  quote @skip(if: $skipQuote) {
    download_url
    mime_type
  }
  created_at
  updated_at
  deleted_at
`;

const PUBLIC_SKIP_OPTIONS =
  '$skipPayments: Boolean!, $skipLineItems: Boolean!, $skipNotes: Boolean!, $skipCustomer: Boolean!, $skipTradesperson: Boolean!, $skipQuote: Boolean!';

export const RETRIEVE_PUBLIC_JOB_QUERY = `
query PublicJob($jobId: ID!, ${PUBLIC_SKIP_OPTIONS}) {
  publicJob(id: $jobId) {
    ${PUBLIC_JOB_FRAGMENT}
  }
}
`;

// Change this back to `false` when service is working
export const RETRIEVE_PUBLIC_JOB_QUERY_OPTIONS = {
  skipPayments: true,
  skipCustomer: false,
  skipTradesperson: false,
  skipLineItems: false,
  skipNotes: false,
  skipQuote: false,
};

export const SEARCH_JOB_QUERY = `
query SearchJob($input: SearchInput!, ${SKIP_OPTIONS}) {
  searchJob(input: $input){
    hits {
      document {
        ${JOB_FRAGMENT}
      }
      highlights {
        field
        snippet
      }
    }
    found
    search_time_ms
    page
    out_of
  }
}
`;

export const LIST_JOB_QUERY_OPTIONS = {
  query: '*',
  perPage: 10,
  page: 1,
  skipPayments: false,
  skipLineItems: false,
  skipCustomer: false,
  skipLoanApplications: false,
  skipNotes: false,
  skipQuote: false,
};

export const CHECK_USED_FREE_JOBS_QUERY = `
query Query {
  jobs {
    data {
      id
    }
  }
}
`;

export const QUERY_BY_FIELDS = ['title', 'description', 'customer_name'];
