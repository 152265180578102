import { useContext } from 'react';

import Provider from '../Provider';

import { DOWNLOAD_BUTTON_PROPS } from './PillButton-constants';

const ContainerComponent = ({ children, buttonProps }) => {
  const { downloadPdf, isLoading, fetchingLogo } = useContext(Provider.Context);

  const downloadButtonProps = {
    ...DOWNLOAD_BUTTON_PROPS,
    ...buttonProps,
  };

  return children({
    isLoading: isLoading || fetchingLogo,
    downloadPdf,
    downloadButtonProps,
  });
};

ContainerComponent.displayName = 'QuoteDownload-PillButton-Container';

export default ContainerComponent;
