import PropTypes from 'prop-types';

import JobPdfFileInput from './JobPdfFileInput-view';

import { withFieldWrapper } from '../../FieldWrapper';

const WrappedJobPdfFileInput = withFieldWrapper(JobPdfFileInput);

WrappedJobPdfFileInput.displayName = 'WrappedJobPdfFileInput';

WrappedJobPdfFileInput.defaultProps = { maxFiles: 0 };

WrappedJobPdfFileInput.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * array of MIME types that fileInput accepts
   */
  accept: PropTypes.arrayOf(PropTypes.string),
  /**
   * maximum number of files
   */
  maxFiles: PropTypes.number,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default WrappedJobPdfFileInput;
