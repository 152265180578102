import React from 'react';

import Icon from '../../../Icon';

import { CLASS_NAMES, ICON_PROPS } from '../../CompanyHeader-constants';

const ViewComponent = () => (
  <div className={CLASS_NAMES.logoContainer}>
    <Icon {...ICON_PROPS} />
  </div>
);

ViewComponent.displayName = 'CompanyHeader-PlaceholderLogo-view';

export default ViewComponent;
