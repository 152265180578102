import React from 'react';
import { pdf, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { JobService, useMutate } from '@kanda-api/library';

import Render from '../Provider/Render';
import { formatJob } from '../Provider/QuoteDownload-functions';
import { FONTS } from '../Provider/QuoteDownload-constants';

Font.register({
  family: 'Galano Grotesque',
  src: FONTS.Galano,
});

Font.register({
  family: 'Galano Grotesque Bold',
  src: FONTS.GalanoBold,
});

const blobToData = (blob) =>
  new Promise((resolve) => {
    const fileBlob = new Blob([blob], { type: 'image/png' });
    const file = new FileReader();
    file.onload = (e) => resolve(e.target.result);
    file.readAsDataURL(fileBlob);
  });

const fetchLogo = async (url) => {
  if (!url) return null;
  const response = await fetch(url);
  const blob = await response.blob();
  const logo = await blobToData(blob);
  return logo;
};

const ContainerComponent = ({ children }) => {
  const { mutate: fetchJob } = useMutate(JobService.retrievePublic);

  const handleFetch = async (jobId) =>
    fetchJob(jobId).then(
      async ({ data: fetchedPublicJob, erorr: fetchedPublicError }) => {
        if (fetchedPublicError) return;
        const job = formatJob(fetchedPublicJob);
        const fileName = job?.details?.reference
          ? `job_${job.details.reference}.pdf`
          : 'job.pdf';
        const url = job?.company?.logo;
        const logo = await fetchLogo(url);
        const blob = await pdf(
          <Render job={job} logo={logo} fetchingLogo={false} />
        ).toBlob();
        // Save the PDF
        saveAs(blob, fileName);
      }
    );

  return children({
    handleFetch,
  });
};

ContainerComponent.displayName = 'QuoteDownload-Fetch-container';

export default ContainerComponent;
