import React from 'react';
import PropTypes from 'prop-types';

import Container from './ModalLayoutCenter-container';
import Button from '../../../Button';

const ViewComponent = React.forwardRef(
  ({ children, onClose, className: extClassName, opacity }, ref) => (
    <Container className={extClassName} opacity={opacity}>
      {({ className }) => (
        <div className={className} ref={ref}>
          <div className="flex flex-1 w-full flex-col relative p-6">
            <div className="absolute top-0 right-0 p-3">
              <Button.Icon onClick={onClose} icon="close" />
            </div>
            {children}
          </div>
        </div>
      )}
    </Container>
  )
);

ViewComponent.displayName = 'ModalLayoutCenter-view';

ViewComponent.defaultProps = {
  children: null,
  onClose: undefined,
  className: undefined,
  opacity: 5,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * on close function
   */
  onClose: PropTypes.func,
  /**
   * Etends current styles
   */
  className: PropTypes.string,
  /**
   * Etends current styles
   */
  opacity: PropTypes.number,
};

export default ViewComponent;
