export const CUSTOMER_FRAGMENT = `
  id
  address_line_one
  address_line_two
  city
  email
  first_name
  last_name
  phone_number
  postal_code
  created_at
  updated_at
  deleted_at
`;

export const LIST_CUSTOMER_QUERY = `
  query {
    customers {
      ${CUSTOMER_FRAGMENT}
    }
  }
`;

export const RETRIEVE_CUSTOMER_QUERY = `
  query Customer($customerId: ID!) {
    customer(id: $customerId) {
      ${CUSTOMER_FRAGMENT}
    }
  }
`;

export const CREATE_CUSTOMER_QUERY = `
  mutation CreateCustomer($input: CustomerInput!) {
    createCustomer(input: $input) {
      ${CUSTOMER_FRAGMENT}
    }
  }
`;

export const UPDATE_CUSTOMER_QUERY = `
  mutation UpdateCustomer($updateCustomerId: ID!, $input: CustomerInput!) {
    updateCustomer(id: $updateCustomerId, input: $input) {
      ${CUSTOMER_FRAGMENT}
    }
  }
`;
