import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { getIP, getCurrentTimeStamp, DEFAULT_IP } from '@kanda-utils/library';

import { CLASS_NAMES } from './Field-constants';

import Handle from '../../../Handle';
import { HANDLE_TPYES } from '../../../Form-constants';
import { useFormTheme } from '../../../FormTheme';

const ContainerComponent = ({
  children,
  handle: handleType,
  name,
  getIpAndTime,
}) => {
  const [_ip, _setIp] = useState(DEFAULT_IP);
  const { skeletonClasses } = useFormTheme();

  const { setValue } = useFormContext();

  const handle =
    handleType === HANDLE_TPYES.SWITCH ? Handle.Switch : Handle.Checkbox;

  const skeletonClassName = clsx(skeletonClasses, CLASS_NAMES.skeleton);

  const value = useWatch({ name });

  useEffect(() => {
    if (!getIpAndTime) return;
    if (!value) {
      setValue(`${name}IP`, DEFAULT_IP);
      setValue(`${name}Time`, 0);
      return;
    }
    setValue(`${name}IP`, _ip);
    setValue(`${name}Time`, getCurrentTimeStamp());
  }, [value, getIpAndTime, name, setValue, _ip]);

  useEffect(() => {
    if (!getIpAndTime) return;
    getIP()
      .then((data) => _setIp(data.ip || DEFAULT_IP))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }, [getIpAndTime]);

  return children({ handle, skeletonClassName });
};

ContainerComponent.displayName = 'Field-container';

export default ContainerComponent;
