export const CLASS_NAMES = {
  container: 'hidden group-hover:block absolute top-0 right-0 w-64 pb-6',
  popover:
    'flex flex-1 flex-col mt-12 bg-neutral-000 border border-neutral-300 rounded-lg overflow-hidden',
};

export const LOGOUT_ITEM = {
  icon: 'logout',
  name: 'Logout',
};
