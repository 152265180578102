/*
 * Produces the opacity suffix for the modal backdrop
 *
 * @param {Int} opacity - the modal opacity percentage, e.g. 10% = 10
 */
export const opacitySuffix = (opacity) => {
  try {
    // Type check on opacity, ensure it is an int
    const intOpacity = parseInt(opacity, 10);
    // Use modulo 100 to get correct fade increment -
    if ((100 + intOpacity) % 100) return `-${(100 + intOpacity) % 100}`;
    // If intOpacity is 100, we just returned an empty string as `fade-in` goes
    // from 0 to 100
    return '';
  } catch {
    return '';
  }
};
