import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { CLASS_NAMES } from '../Cropper-constants';
import {
  CropperTopOptions,
  CropperBottomOptions,
  CropperFooter,
  CropperHeader,
} from '../components';
import Modal from '../../../../../Modal';

const ViewComponent = ({
  cropperTopOptionsProps,
  cropperButtomOptionsProps,
  onSave,
  onCancelCrop,
  cropperProps,
}) => (
  <Modal.Layout.FullScreen
    footer={<CropperFooter onSave={onSave} />}
    header={<CropperHeader onCancelCrop={onCancelCrop} />}
  >
    <CropperTopOptions {...cropperTopOptionsProps} />
    <div className={CLASS_NAMES.container}>
      <Cropper {...cropperProps} />
    </div>
    <CropperBottomOptions {...cropperButtomOptionsProps} />
  </Modal.Layout.FullScreen>
);

ViewComponent.displayName = 'Cropper-Mobile-view';

ViewComponent.propTypes = {
  /**
   * Cropper top options props
   */
  cropperTopOptionsProps: PropTypes.object.isRequired,
  /**
   * Cropper buttom options props
   */
  cropperButtomOptionsProps: PropTypes.object.isRequired,
  /**
   * On save
   */
  onSave: PropTypes.func.isRequired,
  /**
   * On cancel crop
   */
  onCancelCrop: PropTypes.func.isRequired,
  /**
   * cropper Props
   */
  cropperProps: PropTypes.object.isRequired,
};

export default ViewComponent;
