import { CLASS_NAMES, VARIANTS } from './Item-constants';

import { useClasses } from '../../../../hooks';

const ContainerComponent = ({ children, size, selected }) => {
  const classNames = useClasses(CLASS_NAMES, {
    variants: {
      size,
      selected,
    },
  });

  const { iconProps, arrowIconProps } = VARIANTS[size];

  return children({
    classNames,
    iconProps,
    arrowIconProps,
  });
};

ContainerComponent.displayName = 'PopoverMenu-Container';

export default ContainerComponent;
