import { useState, useEffect, useRef } from 'react';

import { getInitialValue, attachMediaListener } from './mediaQuery-functions';

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(getInitialValue(query));

  const queryRef = useRef();

  /**
   * Listens for media query changes to update the state
   */
  useEffect(() => {
    if (!window.matchMedia) return undefined;

    queryRef.current = window.matchMedia(query);
    setMatches(queryRef.current.matches);

    return attachMediaListener(queryRef.current, (event) =>
      setMatches(event.matches)
    );
  }, [query]);

  return matches;
};

export default useMediaQuery;
