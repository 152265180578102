import React from 'react';
import PropTypes from 'prop-types';

import Price from '../../../Price';
import Text from '../../../Text';

const ViewComponent = ({ title, amount, classNames, isLoading }) => (
  <div className={classNames.cardContainer}>
    <Text
      skeletonProps={{
        width: 80,
      }}
      isLoading={isLoading}
      text={title}
      className={classNames.title}
    />
    <Price
      amount={amount}
      poundsClassName={classNames.pounds}
      centsClassName={classNames.cents}
      wrapperClassName={classNames.priceWrapper}
      isLoading={isLoading}
    />
  </div>
);

ViewComponent.defaultProps = {
  title: '',
  amount: undefined,
  classNames: {},
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Card title
   */
  title: PropTypes.string,
  /**
   * Amount
   */
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Text
   */
  classNames: PropTypes.object,
  /**
   * Is loading
   */
  isLoading: PropTypes.bool,
};

ViewComponent.displayName = 'MoneyCard-view';

export default ViewComponent;
