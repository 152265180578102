import React from 'react';
import PropTypes from 'prop-types';

import Error from './Error';
import Warning from './Warning';
import Label from './Label';
import Container from './DefaultWrapper-container';

const ViewComponent = ({
  children,
  id,
  label,
  helperText,
  error,
  className,
  warning,
  isLoading,
  autoWidth,
  prepend,
  append,
}) => (
  <Container
    isLoading={isLoading}
    className={className}
    error={error}
    autoWidth={autoWidth}
  >
    {({ classNames, errorText }) => (
      <div id={`${id}-wrapper`} className={classNames.container}>
        {label && (
          <Label
            id={id}
            label={label}
            isLoading={isLoading}
            autoWidth={autoWidth}
            helperText={helperText}
          />
        )}

        <div className={classNames.content}>
          {prepend}
          {children}
          {append}
        </div>

        {!isLoading && warning && !errorText && <Warning warning={warning} />}
        {!isLoading && errorText && <Error error={errorText} />}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DefaultWrapper-view';

ViewComponent.defaultProps = {
  children: null,
  error: undefined,
  label: undefined,
  helperText: undefined,
  warning: undefined,
  className: undefined,
  isLoading: undefined,
  autoWidth: undefined,
  prepend: undefined,
  append: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string.isRequired,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field helper text
   */
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Auto width
   */
  autoWidth: PropTypes.bool,
  /**
   * Prepend element
   */
  prepend: PropTypes.element,
  /**
   * Append element
   */
  append: PropTypes.element,
};

export default ViewComponent;
