/**
 * This function converts a JSON object to a URI component
 *
 * @param {Object} object The object to convert to a URI encoded component
 */
export const convertJSONToURI = (object) =>
  Object.keys(object)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`,
    )
    .join('&');
