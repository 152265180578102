import PropTypes from 'prop-types';

import RadioSelect from './RadioSelect-view';
import { withFieldWrapper } from '../../FieldWrapper';

const WrappedFileInput = withFieldWrapper(RadioSelect);

WrappedFileInput.displayName = 'WrappedRadioSelect';

WrappedFileInput.defaultProps = {};

WrappedFileInput.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
};

export default WrappedFileInput;
