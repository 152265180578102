import React from 'react';
import PropTypes from 'prop-types';

import Container from './Card-container';
import { Title, Option, Content, Footer } from './components';

const ViewComponent = ({
  children,
  option,
  title,
  footer,
  footerOptions,
  className,
  padding,
  noContent,
  isLoading,
  showLoadingSkeleton,
  variant,
  ...restProps
}) => (
  <Container
    className={className}
    padding={padding}
    footer={footer}
    title={title}
    noContent={noContent}
    footerOptions={footerOptions}
    isLoading={isLoading}
    showLoadingSkeleton={showLoadingSkeleton}
    variant={variant}
  >
    {({ classNames, showFooter }) => (
      <div className={classNames.container} {...restProps}>
        <div className={classNames.header}>
          <Title
            title={title}
            isLoading={isLoading}
            skeleton={showLoadingSkeleton?.title}
          />
          <Option
            option={option}
            isLoading={isLoading}
            skeleton={showLoadingSkeleton?.option}
          />
        </div>
        <Content
          content={children}
          isLoading={isLoading}
          skeleton={showLoadingSkeleton?.content}
        />
        {showFooter && (
          <Footer
            footer={footer}
            footerOptions={footerOptions}
            isLoading={isLoading}
            footerSkeleton={showLoadingSkeleton?.footer}
            footerOptionsSkeleton={showLoadingSkeleton?.footerOptions}
          />
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Card-view';

ViewComponent.defaultProps = {
  children: null,
  className: undefined,
  title: undefined,
  option: undefined,
  footer: undefined,
  footerOptions: [],
  noContent: undefined,
  isLoading: false,
  padding: 'py-5 px-4',
  variant: 'default',
  showLoadingSkeleton: {
    title: false,
    option: false,
    content: false,
    footer: false,
    footerOptions: false,
  },
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Title of card
   */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * padding
   */
  padding: PropTypes.string,
  /**
   * Top right option
   */
  option: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Footer
   */
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /**
   * Footer options
   */
  footerOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  ),
  /**
   * no Content
   */
  noContent: PropTypes.bool,
  /**
   * Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Loading state
   */
  showLoadingSkeleton: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ]),
    option: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ]),
    content: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ]),
    footer: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ]),
    footerOptions: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    ]),
  }),
  /**
   * Variant
   */
  variant: PropTypes.oneOf(['default', 'simple']),
};

export default ViewComponent;
