import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

/**
 * Hook that extends react hook form with default resolver Yup
 * @param {Object} - decomposed into:
 * @param {Array} schema - array of step values
 * @param {Object} options - react hook options
 */
const useYupForm = ({ schema, ...options }) =>
  useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    ...options,
  });

export default useYupForm;
