import {
  DEFAULT_ICON_PROPS,
  DEFAULT_CONTAINER_PROPS,
  DEFAULT_SPAN_PROPS,
} from './Message-constants';

const ContainerComponent = ({ children, icon, container, span }) => {
  const iconProps = {
    ...DEFAULT_ICON_PROPS,
    ...icon,
  };

  const containerProps = {
    ...DEFAULT_CONTAINER_PROPS,
    ...container,
  };
  const spanProps = {
    ...DEFAULT_SPAN_PROPS,
    ...span,
  };

  return children({
    iconProps,
    containerProps,
    spanProps,
  });
};

ContainerComponent.displayName = 'Toast-Message-container';

export default ContainerComponent;
