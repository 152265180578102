import React from 'react';
import PropTypes from 'prop-types';

import FieldContainer from '../../FieldContainer';
import PhoneNumberInput from '../../UncontrolledField/PhoneNumberInput';

const ViewComponent = ({
  phoneNumberName,
  countryCodeName,
  phoneNumberProps,
  countryCodeProps,
  ...restProps
}) => (
  <FieldContainer name={phoneNumberName} {...restProps} passRegister>
    {({ register, ...fieldProps }) => (
      <React.Fragment>
        <PhoneNumberInput
          phoneNumberName={phoneNumberName}
          countryCodeName={countryCodeName}
          phoneNumberProps={{
            ...register(phoneNumberName, restProps),
            ...phoneNumberProps,
          }}
          countryCodeProps={{
            ...register(countryCodeName, restProps),
            ...countryCodeProps,
          }}
          {...fieldProps}
        />
      </React.Fragment>
    )}
  </FieldContainer>
);

ViewComponent.displayName = 'PhoneNumberInput-view';

ViewComponent.defaultProps = {
  phoneNumberName: 'phoneNumber',
  countryCodeName: 'countryCode',
  label: undefined,
  warning: undefined,
  phoneNumberProps: {},
  countryCodeProps: {},
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  phoneNumberName: PropTypes.string,
  /**
   * Name of the input required for form to work
   */
  countryCodeName: PropTypes.string,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  phoneNumberProps: PropTypes.any,
  /**
   * Other props that are passed to wrapper component
   */
  countryCodeProps: PropTypes.any,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
