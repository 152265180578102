import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';
import { useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';

import {
  CLASS_NAMES,
  SCORE_LABELS,
  SCORE_COLORS,
  PASSWORD_REGEX,
} from './PasswordStrengthIndicator-constants';
import useDictionaries from './PasswordStrengthIndicator-useDictionaries';

import { useClasses } from '../../hooks';

const ContainerComponent = ({
  children,
  className,
  userInputs,
  passwordFieldName,
  userInputFieldNames,
}) => {
  const { isLoading, dictionaries } = useDictionaries();

  const [password, ...userInputValues] = useWatch({
    name: [passwordFieldName, ...userInputFieldNames],
  });

  /**
   * Update password options
   */
  useEffect(() => {
    const options = {
      dictionary: {
        ...dictionaries,
        userInputs: [...userInputs, ...userInputValues],
      },
    };

    ZxcvbnOptions.setOptions(options);
  }, [userInputValues, userInputs, dictionaries]);

  const { score: strengthScore } = useMemo(
    () => zxcvbn(password || ''),
    [password]
  );

  const hasPasswordScore = password ? 1 : 0;

  // check if password passes the regex otherwise set score - 1
  const normalisedScore = PASSWORD_REGEX.test(password)
    ? strengthScore
    : Math.max(strengthScore - 1, 0);

  const score = hasPasswordScore + normalisedScore;

  const label = SCORE_LABELS[score];
  const color = SCORE_COLORS[score];

  const classNames = useClasses(CLASS_NAMES, {
    container: ['.baseContainer', className],
    label: ['.baseLabel', `text-${color}`],
  });

  return children({
    classNames,
    score,
    label,
    isLoading,
  });
};

ContainerComponent.displayName = 'PasswordStrengthIndicator-Container';

export default ContainerComponent;
