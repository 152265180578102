import PropTypes from 'prop-types';

import { withFieldWrapper } from '../../FieldWrapper';
import PhoneNumberInput from './PhoneNumberInput-view';

const WrappedPhoneNumberInput = withFieldWrapper(PhoneNumberInput);

WrappedPhoneNumberInput.defaultProps = {};

WrappedPhoneNumberInput.displayName = 'WrappedUncontrolledPhoneNumberInput';

WrappedPhoneNumberInput.propTypes = {
  /**
   * Field name for phone number field
   */
  phoneNumberName: PropTypes.string,
  /**
   * Field name for country code field
   */
  countryCodeName: PropTypes.string,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  phoneNumberProps: PropTypes.any,
  /**
   * Other props that are passed to wrapper component
   */
  countryCodeProps: PropTypes.any,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
};

export default WrappedPhoneNumberInput;
