import config from '../config';
import { Service } from '../lib';
import { camelize } from '../utils';

/**
 * Address Service
 * @typedef {Object} AddressServiceOptions
 * @property {string} baseURL base url
 * @property {string} apiKey api key
 */
export class AddressServiceImplementation extends Service {
  /**
   * Constructor
   * @param {AddressServiceOptions} options
   */
  constructor({ baseURL, apiKey }) {
    super({ baseURL });

    this.apiKey = apiKey;

    this.key = {
      addresses: 'AddressService/addresses',
    };

    this.mapKeys({
      [this.key.addresses]: this.addresses,
    });
  }

  /**
   * Loads users profile
   * @param {string} postcode post code
   * @param {string} houseNumber house number
   */
  async addresses(postcode, houseNumber) {
    const formattedPostcode = postcode.toLowerCase().replace(/\s/g, '');

    const params = [formattedPostcode, houseNumber].filter(Boolean).join('/');

    const res = await super.get(
      `/find/${params}?api-key=${this.apiKey}&expand=true&sort=true`,
    );

    return camelize(res);
  }
}

export const AddressService = new AddressServiceImplementation({
  baseURL: config.GETADDRESSES_API_URL,
  apiKey: config.GETADDRESSES_API_KEY,
});
