import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { BASE_CLASSNAME } from './SlideUp-constants';

const ContainerComponent = ({
  children,
  show,
  className: extClassName,
  onEnd,
}) => {
  const [shouldRender, setShouldRender] = useState(show);

  const onAnimationEnd = () => {
    if (show) return;
    setShouldRender(false);
    if (onEnd) onEnd();
  };

  useEffect(() => {
    if (show) setShouldRender(true);
  }, [show]);

  const className = clsx(
    BASE_CLASSNAME,
    extClassName,
    show ? `animate-slide-up-in` : `animate-slide-up-out`
  );

  return children({
    shouldRender,
    onAnimationEnd,
    className,
  });
};

ContainerComponent.displayName = 'SlideUp-Container';

export default ContainerComponent;
