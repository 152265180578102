import React from 'react';
import PropTypes from 'prop-types';

import Container from './Provider-container';

import { DEFAULT_THEME } from '../../FormTheme-constants';
import Context from '../context';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {(values) => <Context.Provider value={values}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.defaultProps = { children: null, ...DEFAULT_THEME };

ViewComponent.displayName = 'FormThemeProvider-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Element type of wrapper component that would wrap all the fields within context
   */
  fieldWrapper: PropTypes.elementType,
};

export default ViewComponent;
