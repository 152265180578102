import React from 'react';
import PropTypes from 'prop-types';

import { SEARCH_PLACEHOLDER } from '../../../SearchResults-constants';
import Header from '../../../../../../../../Header';
import Button from '../../../../../../../../Button';

const ViewComponent = ({ handleClose, handleSearch }) => (
  <Header.Search
    autoFocus="autoFocus"
    onChange={handleSearch}
    placeholder={SEARCH_PLACEHOLDER}
    options={[<Button.Icon key="close" icon="close" onClick={handleClose} />]}
  />
);

ViewComponent.displayName = 'CompanyLookup-SearchModal-Header-view';

ViewComponent.propTypes = {
  /**
   * Close modal callback
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * On search callback
   */
  handleSearch: PropTypes.func.isRequired,
};

export default ViewComponent;
