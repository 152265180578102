/* eslint import/no-cycle: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import Container from './Provider-container';

import Context from '../context';

const ViewComponent = ({ children, ...restProps }) => (
  <Container {...restProps}>
    {(values) => <Context.Provider value={values}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
  isLoading: undefined,
  item: undefined,
  totalPriceProps: {},
};

ViewComponent.displayName = 'LineItem-Context-Provider-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Name of field
   */
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    vat: PropTypes.string,
  }),
  /**
   * Is loading
   */
  isLoading: PropTypes.bool,
  /**
   * Total price props
   */
  totalPriceProps: PropTypes.object,
};

export default ViewComponent;
