import { useMemo } from 'react';

/**
 * Returns fields names
 * @param {String} name
 * @returns {Object} fields names
 */
const useFields = (name) =>
  useMemo(
    () => ({
      city: `${name}.city`,
      addressLineOne: `${name}.addressLineOne`,
      addressLineTwo: `${name}.addressLineTwo`,
      houseNumber: `${name}.houseNumber`,
      postalCode: `${name}.postalCode`,
      addressDetails: `${name}.addressDetails`,
      selected: `${name}.selected`,
    }),
    [name]
  );

export default useFields;
