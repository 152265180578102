import clsx from 'clsx';

import { NAVIGATON_LINK_CLASS_NAMES } from './NavigationLink-constants';

const ContainerComponent = ({
  children,
  className: extClassName,
  badgeColor,
}) => {
  const { containerBase, badgeBase, ...classNames } =
    NAVIGATON_LINK_CLASS_NAMES;

  const container = clsx(containerBase, extClassName);
  const badge = badgeColor && clsx(badgeBase, `bg-${badgeColor}`);

  return children({
    classNames: {
      ...classNames,
      container,
      badge,
    },
  });
};

ContainerComponent.displayName = 'NavigationLink-Container';

export default ContainerComponent;
