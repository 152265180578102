import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useColumnOrder,
  useResizeColumns,
  useFlexLayout,
} from 'react-table';

import { CLASS_NAMES } from './Table-constants';
import { generatePlaceholderData } from './Table-functions';
import { useClasses } from '../../hooks';
import useActions from './Table-useActions';

const ContainerComponent = ({
  children,
  data: dataInput,
  columns,
  isLoading,
  isValidating,
  defaultColumn: defaultColumnInput,
  pageIndex,
  totalPages,
  setPage,
  hoverPopover,
  onAction,
}) => {
  const defaultColumn = useMemo(() => {
    if (defaultColumnInput) return defaultColumnInput;
    const width = Math.ceil(1024 / columns.length);
    const maxWidth = width + 100;
    return {
      minWidth: 80,
      width,
      maxWidth,
    };
  }, [columns, defaultColumnInput]);

  const data = useMemo(() => {
    if (isLoading) return generatePlaceholderData(columns);

    if (isValidating)
      return [{ reference: 'skeleton', isValidating: true }, ...dataInput];

    return dataInput;
  }, [isLoading, columns, isValidating, dataInput]);

  const {
    allColumns,
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    { columns, data, defaultColumn },
    useColumnOrder,
    useResizeColumns,
    useFlexLayout
  );

  const tableProps = getTableProps();

  const paginationProps = {
    pageCount: totalPages,
    pageIndex,
    setPage,
  };

  const classNames = useClasses(CLASS_NAMES, {
    table: ['.tableBase', isLoading ? 'overflow-x-hidden' : 'overflow-x-auto'],
  });

  const handleAction = useActions({
    onAction,
    setColumnOrder,
    allColumns,
    setHiddenColumns,
  });

  return children({
    tableProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    showPagination: totalPages > 1 && !isLoading,
    paginationProps,
    isLoading: isLoading || false,
    hoverPopover,
    classNames,
    handleAction,
  });
};

ContainerComponent.defaultProps = {
  defaultColumn: null,
  isLoading: false,
};

ContainerComponent.propTypes = {
  /**
   * Data for table
   */
  data: PropTypes.array.isRequired,
  /**
   * Columsn for table
   */
  columns: PropTypes.array.isRequired,
  /**
   * Columsn for table
   */
  defaultColumn: PropTypes.object,
  /**
   * Columsn for table
   */
  isLoading: PropTypes.bool,
};

ContainerComponent.displayName = 'Table-Container';

export default ContainerComponent;
