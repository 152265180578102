import React from 'react';

import Container from './LineItems-container';
import { LineItem } from './components';

const ViewComponent = () => (
  <Container>
    {({ lineItems }) =>
      lineItems.map((lineItem) => (
        <LineItem key={lineItem.title} lineItem={lineItem} />
      ))
    }
  </Container>
);

ViewComponent.displayName = 'Render-LineItems-view';

export default ViewComponent;
