import Cookies from 'universal-cookie';
import { addDays } from '@kanda-utils/library';

import { redirectTo } from '../utils';
import { AuthService } from '../lib';
import config from '../config';

export const KandaEnterpriseAuthServiceImplementation = class extends AuthService {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.logout = this.logout.bind(this);
    this.cookies = new Cookies();
  }

  /**
   * Returns current tooken from cookes
   */
  async token() {
    return this.cookies.get(config.ENTERPRISE_COOKIE_NAME);
  }

  /**
   * Set current token
   * @param {string} token user token
   * @param {boolean} persist persistent login state, default false
   */
  async setToken(token, persist = false) {
    const expiry = persist
      ? addDays(new Date(), config.COOKIE_PERSIST_TIME)
      : undefined;

    const options = {
      ...config.COOKIE_OPTIONS,
      ...(expiry && { expires: expiry }),
    };

    return this.cookies.set(config.ENTERPRISE_COOKIE_NAME, token, options);
  }

  /**
   * Calls logout on auth error
   */
  async onAuthError() {
    await this.logout(true);
  }

  /**
   * Redirect to logout page when logout method is called
   */
  async logout() {
    await super.logout();

    this.cookies.remove(config.ENTERPRISE_COOKIE_NAME, config.COOKIE_OPTIONS);

    redirectTo(config.LOGIN_URL);
  }
};

export const KandaEnterpriseAuthService =
  new KandaEnterpriseAuthServiceImplementation();
