import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../components/Select';
import Modal from '../../../../../../Modal';

const ViewComponent = ({ modalId, ...restProps }) => (
  <Modal.Container id={modalId}>
    {({ handleClose, ref }) => (
      <Modal.Layout.SlideUp onClose={handleClose} ref={ref}>
        <Select handleClose={handleClose} variant="text-only" {...restProps} />
      </Modal.Layout.SlideUp>
    )}
  </Modal.Container>
);

ViewComponent.displayName = 'SelectionModal-Modal-view';

ViewComponent.defaultProps = {
  options: [],
  multiple: undefined,
};

ViewComponent.propTypes = {
  /**
   * ID for the selection modal
   */
  modalId: PropTypes.string.isRequired,
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default ViewComponent;
