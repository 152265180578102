export const CLASS_NAMES = {
  container: 'flex flex-1 flex-row items-center bg-neutral-000 pr-20 min-h-28',
  defaultAvatar:
    'w-12 h-12 my-auto lavender-200 rounded-full flex justify-center items-center',
  loader: 'w-12 h-12 my-auto rounded-full bg-neutral-100',
  companyProfilePicture: 'w-12 h-12 my-auto rounded-full object-contain',
  infoContainer: 'flex flex-1 flex-col ml-4 justify-center my-auto',
  companyName: 'text-style-f-em text-neutral-900',
  plan: 'text-style-g text-neutral-600',
};
