import React from 'react';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';

import { CLASS_NAMES, SAVE_BUTTON } from './CropperFooter-constants';

import Button from '../../../../../../Button';

const ViewComponent = ({ onSave }) => (
  <div className={CLASS_NAMES.footer}>
    <Button.Text onClick={onSave}>{SAVE_BUTTON}</Button.Text>
  </div>
);

ViewComponent.displayName = 'CropperFooter-view';

ViewComponent.propTypes = {
  /**
   * OnSave callback
   */
  onSave: PropTypes.func.isRequired,
};

export default ViewComponent;
