import { useState, useEffect, useRef } from 'react';

import {
  INTERCOM_ENVIRONMENT_KEY,
  INTERCOM_LAUNCHER_SELECTOR,
} from '../../config';

/**
 * Hook for using intercom Javascript API Methods.
 *
 * Docs:
 * - Methods:
 *   https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 * - Attributes & Objects:
 *   https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects
 */
const useIntercom = () => {
  // State to hold intercom instance presence state
  const [intercom, _setIntercom] = useState(false);

  // Reference to track the timeout
  const timerRef = useRef(null);

  /**
   * Function calls the intercom boot method
   */
  const boot = () => {
    // If intercom has been booted, return
    if (intercom) return;
    // Clear timeout
    clearTimeout(timerRef.current);
    // If intercom isn't available, enter logic
    if (!window.Intercom) {
      // Try boot again after 200 milliseconds
      timerRef.current = setTimeout(() => boot(), 200);
      return;
    }
    // Intercom is available and will be booted, so set state to true
    _setIntercom(true);
    // boot intercom
    window.Intercom('boot', {
      app_id: INTERCOM_ENVIRONMENT_KEY,
      custom_launcher_selector: INTERCOM_LAUNCHER_SELECTOR,
      hide_default_launcher: true,
    });
  };

  /**
   * Function calls the intercom shutdown method
   */
  const shutdown = () => {
    // If intercom isn't available, return
    if (!intercom) return;
    // Run shutdown method
    window.Intercom('shutdown');
  };

  /**
   * Function calls the intercom update method
   * @param {Object} - Settings/Data (intercomSettings) to update intercom with,
   * allowed attributes contained in Attributes & Objects docs link above
   */
  const update = (settings) => {
    // If intercom isn't available, return
    if (!intercom) return;
    // Check the settings passed is an object - if so, run the update method
    if (
      typeof settings === 'object' &&
      settings !== null &&
      !Array.isArray(settings)
    ) {
      window.Intercom('update', settings);
    }
  };

  const showNewMessage = (message) => {
    if (!intercom || !typeof message === 'string') return;
    window.Intercom('showNewMessage', message);
  };

  // Effect gets Intercom instance from the window on render
  useEffect(() => {
    if (window.Intercom) _setIntercom(true);
  }, []);

  return {
    intercom,
    boot,
    shutdown,
    update,
    showNewMessage,
  };
};

export default useIntercom;
