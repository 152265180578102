import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { CLASS_NAMES } from '../Cropper-constants';
import {
  CropperTopOptions,
  CropperBottomOptions,
  CropperFooter,
} from '../components';
import Modal from '../../../../../Modal';

const ViewComponent = ({
  cropperTopOptionsProps,
  cropperButtomOptionsProps,
  onSave,
  onCancelCrop,
  cropperProps,
}) => (
  <Modal.Layout.Center className="w-5/6 h-5/6" onClose={onCancelCrop}>
    <div className="flex flex-1 flex-col h-full mb-6">
      <CropperTopOptions {...cropperTopOptionsProps} />
      <div className={CLASS_NAMES.container}>
        <Cropper {...cropperProps} />
      </div>
      <CropperBottomOptions {...cropperButtomOptionsProps} />
    </div>
    <div className="flex w-full">
      <CropperFooter onSave={onSave} />
    </div>
  </Modal.Layout.Center>
);

ViewComponent.displayName = 'Cropper-Desktop-view';

ViewComponent.propTypes = {
  /**
   * Cropper top options props
   */
  cropperTopOptionsProps: PropTypes.object.isRequired,
  /**
   * Cropper buttom options props
   */
  cropperButtomOptionsProps: PropTypes.object.isRequired,
  /**
   * On save
   */
  onSave: PropTypes.func.isRequired,
  /**
   * On cancel crop
   */
  onCancelCrop: PropTypes.func.isRequired,
  /**
   * cropper Props
   */
  cropperProps: PropTypes.object.isRequired,
};

export default ViewComponent;
