import { useMemo } from 'react';
import { DEFAULT_COMPONENTS } from './RichText-constants';
import { createChunks, isKey, unescape } from './RichText-functions';

const ContainerComponent = ({ children, text, components }) => {
  const items = useMemo(() => {
    const mergedComponents = { ...DEFAULT_COMPONENTS, ...components };

    return createChunks(text).map((chunk) => {
      if (isKey(chunk)) return mergedComponents[unescape(chunk)];

      return mergedComponents.default(chunk);
    });
  }, [components, text]);

  return children({
    items,
  });
};

ContainerComponent.displayName = 'RichText-container';

export default ContainerComponent;
