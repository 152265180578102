const ContainerComponent = ({ children, row }) => {
  const popoverProps = {
    right: true,
    above: row.index >= 6,
  };

  return children({
    popoverProps,
  });
};

ContainerComponent.displayName = 'RowPopoverButton-container';

export default ContainerComponent;
