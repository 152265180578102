export const SLOW_ANIMATION = 'duration-3000 transition-all ease';

export const FAST_ANIMATION_SPEED = 300;

export const INITIAL_DELAY = 50;

export const FAST_ANIMATION = 'duration-300 transition-all ease';

export const WIDTHS = {
  START: '0%',
  LOADING: '75%',
  COMPLETE: '100%',
};

export const CLASS_NAMES = {
  overlay: 'fixed top-0 bottom-0 right-0 left-0 z-40',
  container: 'fixed bottom-0 right-0 left-0 z-50',
  baseBar: 'bg-gradient-to-r from-blue-200 to-turquoise-300 h-2',
};

export const DEBOUNCE_INTERVAL = 100;
