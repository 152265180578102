import { useCallback } from 'react';

import useHelpcrunch from '../HelpCrunch/useHelpcrunch';

const ContainerComponent = ({ children }) => {
  const { openChat } = useHelpcrunch();

  const onClick = useCallback(() => openChat(), [openChat]);

  return children({ onClick });
};

ContainerComponent.displayName = 'HelpButton-Container';

export default ContainerComponent;
