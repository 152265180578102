import Input from './Input';
import PasswordInput from './PasswordInput';
import Select from './Select';
import NumberFormatInput from './NumberFormatInput';
import PriceInput from './PriceInput';
import SortCodeInput from './SortCodeInput';
import FileInput from './FileInput';
import BooleanInput from './BooleanInput';
import RadioSelect from './RadioSelect';
import DatePicker from './DatePicker';
import TextArea from './TextArea';
import AutoSizeInput from './AutoSizeInput';
import AutoSizeNumberFormatInput from './AutoSizeNumberFormatInput';
import AutoSizePriceInput from './AutoSizePriceInput';
import AutoSizeTextArea from './AutoSizeTextArea';
import SelectionModal from './SelectionModal';
import Address from './Address';
import CompanyLookup from './CompanyLookup';
import PhoneNumberInput from './PhoneNumberInput';
import DropDown from './DropDown';
import BuildFinancePlan from './BuildFinancePlan';
import JobPdfFileInput from './JobPdfFileInput';

const Field = {
  Input,
  PasswordInput,
  Select,
  NumberFormatInput,
  PriceInput,
  FileInput,
  BooleanInput,
  RadioSelect,
  DatePicker,
  TextArea,
  AutoSizeInput,
  AutoSizeNumberFormatInput,
  AutoSizePriceInput,
  AutoSizeTextArea,
  SelectionModal,
  SortCodeInput,
  Address,
  CompanyLookup,
  PhoneNumberInput,
  DropDown,
  BuildFinancePlan,
  JobPdfFileInput,
};

export default Field;
