import { StyleSheet } from '@react-pdf/renderer';

export const STYLES = StyleSheet.create({
  page: {
    padding: 80,
    backgroundColor: '#FFFFFF',
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  headerHalfColumn: {
    width: 320,
    flexDirection: 'row',
  },
  logoContainer: {
    width: 150,
    height: 150,
    maxWidth: 150,
    maxHeight: 150,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    maxWidth: 150,
    maxHeight: 150,
  },
  headerDetailHeading: {
    width: 88,
    maxWidth: 88,
    minWidth: 88,
    marginRight: 16,
  },
  headerDetailColumn: {
    width: 216,
    maxWidth: 216,
    minWidth: 216,
  },
  detailsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 48,
  },
  customerDetailHeading: {
    width: 64,
    maxWidth: 64,
    minWidth: 64,
  },
  customerDetailColumn: {
    width: 256,
    maxWidth: 256,
    minWidth: 256,
  },
  lineItem: {
    flexDirection: 'row',
    paddingVertical: 20,
    borderBottom: 1,
    borderColor: '#E0E7F0',
    borderStyle: 'solid',
  },
  lineDetailSmall: {
    marginTop: 2,
    marginBottom: 'auto',
  },
  quantity: {
    width: 40,
    minWidth: 40,
    marginRight: 16,
  },
  title: {
    width: 346,
    minWidth: 346,
    maxWidth: 346,
    marginRight: 16,
  },
  price: {
    width: 80,
    minWidth: 80,
    marginRight: 16,
  },
  vat: {
    width: 50,
    minWidth: 50,
    marginRight: 16,
  },
  total: {
    width: 100,
    minWidth: 100,
    textAlign: 'right',
  },
  totalsRow: {
    marginTop: 32,
    flexDirection: 'row',
    marginLeft: 'auto',
  },
  mt1: {
    marginTop: 4,
  },
  mb4: {
    marginBottom: 16,
  },
  mr12: {
    marginRight: 48,
  },
  alightRight: {
    marginLeft: 'auto',
  },
  'text-11-20-em-up': {
    fontSize: 11,
    lineHeight: 1.5,
    fontFamily: 'Galano Grotesque Bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 3,
  },
  'text-12-18': {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: 'Galano Grotesque',
  },
  'text-12-18-em': {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: 'Galano Grotesque Bold',
  },
  'text-16-20-em': {
    fontSize: 16,
    lineHeight: 1.25,
    fontFamily: 'Galano Grotesque Bold',
  },
  'text-14-22-em': {
    fontSize: 14,
    lineHeight: 1.57,
    fontFamily: 'Galano Grotesque Bold',
  },
  'text-neutral-500': {
    color: '#9CADC4',
  },
  'text-neutral-600': {
    color: '#6C819D',
  },
  'text-neutral-700': {
    color: '#475A76',
  },
  'text-neutral-900': {
    color: '#0D1B2E',
  },
});

export const SIZE = [840, 1190];

export const CREATOR = 'Kanda Products & Services Ltd.';
