import { CLASS_NAMES } from './RadioSelect-constants';

const ContainerComponent = ({ children, inline }) => {
  const classNames = inline ? CLASS_NAMES.inline : CLASS_NAMES.multiline;

  return children({
    classNames,
  });
};

ContainerComponent.displayName = 'RadioSelect-container';

export default ContainerComponent;
