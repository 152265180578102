import { CLASS_NAMES } from './DefaultWrapper-constants';

import useFormTheme from '../../FormTheme-useFormTheme';
import { useClasses } from '../../../../../hooks';

const ContainerComponent = ({
  children,
  error,
  isLoading,
  className,
  autoWidth,
}) => {
  const { wrapperClasses } = useFormTheme();

  const baseClassess = { ...CLASS_NAMES, ...wrapperClasses };

  const width = autoWidth ? 'w-auto' : 'w-full';

  const classNames = useClasses(baseClassess, {
    container: [
      '.baseContainer',
      'field-wrapper',
      error && 'field-error',
      isLoading && 'field-loading',
      className,
      width,
    ],
    content: ['.baseContent', width],
  });

  const errorText = error?.message || error;

  return children({
    classNames,
    errorText,
  });
};

ContainerComponent.displayName = 'DefaultWrapper-Container';

export default ContainerComponent;
