import React from 'react';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';

import {
  RANGE_PROPS,
  CLASS_NAMES,
  ICONS,
  FIT_LABEL,
  RESET_LABEL,
} from './CropperBottomOptions-constants';

import RangeSlider from '../../../../../../RangeSlider';
import Icon from '../../../../../../Icon';
import Button from '../../../../../../Button';

const ViewComponent = ({ zoomRange, handleZoom, handleFit, handleReset }) => (
  <div className={CLASS_NAMES.container}>
    <div className={CLASS_NAMES.left}>
      <Button.Link onClick={handleFit}>{FIT_LABEL}</Button.Link>
    </div>
    <div className={CLASS_NAMES.center}>
      <Icon {...ICONS.minus} />
      <RangeSlider {...RANGE_PROPS} value={zoomRange} onChange={handleZoom} />
      <Icon {...ICONS.plus} />
    </div>
    <div className={CLASS_NAMES.right}>
      <Button.Link onClick={handleReset}>{RESET_LABEL}</Button.Link>
    </div>
  </div>
);

ViewComponent.displayName = 'CropperBottomOptions-view';

ViewComponent.propTypes = {
  /**
   * OnCrop callback
   */
  zoomRange: PropTypes.number.isRequired,
  /**
   * handleZoom callback
   */
  handleZoom: PropTypes.func.isRequired,
  /**
   * handleFit callback
   */
  handleFit: PropTypes.func.isRequired,
  /**
   * handleFit callback
   */
  handleReset: PropTypes.func.isRequired,
};

export default ViewComponent;
