import React from 'react';
import PropTypes from 'prop-types';

import Container from './PaginationNavButton-container';

import Icon from '../../Icon';

const ViewComponent = ({ onClick, disabled, ...props }) => (
  <Container disabled={disabled} {...props}>
    {({ className, iconProps }) => (
      <button className={className} onClick={onClick} disabled={disabled}>
        <Icon {...iconProps} />
      </button>
    )}
  </Container>
);

ViewComponent.displayName = 'PaginationNavButton-view';

ViewComponent.defaultProps = {
  next: true,
};

ViewComponent.propTypes = {
  /**
   * Active state of button
   */
  next: PropTypes.bool,
  /**
   * Disabeld state of button
   */
  disabled: PropTypes.bool.isRequired,
  /**
   * onClick function for button
   */
  onClick: PropTypes.func.isRequired,
};

export default ViewComponent;
