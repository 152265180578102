import {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
  useRef,
} from 'react';

import useSteps from './MultiStepForm-useSteps';

const ContainerComponent = ({
  children,
  steps,
  onSubmit,
  initialData,
  initialStepIndex,
  initialStepSafeIndex,
  addStepsToUrl,
}) => {
  /**
   * keeps the data from all forms
   */
  const initialDataRef = useRef(initialData);
  const [data, setData] = useState(initialData);
  const { nextStep, ...stepProps } = useSteps({
    steps,
    initialStepIndex,
    initialStepSafeIndex,
    addStepsToUrl,
  });
  const [submitting, setSubmitting] = useState(false);

  /**
   * Continues to next step and updates data state
   * @param {Object} newData - the new data passed from current form:
   */
  const handleContinue = useCallback(
    (newData) => {
      setData(newData);
      nextStep();
    },
    [nextStep, setData]
  );

  /**
   * Sets subbmitting true in order to call onSubmit on next render
   */
  const handleSubmit = useCallback(() => {
    setSubmitting(true);
  }, []);

  /**
   * triggers on submit on next render in case setData and handleSubmit are called together
   * in order to have te latest data
   */
  useLayoutEffect(() => {
    if (submitting) {
      onSubmit(data);
    }
    setSubmitting(false);
  }, [data, submitting, onSubmit]);

  /**
   * triggers when initial data is changed
   */
  useEffect(() => {
    if (
      JSON.stringify(initialDataRef.current) !== JSON.stringify(initialData)
    ) {
      setData(initialData);
      initialDataRef.current = initialData;
    }
  }, [initialData]);

  return children({
    ...stepProps,
    data,
    steps,
    setData,
    handleSubmit,
    handleContinue,
    nextStep,
  });
};

ContainerComponent.displayName = 'MultiStepForm-container';

export default ContainerComponent;
