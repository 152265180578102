import { calculateJobTotal, formatDate } from '@kanda-utils/library';

/**
 * Formats customer name
 *
 * @param {Object} customer - tradesperson object from kanda job object
 */
const formatCustomerName = (customer) => {
  const firstName = customer?.firstName || '';
  const lastName = customer?.lastName || '';
  if (!firstName) return '';
  return `${firstName} ${lastName}`;
};

/**
 * Extracts company info from kanda job object
 *
 * @param {Object} tradesperson - tradesperson object from kanda job object
 */
const formatCompanyName = (tradesperson) => {
  if (tradesperson?.companyName) return tradesperson.companyName;
  const firstName = tradesperson?.firstName || '';
  const lastName = tradesperson?.lastName || '';
  if (!firstName) return '';
  return `${firstName} ${lastName}`;
};

/**
 * Extracts info for PDF download from kanda job object
 *
 * @param {Object} job - Kanda job object
 */
export const formatJob = (job) => {
  if (!job) return null;
  return {
    company: {
      logo: job?.tradesperson?.companyLogo || null,
      name: formatCompanyName(job?.tradesperson),
      addressLineOne: job?.tradesperson?.address,
      addressLineTwo: job?.tradesperson?.addressLineTwo,
      postalCode: job?.tradesperson?.postalCode,
      email: job?.tradesperson?.username,
      phone: job?.tradesperson?.phone,
      vatNumber: job?.tradesperson?.vatNumber,
      accountName: job?.tradesperson?.accountName,
      accountNumber: job?.tradesperson?.accountNumber,
      sortCode: job?.tradesperson?.sortCode,
    },
    customer: {
      ...job?.customer,
      name: formatCustomerName(job?.customer),
    },
    details: {
      reference: job?.id,
      date: formatDate(new Date(job?.createdAt)),
    },
    title: job?.title,
    lineItems: job?.lineItems,
    totals: calculateJobTotal(job?.lineItems),
    notes: job?.notes,
  };
};

// TODO: move this fetching to API service
/**
 * Fetches the logo from the google API, and truns it into a data URL
 *
 * @param {String} url - the google storage API URL for the logo
 * @param {Function} nullCallback - the callback function for if the logo URL
 * is null
 * @param {Function} successCallback - the callback function for when the logo
 * has been successfully fetcheds
 */
export const getLogoFromURL = (url, nullCallback, successCallback) => {
  if (!url) return nullCallback();

  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const fileBlob = new Blob([blob], { type: 'image/png' });
      const file = new FileReader();
      file.onload = (e) => successCallback(e.target.result);
      file.readAsDataURL(fileBlob);
    })
    .catch(() => nullCallback());
};
