import { useMemo } from 'react';

/**
 * Returns fields names
 * @param {String} name
 * @returns {Object} fields names
 */
const useFields = (name) =>
  useMemo(
    () => ({
      deposit: `${name}.deposit`,
      rate: `${name}.rate`,
      term: `${name}.term`,
    }),
    [name]
  );

export default useFields;
