import React from 'react';
import PropTypes from 'prop-types';

import Container from './Footer-container';
import { CLASSNAME } from './Footer-constants';
import Options from './Options';

import SkeletonLoader from '../../../SkeletonLoader';

const ViewComponent = ({
  footer,
  footerOptions,
  isLoading,
  footerSkeleton,
  footerOptionsSkeleton,
}) => (
  <Container skeleton={footerSkeleton}>
    {({ skeletonProps }) => (
      <div className={CLASSNAME}>
        <SkeletonLoader
          isLoading={isLoading && !!footerSkeleton}
          {...skeletonProps}
          afterLoading={footer}
        />
        <Options
          footer={footer || footerSkeleton}
          footerOptions={footerOptions}
          isLoading={isLoading && !!footerOptionsSkeleton}
          footerOptionsSkeleton={footerOptionsSkeleton}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Card-Footer-view';

ViewComponent.defaultProps = {
  footer: null,
  footerOptions: [],
  isLoading: false,
  footerSkeleton: false,
  footerOptionsSkeleton: false,
};

ViewComponent.propTypes = {
  /**
   * Item footer
   */
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Item footer options
   */
  footerOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  ),
  /**
   * Data is loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Skeleton props for footer
   */
  footerSkeleton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
  /**
   * Skeleton props for footer options
   */
  footerOptionsSkeleton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ]),
};

export default ViewComponent;
