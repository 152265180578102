import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DashboardColor } from '../../assets/icons/dashboard-color.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as HomeColor } from '../../assets/icons/home-color.svg';
import { ReactComponent as Job } from '../../assets/icons/job.svg';
import { ReactComponent as JobColor } from '../../assets/icons/job-color.svg';
import { ReactComponent as SendQuote } from '../../assets/icons/send-quote.svg';
import { ReactComponent as SendQuoteColor } from '../../assets/icons/send-quote-color.svg';
import { ReactComponent as CheckCircle } from '../../assets/icons/check-circle.svg';
import { ReactComponent as CheckCircleColor } from '../../assets/icons/check-circle-color.svg';
import { ReactComponent as Payment } from '../../assets/icons/payment.svg';
import { ReactComponent as PaymentColor } from '../../assets/icons/payment-color.svg';
import { ReactComponent as Account } from '../../assets/icons/account.svg';
import { ReactComponent as AccountColor } from '../../assets/icons/account-color.svg';
import { ReactComponent as Verification } from '../../assets/icons/verification.svg';
import { ReactComponent as VerificationColor } from '../../assets/icons/verification-color.svg';
import { ReactComponent as UserSquare } from '../../assets/icons/user-square.svg';
import { ReactComponent as UserSquareColor } from '../../assets/icons/user-square-color.svg';
import { ReactComponent as SearchFile } from '../../assets/icons/search-file.svg';
import { ReactComponent as SearchFileColor } from '../../assets/icons/search-file-color.svg';
import { ReactComponent as Alarm } from '../../assets/icons/alarm.svg';
import { ReactComponent as AlarmColor } from '../../assets/icons/alarm-color.svg';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as Notification } from '../../assets/icons/notification.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Toolbox } from '../../assets/icons/toolbox.svg';
import { ReactComponent as Load } from '../../assets/icons/load.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as CreditCard } from '../../assets/icons/credit-card.svg';
import { ReactComponent as Paypal } from '../../assets/icons/paypal.svg';
import { ReactComponent as Currency } from '../../assets/icons/currency.svg';
import { ReactComponent as Instalments } from '../../assets/icons/instalments.svg';
import { ReactComponent as More } from '../../assets/icons/more.svg';
import { ReactComponent as Send } from '../../assets/icons/send.svg';
import { ReactComponent as Remove } from '../../assets/icons/remove.svg';
import { ReactComponent as Phone } from '../../assets/icons/phone.svg';
import { ReactComponent as Email } from '../../assets/icons/email.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { ReactComponent as FileUpload } from '../../assets/icons/file-upload.svg';
import { ReactComponent as File } from '../../assets/icons/file.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as Sms } from '../../assets/icons/sms.svg';
import { ReactComponent as Attachement } from '../../assets/icons/attachement.svg';
import { ReactComponent as Note } from '../../assets/icons/note.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as Location } from '../../assets/icons/location.svg';
import { ReactComponent as SpecialCheck } from '../../assets/icons/special-check.svg';
import { ReactComponent as Financed } from '../../assets/icons/financed.svg';
import { ReactComponent as FinancedColor } from '../../assets/icons/financed-color.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as Image } from '../../assets/icons/image.svg';
import { ReactComponent as Wallet } from '../../assets/icons/wallet.svg';
import { ReactComponent as Deposit } from '../../assets/icons/deposit.svg';
import { ReactComponent as Download } from '../../assets/icons/download.svg';
import { ReactComponent as Customer } from '../../assets/icons/customer.svg';
import { ReactComponent as CustomerColor } from '../../assets/icons/customer-color.svg';
import { ReactComponent as Rocket } from '../../assets/icons/rocket.svg';
import { ReactComponent as RocketColor } from '../../assets/icons/rocket-color.svg';
import { ReactComponent as Rotate } from '../../assets/icons/rotate.svg';
import { ReactComponent as Help } from '../../assets/icons/help.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as NotesPaper } from '../../assets/icons/notes-paper.svg';
import { ReactComponent as Office } from '../../assets/icons/office.svg';
import { ReactComponent as Indicator } from '../../assets/icons/indicator.svg';
import { ReactComponent as CustomersColor } from '../../assets/icons/customers-color.svg';
import { ReactComponent as Customers } from '../../assets/icons/customers.svg';
import { ReactComponent as Cart } from '../../assets/icons/cart.svg';
import { ReactComponent as Paper } from '../../assets/icons/paper.svg';
import { ReactComponent as Alphabet } from '../../assets/icons/alphabet.svg';
import { ReactComponent as ArrowUpRight } from '../../assets/icons/arrow-up-right.svg';
import { ReactComponent as EyeOff } from '../../assets/icons/eye-off.svg';
import { ReactComponent as Pound } from '../../assets/icons/pound.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import { ReactComponent as SortAscending } from '../../assets/icons/sort-ascending.svg';
import { ReactComponent as SortDescending } from '../../assets/icons/sort-descending.svg';
import { ReactComponent as Tag } from '../../assets/icons/tag.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { ReactComponent as DownloadFile } from '../../assets/icons/download-file.svg';
import { ReactComponent as Archive } from '../../assets/icons/archive.svg';
import { ReactComponent as Favourite } from '../../assets/icons/favourite.svg';
import { ReactComponent as Warning } from '../../assets/icons/warning.svg';
import { ReactComponent as Error } from '../../assets/icons/error.svg';
import { ReactComponent as ShieldCheck } from '../../assets/icons/shield-check.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';

export const DEFAULT_SIZE = {
  width: 24,
  height: 24,
};

export const CLASS_NAMES = {
  skeletonBase: 'leading-unset',
};

export const ICONS = {
  dashboard: { Icon: Dashboard },
  'dashboard-color': { Icon: DashboardColor },
  home: { Icon: Home },
  'home-color': { Icon: HomeColor },
  job: { Icon: Job },
  'job-color': { Icon: JobColor },
  'send-quote': { Icon: SendQuote },
  'send-quote-color': { Icon: SendQuoteColor },
  'check-circle': { Icon: CheckCircle },
  'check-circle-color': { Icon: CheckCircleColor },
  payment: { Icon: Payment },
  'payment-color': { Icon: PaymentColor },
  account: { Icon: Account },
  'account-color': { Icon: AccountColor },
  verification: { Icon: Verification },
  'verification-color': { Icon: VerificationColor },
  'user-square': { Icon: UserSquare },
  'user-square-color': { Icon: UserSquareColor },
  'search-file': { Icon: SearchFile },
  'search-file-color': { Icon: SearchFileColor },
  alarm: { Icon: Alarm },
  'alarm-color': { Icon: AlarmColor },
  menu: { Icon: Menu },
  'arrow-right': { Icon: ArrowRight },
  notification: { Icon: Notification },
  plus: { Icon: Plus },
  toolbox: { Icon: Toolbox },
  load: { Icon: Load },
  check: { Icon: Check },
  'arrow-left': { Icon: ArrowRight, flip: true },
  'chevron-up': { Icon: ChevronUp },
  close: { Icon: Close },
  'credit-card': { Icon: CreditCard },
  paypal: { Icon: Paypal },
  currency: { Icon: Currency },
  instalments: { Icon: Instalments },
  more: { Icon: More },
  send: { Icon: Send },
  remove: { Icon: Remove },
  phone: { Icon: Phone },
  email: { Icon: Email },
  filter: { Icon: Filter },
  minus: { Icon: Minus },
  copy: { Icon: Copy },
  'file-upload': { Icon: FileUpload },
  file: { Icon: File },
  search: { Icon: Search },
  edit: { Icon: Edit },
  info: { Icon: Info },
  sms: { Icon: Sms },
  attachement: { Icon: Attachement },
  note: { Icon: Note },
  'chevron-right': { Icon: ChevronLeft, flip: true },
  'chevron-left': { Icon: ChevronLeft },
  'chevron-down': { Icon: ChevronDown },
  eye: { Icon: Eye },
  location: { Icon: Location },
  'special-check': { Icon: SpecialCheck },
  financed: { Icon: Financed },
  'financed-color': { Icon: FinancedColor },
  'arrow-up': { Icon: ArrowUp },
  image: { Icon: Image },
  wallet: { Icon: Wallet },
  deposit: { Icon: Deposit },
  download: { Icon: Download },
  customer: { Icon: Customer },
  'customer-color': { Icon: CustomerColor },
  rocket: { Icon: Rocket },
  'rocket-color': { Icon: RocketColor },
  rotate: { Icon: Rotate },
  help: { Icon: Help },
  star: { Icon: Star },
  'notes-paper': { Icon: NotesPaper },
  office: { Icon: Office },
  indicator: { Icon: Indicator },
  'customers-color': { Icon: CustomersColor },
  customers: { Icon: Customers },
  cart: { Icon: Cart },
  paper: { Icon: Paper },
  'arrow-down': { Icon: ArrowUp, flip: true },
  alphabet: { Icon: Alphabet },
  'arrow-up-right': { Icon: ArrowUpRight },
  'eye-off': { Icon: EyeOff },
  pound: { Icon: Pound },
  settings: { Icon: Settings },
  'sort-ascending': { Icon: SortAscending },
  'sort-descending': { Icon: SortDescending },
  tag: { Icon: Tag },
  user: { Icon: User },
  logout: { Icon: Logout },
  'download-file': { Icon: DownloadFile },
  archive: { Icon: Archive },
  favourite: { Icon: Favourite },
  warning: { Icon: Warning },
  error: { Icon: Error },
  'shield-check': { Icon: ShieldCheck },
  calendar: { Icon: Calendar },
};
