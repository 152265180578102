import { SKELETON } from './Title-constants';

const ContainerComponent = ({ children, skeleton }) => {
  const skeletonProps = {
    ...SKELETON,
    ...(Object.keys(skeleton).length !== 0 && skeleton),
  };

  return children({ skeletonProps });
};

ContainerComponent.displayName = 'Card-Title-container';

export default ContainerComponent;
