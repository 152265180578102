import { useEffect, useMemo, useRef } from 'react';
import useSWRImmutable from 'swr/immutable';

import { fetcher } from '../fetcher';
import { HookEvents } from '../lib';
// import { shouldRevalidateOnMount } from '../utils';

/**
 * Hook to load data from services
 * @param {Array|String|Function|undefined} originalKey array key and parameters used for caching and calling the method
 * @param {Object} options useSWR options
 */
const useLoadData = (key, options) => {
  const listener = useRef(() => {});

  // Hook context
  const ctx = useMemo(
    () => ({
      originalKey: key,
      onHookEvents: (func) => {
        listener.current = func;
      },
    }),
    [key],
  );

  const emptyKey = Boolean(key);

  // On empty key event trigger
  useEffect(() => {
    listener?.current?.(HookEvents.onEmptyKey);
  }, [emptyKey]);

  // On unmount event trigger
  useEffect(
    () => () => {
      listener?.current?.(HookEvents.onUnmount);
    },
    [],
  );

  return useSWRImmutable(key, fetcher(ctx), {
    ...options,
    // revalidateOnMount: shouldRevalidateOnMount(key),
  });
};

export default useLoadData;
