import React from 'react';
import PropTypes from 'prop-types';

import Container from './DragDropWrapper-container';
import ColumnOption from './ColumnOption';

const ViewComponent = ({ columns, ...props }) => (
  <Container columns={columns} {...props}>
    {({ droppableProps, innerRef, placeholder, visibleColumns }) => (
      <div {...droppableProps} ref={innerRef}>
        {columns.map((column, index) => (
          <ColumnOption
            key={column.id}
            column={column}
            index={index}
            visibleColumns={visibleColumns}
          />
        ))}
        {placeholder}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DragDropWrapper-view';

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * Table column data
   */
  columns: PropTypes.array.isRequired,
  /**
   * Table column data
   */
  provided: PropTypes.any.isRequired,
  /**
   * Table column data
   */
  snapshot: PropTypes.any.isRequired,
};

export default ViewComponent;
