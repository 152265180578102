import React from 'react';
import PropTypes from 'prop-types';

import Container from './Icon-container';

import SkeletonLoader from '../SkeletonLoader';

const ViewComponent = ({ className: extClassName, isLoading, ...props }) => (
  <Container {...props} className={extClassName}>
    {({
      Icon,
      classNames,
      className,
      skeletonProps,
      width,
      height,
      ...viewProps
    }) => (
      <SkeletonLoader
        width={width}
        height={height}
        isLoading={isLoading}
        className={classNames.skeleton}
        afterLoading={
          <Icon
            width={width}
            height={height}
            className={classNames.svg}
            {...viewProps}
          />
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Icon-view';

ViewComponent.defaultProps = {
  stroke: undefined,
  flip: undefined,
  width: undefined,
  height: undefined,
  size: undefined,
  className: undefined,
  fill: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Icon name
   */
  icon: PropTypes.string.isRequired,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Tailwind color name to set as stroke of the icon e.g. blue-300
   */
  stroke: PropTypes.string,
  /**
   * It will mirror the icon
   */
  flip: PropTypes.bool,
  /**
   * Width of the icon
   */
  width: PropTypes.number,
  /**
   * Height of the icon
   */
  height: PropTypes.number,
  /**
   * Width and height of icon
   */
  size: PropTypes.number,
  /**
   * Tailwind color name to set as fill of the icon e.g. blue-300
   */
  fill: PropTypes.string,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
