import Fade from './Fade';
import SlideUp from './SlideUp';
import SlideLeft from './SlideLeft';

const Button = {
  Fade,
  SlideUp,
  SlideLeft,
};

export default Button;
