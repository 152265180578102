import clsx from 'clsx';

import { STYLE, CLASS_NAMES } from './ButtonWrapper-constants';

const ContainerComponent = ({ children, index }) => {
  const containerStyle = index === 0 ? STYLE : null;

  const classNames = {
    container: clsx(
      CLASS_NAMES.container.base,
      index === 0 ? CLASS_NAMES.container.padding : ''
    ),
    flex: clsx(
      CLASS_NAMES.flex.base,
      index === 0 ? '' : CLASS_NAMES.flex.padding
    ),
  };

  return children({
    classNames,
    containerStyle,
  });
};

ContainerComponent.displayName = 'ButtonWrapper-container';

export default ContainerComponent;
