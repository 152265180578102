const BASE_PROPS = {
  showIcon: true,
  container: {
    className: 'flex flex-row w-full',
  },
  icon: {
    size: 24,
    className: 'my-auto mr-3',
  },
  span: {
    className: 'text-style-g-em text-neutral-900 my-auto',
  },
};

export const INFO_MESSAGE_PROPS = {
  ...BASE_PROPS,
  icon: {
    ...BASE_PROPS.icon,
    icon: 'info',
    stroke: 'neutral-600',
  },
};

export const ERROR_MESSAGE_PROPS = {
  ...BASE_PROPS,
  icon: {
    ...BASE_PROPS.icon,
    icon: 'error',
    stroke: 'red-200',
  },
};

export const SUCCESS_MESSAGE_PROPS = {
  ...BASE_PROPS,
  icon: {
    ...BASE_PROPS.icon,
    icon: 'check',
    stroke: 'turquoise-300',
  },
};

export const WARNING_MESSAGE_PROPS = {
  ...BASE_PROPS,
  icon: {
    ...BASE_PROPS.icon,
    icon: 'warning',
    stroke: 'orange-200',
  },
};
