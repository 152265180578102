export const CLASS_NAMES = {
  container: 'flex flex-row w-full items-center mt-2',
  left: 'flex w-1/2',
  center: 'flex w-full items-center',
  right: 'flex w-1/2 justify-end',
};

export const RANGE_PROPS = {
  max: 20,
  min: -20,
  step: 0.1,
  colors: ['#27DEBF', '#E1E6F1'],
};

export const ICONS = {
  minus: {
    icon: 'minus',
    className: 'mr-4',
    size: 16,
    stroke: 'neutral-500',
  },
  plus: {
    icon: 'plus',
    className: 'ml-4',
    size: 16,
    stroke: 'neutral-500',
  },
};

export const FIT_LABEL = 'Fit';

export const RESET_LABEL = 'Reset';
