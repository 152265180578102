import React from 'react';
import { Helmet } from 'react-helmet';

import { SCRIPT, SCRIPT_INIT } from './HelpCrunch-constants';

const ViewComponent = () => (
  <Helmet>
    <script type="text/javascript">{SCRIPT}</script>
    <script type="text/javascript">{SCRIPT_INIT}</script>
  </Helmet>
);

ViewComponent.displayName = 'HelpCrunch-view';

export default ViewComponent;
