import clsx from 'clsx';

import { CLASS_NAMES } from './PaginationNumberButton-constants';

const ContainerComponent = ({ children, active, ellipsis }) => {
  const classNames = {
    button: ellipsis
      ? clsx(CLASS_NAMES.base, CLASS_NAMES.normal, CLASS_NAMES.ellipsis)
      : clsx(
          CLASS_NAMES.base,
          active ? CLASS_NAMES.active : clsx(CLASS_NAMES.normal, CLASS_NAMES.hover)
        ),
    text: 'm-auto',
  };

  return children({
    classNames,
  });
};

ContainerComponent.displayName = 'PaginationNumberButton-container';

export default ContainerComponent;
