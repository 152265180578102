import { useContext } from 'react';
import { calculateJobTotal, formatToCurrency } from '@kanda-utils/library';

import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { job } = useContext(Context);

  const totals = job?.lineItems
    ? calculateJobTotal(job?.lineItems)
    : {
        subTotal: 0,
        vatTotal: 0,
        total: 0,
      };

  const showSubTotals = totals?.vatTotal !== 0 || false;

  return children({
    showSubTotals,
    subTotal: formatToCurrency(totals.subTotal),
    vatTotal: formatToCurrency(totals.vatTotal),
    total: formatToCurrency(totals.total),
  });
};

ContainerComponent.displayName = 'Render-Totals-container';

export default ContainerComponent;
