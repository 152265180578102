import React from 'react';
import PropTypes from 'prop-types';

import Container from './Message-container';

import Icon from '../../../Icon';

const ViewComponent = ({ message, showIcon, ...props }) => (
  <Container {...props}>
    {({ iconProps, containerProps, spanProps }) => (
      <div {...containerProps}>
        {showIcon && <Icon {...iconProps} />}
        <span {...spanProps}>{message}</span>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Toast-Message-view';

ViewComponent.defaultProps = {
  showIcon: false,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  message: PropTypes.string.isRequired,
  /**
   * ClassName
   */
  showIcon: PropTypes.bool,
};

export default ViewComponent;
