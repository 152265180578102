import Header from './Header';
import Rows from './Rows';
import SettingsButton from './SettingsButton';

const TableComponents = {
  Header,
  Rows,
  SettingsButton,
};

export default TableComponents;
