/**
 * Capitalizes first letter of the text
 * @param {string} text text to capitalize
 * @returns {string} capitalized text
 */
export const capitalize = (text) => {
  if (typeof text !== 'string') return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Replace all strings
 * @param {string} text text to replace
 * @param {Object} object strings map
 * @returns {string} new string
 */
export const replaceAll = (text, object) => {
  // escape helper
  const escape = (key) => `{${key}}`;

  // unescape helper
  const unescape = (key) => key.replace(/{|}/gi, '');

  // search regex
  const re = new RegExp(Object.keys(object).map(escape).join('|'), 'gi');

  // match helper
  const match = (matched) => object[unescape(matched)];

  return text.replace(re, match);
};

/**
 * Formats string
 * @param {string} value string value
 * @param {string} pattern example: (###) ### ####
 * @returns {string} pattern
 */
export const format = (value, pattern) => {
  let index = 0;
  const stringValue = value.toString();

  return pattern.replace(/#/g, () => {
    const character = stringValue[index];
    index += 1;

    return character;
  });
};

/**
 * Show two decimal points only for floats
 * @param {number} value string value
 * @returns {string} formatted number
 */
export const optionalToFixed = (value) => value.toFixed(2).replace(/\.00/g, '');

/**
 * Returns text width for fort style
 * @param {string} text text to display
 * @param {string} fontStyle ex text-style-a
 * @returns {Number} width
 */
export const getTextWidth = (text, fontStyle) => {
  const tag = document.createElement('div');
  tag.style.position = 'absolute';
  tag.style.left = '-999em';
  tag.style.whiteSpace = 'nowrap';
  tag.innerText = text;
  tag.className = fontStyle;

  document.body.appendChild(tag);

  const result = tag.clientWidth;

  document.body.removeChild(tag);

  return result;
};
