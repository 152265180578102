import { useDebounce } from 'use-debounce';
import { AddressService, useLoadData } from '@kanda-api/library';
import { useWatch, useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { DEBOUNCE_INTERVAL, SELECT_ADDRESS_LABEL } from './Address-constants';
import {
  getOptions,
  validatePostcode,
  searchByLines,
  searchByHouseNumber,
  formatAddressLineOne,
  formatAddressLineTwo,
  // getPlaceholder,
} from './Address-functions';
import useFields from './Address-useFields';

const ContainerComponent = ({ children, name, autoHideSelect }) => {
  const fields = useFields(name);

  const { setValue, getValues } = useFormContext();

  /**
   * Gets current form values
   */
  const [postalCode, houseNumber, selected] = useWatch({
    name: [fields.postalCode, fields.houseNumber, fields.selected],
  });

  /**
   * Debounces values to reduce number of calls
   */
  const [debouncedPostalCode] = useDebounce(postalCode, DEBOUNCE_INTERVAL);

  const isPostcodeValid = validatePostcode(debouncedPostalCode || '');

  /**
   * Loads data from API
   */
  const {
    data,
    error: apiError,
    isValidating,
  } = useLoadData(
    isPostcodeValid && [
      AddressService.key.addresses,
      debouncedPostalCode.trim(),
    ],
    { shouldRetryOnError: false }
  );

  const options = useMemo(
    () => getOptions(data, houseNumber),
    [data, houseNumber]
  );

  // Reference to store postalCode
  const postalCodeRef = useRef(null);

  const placeholder = options.length > 1 ? SELECT_ADDRESS_LABEL : '';

  const isLoading = isValidating;

  const disabled = options && options.length === 1 && options[0].value === '';

  const selectWrapperClassName =
    autoHideSelect && selected !== '' ? 'hide' : '';

  const error = apiError?.errors?.message;

  /**
   * Handles address change
   */
  const handleChange = useCallback(
    (e) => {
      const id = e.target.value;
      const address = data?.addresses && data?.addresses[id];

      if (!address) return;

      if (address.buildingNumber) {
        setValue(fields.houseNumber, address.buildingNumber);
        return;
      }
      setValue(fields.houseNumber, address.subBuildingNumber);
    },
    [data?.addresses, setValue, fields.houseNumber]
  );

  /**
   * Handles houseNumber change
   * @param {Event} event
   */
  const onHouseNumberChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (!value) {
        setValue(fields.selected, '');
        setValue(fields.addressLineOne);
        setValue(fields.addressLineTwo);
      }
    },
    [fields.addressLineOne, fields.addressLineTwo, fields.selected, setValue]
  );

  /**
   * Update Field values
   */
  useEffect(() => {
    if (!selected) return;

    const address = data?.addresses && data?.addresses[parseInt(selected, 10)];

    if (!address) return;

    setValue(fields.city, address.townOrCity);
    setValue(fields.addressLineOne, formatAddressLineOne(address));
    setValue(fields.addressLineTwo, formatAddressLineTwo(address));
    setValue(fields.addressDetails, address);
  }, [
    selected,
    data,
    fields.city,
    fields.addressLineOne,
    fields.addressLineTwo,
    fields.houseNumber,
    fields.addressDetails,
    setValue,
  ]);

  /**
   * Handles data refresh
   */
  useEffect(() => {
    if (!data?.addresses?.length) {
      return;
    }

    // If there is only 1 option and no house number selected, select it
    if (data?.addresses?.length === 1 && !houseNumber) {
      setValue(fields.selected, '0');
      return;
    }

    if (houseNumber) {
      const newAddressValue = searchByHouseNumber(data, houseNumber);

      if (newAddressValue === -1) {
        setValue(fields.selected, '');
        setValue(fields.city);
        setValue(fields.addressLineOne);
        setValue(fields.addressLineTwo);
        setValue(fields.addressDetails);
        return;
      }
      setValue(fields.selected, String(newAddressValue));
      return;
    }

    const [line1, line2] = getValues([
      fields.addressLineOne,
      fields.addressLineTwo,
    ]);

    if (line1) {
      const newAddressValue = searchByLines(data, line1, line2);

      if (newAddressValue !== -1) {
        setValue(fields.selected, String(newAddressValue));

        return;
      }
    }

    setValue(fields.houseNumber);
    setValue(fields.selected, '');
    setValue(fields.addressLineOne);
    setValue(fields.addressLineTwo);
  }, [data, houseNumber, getValues, setValue, fields]);

  /**
   * Handles error
   */
  useEffect(() => {
    if (!error) return;

    setValue(fields.houseNumber);
    setValue(fields.selected, '');
    setValue(fields.addressLineOne);
    setValue(fields.addressLineTwo);
  }, [
    error,
    fields.addressLineOne,
    fields.addressLineTwo,
    fields.houseNumber,
    fields.selected,
    setValue,
  ]);

  /**
   * Handles postcode change by removing any selected data if postcode has
   * been changed
   */
  useEffect(() => {
    if (!postalCodeRef) {
      postalCodeRef.current = debouncedPostalCode;
      return;
    }
    if (postalCodeRef.current === debouncedPostalCode) return;
    setValue(fields.houseNumber);
    setValue(fields.selected, '');
    setValue(fields.city);
    setValue(fields.addressLineOne);
    setValue(fields.addressLineTwo);
    setValue(fields.addressDetails);
  }, [debouncedPostalCode, fields, setValue]);

  return children({
    options,
    error,
    selectWrapperClassName,
    onHouseNumberChange,
    handleChange,
    placeholder,
    isLoading,
    disabled,
    fields,
    selected,
  });
};

ContainerComponent.displayName = 'Address-Container';

export default ContainerComponent;
