import React from 'react';
import PropTypes from 'prop-types';

import { ACTIONS } from './Table-constants';
import Container from './Table-container';
import Components from './components';

import Pagination from '../Pagination';

const ViewComponent = ({ onRowClicked, ...restProps }) => (
  <Container {...restProps}>
    {({
      tableProps,
      headerGroups,
      rows,
      prepareRow,
      allColumns,
      setColumnOrder,
      showPagination,
      paginationProps,
      isLoading,
      hoverPopover,
      classNames,
      handleAction,
    }) => (
      <div className={classNames.wrapper}>
        {!isLoading && (
          <Components.SettingsButton
            columns={allColumns}
            setColumnOrder={setColumnOrder}
          />
        )}
        <div className={classNames.table}>
          <div className={classNames.padding}>
            <div {...tableProps}>
              <Components.Header
                headerGroups={headerGroups}
                isLoading={isLoading}
                allColumns={allColumns}
                setColumnOrder={setColumnOrder}
                handleAction={handleAction}
              />
              <Components.Rows
                rows={rows}
                prepareRow={prepareRow}
                isLoading={isLoading}
                hoverPopover={hoverPopover}
                onRowClicked={onRowClicked}
              />
            </div>
          </div>
        </div>
        {showPagination && (
          <div className={classNames.pagination}>
            <Pagination {...paginationProps} />
          </div>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  onRowClicked: undefined,
  onAction: undefined,
  isValidating: undefined,
};

ViewComponent.displayName = 'Table-view';

ViewComponent.Actions = ACTIONS;

ViewComponent.propTypes = {
  /**
   * On row clicked
   */
  onRowClicked: PropTypes.func,
  /**
   * On action
   */
  onAction: PropTypes.func,
  /**
   * Is validating
   */
  isValidating: PropTypes.bool,
};

export default ViewComponent;
