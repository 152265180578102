import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { opacitySuffix } from './Fade-functions';
import { BASE_CLASSNAME } from './Fade-constants';

const ContainerComponent = ({
  children,
  show,
  className: extClassName,
  opacity,
  onEnd,
}) => {
  const [shouldRender, setShouldRender] = useState(show);

  const onAnimationEnd = () => {
    if (show) return;
    setShouldRender(false);
    if (onEnd) onEnd();
  };

  useEffect(() => {
    if (show) setShouldRender(true);
  }, [show]);

  const suffix = opacitySuffix(opacity);

  const className = clsx(
    BASE_CLASSNAME,
    extClassName,
    show ? `animate-fade-in${suffix}` : `animate-fade-out${suffix}`
  );

  return children({
    shouldRender,
    onAnimationEnd,
    className,
  });
};

ContainerComponent.displayName = 'Fade-Container';

export default ContainerComponent;
