import { useContext } from 'react';

import useButtonText from '../SelectionModal-useButtonText';
import ModalsWrapper from '../../../../ModalsWrapper';

const ContainerComponent = ({ children, name, options }) => {
  const buttonText = useButtonText({ name, options });

  const { showModal } = useContext(ModalsWrapper.Context);

  const ids = {
    modal: `${name}-modal`,
    button: `${name}-button`,
  };

  const handleShowModal = () => showModal(ids.modal);

  return children({
    handleShowModal,
    ids,
    buttonText,
  });
};

ContainerComponent.displayName = 'SelectionModal-Mobile-Container';

export default ContainerComponent;
