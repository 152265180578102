import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from '@react-pdf/renderer';

import { Provider } from './Context';
import Container from './Render-container';
import { STYLES, SIZE, CREATOR } from './Render-constants';
import { Header, Title, LineItems, Totals } from './components';

const ViewComponent = ({ ...props }) => (
  <Provider {...props}>
    <Container>
      {({ title, author }) => (
        <Document author={author} title={title} creator={CREATOR}>
          <Page size={SIZE} style={STYLES.page}>
            <Header />
            <Title />
            <LineItems />
            <Totals />
          </Page>
        </Document>
      )}
    </Container>
  </Provider>
);

ViewComponent.defaultProps = {
  children: null,
  job: null,
};

ViewComponent.displayName = 'Render-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Kanda `job` object
   */
  job: PropTypes.object,
};

export default ViewComponent;
