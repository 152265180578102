import React from 'react';
import PropTypes from 'prop-types';

import Container from './PaginationNumberButton-container';

const ViewComponent = ({ text, onClick, active, disabled, ellipsis }) => (
  <Container active={active} ellipsis={ellipsis}>
    {({ classNames }) => (
      <button
        className={classNames.button}
        onClick={onClick}
        disabled={disabled}
      >
        <span className={classNames.text}>{text}</span>
      </button>
    )}
  </Container>
);

ViewComponent.displayName = 'PaginationNumberButton-view';

ViewComponent.propTypes = {
  /**
   * Button text to display
   */
  text: PropTypes.string.isRequired,
  /**
   * Active state of button
   */
  active: PropTypes.bool.isRequired,
  /**
   * Disabeld state of button
   */
  disabled: PropTypes.bool.isRequired,
  /**
   * onClick function for button
   */
  onClick: PropTypes.func.isRequired,
  /**
   * onClick function for button
   */
  ellipsis: PropTypes.bool.isRequired,
};

export default ViewComponent;
