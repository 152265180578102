import React from 'react';
import PropTypes from 'prop-types';

import Container from './ButtonIcon-container';

import Icon from '../../Icon';
import SkeletonLoader from '../../SkeletonLoader';

const ViewComponent = ({
  children,
  id,
  submit,
  size,
  variant,
  icon,
  className: extClassName,
  iconProps: extIconProps,
  isLoading,
  indicator,
  indicatorPosition,
  ...restProps
}) => (
  <Container
    size={size}
    variant={variant}
    submit={submit}
    className={extClassName}
    iconProps={extIconProps}
    indicator={indicator}
    indicatorPosition={indicatorPosition}
  >
    {({ iconProps, classNames, circle, type, style }) => (
      <SkeletonLoader
        className={classNames.skeleton}
        isLoading={isLoading}
        circle={circle}
        {...style}
        afterLoading={
          <button
            id={id}
            className={classNames.button}
            style={style}
            {...restProps}
            type={type}
          >
            <div className={classNames.wrapper}>
              <Icon {...iconProps} icon={icon} />
              {indicator && <span className={classNames.indicator} />}
            </div>
          </button>
        }
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Button.Icon';

ViewComponent.defaultProps = {
  id: null,
  variant: 'ghost-grey', // no style variant
  size: '40-20', // navbar icon size
  icon: undefined,
  submit: undefined,
  className: undefined,
  children: null,
  isLoading: undefined,
  onClick: undefined,
  iconProps: {},
  indicatorPosition: 'top-0 right-0',
  indicator: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * HTML ID of element
   */
  id: PropTypes.string,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Icon name
   */
  icon: PropTypes.string,
  /**
   * Button variant
   */
  variant: PropTypes.oneOf([
    'turquoise',
    'light-turquoise',
    'solid-grey',
    'ghost-grey',
    'custom',
  ]),
  /**
   * Button size
   */
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['48-20', '40-20', '32-20', '32-16', '28-16']),
  ]),
  /**
   * sets button type to submit insted of button
   */
  submit: PropTypes.bool,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Icon props
   */
  iconProps: PropTypes.shape({
    className: PropTypes.string,
    stroke: PropTypes.string,
    flip: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.number,
    fill: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  /**
   * On Click callback
   */
  onClick: PropTypes.func,
  /**
   * indircator color
   */
  indicator: PropTypes.string,
  /**
   * indicator position
   */
  indicatorPosition: PropTypes.string,
};

export default ViewComponent;
