import { useContext } from 'react';

import Provider from '../Provider';

const ContainerComponent = ({ children }) => {
  const { downloadPdf, isLoading, fetchingLogo } = useContext(Provider.Context);

  return children({
    isLoading: isLoading || fetchingLogo,
    downloadPdf,
  });
};

ContainerComponent.displayName = 'QuoteDownload-IconButton-Container';

export default ContainerComponent;
