import { useContext } from 'react';

import {
  DEFAULT_PREV_BUTTON_PROPS,
  DEFAULT_NEXT_BUTTON_PROPS,
} from './Footer-constants';

import Context from '../../Context';

const ContainerComponent = ({
  children,
  nextButtonProps: extNextButtonProps,
  prevButtonProps: extPrevButtonProps,
}) => {
  const { prevStep, currentStepIndex, nextStep } = useContext(Context);

  const disablePrev = currentStepIndex === 0;

  const onClick = !extNextButtonProps?.submit
    ? extNextButtonProps?.onClick || nextStep
    : undefined;

  const prevButtonProps = {
    ...DEFAULT_PREV_BUTTON_PROPS,
    ...extPrevButtonProps,
    disabled: disablePrev,
    onClick: prevStep,
  };

  const nextButtonProps = {
    ...DEFAULT_NEXT_BUTTON_PROPS,
    ...extNextButtonProps,
    onClick,
  };

  return children({
    nextButtonProps,
    prevButtonProps,
  });
};

ContainerComponent.displayName = 'MultiStepForm-Footer-container';

export default ContainerComponent;
