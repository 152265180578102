export const CLASS_NAMES = {
  container: 'relative flex inline-block w-5 h-5 mb-auto',
  checkBox: 'hidden',
  label:
    'w-5 h-5 flex items-center align-center justify-center text-neutral-000 overflow-hidden rounded-full border border-neutral-300 bg-neutral-000 cursor-pointer label-checked:bg-turquoise-300 label-checked:border-transparent',
  icon: 'w-2 h-2 bg-neutral-000 rounded-full',
};

export const SKELETONS = {
  radiobutton: {
    width: 20,
    height: 21,
    circle: true,
    wrapperClassName: 'absolute -top-1',
  },
};
