import clsx from 'clsx';

import { CLASS_NAMES, VARIANTS } from './ModalLayoutSlideUp-constants';

const ContainerComponent = ({ children, variant }) => {
  const { bgBase, ...classNames } = CLASS_NAMES;

  const bg = clsx(bgBase, variant !== VARIANTS.MARKETING && 'shadow-c');

  const linesVisible = variant === VARIANTS.MARKETING;

  return children({
    classNames: {
      ...classNames,
      bg,
    },
    linesVisible,
  });
};

ContainerComponent.displayName = 'ModalLayoutFullScreen-container';

export default ContainerComponent;
