import React from 'react';
import PropTypes from 'prop-types';

import Row from './Row';

const ViewComponent = ({ rows, ...rest }) =>
  rows.map((row) => <Row key={row.id} row={row} {...rest} />);

ViewComponent.defaultProps = {
  rows: [],
};

ViewComponent.displayName = 'Rows-view';

ViewComponent.propTypes = {
  /**
   * rows
   */
  rows: PropTypes.arrayOf(PropTypes.object),
};
export default ViewComponent;
