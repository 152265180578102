import clsx from 'clsx';
import { useCallback, useState } from 'react';

import { preventDefaultOnEnter } from './AutoSizeTextArea-functions';

import { useFormTheme } from '../../FormTheme';

const ContainerComponent = ({
  children,
  error,
  className: extClassName,
  onBlur: extOnBlur,
  collapsible,
  disableNewLine,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue);

  const {
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses,
    skeletonClasses,
  } = useFormTheme();

  const showPlaceholder = collapsible && value;

  const className = clsx(
    'resize-none',
    baseClasses,
    inputClasses,
    paddingClasses,
    focusClasses,
    makeErrorClasses(error),
    extClassName,
    showPlaceholder &&
      'h-full absolute opacity-0 focus:opacity-100 focus:relative focus:h-auto'
  );

  const placeholderClassName = clsx(
    baseClasses.replace('flex', '').replace('w-full', ''),
    paddingClasses,
    focusClasses,
    makeErrorClasses(error),
    extClassName,
    'block field-focus:hidden whitespace-nowrap truncat overflow-ellipsis overflow-hidden min-w-0'
  );

  const onKeyDown = disableNewLine && preventDefaultOnEnter;

  /**
   * Overrrides onBlur event to store the value
   * @param {Event} e onBlur event
   * @returns {boolean}
   */
  const onBlur = useCallback(
    (e) => {
      if (collapsible) {
        setValue(e.target.value);
      }

      return extOnBlur(e);
    },
    [extOnBlur, collapsible]
  );

  return children({
    placeholderClassName,
    className,
    skeletonClasses,
    onKeyDown,
    onBlur,
    value,
    showPlaceholder,
  });
};

ContainerComponent.displayName = 'AutoSizeTextArea-container';

export default ContainerComponent;
