import React from 'react';
import PropTypes from 'prop-types';

import Container from './HeaderBase-container';
import HelpButton from '../../HelpButton';
import { ReactComponent as HeaderBackground } from '../../../assets/header/background.svg';

const ViewComponent = ({
  children,
  className: extClassName,
  options,
  help,
  lines,
  ...restProps
}) => (
  <Container className={extClassName}>
    {({ classNames }) => (
      <div className={classNames.container} {...restProps}>
        <div className={classNames.content}>{children}</div>
        {lines && <HeaderBackground className="absolute left-0 top-0" />}
        <div className={classNames.options}>
          {help && <HelpButton variant="button" />}
          {options.map((option, i) => (
            <div key={String(i)}>{option}</div>
          ))}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'HeaderBase-view';

ViewComponent.defaultProps = {
  options: [],
  children: null,
  className: undefined,
  help: undefined,
  lines: false,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Right side option for header
   */
  options: PropTypes.arrayOf(PropTypes.element),
  /**
   * Right side option for header
   */
  help: PropTypes.bool,
  /**
   * Top lines
   */
  lines: PropTypes.bool,
};

export default ViewComponent;
