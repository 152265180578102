import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import { SKELETON_COUNT } from './TextArea-constants';
import Container from './TextArea-container';

const ViewComponent = ({
  forwardRef,
  error,
  className: extClassName,
  isLoading,
  ...restProps
}) => (
  <Container error={error} className={extClassName}>
    {({ className, skeletonClasses }) => (
      <React.Fragment>
        {isLoading ? (
          <div className={className}>
            <div className={skeletonClasses}>
              <Skeleton count={SKELETON_COUNT} />
            </div>
          </div>
        ) : (
          <textarea className={className} ref={forwardRef} {...restProps} />
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'UncontrolledTextArea-view';

ViewComponent.defaultProps = {
  id: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  forwardRef: undefined,
  onChange: undefined,
  error: undefined,
  className: undefined,
  onBlur: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Name of the textarea required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * Forwared ref from parent component
   */
  forwardRef: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
