import clsx from 'clsx';
import { useMemo } from 'react';

import { CLASS_NAMES } from './HeaderColumn-constants';
import {
  moveLeftItem,
  moveRightItem,
  hideColumn,
} from './HeaderColumn-functions';

const ContainerComponent = ({ children, column, index, totalVisible }) => {
  const {
    id,
    render,
    getHeaderProps,
    items: originalItems = [],
    popoverButtons,
    search,
    searchPlaceholder,
    searchDebounceInterval,
  } = column;

  const aditionalItems = useMemo(
    () =>
      (totalVisible > 1 &&
        [
          originalItems?.length > 0 && {
            seperator: true,
            name: 'separator',
            action: {},
          },
          index !== 0 && moveLeftItem(id),
          index !== totalVisible - 1 && moveRightItem(id),
          hideColumn(id),
        ].filter(Boolean)) ||
      [],
    [id, index, originalItems.length, totalVisible]
  );

  const items = useMemo(
    () => [...originalItems, ...aditionalItems],
    [originalItems, aditionalItems]
  );

  const classNames = {
    ...CLASS_NAMES,
    loading: clsx(
      CLASS_NAMES.loading.base,
      index === 0 ? '' : CLASS_NAMES.loading.margin
    ),
    wrapper: clsx(
      CLASS_NAMES.wrapper.padding,
      popoverButtons ? '' : CLASS_NAMES.wrapper.popover
    ),
  };

  const right = index === totalVisible - 1 && totalVisible > 1;

  const popoverProps = {
    items,
    right,
    search,
    searchPlaceholder,
    searchDebounceInterval,
  };

  return children({
    classNames,
    headerProps: getHeaderProps(),
    popoverProps,
    label: render('Header'),
  });
};

ContainerComponent.displayName = 'HeaderColumn-container';

export default ContainerComponent;
