export const CLASS_NAMES = {
  multiline: {
    container: 'w-full',
    optionWrapper: 'flex flex-1',
  },
  inline: {
    container: 'w-full flex flex-row',
    optionWrapper: 'flex flex-1',
  },
};
