import clsx from 'clsx';
import { abbreviateNumber } from '@kanda-utils/library';

import { CLASS_NAMES } from './Price-constants';

const ContainerComponent = ({
  children,
  color,
  amount,
  className: extClassName,
  wrapperClassName: extWrapperClassName,
}) => {
  const { baseContainer, baseWrapper } = CLASS_NAMES;

  const container = clsx(baseContainer, `text-${color}`, extClassName);
  const wrapper = clsx(baseWrapper, extWrapperClassName);

  const [pounds, suffix] = abbreviateNumber(Math.floor(amount / 100));
  const cents = `${Math.round(amount % 100)}`.padStart(2, '0');

  const centsLabel = `.${cents}`;

  return children({
    classNames: {
      wrapper,
      container,
    },
    pounds,
    suffix,
    centsLabel,
  });
};

ContainerComponent.displayName = 'Price-Container';

export default ContainerComponent;
