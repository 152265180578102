import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Controller } from 'react-hook-form';
import { format } from '@kanda-utils/library';

import Input from '../../UncontrolledField/Input';

const ViewComponent = ({
  name,
  control,
  controlProps,
  isLoading,
  ...restProps
}) => (
  <Controller
    name={name}
    control={control}
    {...controlProps}
    render={({
      field: { onChange, onBlur, value, ref },
      fieldState: { error },
    }) => (
      <NumberFormat
        customInput={Input}
        inputRef={ref}
        name={name}
        format="## / ## / ####"
        mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
        ref={ref}
        onBlur={onBlur}
        value={value?.replace('/', '') || null}
        onValueChange={(event) =>
          onChange(event.value ? format(event.value, '##/##/####') : null)
        }
        isLoading={isLoading}
        {...restProps}
        icon="calendar"
        placeholder="DD / MM / YYYY"
        error={error}
      />
    )}
  />
);

ViewComponent.displayName = 'DatePicker-view';

ViewComponent.defaultProps = {
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  controlProps: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * react hooks control
   */
  control: PropTypes.any.isRequired,
  /**
   * react hook form control props
   */
  controlProps: PropTypes.any,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
