export const CLASS_NAMES = {
  text: '',
  skeleton: '',
};

export const VARIANTS = {
  both: 'both',
  date: 'date',
  time: 'time',
};
