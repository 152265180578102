import { useContext } from 'react';

import Context from '../Context';

const ContainerComponent = ({ children }) => {
  const { classNames } = useContext(Context);

  const { smaller } = classNames.mobile;

  return children({ classNames, smaller });
};

ContainerComponent.displayName = 'LineItem-Mobile-container';

export default ContainerComponent;
