import React from 'react';
import PropTypes from 'prop-types';

import Container from './Advanced-container';
import { ACTIONS } from './Advanced-constants';
import { Search } from './components';

import PopoverMenu from '../../PopoverMenu';

const ViewComponent = ({
  button,
  above,
  right,
  onAction,
  items: originalItems,
  wrapper: ButtonWrapper,
  search: SearchResults,
  searchPlaceholder,
  searchDebounceInterval,
  ...props
}) => (
  <Container
    button={button}
    above={above}
    right={right}
    items={originalItems}
    onAction={onAction}
    search={SearchResults}
    searchDebounceInterval={searchDebounceInterval}
  >
    {({
      classNames,
      showPopover,
      button: Button,
      panel,
      ref,
      handleClose,
      panelStyle,
      items,
      debouncedSearch,
      onSearch,
      clearSearch,
      showPanel,
      value,
    }) => (
      <div className={classNames.wrapper}>
        {showPopover && (
          <div ref={ref} className={classNames.container}>
            <div className={classNames.panelWrapper}>
              <div className={classNames.popover}>
                <PopoverMenu
                  header={
                    SearchResults && (
                      <Search
                        value={value}
                        placeholder={searchPlaceholder}
                        onChange={onSearch}
                      />
                    )
                  }
                  items={items}
                  onClose={handleClose}
                  content={
                    debouncedSearch && (
                      <SearchResults
                        query={debouncedSearch}
                        clearSearch={clearSearch}
                        handleClose={handleClose}
                      />
                    )
                  }
                  {...props}
                />
              </div>
              {showPanel && (
                <div style={panelStyle} className={classNames.panel}>
                  {panel({ handleClose })}
                </div>
              )}
            </div>
          </div>
        )}
        <ButtonWrapper>{Button}</ButtonWrapper>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Popover-Advanced-view';

ViewComponent.Actions = ACTIONS;

ViewComponent.defaultProps = {
  onAction: undefined,
  above: false,
  right: false,
  items: [],
  wrapper: ({ children }) => children,
  className: undefined,
  size: 'xs',
  search: undefined,
  searchPlaceholder: 'Search..',
  searchDebounceInterval: 200,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Position above button rather than below
   */
  above: PropTypes.bool,
  /**
   * Position popover to right-align rather than left-align
   */
  right: PropTypes.bool,
  /**
   * Button wrapper
   */
  wrapper: PropTypes.func,
  /**
   * Items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.object.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }).isRequired
  ),
  /**
   * On action callback
   */
  onAction: PropTypes.func,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Size
   */
  size: PropTypes.string,
  /**
   * Search
   */
  search: PropTypes.func,
  /**
   * Search label
   */
  searchPlaceholder: PropTypes.string,
  /**
   * Search debounce interval
   */
  searchDebounceInterval: PropTypes.number,
};

export default ViewComponent;
