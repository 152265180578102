export const CLASS_NAMES = {
  baseWrapper: 'flex items-center',
  baseContainer: 'flex flex-row items-start',
};

export const SKELETONS = {
  pounds: {
    width: 40,
  },
  cents: {
    width: 16,
  },
};
