import { showDot } from './Options-functions';
import { SKELETON } from './Options-constants';

const ContainerComponent = ({
  children,
  footer,
  footerOptions,
  isLoading,
  footerOptionsSkeleton: skeleton,
}) => {
  const skeletonProps = {
    ...SKELETON,
    ...(Object.keys(skeleton).length !== 0 && skeleton),
  };
  const options =
    isLoading && skeleton
      ? [
          {
            key: 'skeleton',
            showDot: showDot(0, footer),
            content: '',
          },
        ]
      : footerOptions.map((option, i) => ({
          key: `option-${i}`,
          showDot: showDot(i, footer),
          content: option,
        }));
  return children({ options, skeletonProps });
};

ContainerComponent.displayName = 'Card-FooterOptions-container';

export default ContainerComponent;
