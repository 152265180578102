import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES } from './DesktopHeader-constants';
import Container from './DesktopHeader-container';
import { DesktopMenu, CompanyInfo } from './components';

import { ReactComponent as Logo } from '../../assets/logos/kanda-wordmark.svg';
import HelpButton from '../HelpButton';

const ViewComponent = ({
  items,
  help,
  hideHomeLink,
  noLinkLogo,
  ...companyInfo
}) => (
  <Container>
    {({ options, linkComponent: LinkComponent, homeLinkProps }) => (
      <div className={CLASS_NAMES.container}>
        {!hideHomeLink && (
          <React.Fragment>
            {noLinkLogo ? (
              <div className={CLASS_NAMES.homeLink}>
                <Logo className={CLASS_NAMES.logo} />
              </div>
            ) : (
              <LinkComponent
                className={CLASS_NAMES.homeLink}
                {...homeLinkProps}
              >
                <Logo className={CLASS_NAMES.logo} />
              </LinkComponent>
            )}
          </React.Fragment>
        )}
        <div className={CLASS_NAMES.optionsWrapper}>
          <div className={CLASS_NAMES.options}>
            {help && <HelpButton />}
            {options.map((option, i) => (
              <div key={String(i)}>{option}</div>
            ))}
          </div>
          <CompanyInfo {...companyInfo} />
        </div>
        <DesktopMenu items={items} linkComponent={LinkComponent} />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopHeader-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  items: [],
  companyItems: [],
  companyName: undefined,
  companyProfilePicture: undefined,
  help: true,
  notice: undefined,
  logout: undefined,
  hideHomeLink: false,
  noLinkLogo: false,
};

ViewComponent.propTypes = {
  /**
   * Items
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      target: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired
  ),
  /**
   * CompanyLinkProps
   */
  companyItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      target: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired
  ),
  /**
   * Notice banner to show
   */
  notice: PropTypes.func,
  /**
   * Company name
   */
  companyName: PropTypes.string,
  /**
   * Company profile picture url
   */
  companyProfilePicture: PropTypes.string,
  /**
   * isLoading
   */
  isLoading: PropTypes.bool,
  /**
   * Display helpcrunch
   */
  help: PropTypes.bool,
  /**
   * Function for logging out
   */
  logout: PropTypes.func,
  /**
   * Function for logging out
   */
  hideHomeLink: PropTypes.bool,
  /**
   * Display logo without link component
   */
  noLinkLogo: PropTypes.bool,
};

export default ViewComponent;
