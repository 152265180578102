import clsx from 'clsx';
import {
  BUTTON_ICON_SIZE_VARIANTS,
  BUTTON_ICON_VARIANTS,
  CLASS_NAMES,
} from './ButtonIcon-constants';

const ContainerComponent = ({
  children,
  submit,
  variant,
  size,
  iconProps: extIconProps,
  className: extClassName,
  indicator: indicatorColor,
  indicatorPosition,
}) => {
  const { size: variantSize, iconProps: sizeIconProps } =
    BUTTON_ICON_SIZE_VARIANTS[size] || {};

  const { buttonBase, skeletonBase, indicatorBase, ...restClassNames } =
    CLASS_NAMES;

  const { circle, ...iconVariant } = BUTTON_ICON_VARIANTS[variant] || {};

  const button = clsx(
    buttonBase,
    iconVariant.className,
    circle && 'rounded-full',
    extClassName
  );

  const indicator = clsx(
    indicatorBase,
    iconVariant.indicator,
    `bg-${indicatorColor}`,
    indicatorPosition
  );

  const skeleton = clsx(skeletonBase, extClassName);

  const classNames = {
    ...restClassNames,
    skeleton,
    button,
    indicator,
  };

  const style = {
    width: variantSize || size,
    height: variantSize || size,
  };

  const iconProps = {
    ...sizeIconProps,
    ...iconVariant.iconProps,
    ...extIconProps,
  };

  const type = submit ? 'submit' : 'button';

  return children({
    iconProps,
    classNames,
    circle,
    style,
    type,
  });
};

ContainerComponent.displayName = 'ButtonIcon-Container';

export default ContainerComponent;
