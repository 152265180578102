/**
 * Creates helper function to check input validity
 * @param {Number} minValue
 * @param {Number} maxValue
 *
 * @returns {function} isAllowed
 */

export const makeIsAllowed =
  (minValue, maxValue) =>
  /**
   * Check if value is on the range
   * @param {Object} input
   * @param {Number} input.value
   *
   * @returns {Boolean} return true if value is in the rage
   */

  ({ value }) => {
    if (value <= maxValue && value >= minValue) return true;
    return false;
  };

/**
 * Abbreviate Number
 * @param {Number} value
 * @param {Number} fixed
 *
 * @returns {Array} value suffix
 */
export const abbreviateNumber = (value, fixed = 1) => {
  if (value === null) {
    return [null];
  } // terminate early

  if (value < 100000) {
    return [value];
  }

  const powerParts = value.toPrecision(2).split('e'); // get power

  const suffixIndex =
    powerParts.length === 1
      ? 0
      : Math.floor(Math.min(powerParts[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions

  const remainingValue =
    suffixIndex < 1
      ? value.toFixed(0 + fixed)
      : (value / 10 ** (suffixIndex * 3)).toFixed(1 + fixed); // divide by power

  const normalizedValue =
    remainingValue < 0 ? remainingValue : Math.abs(remainingValue); // enforce -0 is 0

  const suffix = ['', 'K', 'M', 'B', 'T'][suffixIndex]; // append power

  return [normalizedValue, suffix];
};
