export const HIGHLIGHT_PROPS = {
  highlightClassName: 'text-turquoise-300 bg-transparent',
  autoEscape: true,
};

export const CLASS_NAMES = {
  container: 'flex flex-1 flex-col w-full p-3 rounded-md hover:bg-neutral-100',
  title: 'flex flex-1 text-style-g-em text-neutral-600 mb-1 text-left',
  content:
    'w-full text-style-g text-neutral-600 whitespace-nowrap overflow-x-hidden text-left',
  seperator: 'text-style-h text-neutral-400 mx-2',
};

export const SKELETONS = {
  title: {
    width: 100,
  },
  companyNumber: {
    width: 100,
  },
  address: {
    width: 200,
  },
};

export const SEPERATOR = '•';
