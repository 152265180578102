import React from 'react';
import PropTypes from 'prop-types';

import { CLASS_NAMES, SKELETONS } from './RadioButton-constants';

import SkeletonLoader from '../../../SkeletonLoader';

const ViewComponent = React.forwardRef(
  ({ id, name, isLoading, ...restProps }, ref) => (
    <div className={CLASS_NAMES.container}>
      <SkeletonLoader
        isLoading={isLoading}
        wrapperClassName={CLASS_NAMES.skeleton}
        {...SKELETONS.radiobutton}
        afterLoading={
          <React.Fragment>
            <input
              {...restProps}
              ref={ref}
              type="radio"
              name={name}
              id={id || name}
              className={CLASS_NAMES.checkBox}
            />
            <label htmlFor={id || name} className={CLASS_NAMES.label}>
              <div className={CLASS_NAMES.icon} />
            </label>
          </React.Fragment>
        }
      />
    </div>
  )
);

ViewComponent.displayName = 'RadioCheck-view';

ViewComponent.defaultProps = {
  id: undefined,
  onChange: undefined,
  onBlur: undefined,
  value: undefined,
  defaultChecked: undefined,
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * value
   */
  value: PropTypes.any,
  /**
   * initialy checked
   */
  defaultChecked: PropTypes.bool,
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
