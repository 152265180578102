import FullScreen from './ModalLayoutFullScreen';
import SlideUp from './ModalLayoutSlideUp';
import Center from './ModalLayoutCenter';
import SlideLeft from './ModalLayoutSlideLeft';
import Lines from './ModalLayoutLines';

const ModalLayout = {
  FullScreen,
  SlideUp,
  Center,
  SlideLeft,
  Lines,
};

export default ModalLayout;
