export const INTERCOM_BUTTON_PROPS = {
  size: '40-20',
  variant: 'ghost-grey',
  key: 'intercom',
  id: 'intercom-launcher',
  iconProps: { stroke: undefined },
  icon: 'help',
};

export const HELP_BUTTON_PROPS = {
  size: '40-20',
  variant: 'ghost-grey',
  key: 'help',
  id: 'help-launcher',
  iconProps: { stroke: undefined },
  icon: 'help',
};

export const MENU_MODAL_ID = 'menu';

export const NOTIFICATIONS_MODAL_ID = 'notifications';

export const MENU_BUTTON_PROPS = {
  size: '40-20',
  variant: 'ghost-grey',
  key: 'menu',
  icon: 'menu',
};

export const DEFAULT_AVATAR_ICON_PROPS = {
  icon: 'toolbox',
  size: 16,
  stroke: 'lavender-200',
};
