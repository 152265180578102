import React from 'react';
import PropTypes from 'prop-types';

import FieldContainer from '../../FieldContainer';
import AutoSizeNumberFormatInput from '../../ControlledField/AutoSizeNumberFormatInput';

const ViewComponent = (props) => (
  <FieldContainer {...props} control>
    {(fieldProps) => <AutoSizeNumberFormatInput {...fieldProps} />}
  </FieldContainer>
);

ViewComponent.displayName = 'AutoSizeNumberFormatInput-view';

ViewComponent.defaultProps = {
  label: undefined,
  warning: undefined,
  wrapperProps: undefined,
  thousandSeparator: undefined,
  decimalSeparator: undefined,
  allowedDecimalSeparators: undefined,
  thousandsGroupStyle: undefined,
  decimalScale: undefined,
  fixedDecimalScale: undefined,
  prefix: undefined,
  suffix: undefined,
  format: undefined,
  mask: undefined,
  isLoading: undefined,
  type: 'tel',
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Field type
   */
  type: PropTypes.string,
  /**
   * Field label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Field warning message
   */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Other props that are passed to wrapper component
   */
  wrapperProps: PropTypes.any,
  /**
   * Add thousand separators on number
   */
  thousandSeparator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([true]),
  ]),
  /**
   * Support decimal point on a number
   */
  decimalSeparator: PropTypes.string,
  /**
   * Characters which when pressed result in a decimal separator. When missing, decimal separator and '.' are used
   */
  allowedDecimalSeparators: PropTypes.arrayOf(PropTypes.string),
  /**
   * Define the thousand grouping style, It support three types. thousand style
   */
  thousandsGroupStyle: PropTypes.oneOf(['thousand', 'lakh', 'wan']),
  /**
   * If defined it limits to given decimal scale
   */
  decimalScale: PropTypes.number,
  /**
   * If true it add 0s to match given decimalScale
   */
  fixedDecimalScale: PropTypes.bool,
  /**
   * Add a prefix before the number
   */
  prefix: PropTypes.string,
  /**
   * Add a suffix after the number
   */
  suffix: PropTypes.string,
  /**
   * If format given as hash string allow number input inplace of hash. If format given as function, component calls the function with unformatted number and expects formatted number.
   */
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * If mask defined, component will show non entered placed with masked value.
   */
  mask: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
