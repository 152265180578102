/**
 * Configurations related to the web application in general
 */

// The name of the cookie where the user token is stored.
export const USER_COOKIE_NAME =
  process.env.REACT_APP_USER_COOKIE_NAME ||
  'kj8kQnt2seA480RwLSodIXUz3Mf0TxKpUHzJeTBV6gNNKOrDxHHFiGnnsGE6rBm4mrumbMCFFo3BbEigG3hPa5JN8MFXJYbXa6NZbDkDSC1KzlB1ZXWkGS0UxawmhuQq';

export const USER_REDIRECT_NAME =
  process.env.REACT_APP_USER_REDIRECT_NAME || 'redirect';

// The domain for the cookie.
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

// days to persist cookie for
export const COOKIE_PERSIST_TIME = 14;

export const AMPLITUDE_DEVICE_ID_COOKIE_NAME =
  process.env.REACT_APP_AMPLITUDE_DEVICE_ID_COOKIE_NAME || 'amplitudeDeviceId';

export const LOGIN_URL =
  process.env.REACT_APP_LOGIN_URL ||
  'http://localhost:3001/?path=/story/firebase-auth--sign-in-with-google';
// 'http://localhost:3001/?path=/story/login--default';

export const HOME_URL =
  process.env.REACT_APP_HOME_URL ||
  'http://localhost:3001/?path=/story/firebaseauthwrapper--default';
// 'http://localhost:3001/?path=/story/dashboard--default';

export const COOKIE_OPTIONS = {
  domain: COOKIE_DOMAIN,
  path: '/',
};
