import { useCallback, useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash.get';

import { useIsDesktop } from '../../../../hooks';
import ModalsWrapper from '../../../ModalsWrapper';

const ContainerComponent = ({ children, name, ...props }) => {
  const isDesktop = useIsDesktop();

  const { showModal, hideModal } = useContext(ModalsWrapper.Context);

  const modalId = `${name}-search-modal`;

  const companyName = useWatch({ name });

  const {
    setValue,
    formState: { errors },
    isLoading,
  } = useFormContext();

  /**
   * Sets field values to form
   * @param {Object} company
   */
  const setFieldValues = useCallback(
    (company = {}) => {
      setValue(name, company.companyName);
      setValue(props.companyNumberName, company.companyNumber);
      setValue(props.addressLineOneName, company.addressLineOne);
      setValue(props.addressLineTwoName, company.addressLineTwo);
      setValue(props.postalCodeName, company.postalCode);
      setValue(props.cityName, company.city);
    },
    [
      name,
      props.addressLineOneName,
      props.addressLineTwoName,
      props.cityName,
      props.companyNumberName,
      props.postalCodeName,
      setValue,
    ]
  );

  /**
   * Handles select company
   * @param {Object} company
   */
  const handleSelect = useCallback(
    (company) => {
      setFieldValues(company);

      if (!isDesktop) {
        hideModal(modalId);
      }
    },
    [hideModal, isDesktop, modalId, setFieldValues]
  );

  /**
   * Handles focus
   */
  const handleFocus = useCallback(() => {
    if (!isDesktop) {
      showModal(modalId);
    }
  }, [isDesktop, modalId, showModal]);

  /**
   * Handles click
   */
  const handleClick = useCallback(() => {
    if (isDesktop) {
      setValue(props.companyFocusName, Math.random());
    }
  }, [isDesktop, props.companyFocusName, setValue]);

  /**
   * Handles unselect company
   */
  const handleUnSelect = useCallback(() => {
    setFieldValues();
  }, [setFieldValues]);

  /**
   * Hiddes modal on desktop
   */
  useEffect(() => {
    if (isDesktop) {
      hideModal(modalId);
    } else {
      setValue(props.companySearchName, undefined);
    }
  }, [hideModal, isDesktop, modalId, name, props.companySearchName, setValue]);

  const error = errors && get(errors, name);

  const searchInputProps = {
    isLoading,
    error,
    onFocus: handleFocus,
    onClick: handleClick,
  };

  const searchResultsProps = { modalId, handleSelect };

  return children({
    error,
    companyName,
    handleUnSelect,
    searchInputProps,
    searchResultsProps,
  });
};

ContainerComponent.displayName = 'CompanyLookup-Container';

export default ContainerComponent;
