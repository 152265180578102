import { JOB_STATUSES } from '@kanda-utils/library';
import { KandaAuthService } from './kandaAuthService';

import {
  RETRIEVE_JOB_QUERY,
  RETRIEVE_PUBLIC_JOB_QUERY,
  CREATE_JOB_MUTATION,
  CREATE_DRAFT_MUTATION,
  RETRIEVE_JOB_QUERY_OPTIONS,
  RETRIEVE_PUBLIC_JOB_QUERY_OPTIONS,
  UPDATE_DRAFT_MUTATION,
  CREATE_JOB_FROM_DRAFT_MUTATION,
  LIST_JOB_QUERY_OPTIONS,
  SEARCH_JOB_QUERY,
  ADD_NOTE_MUTATION,
  UPDATE_NOTE_MUTATION,
  CREATE_EXTERNAL_JOB_DRAFT_MUTATION,
  UPDATE_EXTERNAL_JOB_DRAFT_MUTATION,
  CREATE_EXTERNAL_JOB_FROM_DRAFT_MUTATION,
  CHECK_USED_FREE_JOBS_QUERY,
  QUERY_BY_FIELDS,
} from './jobService-constants';

import config from '../config';
import { Service } from '../lib';
import {
  formatSortBy,
  formatTypesenceData,
  camelize,
  decamelize,
  formatFilterBy,
} from '../utils';

/**
 * Job Service
 * @typedef {Object} JobServiceOptions
 * @property {String} GraphQLURL graphQL url
 * @property {Object} authService graphQL url
 *
 * Job List Options
 * @typedef {Object} JobListOptions
 * @property {String} query search ex:Lorem
 * @property {Number} page page
 * @property {Number} perPage perPage
 * @property {Array.Array} sortBy soryBy ex:[['customerName', 1], ['status', -1]]
 * @property {Object} filters filters
 * @property {Array.String} filters.status status ex:[JOB_STATUSES.DRAFT, JOB_STATUSES.ACCEPTED]
 * @property {Object} filters.price price ex:{ $gt: 10000, $lt: 100000 }
 * @property {String} filters.description description ex:some description
 * @property {String} filters.customerName customer name
 * @property {String} skipPayments dosen't reslove payments
 * @property {String} skipLineItems dosen't reslove line items
 * @property {String} skipNotes dosen't reslove notes
 *
 * Line Item
 * @typedef {Object} LineItem
 * @property {String} id
 * @property {String} title
 * @property {String} description
 * @property {Number} quantity
 * @property {Number} price
 * @property {String} vat
 *
 * Payment
 * @typedef {Object} Payment
 * @property {String} id
 * @property {String} status
 * @property {Number} amount
 * @property {Date} createdAt
 * @property {Date} updatedAt
 *
 * Note
 * @typedef {Object} Note
 * @property {String} id
 * @property {String} content
 * @property {Date} createdAt
 * @property {Date} updatedAt
 *
 * Job Input
 * @typedef {Object} JobInput
 * @property {String} title
 * @property {String} description
 * @property {String} depositType
 * @property {Number} depositValue
 * @property {String} customerId
 * @property {Array.String} paymentMethod
 * @property {Array.LineItem} lineItems
 *
 * Job
 * @typedef {Object} Job
 * @property {String} id
 * @property {String} title
 * @property {String} description
 * @property {String} depositType
 * @property {Number} depositValue
 * @property {String} customerId
 * @property {String} userId
 * @property {String} status
 * @property {Array.String} paymentMethod
 * @property {Array.LineItem} lineItems
 * @property {Array.Note} notes
 * @property {Date} createdAt
 * @property {Date} updatedAt
 *
 * Job Create
 * @typedef {Object} JobCreate
 * @property {String} id
 */

export const JobService = {
  retrieve: 'JobService-retrieve',
  retrievePublic: 'JobService-retrievePublic',
  count: 'JobService-count',
  list: 'JobService-list-USECTX',
  create: 'JobService-create',
  createDraft: 'JobService-createDraft',
  createExternalJobDraft: 'JobService-createExternalJobDraft',
  updateExternalJobDraft: 'JobService-updateExternalJobDraft',
  createExternalJobFromDraft: 'JobService-createExternalJobFromDraft',
  updateDraft: 'JobService-updateDraft',
  createJobFromDraft: 'JobService-createJobFromDraft',
  addNote: 'JobService-addNote',
  updateNote: 'JobService-updateNote',
  checkUsedFreeJobs: 'JobService-checkUsedFreeJobs',
};

const JobServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {JobServiceOptions} options
   */
  constructor({ graphQLURL, authService }) {
    super({ graphQLURL, authService, keys: JobService });
  }

  /**
   * Retrieve a job
   * @param {String} jobId
   * @returns {Job} job
   */
  async [JobService.retrieve](jobId, options = {}) {
    const variables = { jobId, ...RETRIEVE_JOB_QUERY_OPTIONS, ...options };

    const res = await super.query(RETRIEVE_JOB_QUERY, variables);

    return camelize(res.data.job);
  }

  /**
   * Retrieve a public job
   * @param {String} jobId
   * @returns {Job} job
   */
  async [JobService.retrievePublic](jobId, options = {}) {
    const variables = {
      jobId,
      ...RETRIEVE_PUBLIC_JOB_QUERY_OPTIONS,
      ...options,
    };

    const res = await super.query(RETRIEVE_PUBLIC_JOB_QUERY, variables);

    return camelize(res.data.publicJob);
  }

  /**
   * Add note
   * @param {String} jobId
   * @param {String} content
   * @returns {Object} note
   */
  async [JobService.addNote](jobId, content) {
    const variables = { id: jobId, input: { content } };

    const res = await super.query(ADD_NOTE_MUTATION, variables);

    super.refetch([JobService.retrieve, jobId]);

    return camelize(res.data.addJobNote);
  }

  /**
   * Update note
   * @param {String} noteId
   * @param {String} jobId
   * @param {String} content
   * @returns {Object} note
   */
  async [JobService.updateNote](noteId, jobId, content) {
    const variables = { noteId, jobId, input: { content } };

    const res = await super.query(UPDATE_NOTE_MUTATION, variables);

    super.refetch([JobService.retrieve, jobId]);

    return camelize(res.data.updateJobNote);
  }

  /**
   * Count jobs
   * This is needed mostly so it won't be refetched when a draft is created
   * @param {JobListOptions} options
   * @returns {Job} job
   */
  async [JobService.count]() {
    const perPage = 10;

    const variables = {
      skipPayments: true,
      skipLineItems: true,
      skipCustomer: true,
      skipLoanApplications: true,
      skipNotes: true,
      skipQuote: true,
      input: decamelize({
        query: '*',
        perPage,
        page: 1,
        filterBy: formatFilterBy({
          status: Object.values(JOB_STATUSES).filter(
            (status) => status !== JOB_STATUSES.DRAFT,
          ),
        }),
      }),
    };

    const res = await super.query(SEARCH_JOB_QUERY, variables);

    const formattedData = formatTypesenceData(res.data.searchJob, perPage);

    return { total: formattedData?.items?.length };
  }

  /**
   * List jobs
   * @param {Object} ctx
   * @param {JobListOptions} options
   * @returns {Job} job
   */
  async [JobService.list](ctx, options = {}) {
    const { query, perPage, page, sortBy, filters, ...skipOptions } = {
      ...LIST_JOB_QUERY_OPTIONS,
      ...options,
    };

    const variables = {
      ...skipOptions,
      input: decamelize({
        query,
        perPage,
        sortBy: formatSortBy(sortBy),
        filterBy: formatFilterBy(filters),
        queryBy: QUERY_BY_FIELDS,
        page,
      }),
    };

    // TODO: call LIST_JOB_QUERY if there are no filters and query parameters
    const res = await super.query(SEARCH_JOB_QUERY, {
      ...variables,
      skipPayments: true,
      skipLineItems: true,
      skipCustomer: true,
      skipLoanApplications: true,
      skipNotes: true,
      skipQuote: true,
    });

    const formattedData = formatTypesenceData(res.data.searchJob, perPage);

    (async () => {
      try {
        const fullResolvedQuery = await super.query(
          SEARCH_JOB_QUERY,
          variables,
        );

        const fullResolvedformattedData = formatTypesenceData(
          fullResolvedQuery.data.searchJob,
          perPage,
        );

        this.mutate(ctx.originalKey, fullResolvedformattedData, false);
      } catch (e) {
        console.log(e);
        this.refetch(ctx.originalKey);
      }
    })();

    return formattedData;
  }

  /**
   * Creates a new job
   * @param {JobInput} payload
   * @returns {JobCreate}
   */
  async [JobService.create](payload) {
    const variables = { input: decamelize(payload) };

    const res = await super.query(CREATE_JOB_MUTATION, variables);

    super.refetch([JobService.list, '*']);

    return camelize(res.data.sendJob);
  }

  /**
   * Creates a new job draft
   * @param {JobInput} payload
   * @returns {JobCreate}
   */
  async [JobService.createDraft](payload) {
    const variables = { input: decamelize(payload) };

    const res = await super.query(CREATE_DRAFT_MUTATION, variables);

    super.refetch([JobService.list, '*']);

    return camelize(res.data.createJobDraft);
  }

  /**
   * Creates a new external job draft
   * @param {JobInput} payload
   * @returns {JobCreate}
   */
  async [JobService.createExternalJobDraft](payload) {
    const variables = { input: decamelize(payload) };

    const res = await super.query(
      CREATE_EXTERNAL_JOB_DRAFT_MUTATION,
      variables,
    );

    super.refetch([JobService.list, '*']);

    return camelize(res.data.createExternalJobDraft);
  }

  /**
   * Updates draft
   * @param {JobInput} payload
   * @param {String} jobId The job ID of the draft to update
   * @returns {JobCreate}
   */
  async [JobService.updateDraft](payload, jobId) {
    const variables = {
      input: decamelize(payload),
      updateJobDraftId: jobId,
    };

    const res = await super.query(UPDATE_DRAFT_MUTATION, variables);

    super.refetch([JobService.list, '*']);
    super.refetch([JobService.retrieve, jobId]);

    return camelize(res.data.updateJobDraft);
  }

  /**
   * Updates external job draft
   * @param {ExternalJobInput} payload
   * @param {String} jobId The job ID of the draft to update
   * @returns {ExternalJobCreate}
   */
  async [JobService.updateExternalJobDraft](payload, jobId) {
    const variables = {
      input: decamelize(payload),
      updateJobDraftId: jobId,
    };

    const res = await super.query(
      UPDATE_EXTERNAL_JOB_DRAFT_MUTATION,
      variables,
    );

    super.refetch([JobService.list, '*']);
    super.refetch([JobService.retrieve, jobId]);

    return camelize(res.data.updateExternalJobDraft);
  }

  /**
   * Creates an job from a draft
   * @param {JobInput} payload
   * @returns {JobCreate}
   */
  async [JobService.createJobFromDraft](payload, jobId) {
    const variables = {
      input: decamelize(payload),
      sendJobFromDraftId: jobId,
    };

    const res = await super.query(CREATE_JOB_FROM_DRAFT_MUTATION, variables);

    super.refetch([JobService.list, '*']);
    super.refetch([JobService.retrieve, jobId]);
    super.refetch([JobService.count]);

    return camelize(res.data.sendJobFromDraft);
  }

  /**
   * Creates external job from a draft
   * @param {ExternalJobInput} payload
   * @returns {ExternalJobCreate}
   */
  async [JobService.createExternalJobFromDraft](payload, jobId) {
    const variables = {
      id: jobId,
      input: decamelize(payload),
    };

    const res = await super.query(
      CREATE_EXTERNAL_JOB_FROM_DRAFT_MUTATION,
      variables,
    );

    super.refetch([JobService.list, '*']);
    super.refetch([JobService.retrieve, jobId]);
    super.refetch([JobService.count]);

    return camelize(res.data.sendExternalJobFromDraft);
  }

  /**
   * Checks if user has used up free jobs
   * @returns {Boolean} flag of whether user has used 3 or more jobs
   */
  async [JobService.checkUsedFreeJobs]() {
    const res = await super.query(CHECK_USED_FREE_JOBS_QUERY);

    return camelize(res.data.jobs);
  }
};

export default new JobServiceImplementation({
  graphQLURL: config.GRAPHQL_GATEWAY,
  authService: KandaAuthService,
});
