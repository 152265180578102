import { SignedRequestService } from './signedRequestService';

import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';
import { uploadFile } from '../lib/fileService-functions';

/**
 * Omni Service
 * @typedef {Object} OmniUploadDocumentServiceOptions
 * @property {Object.OmniUploadDocumentEnterpriseObject} enterprise base url
 * @property {Object.OmniUploadDocumentDataObject} data authService
 *
 * OmniUploadDocumentEnterpriseObject
 * @typedef {Object} OmniUploadDocumentEnterpriseObject
 * @property {String} id enterprise ID of tradesperson who sent out the job
 *
 * OmniUploadDocumentDataObject
 * @typedef {Object} OmniUploadDocumentDataObject
 * @property {String} documentType document type from dropdown selection
 * @property {GUID} loanApplicationId Omni application ID GUID
 * @property {String} fileType file extension
 */

export const OmniUploadDocumentService = {
  upload: 'OmniUploadDocumentService-upload',
};

const OmniUploadDocumentServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {OmniUploadDocumentServiceOptions} options
   */
  constructor({ baseURL, authService }) {
    super({ baseURL, authService, keys: OmniUploadDocumentService });
  }

  /**
   * Fatches signed url
   * @param {OmniUploadDocumentObject} payload
   */
  async getSignedUrl(payload) {
    const signedRequest = await super.use([
      SignedRequestService.encode,
      payload,
    ]);

    const data = await this.post('/uploads', decamelize({ signedRequest }));

    return data.upload_url;
  }

  /**
   * Retrieve omni initial data
   * @returns {OmniInitialData} initial data
   */
  async [OmniUploadDocumentService.upload](payload, file, onProgress) {
    const signedUrl = await this.getSignedUrl(payload, onProgress);

    const result = await uploadFile(signedUrl, file, onProgress);

    return camelize(result);
  }
};

export default new OmniUploadDocumentServiceImplementation({
  baseURL: config.OMNI_UPLOAD_DOCUMENTS_API_URL,
});
