import { useContext } from 'react';

import Context from '../../Context';

/* eslint-disable react/prop-types */
const ContainerComponent = ({ children }) => {
  const { isLoading, description, classNames } = useContext(Context);

  if (!description && !isLoading) return null;

  return children({ description: description || '', isLoading, classNames });
};

ContainerComponent.displayName = 'LineItem-Description-container';

export default ContainerComponent;
