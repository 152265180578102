import fetch from 'cross-fetch';

import { encodingType } from './types';

import { convertJSONToURI } from '../utils';

/**
 * Represents the API request.
 * @typedef {Object} RequestOptions
 * @property {string} accept accept MIME types
 * @property {string} contentType content type
 * @property {Object} authService auth service
 */
class Request {
  /**
   * Constructor
   * @param {RequestOptions} options
   */
  constructor({
    accept = encodingType.JSON,
    contentType = encodingType.JSON,
    authService,
  } = {}) {
    this.accept = accept;
    this.contentType = contentType;
    this.authService = authService;
  }

  /**
   * Builds the request header.
   * @returns {Object} The request header object.
   */
  async buildRequestHeader() {
    const requestHeader = {
      Accept: this.accept,
      'Content-Type': this.contentType,
    };

    // Add the `Authorization` header if the authKey is included.
    if (this.authService) {
      return this.authService.buildAuthorizationHeader(requestHeader);
    }

    return requestHeader;
  }

  /**
   * Builds request body.
   * @param {Object=} body Represents the request body.
   * @returns {Object} body
   */
  buildBody(body) {
    switch (this.contentType) {
      case encodingType.JSON:
        return JSON.stringify(body);

      case encodingType.FORM:
        return convertJSONToURI(body);

      default:
        return body;
    }
  }

  /**
   * Builds the request options.
   * @param {String=} method The HTML request method.
   * @param {Object=} body Represents the request body.
   * @returns {Object} The fetch request options.
   */
  async buildRequestOptions({ method = 'GET', body = null } = {}) {
    return {
      ...(body && { body: this.buildBody(body) }),
      method,
      headers: await this.buildRequestHeader(),
    };
  }

  /**
   * Makes a GET request to a HTTP endpoint.
   * @param {String} url The URL to send the request to.
   */
  async get(url) {
    const requestOptions = await this.buildRequestOptions();
    return fetch(url, requestOptions);
  }

  /**
   * Makes a POST request to the HTTP endpoint.
   * @param {String} url The URL to send the request to.
   * @param {Object} body The request body.
   */
  async post(url, body) {
    const requestOptions = await this.buildRequestOptions({
      method: 'POST',
      body,
    });
    return fetch(url, requestOptions);
  }

  /** Makes a PUT request to the HTTP endpoint.
   * @param {String} url The URL to send the request to.
   * @param {Object} body The request body.
   */
  async put(url, body) {
    const requestOptions = await this.buildRequestOptions({
      method: 'PUT',
      body,
    });
    return fetch(url, requestOptions);
  }

  /** Makes a PUT request to the HTTP endpoint.
   * @param {String} url The URL to send the request to.
   * @param {Object} body The request body.
   */
  async patch(url, body) {
    const requestOptions = await this.buildRequestOptions({
      method: 'PATCH',
      body,
    });
    return fetch(url, requestOptions);
  }
}

export default Request;
