import { useWatch } from 'react-hook-form';

import useSearch from '../SearchResults-useSearch';

/* eslint-disable react/prop-types */
const ContainerComponent = ({
  children,
  companySearchName,
  companyFocusName,
}) => {
  const [query, visible] = useWatch({
    name: [companySearchName, companyFocusName],
  });

  const { results, isLoading, searchWords } = useSearch(query);

  if (!query || results.length === 0) return null;

  return children({
    visible,
    results,
    isLoading,
    searchWords,
  });
};

ContainerComponent.displayName = 'CompanyLookup-Desktop-container';

export default ContainerComponent;
