import { useState, useCallback } from 'react';

import { getMethod } from '../fetcher';

/**
 * Hook to handle mutations
 * @param {Object} rawMethod service method
 */
const useMutate = (rawMethod) => {
  const method =
    typeof rawMethod === 'string' ? getMethod(rawMethod) : rawMethod;

  const [error, setError] = useState();
  const [data, setData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Calles the method and handles loading and error
   * @param {Array} arg
   */
  const mutate = useCallback(
    async (...arg) => {
      /**
       * Sets error undefined & is loading true before requests
       */
      setError();
      setIsSubmitting(true);

      try {
        /**
         * calles method with payload and sets current data as data
         */
        const res = await method(...arg);
        setData(res);

        /**
         * return current data in function in case we need to add .then on mutate
         */
        return { data: res };
      } catch (e) {
        /**
         * logs the error for debugging
         */
        console.log('Mutate error', e.errors || e);

        setError(e);

        return { error: e };
      } finally {
        /**
         * finally sets loading false
         */
        setIsSubmitting(false);
      }
    },
    [method],
  );

  return {
    mutate,
    error,
    data,
    isSubmitting,
    isLoading: isSubmitting, // deprecated
  };
};

export default useMutate;
