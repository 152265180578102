/**
 * function to decide whether to show or not the separation dot
 * @param {number} index - index of item
 * @param {Element} footer - footer element
 * @returns boolean
 */

export const showDot = (index, footer) => {
  if (index === 0 && !footer) {
    return false;
  }

  return true;
};
