import React from 'react';

import Container from './Total-container';

import Price from '../../../Price';

const ViewComponent = () => (
  <Container>
    {({ total, isLoading, classNames }) => (
      <Price isLoading={isLoading} {...classNames.total} amount={total} />
    )}
  </Container>
);

ViewComponent.displayName = 'LineItem-Total-view';

export default ViewComponent;
