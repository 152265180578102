import { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { extractName } from './SelectionModal-functions';

/**
 * Handles button text logic
 * @param {Array.<Object>} options - Options array for select
 * @param {String} options.name - Name of selection
 * @param {String} options.value - Value of selection
 * @param {String} name - name
 *
 * @returns {String} button text
 */
const useButtonText = ({ options, name }) => {
  const value = useWatch({ name });

  const [buttonText, setButtonText] = useState(extractName(options, value));

  // Effect sets button text on mount and runs whenever any of the props updates
  useEffect(() => {
    setButtonText(extractName(options, value));
  }, [value, name, options]);

  return buttonText;
};

export default useButtonText;
