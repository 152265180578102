export const FORMATTER = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: null,
  suffixFromNow: null,
  seconds: 'just now',
  minute: '%dm ago',
  minutes: '%dm ago',
  hour: '%dh ago',
  hours: '%dh ago',
  day: '%dd ago',
  days: '%dd ago',
  week: '%dw ago',
  weeks: '%dw ago',
  month: '%dmo ago',
  months: '%dmo ago',
  year: '%dy ago',
  years: '%dy ago',
  wordSeparator: ' ',
};
