import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import Container from './PhoneNumberInput-container';
import { BASE_OPTIONS } from './PhoneNumberInput-constants';

const ViewComponent = ({
  id,
  options: _options,
  defaultValue,
  placeholder,
  error,
  className: extClassName,
  isLoading,
  countryCodeName,
  // countryCodeProps,
  phoneNumberName,
  phoneNumberProps,
}) => (
  <Container
    options={_options}
    placeholder={placeholder}
    error={error}
    className={extClassName}
    isLoading={isLoading}
    name={countryCodeName}
    defaultValue={defaultValue}
  >
    {({ classNames, skeletonClasses, code }) => (
      <div className={classNames.container}>
        {isLoading ? (
          <div className={skeletonClasses}>
            <Skeleton />
          </div>
        ) : (
          <React.Fragment>
            <div className={classNames.selectWrapper}>
              {/*
              <select
                className={classNames.select}
                options={options}
                required
                name={countryCodeName}
                {...countryCodeProps}
              >
                {options.map(({ value, name }) => (
                  <option
                    key={value}
                    selected={value === ''}
                    disabled={value === ''}
                    value={value}
                  >
                    {name}
                  </option>
                ))}
              </select>
              */}
              <span className={classNames.country}>UK</span>
            </div>
            <label htmlFor={id} className={classNames.code}>
              {code}
            </label>
            <input
              type="tel"
              id={id}
              className={classNames.phoneInput}
              name={phoneNumberName}
              {...phoneNumberProps}
            />
          </React.Fragment>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'UncontrolledPhoneNumberInput-view';

ViewComponent.defaultProps = {
  id: 'phoneNumber',
  options: BASE_OPTIONS,
  defaultValue: '+44',
  forwardRef: undefined,
  placeholder: undefined,
  error: undefined,
  className: undefined,
  isLoading: undefined,
  phoneNumberProps: undefined,
  // countryCodeProps: undefined,
};

ViewComponent.propTypes = {
  /**
   * Name of the input required for form to work
   */
  phoneNumberName: PropTypes.string.isRequired,
  /**
   * Name of the input required for form to work
   */
  countryCodeName: PropTypes.string.isRequired,
  /**
   * ForwardRef
   */
  forwardRef: PropTypes.any,
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  phoneNumberProps: PropTypes.object,
  // /**
  //  * This function is triggered when the input is unfocused
  //  */
  // countryCodeProps: PropTypes.object,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default ViewComponent;
