import React from 'react';
import PropTypes from 'prop-types';

import Container from './HeaderMain-container';

import HeaderBase from '../HeaderBase';
import { ReactComponent as KandaLogo } from '../../../assets/logos/kanda-wordmark.svg';

import { MENU_MODAL_ID, NOTIFICATIONS_MODAL_ID } from '../../../constants';

const ViewComponent = ({
  skip,
  onSkip,
  className,
  linkComponent: LinkComponent,
  logoProps,
  help,
}) => (
  <Container skip={skip} onSkip={onSkip}>
    {({ options, homeLinkProps }) => (
      <HeaderBase help={help} className={className} options={options} lines>
        <LinkComponent {...homeLinkProps}>
          <KandaLogo height={20} {...logoProps} />
        </LinkComponent>
      </HeaderBase>
    )}
  </Container>
);

ViewComponent.displayName = 'HeaderMain-view';

ViewComponent.defaultProps = {
  className: undefined,
  skip: undefined,
  onSkip: undefined,
  linkComponent: 'a',
  help: true,
  logoProps: {},
};

ViewComponent.propTypes = {
  /**
   * ClassName for main header
   */
  logoProps: PropTypes.object,
  /**
   * Show help
   */
  help: PropTypes.bool,
  /**
   * ClassName for main header
   */
  className: PropTypes.string,
  /**
   * Displays skip button
   */
  skip: PropTypes.bool,
  /**
   * On Skip function callback
   */
  onSkip: PropTypes.func,
  /**
   * linkComponent
   */
  linkComponent: PropTypes.elementType,
};

ViewComponent.MENU_MODAL_ID = MENU_MODAL_ID;
ViewComponent.NOTIFICATIONS_MODAL_ID = NOTIFICATIONS_MODAL_ID;

export default ViewComponent;
