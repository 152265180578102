import { useContext } from 'react';

import Context from '../../Context';

const ContainerComponent = ({ children }) => {
  const { job } = useContext(Context);

  return children({
    lineItems: job?.lineItems || [],
  });
};

ContainerComponent.displayName = 'Render-LineItems-container';

export default ContainerComponent;
