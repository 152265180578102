import React, { useContext } from 'react';

import { CLASS_NAMES } from './DesktopHeader-constants';

import Button from '../Button';
import ModalsWrapper from '../ModalsWrapper';
import ConfigWrapper from '../ConfigWrapper';
import { MENU_BUTTON_PROPS, MENU_MODAL_ID } from '../../constants';

const ContainerComponent = ({ children }) => {
  const { showAnimatedModal } = useContext(ModalsWrapper.Context);
  const { linkComponent, homeLinkProps } = useContext(ConfigWrapper.Context);

  // const handleNotification = () => showModal(NOTIFICATIONS_MODAL_ID);
  const handleMenu = () => showAnimatedModal(MENU_MODAL_ID);

  const options = [
    // <Button.Icon
    //   key="notification"
    //   variant="large"
    //   icon="notification"
    //   onClick={handleNotification}
    // />,
    <Button.Icon
      key="menu"
      {...MENU_BUTTON_PROPS}
      className={CLASS_NAMES.mobileMenu}
      onClick={handleMenu}
    />,
  ];

  return children({ options, linkComponent, homeLinkProps });
};

ContainerComponent.displayName = 'DesktopHeader-Container';

export default ContainerComponent;
