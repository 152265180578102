import React from 'react';
import PropTypes from 'prop-types';

import Container from './CompanyLookup-container';
import { SelectedCompany, SearchResults } from './components';
import { FIELDS, CLASS_NAMES } from './CompanyLookup-constants';

import Input from '../Input';
import BreakPoints from '../../../BreakPoints';

const ViewComponent = ({
  name,
  label,
  placeholder,
  soleTraderButton,
  companySearchName,
  companyFocusName,
  ...props
}) => (
  <Container
    name={name}
    companySearchName={companySearchName}
    companyFocusName={companyFocusName}
    {...props}
  >
    {({
      handleUnSelect,
      companyName,
      searchInputProps,
      searchResultsProps,
    }) => (
      <React.Fragment>
        {companyName ? (
          <SelectedCompany
            name={name}
            {...props}
            removeSelected={handleUnSelect}
          />
        ) : (
          <div className={CLASS_NAMES.searchContainer}>
            <div className={CLASS_NAMES.inputWrapper}>
              <Input
                {...FIELDS.search}
                name={companySearchName}
                label={label}
                placeholder={placeholder}
                {...searchInputProps}
              />
              <BreakPoints desktop={soleTraderButton} />
            </div>
            <SearchResults
              companyFocusName={companyFocusName}
              companySearchName={companySearchName}
              {...searchResultsProps}
            />
          </div>
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'CompanyLookup-view';

ViewComponent.defaultProps = {
  name: 'companyName',
  companySearchName: 'companySearch',
  companyFocusName: 'companyFocus',
  label: 'Lookup your company',
  placeholder: 'Search Companies House',
  cityName: 'city',
  addressLineOneName: 'addressLineOne',
  addressLineTwoName: 'addressLineTwo',
  postalCodeName: 'postalCode',
  companyNumberName: 'companyNumber',
  selectedLabel: 'Your company',
  removeSelectedLabel: 'Choose different company',
  soleTraderButton: null,
};

ViewComponent.propTypes = {
  /**
   * Company search field name
   */
  name: PropTypes.string,
  /**
   * Field label
   */
  label: PropTypes.string,
  /**
   * Field selected label
   */
  selectedLabel: PropTypes.string,
  /**
   * Field remove selected label
   */
  removeSelectedLabel: PropTypes.string,
  /**
   * Company search field label
   */
  placeholder: PropTypes.string,
  /**
   * City field name
   */
  cityName: PropTypes.string,
  /**
   * Company search name
   */
  companySearchName: PropTypes.string,
  /**
   * Company focus name
   */
  companyFocusName: PropTypes.string,
  /**
   * Address line one field name
   */
  addressLineOneName: PropTypes.string,
  /**
   * Address line two field name
   */
  addressLineTwoName: PropTypes.string,
  /**
   * Postal code field name
   */
  postalCodeName: PropTypes.string,
  /**
   * Company number field name
   */
  companyNumberName: PropTypes.string,
  /**
   * Sole trader button
   */
  soleTraderButton: PropTypes.element,
};

export default ViewComponent;
