import React from 'react';
import PropTypes from 'prop-types';
import { Range } from 'react-range';

import { CLASS_NAMES, DEFAULT_COLORS } from './RangeSlider-constants';
import Container from './RangeSlider-container';
import { Thumb, Track } from './components';

const ViewComponent = ({
  value,
  defaultValue,
  min,
  max,
  step,
  colors,
  onChange,
}) => (
  <Container
    value={value}
    min={min}
    max={max}
    defaultValue={defaultValue}
    colors={colors}
    onChange={onChange}
  >
    {({ handleChange, values, trackStyle }) => (
      <div className={CLASS_NAMES.container}>
        <Range
          values={values}
          step={step}
          min={min}
          max={max}
          onChange={handleChange}
          renderTrack={({ props, children }) => (
            <Track {...props} trackStyle={trackStyle}>
              {children}
            </Track>
          )}
          renderThumb={({ props }) => <Thumb {...props} />}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'RangeSlider-view';

ViewComponent.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  step: 1,
  max: 100,
  min: 0,
  colors: DEFAULT_COLORS,
  onChange: undefined,
};

ViewComponent.propTypes = {
  /**
   * Initial value, it can be array of values or a number
   */
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  /**
   * Current value, it can be array of values or a number
   */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  /**
   * The minimal distance between two values. Can be decimal. Default is 1.
   */
  step: PropTypes.number,
  /**
   * The range end. Can be decimal or negative. Default is 100.
   */
  max: PropTypes.number,
  /**
   * The range start. Can be decimal or negative. Default is 0.
   */
  min: PropTypes.number,
  /**
   * Colors
   */
  colors: PropTypes.arrayOf(PropTypes.string),
  /**
   * Event to handle change
   */
  onChange: PropTypes.func,
};

export default ViewComponent;
