import { useRef, useEffect } from 'react';

const ESC_KEY = 27;

/**
 * Hook that detect when the user clicks Esc key
 * @param {funcion} callback
 * @returns ref to pas to that component
 */

const useEscClick = (callback) => {
  /**
   * Stores callback on ref so the effect can be called on mount / unmount
   */
  const callbackRef = useRef(callback);

  /**
   * Sets listeners on mount and removes them on unmount,
   * calls callback if user clicks ESC key
   */
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === ESC_KEY && callbackRef.current)
        callbackRef.current(event);
    };

    document.addEventListener('keydown', handleEsc);
    return () => document.removeEventListener('keydown', handleEsc);
  }, []);
};

export default useEscClick;
