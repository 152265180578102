import { useContext, useMemo } from 'react';

import Context from './Context';
import { CREATOR } from './Render-constants';

const ContainerComponent = ({ children }) => {
  const { job } = useContext(Context);

  const author = useMemo(() => {
    if (!job?.company?.name) return CREATOR;
    return job.company.name;
  }, [job]);

  const title = useMemo(() => {
    if (!job?.details?.reference) return `Job - ${author}`;
    return `Job - ${author} - ${job.details.reference}`;
  }, [job, author]);

  return children({
    title,
    author,
  });
};

ContainerComponent.displayName = 'Render-Provider-container';

export default ContainerComponent;
