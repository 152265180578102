import React from 'react';
import PropTypes from 'prop-types';

import Continer from './Mobile-container';
import { Header, Item } from './components';

import Modal from '../../../../../../Modal';

const ViewComponent = ({ modalId, handleSelect }) => (
  <Modal.Container id={modalId}>
    {({ handleClose }) => (
      <Continer>
        {({ handleSearch, results, isLoading, searchWords }) => (
          <Modal.Layout.FullScreen
            header={
              <Header handleClose={handleClose} handleSearch={handleSearch} />
            }
          >
            {results.map((item) => (
              <Item
                handleSelect={handleSelect}
                searchWords={searchWords}
                key={item.companyNumber}
                company={item}
                isLoading={isLoading}
              />
            ))}
          </Modal.Layout.FullScreen>
        )}
      </Continer>
    )}
  </Modal.Container>
);

ViewComponent.displayName = 'CompanyLookup-Mobile-view';

ViewComponent.defaultProps = {
  handleSelect: undefined,
};

ViewComponent.propTypes = {
  /**
   * Modal id
   */
  modalId: PropTypes.string.isRequired,
  /**
   * handle Select customer
   */
  handleSelect: PropTypes.func,
};

export default ViewComponent;
