import { KandaAuthService } from './kandaAuthService';

import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';

/**
 * Trade Info Service
 * @typedef {Object} TradeInfoServiceOptions
 * @property {String} baseURLS base urls
 * @property {Object} authService authService
 *
 * TradeInfoServiceData
 * @typedef {Object} TradeInfoServiceData
 * @property {string} tradeBodyNumber
 * @property {number} averageJobValue
 * @property {number} averageInstallsPerMonth
 * @property {string} jobType
 * @property {string} tradeAssociation
 * @property {string} id
 * @property {string} userId
 */

export const TradeInfoService = {
  add: 'TradeInfoService-add',
  retrieve: 'TradeInfoService-retrieve',
  update: 'TradeInfoService-update',
};

const TradeInfoServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {OmniServiceOptions} options
   */
  constructor({ baseURLS, authService }) {
    super({ authService, keys: TradeInfoService });

    this.baseURLS = baseURLS;
  }

  /**
   * Retrieve Trade infos
   * @returns {TradeInfoServiceData} trade info
   */
  async [TradeInfoService.retrieve]() {
    const result = await super.get(`/trade-info`, {
      baseURL: this.baseURLS.retrieve,
    });

    return camelize(result);
  }

  /**
   * Adds Trade infos
   * @param {TradeInfoServiceData} payload
   * @returns {TradeInfoServiceData} application status
   */
  async [TradeInfoService.add](payload) {
    const result = await super.post(`/trade-info`, decamelize(payload), {
      baseURL: this.baseURLS.add,
    });

    super.refetch([TradeInfoService.retrieve]);

    return camelize(result);
  }

  /**
   * Updates Trade infos
   * @param {TradeInfoServiceData} payload
   * @returns {TradeInfoServiceData} application status
   */
  async [TradeInfoService.update](payload) {
    const result = await super.patch(
      `/trade-info/${payload.id}`,
      decamelize(payload),
      {
        baseURL: this.baseURLS.update,
      },
    );

    super.refetch([TradeInfoService.retrieve]);

    return camelize(result);
  }
};

export default new TradeInfoServiceImplementation({
  baseURLS: {
    add: config.ADD_TRADE_INFO_URL,
    retrieve: config.RETRIEVE_TRADE_INFO_URL,
    update: config.UPDATE_TRADE_INFO_URL,
  },
  authService: KandaAuthService,
});
