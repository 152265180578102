import { useCallback } from 'react';

import { formatAddress } from '../../../../../CompanyLookup-functions';

const ContainerComponent = ({ children, handleSelect, company, isLoading }) => {
  /** 
    Handles select company
    @param {Event} e
  */
  const onSelect = useCallback(
    (e) => {
      e.stopPropagation();

      handleSelect(company);
    },
    [handleSelect, company]
  );

  const address = formatAddress(company);

  const className = isLoading ? 'mb-4' : 'mb-4 cursor-pointer';

  return children({
    className,
    onSelect,
    address,
  });
};

ContainerComponent.displayName = 'CompanyLookup-Mobile-Item-container';

export default ContainerComponent;
