import React from 'react';
import PropTypes from 'prop-types';

import Settings from './SettingsPopover';
import { CLASS_NAMES, BUTTON_PROPS } from './SettingsButton-constants';

import Button from '../../../Button';
import Popover from '../../../Popover';

const ViewComponent = ({ columns, setColumnOrder }) => (
  <div className={CLASS_NAMES.container}>
    <div className={CLASS_NAMES.fadeLeft} />
    <div className={CLASS_NAMES.button}>
      <div className={CLASS_NAMES.wrapper}>
        <Popover.Standard button={<Button.Icon {...BUTTON_PROPS} />} right>
          {() => <Settings columns={columns} setColumnOrder={setColumnOrder} />}
        </Popover.Standard>
      </div>
    </div>
    <div className={CLASS_NAMES.fadeRight} />
  </div>
);

ViewComponent.displayName = 'SettingsButton-view';

ViewComponent.defaultProps = {};

ViewComponent.propTypes = {
  /**
   * Table column data
   */
  columns: PropTypes.array.isRequired,
  /**
   * Table column data
   */
  setColumnOrder: PropTypes.func.isRequired,
};

export default ViewComponent;
