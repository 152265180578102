import PropTypes from 'prop-types';
import React from 'react';

import Context, { Provider } from './Context';
import Container from './Dropzone-container';
import { ICON_PROPS } from './Dropzone-constants';

import Icon from '../Icon';
import Text from '../Text';

const ViewComponent = ({
  className,
  children,
  accept,
  icon,
  text,
  ...dropzoneOptions
}) => (
  <Container accept={accept} {...dropzoneOptions}>
    {({ classNames, droppedFiles, parentDropZone, innerDropZone }) => (
      <Provider droppedFiles={[droppedFiles]}>
        <div {...parentDropZone.getRootProps()} className={className}>
          <input {...parentDropZone.getInputProps()} />
          {children}
          <div className={classNames.container}>
            <div
              className={classNames.content}
              {...innerDropZone.getRootProps()}
            >
              <input {...innerDropZone.getInputProps()} />
              <Icon icon={icon} {...ICON_PROPS} />
              <Text text={text} className={classNames.text} />
            </div>
          </div>
        </div>
      </Provider>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  accept: ['application/pdf'],
  children: null,
  className: 'flex flex-1 w-full',
  icon: 'file-upload',
  text: 'Drop PDF to upload as a new quote',
  onDrop: undefined,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Accept
   */
  accept: PropTypes.arrayOf(PropTypes.string),
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * icon
   */
  icon: PropTypes.string,
  /**
   * text
   */
  text: PropTypes.string,
  /**
   * On drop function
   */
  onDrop: PropTypes.func,
};

ViewComponent.displayName = 'Dropzone-view';

ViewComponent.Context = Context;

export default ViewComponent;
