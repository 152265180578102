const ContainerComponent = ({ children, headerGroup, allColumns }) => {
  const { headers, getHeaderGroupProps } = headerGroup;
  const headerGroupProps = getHeaderGroupProps({});

  const totalVisible = allColumns.filter((column) => column.isVisible).length;

  return children({
    headerGroupProps,
    headers,
    totalVisible,
  });
};

ContainerComponent.displayName = 'HeaderGroup-container';

export default ContainerComponent;
