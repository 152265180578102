import Cookies from 'universal-cookie';
import { addDays } from '@kanda-utils/library';

import { redirectTo, currentURL } from '../utils';
import { AuthService } from '../lib';
import config from '../config';
import { USER_REDIRECT_NAME } from '../config/application';

export const KandaAuthServiceImplementation = class extends AuthService {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.logout = this.logout.bind(this);
    this.cookies = new Cookies();
  }

  /**
   * Returns current tooken from cookes
   */
  async token() {
    return this.cookies.get(config.USER_COOKIE_NAME);
  }

  /**
   * Set current token
   * @param {string} token user token
   * @param {boolean} persist persistent login state, default false
   */
  async setToken(token, persist = false) {
    const expiry = persist
      ? addDays(new Date(), config.COOKIE_PERSIST_TIME)
      : undefined;

    const options = {
      ...config.COOKIE_OPTIONS,
      ...(expiry && { expires: expiry }),
    };

    return this.cookies.set(config.USER_COOKIE_NAME, token, options);
  }

  /**
   * Calls logout on auth error
   */
  async onAuthError() {
    await this.logout(true);
  }

  /**
   * Redirect to logout page when logout method is called
   */
  async logout(redirectToPrevious) {
    await super.logout();
    this.cookies.remove(config.USER_COOKIE_NAME, config.COOKIE_OPTIONS);

    if (redirectToPrevious) {
      /**
       * We are using localStorage for now since everyting is under one domain
       * This could be swiched to be using cookies if there is an issues with redirect in the future
       */
      localStorage.setItem(USER_REDIRECT_NAME, currentURL());
    }

    redirectTo(config.LOGIN_URL);
  }
};

export const KandaAuthService = new KandaAuthServiceImplementation();
