export const JOB_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
};

export const JOB_STATUSES = {
  DRAFT: 'DRAFT',
  REVOKED: 'REVOKED',
  SENT_TO_CUSTOMER: 'SENT_TO_CUSTOMER',
  DECLINED: 'DECLINED',
  ACCEPTED: 'ACCEPTED',
  FINISHED: 'FINISHED',
  CLOSED: 'CLOSED',
};

export const JOB_LABELS = {
  DRAFT: {
    label: 'draft',
    color: 'grey',
  },
  REVOKED: {
    label: 'cancelled',
    color: 'violet',
  },
  SENT_TO_CUSTOMER: {
    label: 'sent to customer',
    color: 'turquoise',
  },
  DECLINED: {
    label: 'declined',
    color: 'violet',
  },
  ACCEPTED: {
    label: 'accepted',
    color: 'turquoise',
  },
  FINISHED: {
    label: 'completed',
    color: 'turquoise',
  },
  CLOSED: {
    label: 'archived',
    color: 'grey',
  },
};

export const JOB_FINANCE_STATUSES = {
  OFFERED: 'OFFERED',
  CREATED: 'CREATED',
  INITIATED: 'INITIATED',
  CREDIT_CHECK_REFERRED: 'CREDIT CHECK REFERRED',
  PRE_DECLINED: 'PRE DECLINED',
  CREDIT_CHECK_DECLINED: 'CREDIT CHECK DECLINED',
  SIGN_DOCUMENTS: 'SIGN DOCUMENTS',
  APPROVED: 'APPROVED',
  AWAITING_FULFILMENT: 'AWAITING FULFILMENT',
  ORDER_FULFILLED: 'ORDER FULFILLED',
  COMPLETE: 'COMPLETE',
  APPLICATION_LAPSED: 'APPLICATION LAPSED',
  CDS_NOTE_REVIEW: 'C.D.S. NOTE REVIEW',
  CDS_NOTE_REQUIRED: 'C.D.S. NOTE REQUIRED',
  CUSTOMER_ACTION_REQUIRED: 'CUSTOMER ACTION REQUIRED',
  VERIFY_DOCUMENT: 'VERIFY DOCUMENT',
  ORDER_CANCELLED: 'ORDER CANCELLED',
  FINANCE_OFFER_WITHDRAWN: 'FINANCE OFFER WITHDRAWN',
  OFFER_REFUNDED: 'OFFER REFUNDED',
};

export const JOB_FINANCE_LABELS = {
  SENT: {
    label: 'sent to customer',
    color: 'turquoise',
  },
  APPLIED: {
    label: 'applied for finance',
    color: 'blue',
  },
  REJECTED: {
    label: 'finance not approved',
    color: 'violet',
  },
  APPROVED: {
    label: 'financed',
    color: 'turquoise',
  },
  REVOKED: {
    label: 'finance revoked',
    color: 'grey',
  },
};

export const JOB_STATUS_FINANCE_LABEL = {
  OFFERED: JOB_FINANCE_LABELS.SENT,
  CREATED: JOB_FINANCE_LABELS.SENT,
  INITIATED: JOB_FINANCE_LABELS.APPLIED,
  CREDIT_CHECK_REFERRED: JOB_FINANCE_LABELS.APPLIED,
  PRE_DECLINED: JOB_FINANCE_LABELS.REJECTED,
  CREDIT_CHECK_DECLINED: JOB_FINANCE_LABELS.REJECTED,
  SIGN_DOCUMENTS: JOB_FINANCE_LABELS.APPLIED,
  APPROVED: JOB_FINANCE_LABELS.APPROVED,
  AWAITING_FULFILMENT: JOB_FINANCE_LABELS.APPROVED,
  ORDER_FULFILLED: JOB_FINANCE_LABELS.APPROVED,
  COMPLETE: JOB_FINANCE_LABELS.APPROVED,
  APPLICATION_LAPSED: JOB_FINANCE_LABELS.REJECTED,
  CDS_NOTE_REVIEW: JOB_FINANCE_LABELS.APPROVED,
  CDS_NOTE_REQUIRED: JOB_FINANCE_LABELS.APPROVED,
  CUSTOMER_ACTION_REQUIRED: JOB_FINANCE_LABELS.APPLIED,
  VERIFY_DOCUMENT: JOB_FINANCE_LABELS.APPLIED,
  ORDER_CANCELLED: JOB_FINANCE_LABELS.REVOKED,
  FINANCE_OFFER_WITHDRAWN: JOB_FINANCE_LABELS.REVOKED,
  OFFER_REFUNDED: JOB_FINANCE_LABELS.REVOKED,
};
