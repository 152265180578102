import React from 'react';
import PropTypes from 'prop-types';

import Mobile from './Mobile';
import Desktop from './Desktop';
import { Provider } from './Context';

import BreakPoints from '../BreakPoints';

const ViewComponent = (props) => (
  <Provider {...props}>
    <BreakPoints desktop={<Desktop />} mobile={<Mobile />} />
  </Provider>
);

ViewComponent.displayName = 'LineItem-view';

ViewComponent.defaultProps = {
  isLoading: undefined,
  item: undefined,
  variant: 'job-details',
};

ViewComponent.propTypes = {
  /**
   * Name of field
   */
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    vat: PropTypes.string,
  }),
  /**
   * Is loading
   */
  isLoading: PropTypes.bool,
  /**
   * Variant
   */
  variant: PropTypes.oneOf(['job-details', 'customer-job-view', 'job-summary']),
};

export default ViewComponent;
