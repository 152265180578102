export const PLACE_HOLDER_CLASS_NAMES = {
  baseContainer:
    'w-full h-full flex flex-1 flex-col bg-placeholder-card bg-100 relative',
  baseWrapper: 'relative w-full h-full flex flex-1 flex-col items-center',
  baseBackground:
    'absolute w-full h-2/6 bottom-0 z-2 bg-gradient-to-b from-transparent-white',
  baseEffect:
    ' w-full h-2/3 flex flex-col items-center justify-center bg-gradient-radial to-transparent-white',
  content: 'justify-items-center flex-col p-8',
  title: 'text-style-d-em text-neutral-500 flex flex-1 text-center',
  cta: 'mt-6 flex items-center justify-center',
};
