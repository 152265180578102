import React from 'react';

import { CLASS_NAMES } from './Indicator-constants';
import Container from './Indicator-container';

const ViewComponent = () => (
  <Container>
    {({ items }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.content}>
          {items.map((className, i) => (
            <div key={String(i)} className={className} />
          ))}
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Indicator-view';

export default ViewComponent;
