import React from 'react';
import PropTypes from 'prop-types';

import Container from './Select-container';

import RadioSelect from '../../../RadioSelect';

const ViewComponent = ({ handleClose, ...restProps }) => (
  <Container handleClose={handleClose} {...restProps}>
    <RadioSelect {...restProps} />
  </Container>
);

ViewComponent.displayName = 'SelectionModal-Select-view';

ViewComponent.defaultProps = {
  options: [],
  multiple: undefined,
};

ViewComponent.propTypes = {
  /**
   * Handle close function
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * Name of the input required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * Enables multi option select
   */
  multiple: PropTypes.bool,
  /**
   * Select options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default ViewComponent;
