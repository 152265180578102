export const USER_SERVICE_PROFILE_QUERY = `
  {
    profile {
      id
      first_name
      last_name
      username
      user_type
      address
      address_line_two
      city
      postal_code
      phone
      company_logo
      company_name
      company_number
      company_type
      vat_number
      account_name
      account_number
      sort_code
      trade_type
      zero_finance_enabled
      has_done_zero_fin_application
      onboarded
    }
  }
`;

// export const USER_SERVICE_PROFILE_QUERY = `
//   {
//     profile {
//       id
//       first_name
//       last_name
//       username
//       user_type
//       address
//       address_line_two
//       city
//       postal_code
//       phone
//       company_logo
//       company_name
//       company_number
//       company_type
//       vat_number
//       account_name
//       account_number
//       sort_code
//       trade_type
//       zero_finance_enabled
//       has_done_zero_fin_application
//       onboarded
//     }
//     subscription {
//       status
//       tier
//       plan
//       renewal_date
//       created_at
//       updated_at
//     }
//   }
// `;
