import * as api from './api';
import * as application from './application';
import * as integrations from './integrations';
import * as enterprise from './enterprise';
import * as firebase from './firebase';
import * as staff from './staff';
import * as hub from './hub';

const Default = {
  ...api,
  ...application,
  ...integrations,
  ...enterprise,
  ...firebase,
  ...staff,
  ...hub,
};

export default Default;
