import { useState } from 'react';

import useModals from './ModalsWrapper-useModals';
import useAnimatedModals from './ModalsWrapper-useAnimatedModals';
import useViewportVariables from './ModalsWrapper-useViewportVariables';

const ContainerComponent = ({ children }) => {
  useViewportVariables();

  const [showLoader, setShowLoader] = useState(false);

  const contextValues = useModals();
  const animatedContextValues = useAnimatedModals();

  return children({
    ...contextValues,
    ...animatedContextValues,
    showLoader,
    setShowLoader,
  });
};

ContainerComponent.displayName = 'ModalsWrapper-container';

export default ContainerComponent;
