import { INITIAL_STATE } from './ToastContainer-constants';

const ContainerComponent = ({ children, ...props }) => {
  const state = {
    ...INITIAL_STATE,
    ...props,
  };

  return children({
    state,
  });
};

ContainerComponent.displayName = 'Toast-NonField-container';

export default ContainerComponent;
