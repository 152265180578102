import React from 'react';
import PropTypes from 'prop-types';

import Container from './ModalLayoutSlideUp-container';
import { VARIANTS, SPACE } from './ModalLayoutSlideUp-constants';

import Button from '../../../Button';
import { ReactComponent as Lines } from '../../../../assets/images/marketing-slide-up-header.svg';

const ViewComponent = React.forwardRef(
  ({ children, variant, onClose }, ref) => (
    <Container variant={variant}>
      {({ classNames, linesVisible }) => (
        <div className={classNames.container} ref={ref}>
          <div className={classNames.bg}>
            {linesVisible && <Lines className="w-full" />}
          </div>
          <img alt="" src={SPACE} className={classNames.space} />
          {onClose && (
            <div className={classNames.header}>
              <Button.Icon onClick={onClose} icon="close" />
            </div>
          )}
          <div className={classNames.wrapper}>{children}</div>
        </div>
      )}
    </Container>
  )
);

ViewComponent.displayName = 'ModalLayoutSlideUp-view';

ViewComponent.defaultProps = {
  children: null,
  onClose: undefined,
  variant: 'DEFAULT',
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * on close function
   */
  onClose: PropTypes.func,
  /**
   * variant
   */
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
};

export default ViewComponent;
