import React from 'react';
import PropTypes from 'prop-types';

import Container from './Provider-container';

import Context from '../context';

const ViewComponent = ({ children, ...props }) => (
  <Container {...props}>
    {(values) => <Context.Provider value={values}>{children}</Context.Provider>}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
  job: null,
};

ViewComponent.displayName = 'QuoteDownload-Provider-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Kanda `job` object
   */
  job: PropTypes.object,
};

export default ViewComponent;
