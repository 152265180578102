import React from 'react';
import PropTypes from 'prop-types';

import Container from './Button-container';

import Button from '../../../../../Button';

const ViewComponent = ({
  forwardRef,
  error,
  className: extClassName,
  isLoading,
  buttonText,
  ...restProps
}) => (
  <Container error={error} className={extClassName}>
    {({ className }) => (
      <div className={className}>
        <Button.Link
          {...restProps}
          ref={forwardRef}
          isLoading={isLoading}
          variant="grey"
          size={12}
          icon="chevron-down"
          label={buttonText}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'SelectionModal-Button-view';

ViewComponent.defaultProps = {
  id: undefined,
  error: undefined,
  className: undefined,
  isLoading: undefined,
  buttonText: undefined,
  onClick: undefined,
  forwardRef: undefined,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Text to display on button
   */
  buttonText: PropTypes.string,
  /**
   * Button onclick function to display modal
   */
  onClick: PropTypes.func,
  /**
   * Forwared ref from parent component
   */
  forwardRef: PropTypes.any,
};

export default ViewComponent;
