import { KandaAuthService } from './kandaAuthService';
import { JobService } from './jobService';

import config from '../config';
import { FileService } from '../lib';
import { camelize, decamelize } from '../utils';

export const JobQuoteUploadService = {
  upload: 'JobQuoteUploadService-upload',
};

/**
 * Job quote upload service
 * @typedef {Object} JobQuoteUploadServiceOptions
 * @property {string} baseURL base url
 * @property {Object} authService authService
 */
const JobQuoteUploadImplementation = class extends FileService {
  /**
   * Constructor
   * @param {UserCompanyLogoServiceOptions} options
   */
  constructor({ baseURL, authService }) {
    super({ baseURL, authService, keys: JobQuoteUploadService });
  }

  /**
   * Fatches signed url
   * @param {string} fileName
   */
  async getSignedUrl(_, { jobId, fileType }) {
    const data = await this.post(`/uploads/${jobId}`, decamelize({ fileType }));

    return data.upload_url;
  }

  /**
   * Uploads file
   * @param {string} jobId
   * @param {File} file
   * @param {function} onProgress
   */
  async [JobQuoteUploadService.upload](
    jobId,
    file,
    fileType = 'pdf',
    onProgress,
  ) {
    const res = await super.upload(file, onProgress, { jobId, fileType });

    await super.refetch([JobService.retrieve, jobId]);

    return camelize(res);
  }
};

export default new JobQuoteUploadImplementation({
  baseURL: config.JOB_QUOTE_UPLOAD_URL,
  authService: KandaAuthService,
});
