import useVariants from '../variants';
import { formatObject } from './classes-functions';

const useClasses = (constants = {}, extend = {}) => {
  const { variants, ...extendClassNames } = extend;

  const formattedConstants = formatObject(constants, useVariants(variants));

  return formatObject(extendClassNames, variants, formattedConstants);
};

export default useClasses;
