import PropTypes from 'prop-types';
import React from 'react';

import Container from './Item-container';

import Icon from '../../../Icon';

const ViewComponent = ({ name, icon, size, arrow, selected, ...restProps }) => (
  <Container size={size} selected={selected}>
    {({ classNames, iconProps, arrowIconProps }) => (
      <button className={classNames.container} {...restProps}>
        <Icon icon={icon} {...iconProps} />
        <div className={classNames.name}>{name}</div>
        {arrow && <Icon {...arrowIconProps} />}
      </button>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  arrow: false,
  selected: false,
};

ViewComponent.displayName = 'PopoverMenu-Item-view';

ViewComponent.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  arrow: PropTypes.bool,
  selected: PropTypes.bool,
};

export default ViewComponent;
