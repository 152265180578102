import { SignedRequestService } from './signedRequestService';
import { OmniAuthService } from './omniAuthService';

import config from '../config';
import { Service } from '../lib';
import { camelize, decamelize } from '../utils';

/**
 * Omni Service
 * @typedef {Object} OmniServiceOptions
 * @property {String} baseURL base url
 * @property {Object} authService authService
 *
 * OmniObject
 * @typedef {Object} OmniObject
 * @property {String} key key
 * @property {Object} value value
 *
 * OmniDocumentObject
 * @typedef {Object} OmniDocumentObject
 * @property {String} directDebitRefXXXPdf base64 PDF string
 * @property {String} creditAgreementRefXXXPdf base64 PDF string
 * @property {String} preContractExplanationRefXXXPdf base64 PDF string
 *
 * OmniSignedDocumentObject
 * @typedef {Object} OmniDocumentObject
 * @property {String} directDebitRefXXXPdf base64 PDF string
 * @property {String} creditAgreementRefXXXPdf base64 PDF string
 *
 * InitialData
 * @typedef {Object} OmniInitialData
 * @property {Array.OmniObject} rateCardProducs
 * @property {Array.OmniObject} titles
 * @property {Array.OmniObject} genders
 * @property {Array.OmniObject} maritalStatuses
 * @property {Array.OmniObject} yearsAtAddress
 *
 * ApplicationStatus
 * @typedef {Object} OmniApplicationStatus
 * @property {Boolean} isSigned
 * @property {String} status
 *
 * ApplicationDocumentsToSign
 * @typedef {Object} OmniApplicationDocumentsToSign
 * @property {Object.OmniDocumentObject} documents
 * @property {Integer} returnedDocumentsFormat
 * @property {GUID} loanApplicationId
 *
 * ApplicationSignedDocuments
 * @typedef {Object} OmniApplicationSignedDocuments
 * @property {Object.OmniSignedDocumentObject} documents
 * @property {Integer} returnedDocumentsFormat
 * @property {GUID} loanApplicationId
 */

export const OmniService = {
  initialData: 'OmniService-initialData',
  applicationStatus: 'OmniService-applicationStatus',
  getDocumentsToSign: 'OmniService-getDocumentsToSign',
  signDocuments: 'OmniService-signDocuments',
  deposit: 'OmniService-deposit',
};

const OmniServiceImplementation = class extends Service {
  /**
   * Constructor
   * @param {OmniServiceOptions} options
   */
  constructor({ baseURLS, authService }) {
    super({ authService, keys: OmniService });

    this.baseURLS = baseURLS;
  }

  /**
   * Retrieve omni initial data
   * @returns {OmniInitialData} initial data
   */
  async [OmniService.initialData]() {
    const result = await super.get('/api/Retailer/CreditCheck/InitialData', {
      baseURL: this.baseURLS.omni,
    });

    return camelize(result);
  }

  /**
   * Retrieve omni application status
   * @param {String} applicationId
   * @returns {OmniApplicationStatus} application status
   */
  async [OmniService.applicationStatus](applicationId) {
    const result = await super.get(
      `/api/LoanApplication/Status/${applicationId}`,
      {
        baseURL: this.baseURLS.omni,
      },
    );

    return camelize(result);
  }

  /**
   * Retrieve omni documents to sign
   * @param {String} applicationId
   * @returns {OmniApplicationDocumentsToSign} application status
   */
  async [OmniService.getDocumentsToSign](applicationId) {
    const result = await super.get(
      `/api/LoanApplication/Documents/${applicationId}/1`,
      {
        baseURL: this.baseURLS.omni,
      },
    );

    return camelize(result);
  }

  /**
   * Sign Omni documents
   * @param {String} applicationId
   * @returns {OmniApplicationSignedDocuments} application status
   */
  async [OmniService.signDocuments](applicationId) {
    const payload = {
      ReturnSignedDocuments: true,
      DocumentsFormat: 1,
      LoanApplicationId: applicationId,
    };

    const result = await super.put('/api/LoanApplication/Sign', payload, {
      baseURL: this.baseURLS.omni,
    });

    return camelize(result);
  }

  /**
   * Sign Omni documents
   * @param {String} applicationId
   * @returns {OmniApplicationSignedDocuments} application status
   */
  async [OmniService.deposit](applicationId, enterpriseId) {
    const body = {
      enterprise: {
        id: enterpriseId,
      },
      data: {
        loan_application_id: applicationId,
      },
    };

    const signedRequest = await super.use([SignedRequestService.encode, body]);

    const result = await super.post('/deposit', decamelize({ signedRequest }), {
      baseURL: this.baseURLS.deposit,
    });

    return camelize(result);
  }
};

export default new OmniServiceImplementation({
  baseURLS: {
    omni: config.OMNI_API_URL,
    deposit: config.OMNI_DEPOSIT_API_URL,
  },
  authService: OmniAuthService,
});
