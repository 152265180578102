import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import TextareaAutosize from 'react-textarea-autosize';

import Container from './AutoSizeTextArea-container';

const ViewComponent = ({
  forwardRef,
  error,
  className: extClassName,
  isLoading,
  disableNewLine,
  collapsible,
  onBlur: extOnBlur,
  defaultValue,
  ...restProps
}) => (
  <Container
    error={error}
    className={extClassName}
    disableNewLine={disableNewLine}
    defaultValue={defaultValue}
    collapsible={collapsible}
    onBlur={extOnBlur}
  >
    {({
      className,
      skeletonClasses,
      onKeyDown,
      onBlur,
      value,
      showPlaceholder,
      placeholderClassName,
    }) => (
      <React.Fragment>
        {isLoading ? (
          <div className={className}>
            <div className={skeletonClasses}>
              <Skeleton />
            </div>
          </div>
        ) : (
          <div className="flex flex-row relative w-full overflow-hidden">
            {showPlaceholder && (
              <span className={placeholderClassName}>{value}</span>
            )}
            <TextareaAutosize
              className={className}
              ref={forwardRef}
              onKeyDown={onKeyDown}
              {...restProps}
              defaultValue={defaultValue}
              onBlur={onBlur}
            />
          </div>
        )}
      </React.Fragment>
    )}
  </Container>
);

ViewComponent.displayName = 'AutoSizeTextArea-view';

ViewComponent.defaultProps = {
  id: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  forwardRef: undefined,
  onChange: undefined,
  error: undefined,
  className: undefined,
  onBlur: undefined,
  isLoading: undefined,
  disableNewLine: undefined,
  collapsible: undefined,
};

ViewComponent.propTypes = {
  /**
   * ClassName
   */
  className: PropTypes.string,
  /**
   * Name of the textarea required for form to work
   */
  name: PropTypes.string.isRequired,
  /**
   * The HTML element ID.
   */
  id: PropTypes.string,
  /**
   * Text that would be displayed when there is no value
   */
  placeholder: PropTypes.string,
  /**
   * Default value of input
   */
  defaultValue: PropTypes.any,
  /**
   * Forwared ref from parent component
   */
  forwardRef: PropTypes.any,
  /**
   * This function is triggered when the input is changed
   */
  onChange: PropTypes.func,
  /**
   * This function is triggered when the input is unfocused
   */
  onBlur: PropTypes.func,
  /**
   * Field error message
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
  /**
   * Display Loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Display new line on enter
   */
  disableNewLine: PropTypes.bool,
  /**
   * Collapses to one line when is defocused
   */
  collapsible: PropTypes.bool,
};

export default ViewComponent;
